import React, { Component } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Icon } from '@mui/material';
import Box from '@mui/material/Box';

import ComperatoreEnergiaBusinessPopup from './dialog/comperatoreEnergiaBusinessPopup';

import { updateComperatoreEnergia, removeBta } from '../../service';

import store from '../../store';
import { OPEN_NOTIFICATION } from '../../actions/actions';

import RemoveDialog from '../dialogs/removePopup';

import './comperatore.scss';


class ComperatoreEnergiaBusiness extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openPopup: false,
            bta: this.props.bta,
            perdite_di_rete: this.props.comperatoreEne ? this.props.comperatoreEne.perdite_di_rete : "",
            selectedItem: null,
            statusRemovePopup: false
        }

        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);

        this.openRemovePopup = this.openRemovePopup.bind(this);
        this.closeRemovePopup = this.closeRemovePopup.bind(this);

        this.addBta = this.addBta.bind(this);
        this.updateBta = this.updateBta.bind(this);
        this.removeItem = this.removeItem.bind(this);

        this.updateComperatoreEnergia = this.updateComperatoreEnergia.bind(this);
    }

    addBta(data) {
        this.setState(prevState => ({
            bta: [...prevState.bta, data]
        }))
    }

    updateBta(data) {
        const bta = this.state.bta.map(x => {
            if (x.id != data.id)
                return x;
            x = data;
            return x;
        });

        this.setState({ bta });
    }

    openPopup(data) {
        if (data) {
            this.setState({ selectedItem: data });
        }
        this.setState({ openPopup: true });
    }

    closePopup() {
        this.setState({ selectedItem: null })
        this.setState({ openPopup: false });
    }


    // remove popup
    openRemovePopup(data) {
        if (data) {
            this.setState({ selectedItem: data });
        }
        this.setState({ statusRemovePopup: true });
    }

    closeRemovePopup() {
        this.setState({ selectedItem: null })
        this.setState({ statusRemovePopup: false });
    }

    removeItem() {
        const {
            selectedItem,
            bta
        } = this.state;

        this.closeRemovePopup();

        const updatedBta = bta.filter(x => x.id != selectedItem.id)
        this.setState({ bta: updatedBta });
        this.props.updateBta(updatedBta);
        
        removeBta(selectedItem.id).then(result => {
            store.dispatch({ type: OPEN_NOTIFICATION, text: 'Bta removed', open: true });
            console.log("RESULT DATA ", result.data)
        })

    }


    updateComperatoreEnergia() {
        const {
            perdite_di_rete,
        } = this.state;

        const data = { perdite_di_rete };

        updateComperatoreEnergia(data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Perdite di rete was updated', open: true});
            this.props.update(result.data.comperatore);
        })
    }

    render() {
        const {
            openPopup,
            perdite_di_rete,
            bta,
            selectedItem,
            statusRemovePopup
        } = this.state;

        return (
            <div>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {bta &&
                            bta.map(bta => (
                                <div className="comperatore__btaWrapper">
                                    <div className="comperatore__btaActionButtons">
                                        {/* <Tooltip title='Edit'><div onClick={() => this.openPopup(bta)} className='comperatore__actionBtn' variant="fab" mini aria-label="View"><Icon fontSize='small'>edit</Icon></div></Tooltip>
                                        <Box mt={1}>
                                            <Tooltip title='Delete'><div onClick={() => this.openRemovePopup(bta)} className='comperatore__actionBtn--removeBtn' variant="fab" mini aria-label="View"><Icon fontSize="small">delete</Icon></div></Tooltip>
                                        </Box> */}

                                        <Tooltip title='Edit'><Button onClick={() => this.openPopup(bta)} className='actionBtn actionBtn--edit' variant="fab" mini aria-label="Edit"><Icon className='editIcon'>edit_icon</Icon></Button></Tooltip>
                                        <Box mt={1}><Tooltip title='Delete'><Button onClick={() => this.openRemovePopup(bta)} className='actionBtn actionBtn--remove' variant="fab" mini aria-label="Remove"><Icon className='offer_icon removeIcon'>delete</Icon></Button></Tooltip></Box>


                                    </div>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: 120 }}>
                                        <div className="comperatore__btaLabel">Code</div>
                                        <div className="comperatore__btaText">{bta.code}</div>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <Box>
                                            <div className="comperatore__btaTitle">Energia e commercializzazione</div>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">(€/kWh)</div>
                                                    <div className="comperatore__btaText">{bta.ene_kwh}</div>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">(€/kW)</div>
                                                    <div className="comperatore__btaText">{bta.ene_kw}</div>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">PCV (€/POD)</div>
                                                    <div className="comperatore__btaText">{bta.ene_pcv}</div>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">DISPbt (€/POD)</div>
                                                    <div className="comperatore__btaText">{bta.ene_disp}</div>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <hr className="comperatore__separator"></hr>

                                        <Box>
                                            <div className="comperatore__btaTitle">Rete e oneri di sistema</div>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">var reti (€/kWh)</div>
                                                    <div className="comperatore__btaText">{bta.rete_reti}</div>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">var sist (€/kWh) </div>
                                                    <div className="comperatore__btaText">{bta.rete_sist}</div>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">(€/kW)</div>
                                                    <div className="comperatore__btaText">{bta.rete_kw}</div>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">reti (€/POD)</div>
                                                    <div className="comperatore__btaText">{bta.rete_reti_pod}</div>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">oneri (€/POD) </div>
                                                    <div className="comperatore__btaText">{bta.rete_oneri}</div>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <hr className="comperatore__separator"></hr>

                                        <div>
                                            <div className="comperatore__btaTitle">Imposte</div>
                                            <Box sx={{ display: 'flex' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">(€/kWh)</div>
                                                    <div className="comperatore__btaText">{bta.imposte_kwh}</div>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: 150 }}>
                                                    <div className="comperatore__btaLabel">(€/kW)</div>
                                                    <div className="comperatore__btaText">{bta.imposte_kw}</div>
                                                </Box>
                                            </Box>
                                        </div>

                                    </Box>





                                </div>
                            ))

                        }
                    </Box>
                    <Box sx={{ whiteSpace: 'nowrap' }}> <Button className='comperatore__addBtaBtn' onClick={this.openPopup}>+ Add New</Button></Box>
                </Box>
                <Box sx={{ mt: 5 }}>
                    <TextField
                        style={style.input}
                        type="number"
                        defaultValue="1"
                        label="Perdite di rete %"
                        margin="normal"
                        size='small'
                        variant="outlined"
                        value={perdite_di_rete}
                        onChange={event => this.setState({ 'perdite_di_rete': event.target.value })}

                    />

                    <Box textAlign='center' mt={5} className='comperatore__updateBtn'>
                        <Button variant="contained" onClick={this.updateComperatoreEnergia}>
                            Update
                        </Button>
                    </Box>
                </Box>

                {statusRemovePopup && <RemoveDialog remove={this.removeItem} open={statusRemovePopup} close={this.closeRemovePopup}/> }

                {openPopup && <ComperatoreEnergiaBusinessPopup
                    addBta={this.addBta}
                    updateBta={this.updateBta}
                    open={openPopup}
                    handleClose={this.closePopup}
                    selectedItem={selectedItem}
                />
                }

            </div>
        )
    }
}


export default ComperatoreEnergiaBusiness;

const style = {
    input: {
        width: '250px',
        marginRight: '25px',
    }
}