import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/App.js';
import store from './store';
import reportWebVitals from './reportWebVitals';
import {GoogleOAuthProvider} from "@react-oauth/google";

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId='189062098162-9gh56buubhni49rrqorkgi7mvg5islv9.apps.googleusercontent.com'>
      <App/>
    </GoogleOAuthProvider>
  </Provider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
