import React, { Component } from 'react';
import { Button, Icon } from '@material-ui/core';
import { Link, Route, HashRouter, Switch, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';

import Menu from '../menu/menu';
import Home from '../home/home.js';
import Offer from '../offer/offer';
import HowTo from '../howTo/howTo';
import Suplier from '../supplier/supplier';
import Proposal from '../proposal/proposal';
import Operation from '../operation/Operation';
import Scripts from '../scripts';
import Numbers from '../numbers';
import Loading from '../loading/loading';
import PriceTable from '../priceTable/priceTable';
import GestionePause from './../../pages/gestione/gestione.js'

import Comperatore from '../comperatore/comperatore';
import ComperatoreAdmin from '../comperatoreAdmin/comperatoreAdmin';

import TelcoHome from '../../pages/telcoHome/telcoHome';
import TelcoScripts from '../../pages/telcoScripts';
import TelcoNumbers from '../../pages/telcoNumbers'
import TelcoPriceTable from '../../pages/telcoPriceTable/priceTable';
import TelcoOffer from '../../pages/telcoOffer/telcoOffer';
import TelcoOperation from '../../pages/telcoOperation/operation';
import TelcoProposal from '../../pages/telcoProposal/proposal';
import TelcoSuplier from '../../pages/telcoSupplier/supplier';

import Users from '../users/users';
import ManageFields from '../manageFields/manageFields';

// actions 
import {
    OPEN_NOTIFICATION,
    CLOSE_NOTIFICATION,
    OPEN_HELP,
    CLOSE_HELP
} from '../../actions/actions';

// styles
import mainStyle from '../mainStyle';

import Notification from '../notification';
import Remi from '../menu/remi/remi';

const mapStateToProps = state => ({
    ...state,
    open: state.open,
    text: state.text,
    menuType: state.menu.menuType,
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    openNotification: text => {
        return dispatch({type: OPEN_NOTIFICATION, text})
    },
    closeNotification: () => {
        return dispatch({type: CLOSE_NOTIFICATION})
    },
    openHelp: () => {
        return dispatch({ type: OPEN_HELP });
    },
    closeHelp: () => {
        return dispatch({ type: CLOSE_HELP });
    }
})


class Content extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            open,
            text,
        } = this.props.notification;

        const {
            menuType
        } = this.props.menuType;


        const { 
            open: openLoad,
            text: textLoad
        } = this.props.loading;

        const {
            open: openHelpSection,
            component: comp
        } = this.props.help;

        const {
            auth: { access, role }
        } = this.props;

        return(
            <div style={ mainStyle.container }>
                <Loading text={textLoad} open={openLoad} />
                <HowTo open={openHelpSection} component={comp} close={this.props.closeHelp}/>
                <Notification text={text} close={this.props.closeNotification} open={open}/>
                <Menu path={ this.props.location.pathname } />
                <Button 
                    style={{ position: 'absolute', right: '20px', top: '20px', backgroundColor: '#eea53f', padding: '10px', borderRadius: '10px', color: 'white', zIndex: 10000}}
                    onClick={() => {
                        window.open('https://docs.google.com/forms/d/e/1FAIpQLSfoqmhtMtnyxqRS-AHf212JIS2JJ6yQ00L30Yhk8Xu1gNhDkQ/viewform?usp=sf_link', '_blank');
                    }}
                    >
                        Aiutaci a migliorare FormOp
                </Button>
                <div style={ mainStyle.content }>
                    <HashRouter>

                        {/* {menuType === "energia" || !menuType */}
                        {/* ?  */}
                        { access === 'dual' && 
                            <Switch>
                                
                                <Route path='/pause' component={ GestionePause }/>
                                <Route path='/energia/offers' component={ Offer }/>
                                <Route path='/energia/prices'  component={ PriceTable }/>
                                <Route path='/energia/suppliers'  component={ Suplier }/>
                                <Route path='/energia/proposals' component={ Proposal }/>
                                <Route path='/energia/operations'  component={ Operation }/>
                                <Route path='/energia/scripts'  component={ Scripts }/>
                                <Route path='/energia/comparatore'  component={ Comperatore }/>
                                <Route path='/energia/adminComparatore'  component={ ComperatoreAdmin }/>
                                <Route path='/energia/manageFields' component={ ManageFields }/>
                                <Route path='/energia/numbers'  component={ Numbers }/>
                                <Route path='/energia/users' component={ Users }/>
                                <Route path='/energia' component={ Home }/>

                                <Route path='/telco' exact component={ TelcoHome }/>
                                <Route path='/telco/scripts'  component={ TelcoScripts }/>
                                <Route path='/telco/numbers'  component={ TelcoNumbers }/>
                                <Route path='/telco/prices'  component={ TelcoPriceTable }/>
                                <Route path='/telco/offers'  component={ TelcoOffer }/>
                                <Route path='/telco/operations'  component={ TelcoOperation }/>
                                <Route path='/telco/proposals'  component={ TelcoProposal }/>
                                <Route path='/telco/users' component={ Users }/>
                                <Route path='/telco/suppliers'  component={ TelcoSuplier }/>
                                <Route path='/telco/manageFields' component={ ManageFields }/>

                                <Route component={ Home } />

                            </Switch>
                         }

                        { access === 'energia' && 
                            <Switch>
                                <Route path='/pause' component={ GestionePause }/>
                                <Route path='/energia/offers' component={ Offer }/>
                                <Route path='/energia/prices'  component={ PriceTable }/>
                                <Route path='/energia/suppliers'  component={ Suplier }/>
                                <Route path='/energia/proposals' component={ Proposal }/>
                                <Route path='/energia/operations'  component={ Operation }/>
                                <Route path='/energia/scripts'  component={ Scripts }/>
                                <Route path='/energia/comparatore'  component={ Comperatore }/>
                                <Route path='/energia/manageFields' component={ ManageFields }/>
                                <Route path='/energia/numbers'  component={ Numbers }/>
                                <Route path='/energia/users' component={ Users }/>
                                <Route path='/energia' component={ Home }/>

                                <Redirect to='/energia' />

                            </Switch>
                         }

                        { access === 'telco' && 
                            <Switch>
                                <Route path='/pause' component={ GestionePause }/>
                                <Route path='/telco' exact component={ TelcoHome }/>
                                <Route path='/telco/scripts'  component={ TelcoScripts }/>
                                <Route path='/telco/numbers'  component={ TelcoNumbers }/>
                                <Route path='/telco/prices'  component={ TelcoPriceTable }/>
                                <Route path='/telco/offers'  component={ TelcoOffer }/>
                                <Route path='/telco/operations'  component={ TelcoOperation }/>
                                <Route path='/telco/proposals'  component={ TelcoProposal }/>
                                <Route path='/telco/suppliers'  component={ TelcoSuplier }/>
                                <Route path='/telco/manageFields' component={ ManageFields }/>
                                <Route path='/telco/users' component={ Users }/>

                                <Redirect to='/telco' />

                            </Switch>
                         }
                        
                         {/* } */}
                    </HashRouter>
                </div>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Content);