import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

// table 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import { getComperatoreData } from '../../service';


const ComperatoreAgentEneDomestico = (props) => {


    const [monthNumber, setMonthNumber] = useState();
    const [contractType, setContractType] = useState();
    const [offerDetails, setOfferDetails] = useState();
    const [potenza, setPotenza] = useState();
    const [tariffType, setTariffType] = useState();
    const [f0, setF0] = useState(0);
    const [f1, setF1] = useState(0);
    const [f2, setF2] = useState(0);
    const [f3, setF3] = useState(0);
    const [calcResult, setCalcResult] = useState({});
    const [comperatoreEne, setComperatoreEne] = useState({});

    const [offers, setOffers] = useState([]);

    useEffect(() => {
        getComperatoreData().then(result => {
            const { comperatoreEne } = result.data.payload;

            for( let key in comperatoreEne) {
                if(key !== 'uda_imposte') {
                    if(listToTransform.some(k => k === key)) {
                        comperatoreEne[key] = comperatoreEne[key].replaceAll(',', '.')
                    }
                } else {
                    const imposteData = JSON.parse(comperatoreEne[key]);

                    imposteData.map(imp => {
                        imp.price = imp.price.replaceAll(',', '.')
                    })

                    comperatoreEne[key] = JSON.stringify(imposteData);
                }
                
            }
    
            setComperatoreEne(comperatoreEne);
        }).catch(err => {
              console.log("error", err)
        })
    }, [])

    useEffect(() => {
        if (!props.offers) return;

        let filteredOffers = props.offers.filter(offer => {

            const {
                client_type,
                type_product,
            } = offer;

            if (client_type === "resid" && type_product === "luce") return offer;

            // if (client_type === "business" && type_product === "luce") return offer;
            // if (client_type === "resid" && type_product === "gas") return offer;
            // if (client_type === "business" && type_product === "gas") return offer;
        })

        setOffers(filteredOffers);


        console.log("OFFERS", props.offers)
    }, [props.offers])

    useEffect(() => {
        const preparedData = {
            monthNumber,
            contractType,
            offerDetails,
            potenza,
            tariffType,
            f0,
            f1,
            f2,
            f3
        };

        if(!monthNumber || !contractType || !offerDetails || !potenza || !tariffType)
            return false;

        let result = {};

        switch(contractType) {
            case 'uda': 
                result = calculateResidenteUda(preparedData, comperatoreEne);
            break;
            case 'domestico_res': 
                result = calculateResidente(preparedData, comperatoreEne);
            break;
            case 'domestico_non_res': 
                result = calculateNonResidente(preparedData, comperatoreEne);
            break;
        }

        console.log(result);

        setCalcResult(result);

    }, [monthNumber, contractType, offerDetails, potenza, tariffType, f0, f1, f2, f3]);

    const {
        price_energia_resid = 0,
        price_pcv_netta = 0,
        price_total = 0,

        price_rate = 0,
        price_rate_kw = 0,
        price_rate_pcv = 0,
        totale_rate_moniraria = 0,


        total_oneri = 0,
        price_oneri = 0,
        price_oneri_pcv = 0,


        totale_senza_iva = 0,
        price_sum_anno = 0,
        totale_iva = 0,


        totale_senza_rai = 0,
        canone_rai = 0,
        totale_con_rai = 0,
        totale_base_annua = 0
    } = calcResult;

    const withCommma = (number) => {
        let result = number.toFixed(2).toString().replace(".", ",");
        return result
    } 

    return (
        <section className="comperatore__section">
            {/* <Box sx={{ display: 'flex', margin: '10px 0', flexWrap: 'wrap' }}> */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Numero mesi Bolletta
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={monthNumber}
                            label="Numero mesi Bolletta"
                            onChange={(e) => setMonthNumber(e.target.value)}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                        </Select>
                    </FormControl>
                </div>


                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Tipologia Contratto
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={contractType}
                            label="Tipologia Contratto"
                            onChange={(e) => setContractType(e.target.value)}
                        >
                            <MenuItem value={'domestico_res'}>Domestico residente</MenuItem>
                            <MenuItem value={'domestico_non_res'}>Domestico non residente</MenuItem>
                            <MenuItem value={'uda'}>UDA</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className='comperatore__selectInput'>
                    <FormControl fullWidth size="small">
                        <InputLabel
                            id="demo-simple-select-label">
                            Lista Offerte
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={offerDetails}
                            label="Lista Offerte"
                            onChange={(e) => setOfferDetails(e.target.value)}
                        >
                            {offers.length > 0 ? offers.map(offer => {
                                return (
                                    <MenuItem value={offer}>{offer.offer_name}</MenuItem>
                                )
                            }) : <MenuItem>No offers</MenuItem>}

                        </Select>
                    </FormControl>
                </div>
            </Box>
            {/* <Box sx={{ display: 'flex', alignItems: 'flex-end', margin: '10px 0'  }}> */}
                <TextField
                    type="number"
                    variant="standard"
                    className="comperatore__input--long"
                    label="Potenza Impegnata"
                    margin="normal"
                    value={potenza}
                    variant="outlined"
                    size="small"
                    onChange={(e) => setPotenza(e.target.value)}
                />
            {/* </Box> */}

            {/* <Box sx={{ margin: '10px 0' }}> */}
                <div className='comperatore__selectInput'>
                    <FormControl fullWidth size="small">
                        <InputLabel
                            id="demo-simple-select-label">
                            Tipo di Tariffa
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={tariffType}
                            label="Tipo di Tariffa"
                            onChange={(e) => setTariffType(e.target.value)}
                        >
                            <MenuItem value={'mono'}>Monoraria</MenuItem>
                            <MenuItem value={'bio'}>Bioraria</MenuItem>
                        </Select>
                    </FormControl>
                </div>

            {/* </Box> */}


            {/* This field should show only if Tipo di Tariffa chosen is Monoraria  */}
            {/* <Box sx={{ margin: '10px 0' }}> */}
                <TextField
                    type="number"
                    variant="outlined"
                    size='small'
                    className="comperatore__input--long"
                    label="Consumi Fatturati in F0"
                    margin="normal"
                    disabled={tariffType != "mono"}
                    value={f0}
                    onChange={(e) => setF0(e.target.value)}
                />
            {/* </Box> */}

            {/* This fields should show only if Tipo di Tariffa chosen is Bioraria  */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                    type="number"
                    variant="outlined"
                    size='small'
                    className="comperatore__input--long"
                    label="F1"
                    margin="normal"
                    disabled={tariffType != 'bio'}
                    value={f1}
                    onChange={(e) => setF1(e.target.value)}

                />
                <TextField
                    type="number"
                    variant="outlined"
                    size='small'
                    className="comperatore__input--long"
                    label="F2"
                    margin="normal"
                    disabled={tariffType != 'bio'}
                    value={f2}
                    onChange={(e) => setF2(e.target.value)}

                />
                <TextField
                    type="number"
                    variant="outlined"
                    size='small'
                    className="comperatore__input--long"
                    label="F3"
                    margin="normal"
                    disabled={tariffType != 'bio'}
                    value={f3}
                    onChange={(e) => setF3(e.target.value)}
                />
            </Box>

            <Box sx={{ margin: '50px 0', display: 'flex' }}>
            { monthNumber && contractType && offerDetails && tariffType &&  
                <Box sx={{ marginRight: '50px' }}>
                    <TableContainer component={Paper} sx={{ width: 400 }}>
                        <Table sx={{ minWidth: 250 }} size="small" aria-label="simple table">
                            <TableHead className="comperatore__tableHead">
                                <TableRow>
                                    <TableCell>Nome Offerta</TableCell>
                                    <TableCell align="right">{offerDetails ? offerDetails.offer_name : '-'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Costo medio materia energia
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(parseFloat(tariffType === 'mono' ? price_total / +f0 : price_total / (+f1 + +f2 + +f3)))}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='2'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Costo medio unitario della bolletta
                                    </TableCell>
                                    <TableCell align="right">€  {withCommma(parseFloat(tariffType === 'mono' ? totale_senza_rai / +f0 : totale_senza_rai / (+f1 + +f2 + +f3)))}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        PCV Mensile
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(parseFloat(offerDetails.price_pcv))}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                }

                { monthNumber && contractType && offerDetails && tariffType &&  
                <Box sx={{ marginRight: '50px' }}>
                    <TableContainer component={Paper} sx={{ width: 450 }}>
                        <Table sx={{ minWidth: 250 }} size="small" aria-label="simple table">
                            <TableHead className="comperatore__tableHead">
                                <TableRow>
                                    <TableCell>Dettaglio Della Bolletta</TableCell>
                                    <TableCell align="right">{offerDetails ? offerDetails.offer_name : '-'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key='1'
                                    className="accentRow--blue"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <span class="accentText">Spesa per la materia energia</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(price_total)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='2'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Quota energia (a consumo, in €/kWh)
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(price_energia_resid)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='3'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Quota fissa (in €/POD)
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(price_pcv_netta)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='4'
                                    className="accentRow--blue"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">Spesa per il trasporto e la gestione del contatore</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(totale_rate_moniraria)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Quota energia (a consumo €/kWh)
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(price_rate)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='5'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Quota potenza (in €/kW)
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(price_rate_kw)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='6'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Quota fissa (in €/POD)
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(price_rate_pcv)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='7'
                                    className="accentRow--blue"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">Spesa Per Oneri di Sistema</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(total_oneri)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='8'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Quota energia (a consumo)
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(price_oneri)}</TableCell>
                                </TableRow>
                                
                                { contractType != 'domestico_res' && <TableRow
                                    key='9'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Quota fissa (in €/POD)
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(price_oneri_pcv)}</TableCell>
                                </TableRow> }

                                <TableRow
                                    key='10'
                                    className="accentRow--orange"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">Totale senza IVA e Imposte</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(totale_senza_iva)}</span></TableCell>
                                </TableRow>

                                <TableRow
                                    key='11'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Imposte
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(price_sum_anno)}</TableCell>
                                </TableRow>

                                <TableRow
                                    key='12'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    IVA totale
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(totale_iva)}</TableCell>
                                </TableRow>

                                <TableRow
                                    key='13'
                                    className="accentRow--orange"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">{contractType == 'domestico_res' ? 'Totale bolletta senza canone rai' : 'Totale Bolletta'}</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(totale_senza_rai)}</span></TableCell>
                                </TableRow>

                                {contractType == 'domestico_res' && <TableRow
                                    key='14'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <b>Canone RAI</b>
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(canone_rai)}</TableCell>
                                </TableRow>
                                }

                                { contractType == 'domestico_res' && <TableRow
                                    key='15'
                                    className="accentRow--orange"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                     <span class="accentText">Totale Bolletta con canone RAI</span>
                                    </TableCell>
                                    <TableCell align="right"> <span class="accentText">€ {withCommma(totale_con_rai)}</span></TableCell>
                                </TableRow>
                                }

                                <TableRow
                                    key='16'
                                    className="accentRow--lightAccent borderTop"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">{contractType == 'domestico_res' ? 'Totale su base annua (senza il canone RAI)' : 'Totale su base annua'}</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(parseFloat(totale_base_annua))}</span></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                }
            </Box>
        </section>
    )
};

const calculateResidente = (formData, manageData) => {
    const {
        // Energia
        ene_resid_kwh, // Q45
        ene_resid_kw, // Q46
        ene_resid_pcv, // Q47

        ene_non_resid_kwh, // R45
        ene_non_resid_kw, // R46
        ene_non_resid_pcv, // R47

        // Rete
        rete_resid_kwh, // Q49
        rete_resid_kw, // Q50
        rete_resid_pcv, // Q51

        rete_non_resid_kwh, // R49
        rete_non_resid_kw, // R50
        rete_non_resid_pcv, // R51

        // Oneri
        oneri_resid_kwh, // Q53
        oneri_resid_pcv, // Q54

        oneri_non_resid_kwh, // R53 
        oneri_non_resid_pcv, // R54

        // Imposte
        imposte_resid, // Q56 - Q59
        imposte_business, // R56 - R59

        // UDA //

        // Energia
        uda_ene_kwh, // AB45
        uda_ene_kw, // AB46
        uda_ene_pcv, // AB47

        // Rete
        uda_rete_kwh, // AB49
        uda_rete_kw,  // AB50
        uda_rete_pcv, // AB51

        // Oneri
        uda_oneri_kwh, // AB53
        uda_oneri_pcv, // AB54

        // Imposte
        uda_imposte, // AB56

        
        // Domestico
        // perdite_di_rete, // O39
        perdite_di_rete_domestico :perdite_di_rete = 10
    } = manageData;

    const {
        monthNumber, // C2
        contractType, // C3
        potenza, // C4
        messe, // F2
        offerDetails, // B10
        tariffType, // B12
        f0, // C13
        f1, // D13
        f2, // E13
        f3, // F13

    } = formData;

    const {
        price_f0,
        price_f1,
        price_f2,
        price_f3,
        price_pcv: price_pcv2,
        dispbt_fissa
    } = offerDetails;

    const price_pcv = +price_pcv2 + (+dispbt_fissa / 12);

    const consumo_anno = tariffType === 'mono' ? f0 / monthNumber * 12 : (f1 + f2 + f3) / monthNumber * 12;

    // U42
    const PE = sumProduct([+price_f0, +price_f1, +price_f2, +price_f3], [f0,f1,f2,f3]) / (+f0 + +f1 + +f2 + +f3);
    // U43
    const result_predire_di_rate = (PE * perdite_di_rete / 100);
    // U44
    const altro_dispa = ene_resid_kwh;
    // U45
    const totale = (+PE + +result_predire_di_rate + +altro_dispa);

    // console.log('totale', PE, totale, result_predire_di_rate, altro_dispa, tariffType, +f0 * totale)

    // ENERGIA
    // W44 C18
    const price_energia_resid = tariffType === 'mono' ? +f0 * totale : (+f1 + +f2 + +f3) * totale;

    // console.log(price_energia_resid, 'price_energia_resid', (+f1 + +f2 + +f3), totale)

    // W45 C19
    const price_pcv_netta = +price_pcv * monthNumber;

    // W43 C17
    const price_total = +price_energia_resid + +price_pcv_netta;

    // console.log('price_total', price_total, price_pcv_netta)

    // RATE
    // W47 C21
    const price_rate = (tariffType === 'mono' ? rete_resid_kwh * f0 : (+f1 + +f2 + +f3) * rete_resid_kwh);

    // W48 C22
    const price_rate_kw = potenza * rete_resid_kw / 12 * monthNumber;

    // W49 C23
    const price_rate_pcv = rete_resid_pcv / 12 * monthNumber;

    // W46 C20
    const totale_rate_moniraria = +price_rate + +price_rate_kw + +price_rate_pcv;

    // ONERI
    // W51
    const price_oneri = tariffType === 'mono' ? oneri_resid_kwh * f0 : (+f1 + +f2 + +f3) * oneri_resid_kwh;

    // X53
    const price_oneri_pcv = oneri_resid_pcv / 12 * monthNumber;

    // W50
    const total_oneri = price_oneri + (contractType == 'domestico_non_res' ? price_oneri_pcv : 0);

    // C28 W54
    const totale_senza_iva = (+price_total + +totale_rate_moniraria + +total_oneri)

    // console.log(price_total, totale_rate_moniraria, total_oneri, 'C28')

    
    
    const parsedImposte = JSON.parse(imposte_resid);
    
    const imposte = parsedImposte[2].price;
    const imposte2 = parsedImposte[3].price;

    const imposte_non_resid = 0.0227;

    // W55
    const price_sum_anno = consumo_anno > 1800 ? (consumo_anno / 12 * monthNumber) * imposte : (consumo_anno / 12 * monthNumber) * imposte2;

    // C30 || W56
    const totale_iva = totale_senza_iva * (contractType == 'uda' ? 22 : 10) / 100;

    // C31
    const totale_senza_rai = +totale_senza_iva + +price_sum_anno + +totale_iva

    console.log(totale_senza_iva, +totale_iva, +price_sum_anno, +totale_iva)

    // C32
    const canone_rai = monthNumber * 9;

    // C33
    const totale_con_rai = +totale_senza_rai + +canone_rai;

    // C34
    const totale_base_annua = parseFloat(+totale_senza_rai / +monthNumber * 12).toFixed(2)

    console.log(totale_senza_rai / monthNumber * 12, '+totale_senza_rai / +monthNumber * 12');

    return {
        // ENERGIA TOP
        price_energia_resid,
        price_pcv_netta,
        price_total,

        price_rate,
        price_rate_kw,
        price_rate_pcv,
        totale_rate_moniraria,


        total_oneri,
        price_oneri,
        price_oneri_pcv,


        totale_senza_iva,
        price_sum_anno,
        totale_iva,


        totale_senza_iva,
        price_sum_anno,
        totale_iva,
        totale_senza_rai,
        canone_rai,
        totale_con_rai,
        totale_base_annua
    }
}

const calculateNonResidente = (formData, manageData) => {
    const {
        // Energia
        ene_resid_kwh, // Q45
        ene_resid_kw, // Q46
        ene_resid_pcv, // Q47

        ene_non_resid_kwh, // R45
        ene_non_resid_kw, // R46
        ene_non_resid_pcv, // R47

        // Rete
        rete_resid_kwh, // Q49
        rete_resid_kw, // Q50
        rete_resid_pcv, // Q51

        rete_non_resid_kwh, // R49
        rete_non_resid_kw, // R50
        rete_non_resid_pcv, // R51

        // Oneri
        oneri_resid_kwh, // Q53
        oneri_resid_pcv, // Q54

        oneri_non_resid_kwh, // R53 
        oneri_non_resid_pcv, // R54

        // Imposte
        imposte_resid, // Q56 - Q59
        imposte_business, // R56 - R59

        // UDA //

        // Energia
        uda_ene_kwh, // AB45
        uda_ene_kw, // AB46
        uda_ene_pcv, // AB47

        // Rete
        uda_rete_kwh, // AB49
        uda_rete_kw,  // AB50
        uda_rete_pcv, // AB51

        // Oneri
        uda_oneri_kwh, // AB53
        uda_oneri_pcv, // AB54

        // Imposte
        uda_imposte, // AB56


        // BUSINESS
        // perdite_di_rete, // O39

        // Domestico
        perdite_di_rete_domestico :perdite_di_rete = 10
    } = manageData;

    const {
        monthNumber, // C2
        contractType, // C3
        potenza, // C4
        messe, // F2
        offerDetails, // B10
        tariffType, // B12
        f0, // C13
        f1, // D13
        f2, // E13
        f3, // F13

    } = formData;

    const {
        price_f0,
        price_f1,
        price_f2,
        price_f3,
        price_pcv: price_pcv2,
        dispbt_fissa
    } = offerDetails;

    const price_pcv = +price_pcv2 + (dispbt_fissa / 12);

    const consumo_anno = tariffType === 'mono' ? f0 / monthNumber * 12 : (f1 + f2 + f3) / monthNumber * 12;

    // U42
    const PE = sumProduct([+price_f0, +price_f1, +price_f2, +price_f3], [f0,f1,f2,f3]) / (+f0 + +f1 + +f2 + +f3);
    // U43
    const result_predire_di_rate = (PE * perdite_di_rete / 100);
    // U44
    const altro_dispa = ene_non_resid_kwh;
    // U45
    const totale = (+PE + +result_predire_di_rate + +altro_dispa);

    // console.log('totale', PE, totale, result_predire_di_rate, altro_dispa, tariffType, +f0 * totale)

    // ENERGIA
    // W44 C18
    const price_energia_resid = tariffType === 'mono' ? +f0 * totale : (+f1 + +f2 + +f3) * totale;

    // console.log(price_energia_resid, 'price_energia_resid', (+f1 + +f2 + +f3), totale)

    // W45 C19
    const price_pcv_netta = +price_pcv * monthNumber;

    // W43 C17
    const price_total = +price_energia_resid + +price_pcv_netta;

    // console.log('price_total', price_total, price_pcv_netta)

    // RATE
    // W47 C21
    const price_rate = (tariffType === 'mono' ? rete_non_resid_kwh * f0 : (+f1 + +f2 + +f3) * rete_non_resid_kwh);

    // W48 C22
    const price_rate_kw = potenza * rete_non_resid_kw / 12 * monthNumber;

    // W49 C23
    const price_rate_pcv = rete_non_resid_pcv / 12 * monthNumber;

    // W46 C20
    const totale_rate_moniraria = +price_rate + +price_rate_kw + +price_rate_pcv;

    // ONERI
    // W51
    const price_oneri = tariffType === 'mono' ? oneri_non_resid_kwh * +f0 : (+f1 + +f2 + +f3) * oneri_non_resid_kwh;

    console.log(tariffType === 'mono' ? oneri_non_resid_kwh * f0 : (+f1 + +f2 + +f3) * oneri_non_resid_kwh, oneri_non_resid_kwh, f0)

    // X53
    const price_oneri_pcv = oneri_non_resid_pcv / 12 * monthNumber;

    // W50
    const total_oneri = price_oneri + (contractType == 'domestico_non_res' ? price_oneri_pcv : 0);

    // C28 W54
    const totale_senza_iva = (+price_total + +totale_rate_moniraria + +total_oneri)

    // console.log(price_total, totale_rate_moniraria, total_oneri, 'C28')

    const parsedImposte = JSON.parse(imposte_business);

    // 
    const imposte = parsedImposte[2].price;
    const imposte2 = parsedImposte[3].price;

    const imposte_non_resid = 0.0227;

    // W55
    const price_sum_anno = consumo_anno > 1800 ? (consumo_anno / 12 * monthNumber) * imposte : (consumo_anno / 12 * monthNumber) * imposte2

    // C30 || W56
    const totale_iva = totale_senza_iva * (contractType == 'uda' ? 22 : 10) / 100;

    // C31
    const totale_senza_rai = +totale_senza_iva + +price_sum_anno + +totale_iva

    console.log(totale_senza_iva, +totale_iva, +price_sum_anno, +totale_iva)

    // C32
    const canone_rai = monthNumber * 9;

    // C33
    const totale_con_rai = +totale_senza_rai + +canone_rai;

    // C34
    const totale_base_annua = parseFloat(+totale_senza_rai / +monthNumber * 12).toFixed(2)

    console.log(totale_senza_rai / monthNumber * 12, '+totale_senza_rai / +monthNumber * 12');

    return {
        // ENERGIA TOP
        price_energia_resid,
        price_pcv_netta,
        price_total,

        price_rate,
        price_rate_kw,
        price_rate_pcv,
        totale_rate_moniraria,


        total_oneri,
        price_oneri,
        price_oneri_pcv,


        totale_senza_iva,
        price_sum_anno,
        totale_iva,


        totale_senza_iva,
        price_sum_anno,
        totale_iva,
        totale_senza_rai,
        canone_rai,
        totale_con_rai,
        totale_base_annua
    }
}


const calculateResidenteUda = (formData, manageData) => {
    const {
        // Energia
        ene_resid_kwh, // Q45
        ene_resid_kw, // Q46
        ene_resid_pcv, // Q47

        ene_non_resid_kwh, // R45
        ene_non_resid_kw, // R46
        ene_non_resid_pcv, // R47

        // Rete
        rete_resid_kwh, // Q49
        rete_resid_kw, // Q50
        rete_resid_pcv, // Q51

        rete_non_resid_kwh, // R49
        rete_non_resid_kw, // R50
        rete_non_resid_pcv, // R51

        // Oneri
        oneri_resid_kwh, // Q53
        oneri_resid_pcv, // Q54

        oneri_non_resid_kwh, // R53 
        oneri_non_resid_pcv, // R54

        // Imposte
        imposte_resid, // Q56 - Q59
        imposte_business, // R56 - R59

        // UDA //

        // Energia
        uda_ene_kwh, // AB45
        uda_ene_kw, // AB46
        uda_ene_pcv, // AB47

        // Rete
        uda_rete_kwh, // AB49
        uda_rete_kw,  // AB50
        uda_rete_pcv, // AB51

        // Oneri
        uda_oneri_kwh, // AB53
        uda_oneri_pcv, // AB54

        // Imposte
        uda_imposte, // AB56


        // BUSINESS
        // perdite_di_rete, // O39

        // Domestico
        perdite_di_rete_domestico :perdite_di_rete = 10
    } = manageData;

    const {
        monthNumber, // C2
        contractType, // C3
        potenza, // C4
        messe, // F2
        offerDetails, // B10
        tariffType, // B12
        f0, // C13
        f1, // D13
        f2, // E13
        f3, // F13

    } = formData;

    const {
        price_f0,
        price_f1,
        price_f2,
        price_f3,
        price_pcv: price_pcv2,
        dispbt_fissa
    } = offerDetails;

    const price_pcv = +price_pcv2 + (+dispbt_fissa / 12);

    const consumo_anno = tariffType === 'mono' ? f0 / monthNumber * 12 : (f1 + f2 + f3) / monthNumber * 12;

    // U42
    const PE = sumProduct([+price_f0, +price_f1, +price_f2, +price_f3], [f0,f1,f2,f3]) / (+f0 + +f1 + +f2 + +f3);
    // U43
    const result_predire_di_rate = (PE * perdite_di_rete / 100);
    // U44
    const altro_dispa = ene_resid_kwh;
    // U45
    const totale = (+PE + +result_predire_di_rate + +altro_dispa);

    // console.log('totale', PE, totale, result_predire_di_rate, altro_dispa, tariffType, +f0 * totale)

    // ENERGIA
    // W44 C18
    const price_energia_resid = tariffType === 'mono' ? +f0 * totale : (+f1 + +f2 + +f3) * totale;

    // console.log(price_energia_resid, 'price_energia_resid', (+f1 + +f2 + +f3), totale)

    // W45 C19
    const price_pcv_netta = +price_pcv * monthNumber;

    // W43 C17
    const price_total = +price_energia_resid + +price_pcv_netta;

    // console.log('price_total', price_total, price_pcv_netta)

    // RATE
    // W47 C21
    const price_rate = (tariffType === 'mono' ? uda_rete_kwh * f0 : (+f1 + +f2 + +f3) * uda_rete_kwh);

    // W48 C22
    const price_rate_kw = potenza * uda_rete_kw / 12 * monthNumber;

    // W49 C23
    const price_rate_pcv = uda_rete_pcv / 12 * monthNumber;

    // W46 C20
    const totale_rate_moniraria = +price_rate + +price_rate_kw + +price_rate_pcv;

    // ONERI
    // W51
    const price_oneri = tariffType === 'mono' ? uda_oneri_kwh * f0 : (+f1 + +f2 + +f3) * uda_oneri_kwh;

    // X53
    const price_oneri_pcv = uda_oneri_pcv / 12 * monthNumber;

    // W50
    const total_oneri = price_oneri + price_oneri_pcv;

    // C28 W54
    const totale_senza_iva = (+price_total + +totale_rate_moniraria + +total_oneri)

    // console.log(price_total, totale_rate_moniraria, total_oneri, 'C28')

    const parsedImposte = JSON.parse(uda_imposte);

    // 
    const imposte = parsedImposte[1].price;
    const imposte2 = parsedImposte[3].price;

    // W55
    const price_sum_anno = consumo_anno > 1800 ? (consumo_anno / 12 * monthNumber) * imposte : (consumo_anno / 12 * monthNumber) * imposte2;

    // C30 || W56
    const totale_iva = totale_senza_iva * (contractType == 'uda' ? 22 : 10) / 100;

    // C31
    const totale_senza_rai = +totale_senza_iva + +price_sum_anno + +totale_iva

    console.log(totale_senza_iva, +totale_iva, +price_sum_anno, +totale_iva)

    // C32
    const canone_rai = monthNumber * 9;

    // C33
    const totale_con_rai = +totale_senza_rai + +canone_rai;

    // C34
    const totale_base_annua = parseFloat(+totale_senza_rai / +monthNumber * 12).toFixed(2)

    console.log(totale_senza_rai / monthNumber * 12, '+totale_senza_rai / +monthNumber * 12');

    return {
        // ENERGIA TOP
        price_energia_resid,
        price_pcv_netta,
        price_total,

        price_rate,
        price_rate_kw,
        price_rate_pcv,
        totale_rate_moniraria,


        total_oneri,
        price_oneri,
        price_oneri_pcv,


        totale_senza_iva,
        price_sum_anno,
        totale_iva,


        totale_senza_iva,
        price_sum_anno,
        totale_iva,
        totale_senza_rai,
        canone_rai,
        totale_con_rai,
        totale_base_annua
    }
}

const sumProduct = (arr, arr2) => {
    let sum = 0;

    arr.map((el, index) => {
        const result = el * arr2[index];

        sum += result;
    })

    return sum;
}

export default ComperatoreAgentEneDomestico;


const listToTransform = [
    'ene_resid_kwh', // Q45
        'ene_resid_kw', // Q46
        'ene_resid_pcv', // Q47

        'ene_non_resid_kwh', // R45
        'ene_non_resid_kw', // R46
        'ene_non_resid_pcv', // R47

        // Rete
        'rete_resid_kwh', // Q49
        'rete_resid_kw', // Q50
        'rete_resid_pcv', // Q51

        'rete_non_resid_kwh', // R49
        'rete_non_resid_kw', // R50
        'rete_non_resid_pcv', // R51

        // Oneri
        'oneri_resid_kwh', // Q53
        'oneri_resid_pcv', // Q54

        'oneri_non_resid_kwh', // R53 
        'oneri_non_resid_pcv', // R54

        // Imposte
        // 'imposte_resid', // Q56 - Q59
        // 'imposte_business', // R56 - R59

        // UDA //

        // Energia
        'uda_ene_kwh', // AB45
        'uda_ene_kw', // AB46
        'uda_ene_pcv', // AB47

        // Rete
        'uda_rete_kwh', // AB49
        'uda_rete_kw',  // AB50
        'uda_rete_pcv', // AB51

        // Oneri
        'uda_oneri_kwh', // AB53
        'uda_oneri_pcv', // AB54

        // Imposte
        'uda_imposte', // AB56

        
        // Domestico
        // perdite_di_rete, // O39
        'perdite_di_rete_domestico'
]