const style = {
    formBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        position: 'relative',
        top: '-90px'
    },

    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    loginFl: {
        width: '200px',
        marginTop: '20px'
    },

    button: {
        background: "linear-gradient(277.72deg, #FF9C29 28.61%, #E9A744 102.98%)",
        borderRadius: 2,
        border: 0,
        color: "white",
        height: 48,
        padding: "0 70px",
        margin: '0 auto',
        display: 'block',
        marginTop: '50px',
        boxShadow: 'none'
    }
}

export default style;