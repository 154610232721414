import React, { Component } from 'react';
import { Button, Tooltip, GridList, TextField, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

import './operationAdmin.scss';

import store from '../../../../store';
import {   
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
    } from '../../../../actions/actions';

import RemoveDialog from '../../../dialogs/removePopup';

import { 
    getAllSuppliers,
    getAllOperations,
    updateOperation,
    removeOperation,
    baseUrlUploads
} from './../../../../service';

import PopupScript from '../../../dialogs/script';
import OperationScript from '../../operationScript/';

class OperationAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            supplier_id: null,
            operations: [],
            operation: null,
            dialogRemove: false,

            switch: {
                resid: true,
                business: false
            },

            allacciamento_power_resid: '',
            subentro_power_resid: '',
            '1a_attivazione_power_resid': '',
            cambio_fornitore_power_resid: '',
            cambio_con_voltura_power_resid: '',
            allacciamento_gas_resid: '',
            subentro_gas_resid: '',
            '1a_attivazione_gas_resid': '',
            cambio_fornitore_gas_resid: '',
            cambio_con_voltura_gas_resid: '',
            allacciamento_power_business: '',
            subentro_power_business: '',
            '1a_attivazione_power_business': '',
            cambio_fornitore_power_business: '',
            cambio_con_voltura_power_business: '',
            allacciamento_gas_business: '',
            subentro_gas_business: '',
            '1a_attivazione_gas_business': '',
            cambio_fornitore_gas_business: '',
            cambio_con_voltura_gas_business: '',

            taglio_colonna_power_resid: '',
            contatore_rimosso_power_resid: '',
            chiuso_per_morosita_power_resid: '',

            taglio_colonna_power_business: '',
            contatore_rimosso_power_business: '',
            chiuso_per_morosita_power_business: '',

            taglio_colonna_gas_resid: '',
            contatore_rimosso_gas_resid: '',
            chiuso_per_morosita_gas_resid: '',

            taglio_colonna_gas_business: '',
            contatore_rimosso_gas_business: '',
            chiuso_per_morosita_gas_business: '',

            nds_gas_business: '',
            nds_gas_resid: '',

            loading: true,

            statusPopupScript: false,
            selectedScript: null,
            selectScriptText: ''
        }

        this.switchTab = this.switchTab.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.updateOperation = this.updateOperation.bind(this);
        this.removeOperation = this.removeOperation.bind(this);
        this.askCancel = this.askCancel.bind(this);
        this.askRemove = this.askRemove.bind(this);

        this.openPopupScript = this.openPopupScript.bind(this);
        this.closePopupScript = this.closePopupScript.bind(this);
        this.changeTextScript = this.changeTextScript.bind(this);
        this.updateOperationField = this.updateOperationField.bind(this);
    }

    componentWillMount() {
        const suppliersPromise = getAllSuppliers();
        const operationsPromise = getAllOperations();

        store.dispatch({ type: OPEN_LOADING, text: 'Loading operations...'})

        Promise.all([suppliersPromise, operationsPromise]).then(result => {
            const suppliers = result[0].data.suppliers;
            const operations = result[1].data.operations;

            this.setState({ suppliers, operations, loading: false });
            store.dispatch({ type: CLOSE_LOADING })

        }).catch( err => {
            console.log(err);
            store.dispatch({ type: OPEN_LOADING, text: `Can't load operations`})

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING })
            }, 2500);

            this.setState({ loading: false, err: true });
        });
    }

    switchTab() {
        let { switch: { resid, business } } = this.state;

        resid= !resid;
        business = !business;

        this.setState({ switch: { resid, business } });
    }

    selectSupplier(id) {
        let { suppliers, operations } = this.state;

        let theSame = false;

        suppliers.map(supplier => {
            if(supplier.id == id) {
                supplier.selected = !supplier.selected;
                theSame = supplier.selected;
            } else if(supplier.selected) {
                supplier.selected = false;
            }

            return supplier;
        });

        let operation = operations.find( operation => operation.supplier_id == id);

        for(let key in operation) {
            if(operation[key] == null)
                operation[key] = '';
        }


        if(theSame)
            this.setState({ supplier_id: id, ...operation, operation });
        else
            this.setState({ supplier_id: null, operation: null });
    }

    changeValue(name, value) {
        const state = this.state;

        state[name] = value;

        this.setState(state);
    }

    updateOperation() {
        const {
            supplier_id,
            allacciamento_power_resid,
            subentro_power_resid,
            '1a_attivazione_power_resid': a_attivazione_power_resid,
            cambio_fornitore_power_resid,
            cambio_con_voltura_power_resid,
            allacciamento_gas_resid,
            subentro_gas_resid,
            '1a_attivazione_gas_resid': a_attivazione_gas_resid,
            cambio_fornitore_gas_resid,
            cambio_con_voltura_gas_resid,
            allacciamento_power_business,
            subentro_power_business,
            '1a_attivazione_power_business': a_attivazione_power_business,
            cambio_fornitore_power_business,
            cambio_con_voltura_power_business,
            allacciamento_gas_business,
            subentro_gas_business,
            '1a_attivazione_gas_business': a_attivazione_gas_business,
            cambio_fornitore_gas_business,
            cambio_con_voltura_gas_business,

            taglio_colonna_power_resid,
            contatore_rimosso_power_resid,
            chiuso_per_morosita_power_resid,

            taglio_colonna_power_business,
            contatore_rimosso_power_business,
            chiuso_per_morosita_power_business,

            taglio_colonna_gas_resid,
            contatore_rimosso_gas_resid,
            chiuso_per_morosita_gas_resid,

            taglio_colonna_gas_business,
            contatore_rimosso_gas_business,
            chiuso_per_morosita_gas_business,

            nds_gas_business,
            nds_gas_resid,

            operation: { id }
        } = this.state;

        const data = {
            supplier_id,
            allacciamento_power_resid,
            subentro_power_resid,
            a_attivazione_power_resid,
            cambio_fornitore_power_resid,
            cambio_con_voltura_power_resid,
            allacciamento_gas_resid,
            subentro_gas_resid,
            a_attivazione_gas_resid,
            cambio_fornitore_gas_resid,
            cambio_con_voltura_gas_resid,
            allacciamento_power_business,
            subentro_power_business,
            a_attivazione_power_business,
            cambio_fornitore_power_business,
            cambio_con_voltura_power_business,
            allacciamento_gas_business,
            subentro_gas_business,
            a_attivazione_gas_business,
            cambio_fornitore_gas_business,
            cambio_con_voltura_gas_business,

            taglio_colonna_power_resid,
            contatore_rimosso_power_resid,
            chiuso_per_morosita_power_resid,

            taglio_colonna_power_business,
            contatore_rimosso_power_business,
            chiuso_per_morosita_power_business,

            taglio_colonna_gas_resid,
            contatore_rimosso_gas_resid,
            chiuso_per_morosita_gas_resid,

            taglio_colonna_gas_business,
            contatore_rimosso_gas_business,
            chiuso_per_morosita_gas_business,

            nds_gas_business,
            nds_gas_resid
        }

        delete data.a_attivazione_power_resid;
        delete data.a_attivazione_gas_resid;
        delete data.a_attivazione_power_business;
        delete data.a_attivazione_gas_business;

        data['1a_attivazione_power_resid'] = a_attivazione_power_resid;
        data['1a_attivazione_gas_resid'] = a_attivazione_gas_resid;
        data['1a_attivazione_power_business'] = a_attivazione_power_business;
        data['1a_attivazione_gas_business'] = a_attivazione_gas_business;

        store.dispatch({ type: OPEN_LOADING, text: 'Updating the operation...' });

        updateOperation(id, data).then(result => {
            store.dispatch({ type: CLOSE_LOADING });
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Operation been updated', open: true})
        }).catch(err => {
            console.log(err);
            store.dispatch({ type: OPEN_LOADING, text: `Can't update the operation` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    updateOperationField(name, value) {
        const other = ['a_attivazione_power_resid', 'a_attivazione_gas_resid', 'a_attivazione_power_business', 'a_attivazione_gas_business'];
        const obj = {};
        let fieldName = name;

        if(other.some(key => key == name)) {
            fieldName = '1'+fieldName;
        };

        obj[fieldName] = value;

        const id = this.state.id;
    
        updateOperation(id, obj).then(resp => {
            console.log(resp);
        })
    }

    removeOperation() {
        const { operations, operation: { id }} = this.state;
        const operationsFiltered = operations.filter(operation => operation.id != id);

        this.setState({ dialogRemove: false, operation: null, operations: operationsFiltered });

        removeOperation(id).then(result => {
            store.dispatch({ type: OPEN_NOTIFICATION, text: 'Operation has been removed'})
        }).catch(err => {
            console.log(err);
            this.setState({ operations });
        })
    };

    askRemove() {
        this.setState({ dialogRemove: true });
    }

    askCancel() {
        this.setState({ dialogRemove: false });
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    getSupplier(id) {
        return this.state.suppliers.find(sup => sup.id == id);
    }

    openPopupScript(selectedScript, text) {
        this.setState({ statusPopupScript: true, selectedScript, selectScriptText: text });
    }

    closePopupScript() {
        this.setState({ statusPopupScript: false, selectScript: null });
    }

    changeTextScript(name, text) {
        const state = this.state;

        state[name] = text;

        this.updateOperationField(name, text);

        this.setState(state);
    }
    
    render() {
        let supplier = null;
        const { suppliers, operations, operation, supplier_id, dialogRemove } = this.state;
        const { resid, business } = this.state.switch;
        const {open: loading} = store.getState().loading;

        const {
            allacciamento_power_resid,
            subentro_power_resid,
            '1a_attivazione_power_resid': a_attivazione_power_resid,
            cambio_fornitore_power_resid,
            cambio_con_voltura_power_resid,
            allacciamento_gas_resid,
            subentro_gas_resid,
            '1a_attivazione_gas_resid': a_attivazione_gas_resid,
            cambio_fornitore_gas_resid,
            cambio_con_voltura_gas_resid,
            allacciamento_power_business,
            subentro_power_business = '',
            '1a_attivazione_power_business': a_attivazione_power_business,
            cambio_fornitore_power_business,
            cambio_con_voltura_power_business,
            allacciamento_gas_business,
            subentro_gas_business,
            '1a_attivazione_gas_business': a_attivazione_gas_business,
            cambio_fornitore_gas_business,
            cambio_con_voltura_gas_business,

            taglio_colonna_power_resid,
            contatore_rimosso_power_resid,
            chiuso_per_morosita_power_resid,

            taglio_colonna_power_business,
            contatore_rimosso_power_business,
            chiuso_per_morosita_power_business,

            taglio_colonna_gas_resid,
            contatore_rimosso_gas_resid,
            chiuso_per_morosita_gas_resid,

            taglio_colonna_gas_business,
            contatore_rimosso_gas_business,
            chiuso_per_morosita_gas_business,

            statusPopupScript,
            selectedScript,
            selectScriptText,

            // new
            nds_gas_business,
            nds_gas_resid
        } = this.state;

        if(supplier_id) 
            supplier = this.getSupplier(supplier_id);
        
        return (
            <div className={loading ? 'hide' : 'operation_section'}>
                <Link to='/new' supplier_id={supplier_id}>
                    <Box sx={{width: 'fit-content', marginLeft: 'auto'}}>
                        <Tooltip title='Add new'>
                            <Button className='add_button'><span className='add_button--plusSign'>+</span><span>add new</span></Button>
                        </Tooltip>
                    </Box>

                </Link>
                <Tooltip title='Help'>
                    <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>
                <RemoveDialog open={dialogRemove} remove={this.removeOperation} close={this.askCancel}/>

                {
                    statusPopupScript && <PopupScript
                        open={statusPopupScript} 
                        selectedScript={selectedScript} 
                        comfirm={this.changeTextScript}
                        handleClose={this.closePopupScript}
                        text={selectScriptText}
                        />
                }

                { operation 
                    ?
                        <div className='actions'>
                            <div className='action -edit' onClick={this.updateOperation}><Icon className='offer_icon'>edit_icon</Icon></div>
                            <div className='action -remove' onClick={this.askRemove}><Icon className='offer_icon'>delete</Icon></div>
                        </div>
                    : '' }
                <div className='content anim_content'>
                    <div className='supplier_list'>
                        <div className='saparetor'>SUPPLIERS</div>
                        <div className='content' >
                            <GridList cols={0} id='grid-scroll'>
                                {
                                    suppliers.map(supplier => {
                                        const { id, name, selected, img } = supplier;

                                        return (
                                            <div key={id} className='supplier'>
                                                <div className='actions'>
                                                    <div className='background'></div>
                                                    { !selected 
                                                        ? <div onClick={() => this.selectSupplier(id)} className='action -select'>Select</div>
                                                        : <div onClick={() => this.selectSupplier(id)} className='action -cancel'>Cancel</div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                    {img 
                                                    ? <div className='block_img'>
                                                        <img className='image'src={baseUrlUploads+img}/>
                                                     </div> 
                                                    : <div className='text_block'>
                                                        {name}
                                                     </div>
                                                    }
                                                    { selected ? <div className='selected'></div> : '' }
                                                </div>
                                            </div>
                                        )
                                    })
                                
                                }
                            </GridList>
                        </div>
                    </div>

                    { operation 
                        ?
                        <div className='main_section'>
                                <div className="comperatore__switcher">
                                    <div className={!resid ? "comperatore__switcherTab--active" : "comperatore__switcherTab"} onClick={this.switchTab}>Business</div>
                                    <div className={resid ? "comperatore__switcherTab--active" : "comperatore__switcherTab"} onClick={this.switchTab}>Resid</div>
                                </div>

                            <div className='fields_section'>
                            { resid ? // RESIDENT
                                <div className={'operations -resid'}>
                                    <div className='saparetor'>LUCE</div>
                                    <div className='fields anim_content'>
                                        <div className='content'>
                                        <OperationScript
                                            className={supplier && CheckUsed(['luce', 'dual'], supplier.allacciamento) ? 'show_operation' : 'disabled_operation'}
                                            title={'Allacciamento'}
                                            text={allacciamento_power_resid}
                                            edit={() => this.openPopupScript('allacciamento_power_resid', allacciamento_power_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['luce', 'dual'], supplier.prima_attivazione) ? 'show_operation' : 'disabled_operation'}
                                            title={'1a Attivazione'}
                                            text={a_attivazione_power_resid}
                                            edit={() => this.openPopupScript('1a_attivazione_power_resid', a_attivazione_power_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['luce', 'dual'], supplier.subentro) ? 'show_operation' : 'disabled_operation'}
                                            title={'Subentro'}
                                            text={subentro_power_resid}
                                            edit={() => this.openPopupScript('subentro_power_resid', subentro_power_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['luce', 'dual'], supplier.cambio_fornitore) ? 'show_operation' : 'disabled_operation'}
                                            title={'Cambio fornitore'}
                                            text={cambio_fornitore_power_resid}
                                            edit={() => this.openPopupScript('cambio_fornitore_power_resid', cambio_fornitore_power_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['luce', 'dual'], supplier.cambio_fornitore_con_voltura) ? 'show_operation' : 'disabled_operation'}
                                            title={'Cambio con voltura'}
                                            text={cambio_con_voltura_power_resid}
                                            edit={() => this.openPopupScript('cambio_con_voltura_power_resid', cambio_con_voltura_power_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['luce', 'dual'], supplier.taglio_colonna) ? 'show_operation' : 'disabled_operation'}
                                            title={'Taglio colonna'}
                                            text={taglio_colonna_power_resid}
                                            edit={() => this.openPopupScript('taglio_colonna_power_resid', taglio_colonna_power_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['luce', 'dual'], supplier.contatore_rimosso) ? 'show_operation' : 'disabled_operation'}
                                            title={'Contatore rimosso'}
                                            text={contatore_rimosso_power_resid}
                                            edit={() => this.openPopupScript('contatore_rimosso_power_resid', contatore_rimosso_power_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['luce', 'dual'], supplier.chiuso_per_morosita) ? 'show_operation' : 'disabled_operation'}
                                            title={'Allacciamento temporaneo'}
                                            text={chiuso_per_morosita_power_resid}
                                            edit={() => this.openPopupScript('chiuso_per_morosita_power_resid', chiuso_per_morosita_power_resid)}
                                            />
                                            </div>
                                    </div>

                                    <div className='saparetor'>GAS</div>
                                    <div className='fields anim_content'>
                                        <div className='content'>
                                        <OperationScript
                                            className={supplier && CheckUsed(['gas', 'dual'], supplier.allacciamento) ? 'show_operation' : 'disabled_operation'}
                                            title={'Allacciamento'}
                                            text={allacciamento_gas_resid}
                                            edit={() => this.openPopupScript('allacciamento_gas_resid', allacciamento_gas_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['gas', 'dual'], supplier.prima_attivazione) ? 'show_operation' : 'disabled_operation'}
                                            title={'1a Attivazione'}
                                            text={a_attivazione_gas_resid}
                                            edit={() => this.openPopupScript('1a_attivazione_gas_resid', a_attivazione_gas_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['gas', 'dual'], supplier.subentro) ? 'show_operation' : 'disabled_operation'}
                                            title={'Subentro'}
                                            text={subentro_gas_resid}
                                            edit={() => this.openPopupScript('subentro_gas_resid', subentro_gas_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['gas', 'dual'], supplier.cambio_fornitore) ? 'show_operation' : 'disabled_operation'}
                                            title={'Cambio fornitore'}
                                            text={cambio_fornitore_gas_resid}
                                            edit={() => this.openPopupScript('cambio_fornitore_gas_resid', cambio_fornitore_gas_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['gas', 'dual'], supplier.cambio_fornitore_con_voltura) ? 'show_operation' : 'disabled_operation'}
                                            title={'Cambio con voltura'}
                                            text={cambio_con_voltura_gas_resid}
                                            edit={() => this.openPopupScript('cambio_con_voltura_gas_resid', cambio_con_voltura_gas_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['gas', 'dual'], supplier.taglio_colonna) ? 'show_operation' : 'disabled_operation'}
                                            title={'Taglio colonna'}
                                            text={taglio_colonna_gas_resid}
                                            edit={() => this.openPopupScript('taglio_colonna_gas_resid', taglio_colonna_gas_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['gas', 'dual'], supplier.contatore_rimosso) ? 'show_operation' : 'disabled_operation'}
                                            title={'Contatore rimosso'}
                                            text={contatore_rimosso_gas_resid}
                                            edit={() => this.openPopupScript('contatore_rimosso_gas_resid', contatore_rimosso_gas_resid)}
                                            />
                                        <OperationScript
                                            className={supplier && CheckUsed(['gas', 'dual'], supplier.chiuso_per_morosita) ? 'show_operation' : 'disabled_operation'}
                                            title={'Allacciamento temporaneo'}
                                            text={chiuso_per_morosita_gas_resid}
                                            edit={() => this.openPopupScript('chiuso_per_morosita_gas_resid', chiuso_per_morosita_gas_resid)}
                                            />
                                        </div>
                                    </div>

                                    <div className='saparetor'>NDS</div>
                                    <div className='fields anim_content2'>
                                        <div className='content'>

                                        <OperationScript
                                            className='show_operation'
                                            title={'NDS'}
                                            text={nds_gas_resid}
                                            edit={() => this.openPopupScript('nds_gas_resid', nds_gas_resid)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : // BUSINESS
                                <div className='operations -business'>
                                    <div className='saparetor'>LUCE</div>
                                    <div className='fields anim_content2'>
                                    <div className='content'>
                                    <OperationScript
                                        className={supplier && CheckUsed(['luce', 'dual'], supplier.allacciamento) ? 'show_operation' : 'disabled_operation'}
                                        title={'Allacciamento'}
                                        text={allacciamento_power_business}
                                        edit={() => this.openPopupScript('allacciamento_power_business', allacciamento_power_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['luce', 'dual'], supplier.prima_attivazione) ? 'show_operation' : 'disabled_operation'}
                                        title={'1a Attivazione'}
                                        text={a_attivazione_power_business}
                                        edit={() => this.openPopupScript('1a_attivazione_power_business', a_attivazione_power_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['luce', 'dual'], supplier.subentro) ? 'show_operation' : 'disabled_operation'}
                                        title={'Subentro'}
                                        text={subentro_power_business}
                                        edit={() => this.openPopupScript('subentro_power_business', subentro_power_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['luce', 'dual'], supplier.cambio_fornitore) ? 'show_operation' : 'disabled_operation'}
                                        title={'Cambio fornitore'}
                                        text={cambio_fornitore_power_business}
                                        edit={() => this.openPopupScript('cambio_fornitore_power_business', cambio_fornitore_power_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['luce', 'dual'], supplier.cambio_fornitore_con_voltura) ? 'show_operation' : 'disabled_operation'}
                                        title={'Cambio con voltura'}
                                        text={cambio_con_voltura_power_business}
                                        edit={() => this.openPopupScript('cambio_con_voltura_power_business', cambio_con_voltura_power_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['luce', 'dual'], supplier.taglio_colonna) ? 'show_operation' : 'disabled_operation'}
                                        title={'Taglio colonna'}
                                        text={taglio_colonna_power_business}
                                        edit={() => this.openPopupScript('taglio_colonna_power_business', taglio_colonna_power_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['luce', 'dual'], supplier.contatore_rimosso) ? 'show_operation' : 'disabled_operation'}
                                        title={'Contatore rimosso'}
                                        text={contatore_rimosso_power_business}
                                        edit={() => this.openPopupScript('contatore_rimosso_power_business', contatore_rimosso_power_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['luce', 'dual'], supplier.chiuso_per_morosita) ? 'show_operation' : 'disabled_operation'}
                                        title={'Allacciamento temporaneo'}
                                        text={chiuso_per_morosita_power_business}
                                        edit={() => this.openPopupScript('chiuso_per_morosita_power_business', chiuso_per_morosita_power_business)}
                                        />
                                        </div>
                                    </div>

                                    <div className='saparetor'>GAS</div>
                                    <div className='fields anim_content2'>
                                    <div className='content'>
                                    <OperationScript
                                        className={supplier && CheckUsed(['gas', 'dual'], supplier.allacciamento) ? 'show_operation' : 'disabled_operation'}
                                        title={'Allacciamento'}
                                        text={allacciamento_gas_business}
                                        edit={() => this.openPopupScript('allacciamento_gas_business', allacciamento_gas_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['gas', 'dual'], supplier.prima_attivazione) ? 'show_operation' : 'disabled_operation'}
                                        title={'1a Attivazione'}
                                        text={a_attivazione_gas_business}
                                        edit={() => this.openPopupScript('1a_attivazione_gas_business', a_attivazione_gas_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['gas', 'dual'], supplier.subentro) ? 'show_operation' : 'disabled_operation'}
                                        title={'Subentro'}
                                        text={subentro_gas_business}
                                        edit={() => this.openPopupScript('subentro_gas_business', subentro_gas_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['gas', 'dual'], supplier.cambio_fornitore) ? 'show_operation' : 'disabled_operation'}
                                        title={'Cambio fornitore'}
                                        text={cambio_fornitore_gas_business}
                                        edit={() => this.openPopupScript('cambio_fornitore_gas_business', cambio_fornitore_gas_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['gas', 'dual'], supplier.cambio_fornitore_con_voltura) ? 'show_operation' : 'disabled_operation'}
                                        title={'Cambio con voltura'}
                                        text={cambio_con_voltura_gas_business}
                                        edit={() => this.openPopupScript('cambio_con_voltura_gas_business', cambio_con_voltura_gas_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['gas', 'dual'], supplier.taglio_colonna) ? 'show_operation' : 'disabled_operation'}
                                        title={'Taglio colonna'}
                                        text={taglio_colonna_gas_business}
                                        edit={() => this.openPopupScript('taglio_colonna_gas_business', taglio_colonna_gas_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['gas', 'dual'], supplier.contatore_rimosso) ? 'show_operation' : 'disabled_operation'}
                                        title={'Contatore rimosso'}
                                        text={contatore_rimosso_gas_business}
                                        edit={() => this.openPopupScript('contatore_rimosso_gas_business', contatore_rimosso_gas_business)}
                                        />
                                    <OperationScript
                                        className={supplier && CheckUsed(['gas', 'dual'], supplier.chiuso_per_morosita) ? 'show_operation' : 'disabled_operation'}
                                        title={'Allacciamento temporaneo'}
                                        text={chiuso_per_morosita_gas_business}
                                        edit={() => this.openPopupScript('chiuso_per_morosita_gas_business', chiuso_per_morosita_gas_business)}
                                        />
                                        </div>
                                    </div>

                                    <div className='saparetor'>NDS</div>
                                    <div className='fields anim_content2'>
                                        <div className='content'>

                                        <OperationScript
                                            className='show_operation'
                                            title={'NDS'}
                                            text={nds_gas_business}
                                            edit={() => this.openPopupScript('nds_gas_business', nds_gas_business)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                        </div> 
                        : 
                        <div className='info_section'>
                            { supplier_id 
                                ? <div>The supplier does't have associated operation</div>
                                : <div>Please, select supplier</div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default OperationAdmin;

const CheckUsed = (fields, filter) => fields.some(field => field == filter);