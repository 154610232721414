import React, { Component } from 'react';
import { Button, TextField, Tooltip, Icon } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { generate } from 'generate-password';

class GenerateNew extends Component {
    constructor() {
        super()

        this.state = {
            password: ''
        };

        this.generatePass = this.generatePass.bind(this);
        this.changeIntup = this.changeIntup.bind(this);
    }

    changeIntup(event) {
        this.setState({ password: event.target.value });
    }

    generatePass() {
        const password = generate({
            length: 24,
            numbers: true
        })

        this.setState({
            password
        });
    }

  render() {
    const {
        open,
        event,
        close
    } = this.props;

    const {
        password
    } = this.state;

    return (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <div>
                <TextField 
                    value={password}
                    onChange={this.changeIntup}
                />
                <Tooltip title='Generate Password'><Button className='action -generate' onClick={this.generatePass} variant="fab" mini aria-label="Generate"><Icon className='offer_icon'>all_inclusive</Icon></Button></Tooltip>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button onClick={() => event(password)} color="primary">
              Update password
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default GenerateNew;