import { OPEN_MESSAGE_POPUP, EDIT_MESSAGE_POPUP, CLOSE_MESSAGE_POPUP } from '../actions/actions';

const popupAction = (state = {open: false}, action) => {
    switch(action.type) {
        case OPEN_MESSAGE_POPUP: 
            return {
                ...state,
                open: true,
                id: null,
                text: null,
                title: null
            }

        case CLOSE_MESSAGE_POPUP: 
            return {
                ...state,
                open: false
            }

        case EDIT_MESSAGE_POPUP: 
            return {
                ...state,
                open: true,
                id: action.id,
                text: action.text,
                title: action.title
            }

        default: 
            return state;
    }
}

export default popupAction;

