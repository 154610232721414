import React, { Component } from 'react';

import Message from './message.js';
import './messageList.scss';

import { changeTelcoOrder } from './../../../service';

class MessageList extends Component {
    constructor(props) {
        super(props)

        this.state = {};

        this.changeOrder = this.changeOrder.bind(this);
    }

    changeOrder(curr, to) {
        const currQ = curr.q;
        const toQ = to.q;

        curr.q = toQ;
        to.q = currQ;

        changeTelcoOrder({...curr}, {...to}).then(resp => {
            const { ok } = resp.data;
        });

        this.forceUpdate();
    }

    render() {
        const messages = this.props.messages || [];

        return(
            <div className='message_list anim_content'>
                { messages ? (
                    messages.sort((a, b) => {
                        return (a.q > b.q) ? 1 : (a.q < b.q) ? -1 : 0;
                    }).map((message, index) => {
                        const curr = message;
                        const prev = messages[index - 1] || null;
                        const next = messages[index + 1] || null;

                        return <Message 
                            key={message.id} 
                            removeMessage={this.props.removeMessage} 
                            editMessage={this.props.editMessage} 
                            downOrder={prev ? () => this.changeOrder(curr, prev): null}
                            upOrder={next ? () => this.changeOrder(curr, next): null}
                            message={message} />
                    })
                ) : (
                    <div></div>
                ) }
            </div>
        )
    }
}

export default MessageList;
