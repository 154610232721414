import React, { Component } from 'react';
import { Button, Icon} from '@material-ui/core';
import renderHTML from 'react-render-html';

import store from '../../../store';
import Box from '@mui/material/Box';

import './message.scss';

class Message extends Component {
    constructor(props) {
        super(props);
        this.messageRef = React.createRef();
        this.state = {
            isTooltipVisible: false, 
        }

        this.clickEdit = this.clickEdit.bind(this);
        this.removeMessage = this.removeMessage.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    clickEdit() {
        const { message: { text, id, title } } = this.props;
        this.props.editMessage(id, text, title);
    }

    removeMessage() {
        this.props.removeMessage(this.props.message.id);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }


    handleClickOutside(event) {
        if (this.messageRef && !this.messageRef.current.contains(event.target)) {
        this.setState({ isTooltipVisible: false });
        }
    }

    render() {
        const textMessage = this.props.message.text;
        const titileMessage = this.props.message.title;

        const { role } = store.getState().auth;
        const { downOrder, upOrder } = this.props;
        const { isTooltipVisible } = this.state;

        return (
            role == 'admin' ? (
                <div className='message_section' onClick={() => this.setState({ isTooltipVisible: true })} ref={this.messageRef}>
                    <div className='orderButtons'>
                        { downOrder && <div className="orderArrowWrapper" onClick={downOrder}><div className="triangle-top"></div></div> }
                        { upOrder && <div className="orderArrowWrapper" onClick={upOrder}><div className="triangle-down"></div></div>}
                    </div>
                    <div className='actionAbuttons'>
                        <Box sx={{marginRight: '5px'}}><Button onClick={this.clickEdit} className='actionBtn actionBtn--edit' variant="fab" mini aria-label="Edit"><Icon className='editIcon'>edit_icon</Icon></Button></Box>
                        <Button onClick={this.removeMessage} className='actionBtn actionBtn--remove action' variant="fab" mini aria-label="Remove"><Icon className='offer_icon removeIcon'>delete</Icon></Button>
                    </div>

                    <div className={isTooltipVisible ? 'message--active admin' : 'message admin'}>
                        {titileMessage ? titileMessage : "No title"}
                    </div>

                    {isTooltipVisible && 
                    <div className='messageTooltipWrapper'>
                        <div className='messageTooltip'>{renderHTML(textMessage)}</div>
                    </div>}
                </div>
            ) : (
                <div className='message_section' onClick={() => this.setState({ isTooltipVisible: true })} ref={this.messageRef}>
                    <div className={isTooltipVisible ? 'message--active' : 'message'}>
                        {titileMessage ? titileMessage : "No title"}
                    </div>
                    {isTooltipVisible && 
                    <div className='messageTooltipWrapper'>
                        <div className='messageTooltip'>{renderHTML(textMessage)}</div>
                    </div>}
                </div>
            )
        )
    }
}

export default Message