import React, { Component } from 'react';
import { Button, Tooltip, Icon } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import Box from '@mui/material/Box';

import './supplierList.scss';

import RemoveDialog from '../../../../components/dialogs/removePopup';
import store from '../../../../store';
import {  
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
   } from '../../../../actions/actions';

import { getTelcoAllSuppliers, removeTelcoSupplier, baseUrlUploads } from '../../../../service';

class SupplierAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            status: 'pendding',
            redirect: null,
            id: null,
            dialogRemove: false,
        }

        this.removeSupplier = this.removeSupplier.bind(this);
        this.editSupplier = this.editSupplier.bind(this);
        this.askRemove = this.askRemove.bind(this);
        this.cancelRemove = this.cancelRemove.bind(this);
    }

    componentWillMount() {
        store.dispatch({ type: OPEN_LOADING, text: 'Loading suppliers...' });
        getTelcoAllSuppliers().then(result => {
            const suppliers = result.data.suppliers;

            this.setState({
                suppliers,
                state: 'loaded'
            });

            store.dispatch({ type: CLOSE_LOADING });
        }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't load suppliers` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    askRemove(id) {
        this.setState({ dialogRemove: true, removeId: id });
    }

    cancelRemove() {
        this.setState({ dialogRemove: false, removeId: null });
    }

    removeSupplier() {
        const { suppliers, removeId: id } = this.state;

        this.cancelRemove();

        removeTelcoSupplier(id).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Supplier been removed', open: true});
        }).catch(err => {
            console.log('can"t remove the supplier');

            this.setState({suppliers});
        })

        const removedSupplier = suppliers.filter(supplier => supplier.id != id);

        this.setState({ suppliers: removedSupplier });
    }

    editSupplier(id) {
        this.setState({
            id: id,
            redirect: true
        });
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_telco' });
    }

    render() {
        const { suppliers, redirect, id, dialogRemove } = this.state;

        if(redirect)
            return (<Redirect to={'/'+id} />)

        return (
            <div className='supplier_section'>
                <Link to='/new'>
                    <Box sx={{width: 'fit-content', marginLeft: 'auto'}}>
                        <Tooltip title='Add new'>
                            <Button className='add_button'><span className='add_button--plusSign'>+</span><span>add new</span></Button>
                        </Tooltip>
                    </Box>
                </Link>
               
                <Tooltip title='Help'>
                        <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>
                <RemoveDialog open={dialogRemove} remove={this.removeSupplier} close={this.cancelRemove} />

                <div className='header anim_content'>
                </div>

                <div className='content anim_content'>
                    {suppliers.map( sup => {
                        return (
                            <div key={sup.id} className='supplier'>
                                <div className='actions'> 
                                    <div className='action edit' onClick={() => this.editSupplier(sup.id) }>Edit</div>
                                    <div className='action remove' onClick={ () => { this.askRemove(sup.id) }}>Remove</div>
                                </div>

                                <div className='content'>
                                    { sup.img 
                                        ? 
                                        <div className='block_img'>
                                            <img className='image'src={baseUrlUploads+sup.img}/>
                                        </div> 
                                        : 
                                        <div className='block_text'>
                                            {sup.name}
                                        </div> 
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default SupplierAdmin;