import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Link, HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import NewOffer from './new/NewOffer';
import InfoOffer from './info/index';
import OfferList from './list/OfferList';


import store from '../../store';

class Offer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { role } = store.getState().auth;

        return(
            <HashRouter basename='energia/offers'>
                <Switch>
                    <Route path='/new' render={ props => {
                            return (role === 'admin') ?
                                <NewOffer {...props}/>
                                : 
                                <Redirect to='/' />
                        }}/>
                        <Route path='/:id' render={ props => {
                            return (role === 'admin') ?
                               <NewOffer {...props}/>
                                : 
                                <NewOffer {...props}/>
                        }}/>
                    <Route path='/' component={ OfferList }/>
                </Switch>
            </HashRouter>
        )
    }
}

export default Offer;