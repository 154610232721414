import React, { Component, useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useOnClickOutside } from '../../../hooks/clickOutsideHook';

import {
    Button,
    TextField,
    Tooltip,
    Icon,
    Radio,
    MenuItem
} from '@material-ui/core';
import renderHTML from 'react-render-html';

import './index.scss';

import store from '../../../store';

const Script = (props) => {
    const { role } = store.getState().auth;
    const { removeScript, script, editScript, downOrder, upOrder, bottomTooltipPosition, hasExtraSpace } = props;

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const messageRef = useRef();
    useOnClickOutside(messageRef, () => setIsTooltipVisible(false));

    return (

        <div className='messageWrapper'>
        {role === 'admin' && 
            <div className="orderArrowsWrapper">
                {downOrder && <div className="orderArrowWrapper" onClick={downOrder}><div className="triangle-left"></div></div>}
                {upOrder && <div className="orderArrowWrapper" onClick={upOrder}><div className="triangle-right"></div></div>}
                {/* {downOrder && <div className="orderArrowWrapper" onClick={downOrder}><div className="triangle-left"></div></div> } */}
                {/* {upOrder && <div className="orderArrowWrapper" onClick={upOrder}><div className="triangle-right"></div></div>} */}
            </div> 
        }

            <div className='message_section' onClick={() => setIsTooltipVisible(true)} ref={messageRef}>
             {role === 'admin' && 
                <div className='actionAbuttons'>
                    <Box sx={{marginRight: '5px'}}><Button onClick={() => editScript(script)} className='actionBtn actionBtn--edit' variant="fab" mini aria-label="Edit"><Icon className='editIcon'>edit_icon</Icon></Button></Box>
                    <Button onClick={removeScript} className='actionBtn actionBtn--remove action' variant="fab" mini aria-label="Remove"><Icon className='offer_icon removeIcon'>delete</Icon></Button>
                </div>
            }
                    <div className={isTooltipVisible ? 'message--active' : 'message'} >
                        {script.title ? script.title : "No title"}
                    </div>
                    {isTooltipVisible && 
                        <div className={bottomTooltipPosition && !hasExtraSpace ? 'messageTooltipWrapper--bottom' : bottomTooltipPosition && hasExtraSpace ? 'messageTooltipWrapper--bottomRight' :'messageTooltipWrapper'}>
                            <div className='messageTooltip'>{renderHTML(script.text || ``)}</div>
                        </div>
                    }
                </div>
        </div>
    )
}

export default Script;