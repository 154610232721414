import React, { Component } from 'react';
import { 
    Button,
    // TextField,
    Tooltip, 
    IconButton, 
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    Toolbar,
    Icon
} from '@material-ui/core';
import TableContainer from '@mui/material/TableContainer';

import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';

import { Link, Redirect } from 'react-router-dom';
import store from '../../store';
import { 
  OPEN_NOTIFICATION,
  OPEN_LOADING,
  CLOSE_LOADING,
  OPEN_HELP
} from '../../actions/actions'

import RemoveDialog from '../dialogs/removePopup';
import { getAllFields, createField, updateField, removeField, customeGetRequest } from './../../service';

import './manageFields.scss';
import { red } from '@mui/material/colors';

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
      this.props.onRequestSort(event, property);
    };
  
    render() {
      const { onSelectAllClick, order, orderBy, numSelected, rowCount, removeField, location } = this.props;
  
      return (
        <TableHead>
          <TableRow className='row_block'>
            {rows.map(row => {
              return (
                <TableCell
                  key={row.id}
                  style={{borderBottom:"none"}}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {/* <Tooltip
                    title="Sort"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                    </TableSortLabel>
                  </Tooltip> */}
                      {row.label}
                </TableCell>
              );
            }, this)}
            <TableCell align='right' style={{borderBottom:"none"}}>Actions</TableCell>
          </TableRow>
        </TableHead>
      );
    }
  }

function desc(a, b, orderBy) {
  if(!(a && b))
    return -1;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// function stableSort(array, cmp) {
//   const stabilizedThis = array;
//     stabilizedThis.sort((a, b) => {
//       const order = cmp(a, b);

//       if (order !== 0) return order;
      
//       return a - b;
//     });


//   return stabilizedThis.map(el => el);
// }

// function getSorting(order, orderBy) {
//   return order === 'field_name' || orderBy === 'field_name' ? order === 'desc' ? (a, b) => desc(a.field_name, b.field_name, 'name') : (a, b) => -desc(a.field_name, b.field_name, 'name') : order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
// }

const rows = [
  { id: 'field_name', numeric: false, disablePadding: true, label: 'Field name' },
  { id: 'formop_field', numeric: false, disablePadding: true, label: 'FromOp code' },
  { id: 'sync_code_field', numeric: false, disablePadding: true, label: 'Other site code' },
];

class ManageFields extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: 'asc',
            orderBy: 'name',
            selected: [],

            fields: [],
            filteredFields: [],
            filtering: false,
            prev: '', 
            next: '',
            dialogRemove: false,
            removedId: null,
            
            searchText: '',

            count: 0,
            page: 0,
            rowsPerPage: 25,

            field_name: '',
            formop_field: '',
            sync_code_field: '',
            isFieldSelected: false,
            selectedFieldId: '',
        }

        this.search = this.search.bind(this);
        this.askRemove = this.askRemove.bind(this);
        this.askCancel = this.askCancel.bind(this);
        this.onCreateField = this.onCreateField.bind(this);
        this.onUpdateField = this.onUpdateField.bind(this);
        this.removeField = this.removeField.bind(this);
        this.onFieldSelect = this.onFieldSelect.bind(this);
        this.onFieldUnselect = this.onFieldUnselect.bind(this);

    }

    componentWillMount() {
      store.dispatch({ type: OPEN_LOADING, text: 'Loading fields ...' });

      getAllFields().then(result => {

        const { fields } = result.data;

        console.log(result.data)

        if(!fields) {
          this.setState({ fields: [] });
        } else {
          this.setState({ fields: fields });
        }
        store.dispatch({ type: CLOSE_LOADING });

      }).catch(err => {
        store.dispatch({ type: OPEN_LOADING, text: `Can't load fields` });

        setTimeout(() => {
          store.dispatch({ type: CLOSE_LOADING });
        }, 2500)
        console.log(err)
      })
    }


    search(text) {
      this.setState({searchText: text});
      if(text.length == 0)
        return this.setState({ filtering: false, filteredFields: [] })

      const fields = this.state.fields;
      const values = text.split(' ');

      let filteredFields = fields;

      for(let i = 0, leng = values.length; i < leng; i++) {
        const regExp = new RegExp(values[i], 'i');

        filteredFields = filteredFields.filter(field => {
          const { 
            field_name, formop_field, sync_code_field
          } = field;
  
          if(
            regExp.test(field_name)
            || regExp.test(formop_field)
            || regExp.test(sync_code_field)
          )
            return field;
        })
      }
      this.setState({ filteredFields, filtering: true });
    }

    handleRequestSort = (event, property) => {
      const orderBy = property;
      let order = 'desc';
  
      if (this.state.orderBy === property && this.state.order === 'desc') {
        order = 'asc';
      }
  
      this.setState({ order, orderBy });
    };

    requestNextPage() {
      const next = this.state.next;

      if(next)
        customeGetRequest(next).then(result => {
          const { fields, prev, next, count } = result.data;
  
          this.setState({ fields, prev, next, count });
          store.dispatch({ type: CLOSE_LOADING });
        }).catch(err => {
          store.dispatch({ type: OPEN_LOADING, text: `Can't load fields` });
  
          setTimeout(() => {
            store.dispatch({ type: CLOSE_LOADING });
          }, 2500)
        })
    }

    requestPrevPage() {
      const prev = this.state.prev;

      if(prev)
        customeGetRequest(prev).then(result => {
          const { fields, prev, next, count } = result.data;
  
          this.setState({ fields, prev, next, count });
          store.dispatch({ type: CLOSE_LOADING });
        }).catch(err => {
          store.dispatch({ type: OPEN_LOADING, text: `Can't load fields` });
  
          setTimeout(() => {
            store.dispatch({ type: CLOSE_LOADING });
          }, 2500)
        })
    }
    
    handleChangePage = (event, changePage) => {
    //   if(page < changePage)
    //     this.requestNextPage();
    //   else 
    //     this.requestPrevPage();

      this.setState({ page: changePage });
    };
  
    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
      // setPage(0);
    };


    askRemove(id) {
      this.setState({ dialogRemove: true, removeId: id});
    }
    askCancel() {
      this.setState({ dialogRemove: false, removeId: null});
    }

    openHelp() {
      store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    
onCreateField(event) {
    event.preventDefault();
    const { field_name, formop_field, sync_code_field } = this.state;
    const newField = { field_name, formop_field, sync_code_field };

    createField({ ...newField }).then(result => {
        newField.id =  result.data.field.id;;

        const fields = this.state.fields;
        this.setState({ field_name: "", formop_field: "", sync_code_field: "" });
        fields.push({...newField});
        this.setState({ fields });
        store.dispatch({type: OPEN_NOTIFICATION, text: 'New fields was added', open: true});
        
    }).catch(err => {
      console.log(err)
    })
}

onFieldSelect(id) {
  let fields = this.state.fields;
  let selectedFiled = fields.find(x => x.id === id);
  let {field_name, formop_field, sync_code_field} = selectedFiled;
  this.setState({ field_name, formop_field, sync_code_field });
  this.setState({ isFieldSelected: true });
  this.setState({selectedFieldId: id})
}

onFieldUnselect() {
  this.setState({ field_name: "", formop_field: "", sync_code_field: "" });
  this.setState({ isFieldSelected: false });
  this.setState({selectedFieldId: ''})
}

onUpdateField(event) {

  event.preventDefault()
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();

  // event.stopPropagation();

  const { field_name, formop_field, sync_code_field, selectedFieldId } = this.state;
  const updatedField = { field_name, formop_field, sync_code_field };

  updateField(selectedFieldId, { ...updatedField }).then(result => {
    let fields  = this.state.fields;
    let fieldIndex = fields.findIndex(element => element.id == selectedFieldId );
    let updatedFields = fields;
    updatedFields[fieldIndex] = {...updatedFields[fieldIndex], ...updatedField}
    this.setState({ fields: updatedFields});

    this.setState({ field_name: "", formop_field: "", sync_code_field: "" });
    this.setState({ isFieldSelected: false });
    this.setState({selectedFieldId: ''})
    store.dispatch({type: OPEN_NOTIFICATION, text: 'Fields updated', open: true});

  }).catch(err => {
    console.log('err', err)
})
}


removeField(event)  {
  event.stopPropagation();

  const {fields, removeId: id} = this.state;

  this.askCancel();
  removeField(id).then(result => {
    store.dispatch({type: OPEN_NOTIFICATION, text: 'Fields was removed', open: true});
    const filteredFields = fields.filter(offer => offer.id != id);
    this.setState({ fields: filteredFields });
  }).catch(err => {
    this.setState({ fields });
  })

}

changeInput(name, text) {
  const state = this.state;
  state[name] = text;
  this.setState(state);
}



    render() {
        const {
            order,
            orderBy,
            selected,
            fields,
            filteredFields,
            filtering,
            page,
            rowsPerPage,
            selectedOffer,
            dialogRemove,
            count,
            searchText,

            field_name,
            formop_field,
            sync_code_field,
            isFieldSelected,

        } = this.state;
        const {open: loading} = store.getState().loading;

        const data = (filteredFields[0] || filtering) ? filteredFields : fields;

        return (
            <div className={loading ? 'hide' : 'offer_section anim_content'}>
                   
                    <Tooltip title='Help'>
                        <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                    </Tooltip>
                    <RemoveDialog open={dialogRemove} remove={this.removeField} close={this.askCancel}/>
                <
                  div className='header'>
                  <TextField onChange={event => this.search(event.target.value)} className='search' variant="outlined" size="medium" value={searchText} label='Search'/>
                </div>
                    <form
                      onSubmit={isFieldSelected ? this.onUpdateField : this.onCreateField }
                      autoComplete="off"
                      className="manageFields__mainActions"
                    >
                      <div className="manageFields__inputsWrapper">
                            <TextField
                                value={field_name}
                                label="Field name"
                                onChange={event => this.changeInput('field_name', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                required
                            />
                            <TextField
                                value={formop_field}
                                label="FormOp code"
                                onChange={event => this.changeInput('formop_field', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                required
                            />
                             <TextField
                                value={sync_code_field}
                                label="Other site code"
                                onChange={event => this.changeInput('sync_code_field', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                required
                            />
                            </div>

                            {isFieldSelected ? 
                            <>
                              <Box sx={{ display: 'flex', mt: '7px'}}>
                                <Box sx={{ mr: '10px' }}>
                                  <Button variant="outlined" onClick={() => this.onFieldUnselect()}>
                                      Cancel
                                  </Button>
                                </Box>
                                  <Button variant="contained" type="submit" className="manageFields__updateBtn">
                                    Update
                                </Button>
                              </Box>
                            </>
                            :
                            <Tooltip title='Add new'>
                                <Button type="submit" className='add_button'><span className='add_button--plusSign'>+</span><span>add new</span></Button>
                            </Tooltip>
                            }
                            </form>

                <div className='tableWrapper'>
                    {/* <div> */}
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                    <Table aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={count}
                            />
                        <TableBody>
                        {/* {stableSort(data, getSorting(order, orderBy)) */}
                         {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((fieldsGroup, i) => {
                            return (
                              <TableRow
                                    sx={{ "& > * ": { borderBottom: "none" } }}
                                    className={i % 2 ? "oddRow" : "evenRow"}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={fieldsGroup.field_name + i}
                                    >
                                    <TableCell style={{borderBottom:"none"}}>{fieldsGroup.field_name}</TableCell>
                                    <TableCell style={{borderBottom:"none"}}>{fieldsGroup.formop_field}</TableCell>
                                    <TableCell style={{borderBottom:"none"}}>{fieldsGroup.sync_code_field}</TableCell>
                                    <TableCell style={{borderBottom:"none"}}  className='actions_table'>
                                        <Tooltip title='Edit'><Button onClick={() => this.onFieldSelect(fieldsGroup.id)} className='actionBtn action -edit' variant="fab" mini aria-label="Edit"><Icon className='offer_icon editIcon'>edit_icon</Icon></Button></Tooltip>
                                        <Tooltip title='Remove'><Button onClick={(event) => {event.stopPropagation(); this.askRemove(fieldsGroup.id)}} className='actionBtn actionBtn--remove action' variant="fab" mini aria-label="Remove"><Icon className='offer_icon removeIcon'>delete</Icon></Button></Tooltip>
                                    </TableCell>
                             </TableRow>
                            );
                          })}

                        </TableBody>
                    </Table>
                    </TableContainer>
                    {/* </div> */}
                    <TablePagination
                      component="div"
                      backIconButtonProps={{
                          'aria-label': 'Previous Page',
                      }}
                      nextIconButtonProps={{
                          'aria-label': 'Next Page',
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      // rowsPerPageOptions={[25, 100]}
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}

                    />
                    </Paper>
                  </div>
            </div>
        )
    }
}

export default ManageFields;