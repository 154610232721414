import React, { Component } from 'react';
import {
    Button,
    TextField,
    Tooltip,
    Icon,
    Radio,
    MenuItem
} from '@material-ui/core';
import renderHTML from 'react-render-html'
import { Link, Redirect } from 'react-router-dom';
import { parse } from 'query-string';

import { OPEN_HELP } from './../../actions/actions';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import { getTelcoAllNumbers, removeTelcoNumber } from '../../service';
import store from '../../store';

import './index.scss';

import Number from './number';
import Dialog from './dialog';
import RemoveDialog from '../../components/dialogs/removePopup';

class TelcoNumbers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numbers: [],

            statusPopup: false,
            statusRemovePopup: false,
            selectedNumber: null,
            open: false,
        }

        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.openRemovePopup = this.openRemovePopup.bind(this);
        this.closeRemovePopup = this.closeRemovePopup.bind(this);

        this.createNumber = this.createNumber.bind(this);
        this.updateNumber = this.updateNumber.bind(this);
        this.removeNumber = this.removeNumber.bind(this);
    }

    componentDidMount() {
        getTelcoAllNumbers().then(resp => {
            const { ok, numbers } = resp.data;

            if(!ok)
                return 

            this.setState({ numbers });
        })
    }

    openPopup(number = null) {
        this.setState({ statusPopup: true, selectedNumber: number });
    }

    closePopup() {
        this.setState({ statusPopup: false, selectedNumber: null });
    }

    openRemovePopup(number = null) {
        this.setState({ statusRemovePopup: true, selectedNumber: number });
    }

    closeRemovePopup() {
        this.setState({ statusRemovePopup: false, selectedNumber: null });
    }

    createNumber(payload = null) {
        if(!payload)
            return this.forceUpdate();

        const numbers = this.state.numbers;

        numbers.push(payload);

        this.setState({ numbers });
    }

    updateNumber(id, payload) {
        const { numbers } = this.state;
        const { head, text, number } = payload;

        const updatedNumbers = numbers.map(num => {
            if(num.id == id)
                return {
                    id,
                    head,
                    text,
                    number,
                }

            return num;
        });

        this.setState({ numbers: updatedNumbers });
    }

    removeNumber() {
        const { selectedNumber: { id }, numbers } = this.state;

        const filteredNumbers = numbers.filter(number => number.id != id);

        this.setState({ numbers: filteredNumbers, statusRemovePopup: false, selectedNumber: null });

        removeTelcoNumber(id).then(resp => {

        })
    }

    openHelp() {
      store.dispatch({ type: OPEN_HELP, component: 'proposal_telco' });
  }

    render() {
        const { role } = store.getState().auth;
        const {
            numbers,
            statusPopup,
            statusRemovePopup,

            selectedNumber,
            open
        } = this.state;

        const id = selectedNumber ? selectedNumber.id : '';

        return (
            <div className='numbers_section'>
                {role == 'admin' && (
                    <Box sx={{ width: "fit-content", marginLeft: "auto" }}>
                        <Tooltip title='Add new'>
                        <Button className="add_button" onClick={this.openPopup}>
                            <span className="add_button--plusSign">+</span>
                            <span>add new</span>
                        </Button>
                        </Tooltip>
                    </Box>
                )}

                <Tooltip title='Help'>
                    <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>

                {statusPopup && <Dialog open={statusPopup} id={id} number={selectedNumber} handleClose={this.closePopup} addNumber={this.createNumber} updateNumber={this.updateNumber}/>}
                <RemoveDialog remove={this.removeNumber} open={statusRemovePopup} close={this.closeRemovePopup}/>

                <div className='content'>
                    <div className="numbersTableWrapper">
                        <TableContainer className="numbersTable">
                            <Table aria-label="collapsible table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {numbers.map((row, i) => (
                                    <Row
                                    key={row.name}
                                    row={row}
                                    index={i}
                                    removeNumber={() => this.openRemovePopup(row)}
                                    editNumber={() => this.openPopup(row)}
                                    />
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        )
    }
}

export default TelcoNumbers;


function Row(props) {
    const { row, index, editNumber, removeNumber } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > * ": { borderBottom: "none" } }}
          className={index % 2 ? "oddRow" : "evenRow"}
        >
          <TableCell onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
            {row.head}
          </TableCell>
          <TableCell style={{ borderBottom: "none" }} className="actionsButtons">
            <Tooltip title="Edit">
              <Button
                onClick={() => editNumber()}
                className="actionBtn action actionBtn--edit"
                variant="fab"
                mini
                aria-label="Edit"
              >
                <Icon className="offer_icon editIcon">edit_icon</Icon>
              </Button>
            </Tooltip>
            <Tooltip title="Remove">
              <Button
                onClick={(event) => removeNumber()}
                className="actionBtn actionBtn--remove action"
                variant="fab"
                mini
                aria-label="Remove"
              >
                <Icon className="offer_icon removeIcon">delete</Icon>
              </Button>
            </Tooltip>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              className="expandTableBtnWrapper"
            >
              <div onClick={() => setOpen(!open)}>
                {open ? (
                  <div className="triangle-top"></div>
                ) : (
                  <div className="triangle-down"></div>
                )}
              </div>
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{ border: "none", background: "#E4ECF8" }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className="collapse_text">{renderHTML(row.text)}</div>
              <div className="collapse_number">{row.number}</div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }