import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class RemoveDialog extends Component {
  constructor(props) {
      super();

      this.remove = props.remove;
      this.close = props.close;
  }

  render() {
    const open = this.props.open;

    return (
        <Dialog
          open={open}
          onClose={this.close}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Remove</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure what you want to remove?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.close} color="primary">
              Close
            </Button>
            <Button onClick={this.remove} color="primary" autoFocus>
              Remove
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default RemoveDialog;