import React, { Component, useState } from 'react';
import { 
    TextField,
    Button
} from '@material-ui/core';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import {useGoogleLogin} from "@react-oauth/google";

import * as service from '../../service';
import { LOGIN } from '../../actions/actions';

import path from '../../images/path';
import style from './style';
import './login.scss';

const mapStateToProps = state => ({
    ...state,
    auth: state.auth,
    role: state.role
})

const mapDispatchToProps = dispatch => ({
    logined: (auth, role) => {
        return dispatch({type: LOGIN, auth, role})
    }
})

const Login = (props) => {
    const [state, setState] = useState({
        login: null,
        pass: null,
        auth: null,
        sectionName: 'login'
    });

    const changeText = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const login = (event) => {
        event.preventDefault();

        const loginButton = document.getElementById('login-button');
        loginButton.classList.remove('amin-login-fail');

        const { login, pass } = state;

        service.login({ login, pass }).then(result => {
            // const { ok, role } = result.data;
            // this.setState({auth: ok});
            // this.props.logined(ok, role);

            changeText('sectionName', 'googleVerification');

        }).catch(err => {
            loginButton.classList.add('amin-login-fail');

            console.log(err);
        })
    };

    const loginGoogle = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async (codeResponse) => {
          // console.log("codeResponse", codeResponse);
          responseGoogle(codeResponse.code);
        },
        onError: (errorResponse) => console.log(errorResponse),
      });

    const responseGoogle = (response) => {
        let errorElement = document.getElementById("googleVerificationErrorText");

        service.loginVerifyEmail({ token: response }).then(result => {
            if(result.data.ok) {
                changeText('sectionName', 'verificationSuccessSection');

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                errorElement.textContent += "Verification fail";
            }
        }).catch(err => {
            console.log(err)
        })
    };

    const auth = state.auth;

    if (auth) window.location.reload();

    return (
        <div style={ style.formBlock }>
                <img className='login_logo' src={ path.logo } />
                
                { state.sectionName === 'login' &&
                    <form style={ style.form } onSubmit={login}>
                        <div className='login_fields' style={ style.form }>
                            <TextField required label="Login" type='text' style={ style.loginFl } onChange={ value => changeText('login', value.target.value) }/>
                            <TextField required label="Password" type="password" style={ style.loginFl } onChange={value => changeText('pass', value.target.value)}/>
                        </div>
                        <Button id='login-button' style={ style.button } variant="contained" size="medium" type='submit'>Login</Button>
                    </form>
                }

                { state.sectionName === 'googleVerification' &&
                    <div className="googleVerification">
                        <div className="loginPage__text">Verify your E-mail!</div>
                        <div className="googleVerification__verifyBtn">
                        <button className="menu__googleBtn" onClick={() => loginGoogle()}>
                            <img src="./images/logo/google.svg" />
                            Google Auth
                        </button>
                        </div>
                        <div id="googleVerificationErrorText" className="loginPage__text--error"></div>
                    </div>
                }


                {state.sectionName === 'verificationSuccessSection' &&
                    <div className="verificationSuccess">
                        <div className="verificationSuccess__successImgWrapper">
                            <object type="image/svg+xml" className="verificationSuccess__successImg" data={'/images/verificationSuccess.svg'}></object>
                        </div>
                        <div className="loginPage__text">Verify your E-mail!</div>
                        <div className="verificationSuccess__text">Your email has been successfully verified.</div>
                        <Button onClick={() =>  window.location.reload()} style={ style.button } variant="contained" size="medium">Ok</Button>
                    </div>
                }

            </div>
    );
};

/*
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: null,
            pass: null,
            auth: null,
            sectionName: 'login'
        }

        this.login = this.login.bind(this);
        this.changeText = this.changeText.bind(this);
    }

    changeText(name, value) {
        const data = {};
        data[name] = value.target.value;

        this.setState(data);
    }

    login(event) {
        event.preventDefault();

        const loginButton = document.getElementById('login-button');
        loginButton.classList.remove('amin-login-fail');

        const { login, pass } = this.state;

        service.login({ login, pass }).then(result => {
            // const { ok, role } = result.data;
            // this.setState({auth: ok});
            // this.props.logined(ok, role);

            this.setState({ sectionName: 'googleVerification' })

        }).catch(err => {
            loginButton.classList.add('amin-login-fail');

            console.log(err);
        })
    }

    responseGoogle = (response) => {
        let errorElement = document.getElementById("googleVerificationErrorText");

        service.loginVerifyEmail({ token: response.tokenId }).then(result => {
            if(result.data.ok) {
                this.setState( { sectionName: 'verificationSuccessSection' });

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                errorElement.textContent += "Verification fail";
            }
        }).catch(err => {
            console.log(err)
        })
    }


    render() {
        const auth = this.state.auth;

        if(auth)
            window.location.reload();

        return (
                <div style={ style.formBlock }>
                <img className='login_logo' src={ path.logo } />
                
                { this.state.sectionName === 'login' &&
                    <form style={ style.form } onSubmit={this.login}>
                        <div className='login_fields' style={ style.form }>
                            <TextField required label="Login" type='text' style={ style.loginFl } onChange={ value => this.changeText('login', value) }/>
                            <TextField required label="Password" type="password" style={ style.loginFl } onChange={value => this.changeText('pass', value)}/>
                        </div>
                        <Button id='login-button' style={ style.button } variant="contained" size="medium" type='submit'>Login</Button>
                    </form>
                }

                { this.state.sectionName === 'googleVerification' &&
                    <div className="googleVerification">
                        <div className="loginPage__text">Verify your E-mail!</div>
                        <div className="googleVerification__verifyBtn">
                            <GoogleLogin
                                clientId="189062098162-9gh56buubhni49rrqorkgi7mvg5islv9.apps.googleusercontent.com"
                                buttonText="Verify with Google"
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                        <div id="googleVerificationErrorText" className="loginPage__text--error"></div>
                    </div>
                }


                {this.state.sectionName === 'verificationSuccessSection' &&
                    <div className="verificationSuccess">
                        <div className="verificationSuccess__successImgWrapper">
                            <object type="image/svg+xml" className="verificationSuccess__successImg" data={'/images/verificationSuccess.svg'}></object>
                        </div>
                        <div className="loginPage__text">Verify your E-mail!</div>
                        <div className="verificationSuccess__text">Your email has been successfully verified.</div>
                        <Button onClick={() =>  window.location.reload()} style={ style.button } variant="contained" size="medium">Ok</Button>
                    </div>
                }

            </div>
        );
    }
}
*/

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);