import React, { Component } from 'react';
import { 
    Button,
    // TextField,
    Tooltip, 
    IconButton, 
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    Toolbar,
    Icon
} from '@material-ui/core';
import TableContainer from '@mui/material/TableContainer';
import PausaDialog from '../../components/dialogs/pausaPopup';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import { DatePicker, InlineDatePicker } from 'material-ui-pickers';
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils';

import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';

import { Link, Redirect } from 'react-router-dom';
import store from '../../store';
import { 
  OPEN_NOTIFICATION,
  OPEN_LOADING,
  CLOSE_LOADING,
  OPEN_HELP
} from '../../actions/actions'

import { setBreakConfig, getBreakConfig, getMyPauseStatus, getBreakUsers, setBreak, generateTimeReport, stopBreakForOperator } from './../../service';

import './gestione.scss';
import { red } from '@mui/material/colors';
import moment from 'moment';

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
      this.props.onRequestSort(event, property);
    };
  
    render() {
      const { role } = store.getState().auth;
      const { onSelectAllClick, order, orderBy, numSelected, rowCount, removeField, location } = this.props;

      const headers = role !== 'admin' ? rows.filter(row => !row.admin) : rows;
  
      return (
        <TableHead>
          <TableRow className='row_block'>
            {headers.map(row => {
              return (
                <TableCell
                  key={row.id}
                  style={{borderBottom:"none"}}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {/* <Tooltip
                    title="Sort"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                    </TableSortLabel>
                  </Tooltip> */}
                      {row.label}
                </TableCell>
              );
            }, this)}
          </TableRow>
        </TableHead>
      );
    }
  }

function desc(a, b, orderBy) {
  if(!(a && b))
    return -1;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// function stableSort(array, cmp) {
//   const stabilizedThis = array;
//     stabilizedThis.sort((a, b) => {
//       const order = cmp(a, b);

//       if (order !== 0) return order;
      
//       return a - b;
//     });


//   return stabilizedThis.map(el => el);
// }

// function getSorting(order, orderBy) {
//   return order === 'field_name' || orderBy === 'field_name' ? order === 'desc' ? (a, b) => desc(a.field_name, b.field_name, 'name') : (a, b) => -desc(a.field_name, b.field_name, 'name') : order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
// }

const rows = [
  { id: 'name', numeric: false, disablePadding: true, label: 'None Cognome' },
  { id: 'pauseTeam', numeric: false, disablePadding: true, label: 'Dipartimento' },
  { id: 'pauseTime', numeric: false, disablePadding: true, label: 'Inizio Pausa' },
  { id: 'actions', numeric: false, disablePadding: true, label: 'Actions', admin: true },
];

class GestionePause extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            config: {
                energia: 0,
                telco: 0,
                energia_w: 0,
                telco_w: 0
            },
            currUser: null,
            openPopup: false,
            start_date: new Date(),
            end_date: new Date(),
            timer: 0
        }

        this.changeConfig = this.changeConfig.bind(this);
        this.changePauseStatus = this.changePauseStatus.bind(this);
        this.setConfig = this.setConfig.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.generateReport = this.generateReport.bind(this);
        this.stopPauseOperator = this.stopPauseOperator.bind(this);
        this.updateElapsedTime = this.updateElapsedTime.bind(this);
    }

    componentWillMount() {
      store.dispatch({ type: OPEN_LOADING, text: 'Loading fields ...' });

      getMyPauseStatus().then(result => {
        const { status } = result.data;

        this.setState({ currUser: { ...status }});
      })

      setInterval(this.updateElapsedTime, 1000);

      getBreakUsers().then(result => {
        const { users } = result.data;

        console.log(users, 'users')

        this.setState({ users });
      })

      getBreakConfig().then(result => {

        const { config } = result.data;

        console.log(result.data, 'config')

        if(config) {
            this.setState({ config });
        }

        store.dispatch({ type: CLOSE_LOADING });

      }).catch(err => {
        store.dispatch({ type: OPEN_LOADING, text: `Can't load fields` });

        setTimeout(() => {
          store.dispatch({ type: CLOSE_LOADING });
        }, 2500)
        console.log(err)
      })
    }

    stopPauseOperator(id) {
      const { currUser } = this.state;

      stopBreakForOperator(id).then(resp => {
        getBreakUsers().then(result => {
            const { users } = result.data;
    
            this.setState({ users });

            if(currUser.id === id) {
              const active = !currUser?.pause;

              this.setState({ currUser: { ...currUser, pause: active, pauseTime: new Date() }});
            }
        })
      });
    }

    updateElapsedTime = () => {
      if(!this.state.currUser?.pauseTime || !this.state.currUser?.pause)
        return;

      const startDate = new Date(this.state.currUser.pauseTime);
      const currentTime = new Date();
      const difference = currentTime.getTime() - startDate.getTime();

      this.setState({ timer: difference });
    };

    changePauseStatus() {
        const { currUser, openPopup, users, config } = this.state;

        if(!openPopup && !currUser?.pause) {
            const telcoUsers = users.filter(user => user.pauseTeam === 'telco').length;
            const energiaUsers = users.filter(user => user.pauseTeam === 'energia').length;

            if(currUser.pauseTeam === 'energia') {
                if(checkSaturday()) {
                    if(energiaUsers >= config.energia_w) {
                        return this.showPopup();
                    }
                } else {
                    if(energiaUsers >= config.energia) {
                        return this.showPopup();
                    }
                }
            } else {
                if(checkSaturday()) {
                    if(telcoUsers >= config.telco_w) {
                        return this.showPopup();
                    }
                } else {
                    if(telcoUsers >= config.telco) {
                        return this.showPopup();
                    }
                }
            }
        }

        const active = !currUser?.pause;

        setBreak({ pause: active }).then(resp => {
            getBreakUsers().then(result => {
                const { users } = result.data;
        
                this.setState({ users });
            })
        });
        
        this.setState({ currUser: { ...currUser, pause: active, pauseTime: new Date() }});
    }

    showPopup() {        
        this.setState({ openPopup: !this.state.openPopup  });
    }

    setConfig() {
        const { config } = this.state;

        setBreakConfig(config).then(resp => {
            store.dispatch({ type: OPEN_LOADING, text: `Configs saved!` });

            getBreakConfig().then(result => {

                const { config } = result.data;

                console.log(result.data, 'config')

                if(config) {
                    this.setState({ config });
                }

                store.dispatch({ type: CLOSE_LOADING });

                }).catch(err => {
                store.dispatch({ type: OPEN_LOADING, text: `Can't load fields` });

                setTimeout(() => {
                    store.dispatch({ type: CLOSE_LOADING });
                }, 2500)
                console.log(err)
            })
        })
    }

    changeConfig(name, text) {
      const state = this.state;
      state.config[name] = text;
      this.setState(state);
    }

    generateReport() {
      const {
        start_date,
        end_date
      } = this.state;

      const startDate = moment(start_date).format('MM-DD-YYYY');
      const endDate = moment(end_date).format('MM-DD-YYYY');

      const query = `startDate=${startDate}&endDate=${endDate}`;

      generateTimeReport(query).then(resp => {
        const { file } = resp.data;

        window.open('/reports/' + file, '_timeReport');
      })
    }


    render() {
        const { role } = store.getState().auth;
        
        const {
            order,
            orderBy,
            selected,
            fields,
            filteredFields,
            filtering,
            page,
            rowsPerPage = 100,
            selectedOffer,
            dialogRemove,
            count,
            searchText,

            users,

            field_name,
            formop_field,
            sync_code_field,
            isFieldSelected,

            config: { telco, energia, telco_w, energia_w },
            currUser,
            openPopup,
            start_date,
            end_date,

            timer

        } = this.state;
        const {open: loading} = store.getState().loading;

        const telcoUsers = users.filter(user => user.pauseTeam === 'telco').length;
        const energiaUsers = users.filter(user => user.pauseTeam === 'energia').length;
  
        const seconds = Math.floor(timer / 1000);
    
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return (
            <div className={loading ? 'hide' : 'offer_section anim_content'}>
                    <PausaDialog open={openPopup} close={this.showPopup} remove={this.changePauseStatus}/>
                   
                    <Tooltip title='Help'>
                        <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                    </Tooltip>
                <div className='header_pausa'>
                   { currUser && currUser.pause &&
                        <div onClick={this.changePauseStatus} className='pause_button_active'>
                            STOP PAUSA
                        </div>
                    }

                    { currUser && !currUser.pause &&
                         <div onClick={this.changePauseStatus} className='pause_button'>
                            INIZIO PAUSA
                        </div>
                    }
                    { currUser?.pause && <div>
                      <p style={{ paddingLeft: '10px', marginTop: '10px'}}>{hours} hours, {minutes} minutes, {remainingSeconds} seconds</p>
                    </div>}
                    <div className='statistic' style={{ marginTop: '40px'}}>
                        <div className='statistic_header'>OPERATORI IN PAUSA</div>
                        <div className='statistic_info'>
                            <div>Energia: {energiaUsers}/{energia}</div>
                            <div>Telco: {telcoUsers}/{telco}</div>
                        </div>
                    </div>
                </div>
                    { role === 'admin' && <form
                      autoComplete="off"
                      className="manageFields__mainActions"
                    >
                        <div>
                            <div style={{ color:'#598BCA' }}>MONDAY TO FRIDAY</div>
                            <div className="manageFields__inputsWrapper">
                                <TextField
                                    value={telco}
                                    label="Telco"
                                    name='telco'
                                    onChange={event => this.changeConfig('telco', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    required
                                />
                                <TextField
                                    value={energia}
                                    label="Energia"
                                    name='energia'
                                    onChange={event => this.changeConfig('energia', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    required
                                />
                            </div>

                            <div style={{ color:'#598BCA' }}>SATURDAY</div>
                            <div className="manageFields__inputsWrapper">
                                <TextField
                                    value={telco_w}
                                    label="Telco"
                                    name='telco_w'
                                    onChange={event => this.changeConfig('telco_w', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    required
                                />
                                <TextField
                                    value={energia_w}
                                    label="Energia"
                                    name='energia_w'
                                    onChange={event => this.changeConfig('energia_w', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    required
                                />
                            </div>

                            <div style={{ marginTop: '10px'}}>
                                <Tooltip title='Add new'>
                                    <Button onClick={this.setConfig} className='add_button'><span>Update Pausa</span></Button>
                                </Tooltip>
                            </div>

                            { role === 'admin' && <div style={{ marginTop: '10px', display: 'flex'}}>
                              <MuiThemeProvider theme={materialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="picker">
                                        <InlineDatePicker
                                            format="dd/MM/yyyy"
                                            label="Start Date"
                                            value={start_date}
                                            onChange={ event => this.setState({ start_date: event })}
                                        />
                                    </div>
                                    </MuiPickersUtilsProvider>
                                </MuiThemeProvider>

                                <MuiThemeProvider theme={materialTheme}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <div className="picker">
                                          <InlineDatePicker
                                              format="dd/MM/yyyy"
                                              label="End Date"
                                              value={end_date}
                                              onChange={ event => this.setState({ end_date: event })}
                                          />
                                      </div>
                                      </MuiPickersUtilsProvider>
                                  </MuiThemeProvider>

                                  <Tooltip title='Add new'>
                                      <Button onClick={this.generateReport} className='add_button'><span>Generate</span></Button>
                                  </Tooltip>
                            </div>}
                        </div>
                    </form>
                    }
                <div className='tableWrapper' style={{ marginTop: '40px'}}>
                    {/* <div> */}
                    <Paper style={{ width: '600px'}} sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                    <Table aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={users.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={count}
                            />
                        <TableBody>
                        {/* {stableSort(data, getSorting(order, orderBy)) */}
                         {users
                            .map((fieldsGroup, i) => {
                            return (
                              <TableRow
                                    sx={{ "& > * ": { borderBottom: "none" } }}
                                    className={i % 2 ? "oddRow" : "evenRow"}
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={fieldsGroup.field_name + i}
                                    >
                                    <TableCell style={{borderBottom:"none"}}>{fieldsGroup.name}</TableCell>
                                    <TableCell style={{borderBottom:"none"}}>{fieldsGroup.pauseTeam ? fieldsGroup.pauseTeam : 'Not set'}</TableCell>
                                    <TableCell style={{borderBottom:"none"}}>{fieldsGroup.pauseTime ? moment(fieldsGroup.pauseTime).format('HH:mm:ss'): 'Not set'}</TableCell>
                                    { role === 'admin' && <TableCell style={{borderBottom:"none"}}>
                                      <Tooltip title='Remove'><Button onClick={(event) => {this.stopPauseOperator(fieldsGroup.id)}} className='actionBtn actionBtn--remove action' variant="fab" mini aria-label="Remove"><Icon className='offer_icon removeIcon'>delete</Icon></Button></Tooltip>
                                    </TableCell>}
                             </TableRow>
                            );
                          })}

                        </TableBody>
                    </Table>
                    </TableContainer>
                    {/* </div> */}
                    </Paper>
                  </div>
            </div>
        )
    }
}

export default GestionePause;

const checkSaturday = () => {
    const currentDate = new Date();

    const currentDay = currentDate.getDay();

    return currentDay === 6;
}


const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#1460AA',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#1460AA'
      },
      selected: {
        backgroundColor: '#1460AA'
      },
      current: {
        color: '#1460AA'
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#1460AA'
      }
    }
  }
});