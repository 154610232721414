import { OPEN_NOTIFICATION, CLOSE_NOTIFICATION } from '../actions/actions';

export default (state = { open: false, text: null }, action) => {
    switch(action.type) {
        case OPEN_NOTIFICATION: 
            return {
                ...state,
                open: action.open,
                text: action.text
            }

        case CLOSE_NOTIFICATION:
            return {
                ...state,
                open: false,
                text: null
            }

        default:
            return state;
    }
}