import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';


import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router'; 


import { logout } from '../../service';
import Remi from './remi/remi';
import Users from './users/users';
 
import { Link } from 'react-router-dom';
import images from '../../images/path';

import store from '../../store';
import './menu.scss';
import { elementAcceptingRef } from '@mui/utils';
import menuActions from '../../actions/menuActions';

// import {
//     CHANGE_MENU_TYPE,
// } from '../../actions/actions';


// const mapDispatchToProps = dispatch => ({
//     changeMenuType: (menuType) => {
//         return dispatch({type: CHANGE_MENU_TYPE, menuType });
//     }
// }); 

class Menu extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            openUsers: false,
            openRemi: false,
            menuType: "energia"
        }
        
        this.closeRemi = this.closeRemi.bind(this);
        this.closeUsers = this.closeUsers.bind(this);
        this.openRemi = this.openRemi.bind(this);
        this.openUsers = this.openUsers.bind(this);
        this.onChangeMenuType = this.onChangeMenuType.bind(this);
        this.showMenuItems = this.showMenuItems.bind(this);

    }

    

    componentDidMount() {
        if(this.props.location.pathname.includes('telco')){
            this.setState({ menuType: "telco"});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if(this.props.location.pathname.includes('telco')){
                this.setState({ menuType: "telco"});
            }
            else {
                this.setState({ menuType: "energia"})
            }
        }
      }
    
    logout() {
        logout();
    }
    
    openRemi() {
        this.setState({ openRemi: true });
    }
    
    closeRemi() {
        this.setState({ openRemi: false });
    }
    
    openUsers() {
        this.setState({ openUsers: true });
    }
    
    closeUsers() {
        this.setState({ openUsers: false });
    }
    
    onChangeMenuType(type) {
        const { history } = this.props
        this.setState({menuType: type});
        // this.props.changeMenuType({menuType: type})

        if(type === "energia") {
            history.push('/energia')
            console.log('helloo')
        } else {
            history.push('/telco')
        }
    }

    showMenuItems(items) {
        const { role, access } = store.getState().auth;
        const path = this.props.path;
        items = items.filter(x=> (x.link.includes(this.state.menuType) || x.menuType === this.state.menuType));

        console.log('access', access, items)

        if(role === 'user') {
            items = items.filter(x=> x.role != 'admin');
        }

        // let findItem;
        return (
            items.map(elem => {
                
                const regexp = elem.test ? new RegExp(`/${elem.link}$|/${elem.link}/$`, 'i') : new RegExp(`(/${elem.link})`, 'i') ;

                if(elem.external && elem.menuType === this.state.menuType) {
                    return (
                        <>
                            <Button className='menu_button -bigger' onClick={() => {
                                window.open(elem.link, '_ocr');
                            }}>OCR</Button>
                            {elem.space && <div className='menu_buttons_separator'></div>}
                        </>
                    )
                }

                if(elem.access != undefined && role != elem.access) return;

                // const link = `/${elem.link}`;

                // (home)/energia == /energia/offers/123
                //if path /energia -> energia , if path /energia/offers/123 -> /energia/offers
                if (!elem.test && regexp.test(path)) { //partial includes ?
                    // findItem = true
                    //show active menu item
                    return (
                        path == '/' + elem.link ?
                            <><Button key={elem.name} className='menu_button_active'>{elem.name}</Button>
                            {elem.space && <div className='menu_buttons_separator'></div>}</>
                            :
                            <><Link key={elem.name} to={elem.link == '/' ? '/' : '/' + elem.link}>
                                <Button key={elem.name} className='menu_button_active'>{elem.name}</Button>
                            </Link>
                            {elem.space && <div className='menu_buttons_separator'></div>}</>
                    )
                }

                else if (elem.test && regexp.test(path)) { 
                    return (
                        path == '/' + elem.link ?
                            <><Button key={elem.name} className='menu_button_active'>{elem.name}</Button>
                             {elem.space && <div className='menu_buttons_separator'></div>}</>

                            :
                            <><Link key={elem.name} to={elem.link == '/' ? '/' : '/' + elem.link}>
                                <Button key={elem.name} className='menu_button_active'>{elem.name}</Button>
                            </Link>
                            {elem.space && <div className='menu_buttons_separator'></div>}</>
                    )
                }

                //show non active
                else {
                    return (
                        path == elem.link ?
                            <><Button key={elem.name} className='menu_button_active'>{elem.name}</Button>
                             {elem.space && <div className='menu_buttons_separator'></div>}</>
                            :
                            <><Link key={elem.name} to={elem.link == '/' ? '/' : '/' + elem.link}>
                                <Button className='menu_button'>{elem.name}</Button>
                            </Link>
                            {elem.space && <div className='menu_buttons_separator'></div>}</>
                    )
                }
            })

        )
    }

    componentDidUpdate() {
        console.log('path', this.props.path)
    }

    render() {
        const {
            openRemi,
            openUsers,
            menuType,
        } = this.state;

        const { role, access } = store.getState().auth;
        const path = this.props.path;

        const showSwitcher = () => {
            if(role === 'admin' || access === 'dual') {
                return (
                <div className='menu_switcher'>
                    <div className={menuType === 'energia' ? 'menu_switcherBtn--active' : 'menu_switcherBtn'} onClick={() => this.onChangeMenuType('energia')}>Energia</div>
                    <div className={menuType === 'telco' ? 'menu_switcherBtn--active' : 'menu_switcherBtn'} onClick={() => this.onChangeMenuType('telco')}>Telco</div>
                </div> 
                )
            }
        }

        return(
            <div className='menu'>
                <Remi open={openRemi} close={this.closeRemi}/>
                <Users open={openUsers} close={this.closeUsers}/>
                <div>
                    <img className='menu_logo' src={ images.logo }/>
                </div>

                <div className='menu_buttons'>
                    {/* {menuType === "energia"
                        ? this.showMenuItems(menuItems)
                        : this.showMenuItems(telcoMenuItems)
                    } */}

                    { (role === 'admin' || access === 'dual') && <div className='menu_switcher'>
                        <div className={menuType === 'energia' ? 'menu_switcherBtn--active' : 'menu_switcherBtn'} onClick={() => this.onChangeMenuType('energia')}>Energia</div>
                        <div className={menuType === 'telco' ? 'menu_switcherBtn--active' : 'menu_switcherBtn'} onClick={() => this.onChangeMenuType('telco')}>Telco</div>
                    </div>
                    }

                        {(path === '/pause' ?
                                <Button className='menu_button_active'>Gestione Pause</Button>
                                :
                                <Link key='manageFields' to='/pause'>
                                    <Button className='menu_button'>Gestione Pause</Button>
                                </Link>)
                            }


                    {this.showMenuItems([...menuItems, ...telcoMenuItems])}

                    {role === 'admin'
                        &&
                        <>

                            {menuType === 'energia' &&  (path === '/energia/manageFields' ?
                                <Button className='menu_button_active'>Manage fields</Button>
                                :
                                <Link key='manageFields' to='/energia/manageFields'>
                                    <Button className='menu_button'>Manage fields</Button>
                                </Link>)
                            }

                            {menuType === 'telco' &&  (path === '/telco/manageFields' ?
                                <Button className='menu_button_active'>Manage fields</Button>
                                :
                                <Link key='manageFields' to='/telco/manageFields'>
                                    <Button className='menu_button'>Manage fields</Button>
                                </Link>)
                            }

                            {menuType === 'energia' &&  (path === '/energia/users' ?
                                <Button className='menu_button_active'>Users</Button>

                                :
                                <Link key='Users' to='/energia/users'>
                                    <Button className='menu_button'>Users</Button>
                                </Link>)
                            }
                            
                            {menuType === 'telco' &&  (path === '/telco/users' ?
                                <Button className='menu_button_active'>Users</Button>

                                :
                                <Link key='Users' to='/telco/users'>
                                    <Button className='menu_button'>Users</Button>
                                </Link>)
                            }

                            <div className='saparate_button'>
                                <Button onClick={this.openRemi} className='menu_button'>REMI</Button>
                                {/* <Button onClick={this.openUsers} className='menu_button'>Users</Button> */}
                            </div>
                        </>
                    }
 
                {/* <Button className='menu_button -bigger' onClick={() => {
                    window.open('https://selectra.typeform.com/to/xNfjWUha', '_blank');
                }}>Suggerimenti e Segnalazioni</Button> */}

                <Button className='menu_button logout' onClick={this.logout}>Logout</Button>
                </div>
            </div>
        )
    }
}

export default withRouter(Menu);

const menuItems = [
    {
        name: 'Home',
        link: 'energia',
        test: true
    },
    {
        name: 'Scripts',
        link: 'energia/scripts'
    },
    {
        name: 'Proposals',
        link: 'energia/proposals'
    },
    {
        name: 'Comparatore',
        link: 'energia/comparatore',
    },
    {
        name: 'Comparatore Admin',
        link: 'energia/adminComparatore',
        role: 'admin'
    },
    {
        name: 'Numbers',
        link: 'energia/numbers'
    },
    {
        name: 'OCR',
        link: 'https://ocr-energy.web.app/',
        space: true,
        external: true,
        menuType: 'energia'
    },
    {
        name: 'Offers',
        link: 'energia/offers'
    },
    {
        name: 'Price Table',
        link: 'energia/prices'
    },
    {
        name: 'Suppliers',
        link: 'energia/suppliers'
    },
    {
        name: 'Operations',
        link: 'energia/operations',
        space: store.getState().auth.role === 'admin' && true,
    }
]



const telcoMenuItems = [
    {
        name: 'Home',
        link: 'telco',
        test: true
    },
    {
        name: 'Scripts',
        link: 'telco/scripts'
    },
    {
        name: 'Proposals',
        link: 'telco/proposals'
    },
    {
        name: 'Numbers',
        link: 'telco/numbers',
    },
    {
        name: 'OCR',
        link: 'https://ocr-energy.web.app/',
        space: true,
        external: true,
        menuType: 'telco'
    },
    {
        name: 'Offers',
        link: 'telco/offers'
    },
    {
        name: 'Price Table',
        link: 'telco/prices'
    },
    {
        name: 'Suppliers',
        link: 'telco/suppliers'
    },
    {
        name: 'Operations',
        link: 'telco/operations',
        space: store.getState().auth.role === 'admin' && true,
    }
]


