import React, { Component } from 'react';
import { 
    Button,
    // TextField,
    Tooltip, 
    IconButton, 
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    Toolbar,
    Icon,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import moment from 'moment';

import { Link, Redirect } from 'react-router-dom';

import { getTelcoAllOffers, removeTelcoOffer, baseUrlUploads } from './../../../../service';
import store from '../../../../store';
import {  
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
} from '../../../../actions/actions';

import PopupInfo from '../../popupInfo';

import './offerUser.scss';

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
      this.props.onRequestSort(event, property);
    };
  
    render() {
      const { onSelectAllClick, order, orderBy, numSelected, rowCount, removeOffer } = this.props;
  
      return (
        <TableHead>
          <TableRow className='row_block'>
            {rows.map(row => {
              return (
                <TableCell
                  key={row.id}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              );
            }, this)}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
      );
    }
  }

let counter = 0;
function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return { id: counter, name, calories, fat, carbs, protein };
}

function desc(a, b, orderBy) {
  if(!(a && b))
    return -1;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array;
    stabilizedThis.sort((a, b) => {
      const order = cmp(a, b);

      if (order !== 0) return order;
      
      return a - b;
    });

  return stabilizedThis.map(el => el);
}

function getSorting(order, orderBy) {
  return order === 'TelcoSupplier' || orderBy === 'TelcoSupplier' ? order === 'desc' ? (a, b) => desc(a.TelcoSupplier, b.TelcoSupplier, 'name') : (a, b) => -desc(a.TelcoSupplier, b.TelcoSupplier, 'name') : order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'offer_name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'offer_code', numeric: false, disablePadding: true, label: 'Code' },
  { id: 'TelcoSupplier', numeric: false, disablePadding: true, label: 'Supplier' },
  { id: 'client_type', numeric: false, disablePadding: true, label: 'Type' },
  { id: 'exp_date', numeric: false, disablePadding: true, label: 'Valid' },
  // { id: 'duration', numeric: false, disablePadding: true, label: 'durata' },
];

class OfferUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: 'asc',
            orderBy: 'name',
            selected: [],
            offers: [],
            filteredOffers: [],
            filtering: false,
            page: 0,
            rowsPerPage: 25,
            redirect: false,
            selectedOffer: null,
            searchText: localStorage.getItem("searchText"),
            statusPopupInfo: false,
            selectedOffer: null,
            showRedOffers: false
        }

        this.editOffer = this.editOffer.bind(this);
        this.search = this.search.bind(this);
        this.openPopupInfo = this.openPopupInfo.bind(this);
        this.closePopupInfo = this.closePopupInfo.bind(this);
    }

    componentWillMount() {
      store.dispatch({ type: OPEN_LOADING, text: 'Loading offers...' });
      getTelcoAllOffers().then(result => {
        const { offers } = result.data;

        this.setState({ offers });
        store.dispatch({ type: CLOSE_LOADING });

        if(localStorage.getItem("searchText") != null) {
          this.search(localStorage.getItem("searchText"));
        }
      }).catch(err => {
        store.dispatch({ type: OPEN_LOADING, text: `Can't load offers` });
        console.log(err);

        setTimeout(() => {
            store.dispatch({ type: CLOSE_LOADING });
        }, 2500);
    })
    }

    editOffer(id) {
      const offer = this.state.offers.find(offer => offer.id == id );
      this.setState({ selectedOffer: offer, redirect: true });
    }

    removeOffer(id) {
      const offers = this.state.offers;

      store.dispatch({ type: OPEN_LOADING, text: 'Removing the offer...' });
      removeTelcoOffer(id).then(result => {
        store.dispatch({ type: CLOSE_LOADING });
      }).catch(err => {
        this.setState({ offers });
        store.dispatch({ type: OPEN_LOADING, text: `Can't remove the offer` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
      })

      const filteredOffers = offers.filter(offer => offer.id != id);

      this.setState({ offers: filteredOffers });
    }

    search(text) {
      this.setState({searchText: text});
      localStorage.setItem("searchText", text);

      if(text.length == 0)
        return this.setState({ filtering: false, filteredOffers: [] })

      const offers = this.state.offers;
      const values = text.split(' ');

      let filteredOffers = offers;

      for(let i = 0, leng = values.length; i < leng; i++) {
        const regExp = new RegExp(values[i], 'i');

        filteredOffers = filteredOffers.filter(offer => {
          const { 
            offer_name,
            type_product,
            Supplier,
            client_type
          } = offer;
  
          if(
            regExp.test(offer_name)
            || regExp.test(type_product)
            || Supplier && regExp.test(Supplier.name)
            || regExp.test(client_type)
          )
            return offer;
        })
      }

      this.setState({ filteredOffers, filtering: true });
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';
    
        if (this.state.orderBy === property && this.state.order === 'desc') {
          order = 'asc';
        }
    
        this.setState({ order, orderBy });
      };
      
      handleChangePage = (event, page) => {
        this.setState({ page });
      };
    
      handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
      };

    openHelp() {
      store.dispatch({ type: OPEN_HELP, component: 'proposal_telco' });
    }

    openPopupInfo(offer) {
      this.setState({ selectedOfferInfo: offer, statusPopupInfo: true })
    }

    closePopupInfo() {
      this.setState({ selectedOfferInfo: null, statusPopupInfo: false })
    }

    render() {
        const {
            order,
            orderBy,
            selected,
            offers,
            filteredOffers,
            filtering,
            page,
            rowsPerPage,
            redirect,
            selectedOffer,
            searchText,
            statusPopupInfo,
            selectedOfferInfo,
            showRedOffers
            
        } = this.state; 
        const {open: loading} = store.getState().loading;

        const data = (filteredOffers[0] || filtering) ? filteredOffers : offers;

        const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

        if(redirect)
          return <Redirect to={'/'+selectedOffer.id}/>

        return (
            <div className={loading ? 'hide' : 'offer_section anim_content'}>
                <div className='header'>
                  <TextField onChange={event => this.search(event.target.value)} className='search' variant="outlined" size="medium" value={searchText} label='Search'/>
                  <FormControlLabel
                    style={{ position: 'absolute', marginTop: 45 }}
                    control={
                      <Checkbox 
                        name='TEST'
                        value={showRedOffers} 
                        onClick={() => this.setState({ showRedOffers: !showRedOffers })}
                      />
                    }
                    label="Nascondi le offerte rosse"/>
                </div>
                <Tooltip title='Help'>
                        <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>

                {statusPopupInfo && <PopupInfo open={statusPopupInfo} offer={selectedOfferInfo} handleClose={this.closePopupInfo}/> }
                <div className='content'>
                {/* <Paper> */}
                <div className='tableWrapper'>

                    <div>
                    <Table aria-labelledby="tableTitle">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            rowCount={data.length}
                            />
                        <TableBody>
                        {stableSort(data, getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            // .sort((a, b) => {
                            //   const ratingA = a.rating;
                            //   const ratingB = b.rating;

                            //   if(ratingA == 0)
                            //     return 0;

                            //     return ratingA > ratingB ? 1 : ratingA === ratingB ? 0 : -1;
                            // })
                            .filter(offer => showRedOffers ? moment(offer.exp_date).format() >= moment().format() : true )
                            .map(offer => {
                              const date = new Date(offer.exp_date);
                              const day = (date.getDate() < 10) ? '0' + date.getDate(): date.getDate();
                              const month = (date.getMonth()+1 < 10) ? '0' + (date.getMonth() + 1): date.getMonth()+1;

                              const todayStart = + new Date().setHours(0,0,0,0);
                              const todayEnd = + new Date().setHours(23,59,59,999);
                              const offerExp = + new Date(offer.exp_date);

                              let offerClass = 'row_block'

                              if(offerExp >= todayStart && offerExp <= todayEnd)
                                offerClass += ' -today';
                              
                              if(offerExp < todayStart)
                                offerClass += ' -not_valid';

                              if(offerExp > todayStart && offerExp > todayEnd)
                                offerClass += ' -valid';

                              const formatedDate = `${day}/${month}/${date.getFullYear()}`;
                              
                            return (
                                <TableRow
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={offer.id}
                                    className='row_block'
                                    className={offerClass}
                                    onClick={() => this.openPopupInfo(offer)}
                                    >
                                    <TableCell>{offer.offer_name}</TableCell>
                                    <TableCell title={offer.offer_code} style={{borderBottom:"none", overflow: 'hidden', 'textOverflow': 'ellipsis', maxWidth: '50px'}}>{offer.offer_code}</TableCell>
                                    {/* <TableCell>{offer.type_product}</TableCell> */}
                                    <TableCell>
                                      <div className='offer_supplier'>
                                      {offer.TelcoSupplier ? offer.TelcoSupplier.name : ''}
                                        {/* { (offer.TelcoSupplier && offer.TelcoSupplier.img ) && <div className='logo_supplier_section'>
                                          <div className='logo_supplier'>
                                              <img src={baseUrlUploads + offer.TelcoSupplier.img}/>
                                          </div>
                                          <div className='tooltip_image'>
                                            <img src={baseUrlUploads + offer.TelcoSupplier.img}/>
                                          </div>
                                        </div> 
                                        } */}
                                      </div>
                                    </TableCell>
                                    <TableCell>{offer.client_type}</TableCell>
                                    <TableCell>{formatedDate}</TableCell>
                                    {/* <TableCell>{offer.duration} Mese</TableCell> */}
                                    <TableCell  className='actions_table_user_view'>
                                        <Tooltip title='View'><Button onClick={() => this.editOffer(offer.id)} className='action -edit' variant="fab" mini aria-label="View"><Icon className='offer_icon'>input</Icon></Button></Tooltip>
                                    </TableCell>
                             </TableRow>
                            );
                          })}
                          {/* {emptyRows > 0 && (                          
                            <TableRow style={{ height: 49 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                        </TableBody>
                    </Table>
                    </div>
                    <TablePagination
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                {/* </Paper> */}
                </div>

                <div className='actions'>
                  <a href='https://confronto.luce-gas.it' target='_blank'><Button>Comparatore</Button></a>
                </div>
                </div>
            </div>
        )
    }
}

export default OfferUser;