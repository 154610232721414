import axios from 'axios';
import { id } from 'date-fns/locale';

export const baseUrl = (process.env.NODE_ENV == 'production') ? '/api/v1/' : 'http://localhost:9090/api/v1/';
export const baseUrlUploads = (process.env.NODE_ENV == 'production') ? '/uploads/images/' : 'http://localhost:9090/uploads/images/';
// export const baseUrl = (process.env.ENV == 'production') ? '/api/v1' : 'http://35.180.95.25/api/v1';
// export const baseUrlUploads = (process.env.ENV == 'production') ? '/uploads/images/' : 'http://35.180.95.25/uploads/images/';
export const OPPUrl = process.env.OPP;
export const OPPRecordUrl = OPPUrl + '/#!/import/zoho?';
export const energia = '/energia';
export const telco = '/telco';

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    config.headers = {
        'content-type': 'application/json',
        'x-accesstoken': (token) ? token : ''
    }

    return config;
})


export const isAuth = 'pending';
export const isAdminAuth = 'pending';
export const token = localStorage.getItem('token');

// AUTH
export const checkAuth = () => axios.get(baseUrl  + '/users/check-auth');
export const getMe = () => axios.get(baseUrl  + '/users/me');
export const checkAdminAuth = () => axios.get(baseUrl  + '/users/check-auth');
export const loginVerifyEmail   = (payload) => axios.post(baseUrl + 'verification/email', payload);
export const login = (data) => {
    return axios.post(baseUrl + '/users/login', data).then(result => {
        const { ok, token } = result.data;

        if(ok)
            localStorage.setItem('token', token);

        return result;
    })
}

export const logout = () => {
    localStorage.removeItem('token');

    return window.location.reload();
}



// MESSAGES
export const getMessages = () => axios.get(baseUrl + energia + '/messages');
export const postMessage = (data) => axios.post(baseUrl + energia + '/messages', data);
export const updateMessage = (id, text, title) => axios.put(baseUrl + energia + '/messages/'+ id, { text, title });
export const removeMessage = (id) => axios.delete(baseUrl + energia + '/messages/'+id);
export const changeOrder = (from, to) => axios.post(baseUrl + energia + '/messages/change-order', { from , to });


// USERS
export const getUsers = () => axios.get(baseUrl  + '/users');
export const createUser = (data) => axios.post(baseUrl  + '/users', data);
export const updateUser = (id, data) => axios.put(baseUrl  + '/users/' + id, data);
export const updatePassword = (id, data) => axios.put(baseUrl  + '/users/' + id + '/pass', data);
export const removeUser = (id) => axios.delete(baseUrl  + '/users/' + id);

// SUPLIERS
export const createSupplier = (data) => axios.post(baseUrl + energia + '/suppliers', data);
export const getSupplierById = (id) => axios.get(baseUrl + energia + '/suppliers/'+id);
export const updateSupplier = (id, data) => axios.put(baseUrl + energia + '/suppliers/'+id, data);
export const getAllSuppliers = () => axios.get(baseUrl + energia + '/suppliers');
export const removeSupplier = (id) => axios.delete(baseUrl + energia + '/suppliers/'+id);
export const uploadLogo = (id, data) => axios.post(baseUrl + energia + '/suppliers/'+id+'/upload-image', data);

// OPERATIONS
export const createOperation = (data) => axios.post(baseUrl + energia + '/operations', data);
export const getOperation = (id) => axios.get(baseUrl + energia + '/operations/'+id);
export const getAllOperations = () => axios.get(baseUrl + energia + '/operations');
export const updateOperation = (id, data) => axios.put(baseUrl + energia + '/operations/' + id, data);
export const removeOperation = (id) => axios.delete(baseUrl + energia + '/operations/' + id);


// OFFERS
export const createOffer = (data) => axios.post(baseUrl + energia + '/offers', data);
export const getOfferById = (id) => axios.get(baseUrl + energia + '/offers/' + id );
export const getAllOffers = () => axios.get(baseUrl + energia + '/offers');
export const updateOffer = (id, data) => axios.put(baseUrl + energia + '/offers/' + id, data);
export const updateMultipleOffers = (data) => axios.put(baseUrl + energia + '/offers/many', data);
export const generateReport = () => axios.get(baseUrl + energia + '/offers/report');

export const removeOffer = (id) => axios.delete(baseUrl + energia + '/offers/' + id);
export const filterOffers = (page, limit, params) => axios.post(baseUrl + energia + `/offers/filter?page=${page}&limit=${limit}`, params);

// TOOLTIPS
export const createTooltip = (data) => axios.post(baseUrl + energia + '/tooltips', data);
export const getTooltipById = (id) => axios.get(baseUrl + energia + '/tooltips/'+id);
export const getAllTooltips = () => axios.get(baseUrl + energia + '/tooltips');
export const updateTooltip = (id, data) => axios.put(baseUrl + energia + '/tooltips/' + id, data);
export const removeTooltip = (id) => axios.delete(baseUrl + energia + '/tooltips/' + id);

// REMI
export const updadFile = (file) => axios.post(baseUrl  + '/remi/upload', file);
export const findCity = (city) => axios.get(baseUrl  + '/remi/find/' + city);

export const findAddress = (text, limit) => {
    const params = new URLSearchParams({
        text,
        limit
    });

    let keysForDel = [];
    params.forEach((value, key) => {
        if (value == "null" || value == "undefined" ) {
            keysForDel.push(key);
        }
    });

    keysForDel.forEach(key => {
        params.delete(key);
    });

    return axios.get(baseUrl + '/remi/search/' + '?' + params.toString());
}

// NUMBERS
export const createNumber = (payload) => axios.post(baseUrl + energia + '/numbers/', payload);
export const getAllNumbers = () => axios.get(baseUrl + energia + '/numbers/');
export const updateNumber = (id, payload) => axios.put(baseUrl + energia + '/numbers/' + id, payload);
export const removeNumber = (id) => axios.delete(baseUrl + energia + '/numbers/' + id);

// SCRIPTS
export const createScript = (payload) => axios.post(baseUrl + energia + '/scripts/', payload);
export const getAllScripts = () => axios.get(baseUrl + energia + '/scripts/');
export const updateScript = (id, payload) => axios.put(baseUrl + energia + '/scripts/' + id, payload);
export const removeScript = (id) => axios.delete(baseUrl + energia  + '/scripts/' + id);
export const changeOrderScripts = (from, to) => axios.post(baseUrl + energia + '/scripts/change-order', { from , to });


// OPP
export const getListZohoUsers = () => axios.get(baseUrl + energia + '/users/zoho');
export const getProducts = () => axios.get(baseUrl + energia + '/products');
export const createRecord = (data) => window.open(OPPRecordUrl + energia + data, '_blank');

// LOGS
export const getAllLogs = () => axios.get(baseUrl  + '/logs/list');
export const getLogsById = (id) => axios.get(baseUrl  + '/logs/' + id);
export const customeGetRequest = (url) => axios.get(url);


// FIELDS 
export const getAllFields = () => axios.get(baseUrl  + '/sync');
export const createField = (payload) => axios.post(baseUrl  + '/sync', payload);
export const updateField = (id, payload) => axios.put(baseUrl + '/sync/' + id, payload);
export const removeField = (id) => axios.delete(baseUrl + '/sync/' + id);

// PROPOSALS BOTTOM SCRIPTS
export const getAllProposalsScripts = () => axios.get(baseUrl  + '/proposals/scripts');
export const addProposalScript = (payload) => axios.post(baseUrl  + '/proposals/scripts', payload);
export const updateProposalScript = (id, payload) => axios.put(baseUrl  + '/proposals/scripts/'+ id, payload);
export const deleteProposalScript = (id) => axios.delete(baseUrl  + '/proposals/scripts/'+ id);

// COMPERATORE BOTTOM SCRIPTS
export const getAllComperatoreScripts = () => axios.get(baseUrl  + '/comperatore/scripts');
export const addComperatoreScript = (payload) => axios.post(baseUrl  + '/comperatore/scripts', payload);
export const updateComperatoreScript = (id, payload) => axios.put(baseUrl  + '/comperatore/scripts/'+ id, payload);
export const deleteComperatoreScript = (id) => axios.delete(baseUrl  + '/comperatore/scripts/'+ id);

// TELCO -----------------





// OFFERS
export const createTelcoOffer = (data) => axios.post(baseUrl + telco + '/offers', data);
export const getTelcoOfferById = (id) => axios.get(baseUrl + telco + '/offers/' + id );
export const getTelcoAllOffers = () => axios.get(baseUrl + telco + '/offers');
export const updateTelcoOffer = (id, data) => axios.put(baseUrl + telco + '/offers/' + id, data);
export const updateTelcoMultipleOffers = (data) => axios.put(baseUrl + telco + '/offers/many', data);

export const removeTelcoOffer = (id) => axios.delete(baseUrl + telco + '/offers/' + id);
export const filterTelcoOffers = (page, limit, params) => axios.post(baseUrl + telco + `/offers/filter?page=${page}&limit=${limit}`, params);

// SUPLIERS
export const createTelcoSupplier = (data) => axios.post(baseUrl + telco + '/suppliers', data);
export const getTelcoSupplierById = (id) => axios.get(baseUrl + telco + '/suppliers/'+id);
export const updateTelcoSupplier = (id, data) => axios.put(baseUrl + telco + '/suppliers/'+id, data);
export const getTelcoAllSuppliers = () => axios.get(baseUrl + telco + '/suppliers');
export const removeTelcoSupplier = (id) => axios.delete(baseUrl + telco + '/suppliers/'+id);
export const uploadTelcoLogo = (id, data) => axios.post(baseUrl + telco + '/suppliers/'+id+'/upload-image', data);


// OPERATIONS
export const createTelcoOperation = (data) => axios.post(baseUrl + telco + '/operations', data);
export const getTelcoOperation = (id) => axios.get(baseUrl + telco + '/operations/'+id);
export const getTelcoAllOperations = () => axios.get(baseUrl + telco + '/operations');
export const updateTelcoOperation = (id, data) => axios.put(baseUrl + telco + '/operations/' + id, data);
export const removeTelcoOperation = (id) => axios.delete(baseUrl + telco + '/operations/' + id);

// MESSAGES
export const getTelcoMessages = () => axios.get(baseUrl + telco + '/messages');
export const postTelcoMessage = (data) => axios.post(baseUrl + telco + '/messages', data);
export const updateTelcoMessage = (id, text, title) => axios.put(baseUrl + telco + '/messages/'+id, { text, title });
export const removeTelcoMessage = (id) => axios.delete(baseUrl + telco + '/messages/'+id);
export const changeTelcoOrder = (from, to) => axios.post(baseUrl + telco + '/messages/change-order', { from , to });

// SCRIPTS
export const createTelcoScript = (payload) => axios.post(baseUrl + telco + '/scripts/', payload);
export const getTelcoAllScripts = () => axios.get(baseUrl + telco + '/scripts/');
export const updateTelcoScript = (id, payload) => axios.put(baseUrl + telco + '/scripts/' + id, payload);
export const removeTelcoScript = (id) => axios.delete(baseUrl + telco  + '/scripts/' + id);
export const changeOrderTelcoScripts = (from, to) => axios.post(baseUrl + telco + '/scripts/change-order', { from , to });

// NUMBERS
export const createTelcoNumber = (payload) => axios.post(baseUrl + telco + '/numbers/', payload);
export const getTelcoAllNumbers = () => axios.get(baseUrl + telco + '/numbers/');
export const updateTelcoNumber = (id, payload) => axios.put(baseUrl + telco + '/numbers/' + id, payload);
export const removeTelcoNumber = (id) => axios.delete(baseUrl + telco + '/numbers/' + id);


// TOOLTIPS
export const createTelcoTooltip = (data) => axios.post(baseUrl + telco + '/tooltips', data);
export const getTelcoTooltipById = (id) => axios.get(baseUrl + telco + '/tooltips/'+id);
export const getTelcoAllTooltips = () => axios.get(baseUrl + telco + '/tooltips');
export const updateTelcoTooltip = (id, data) => axios.put(baseUrl + telco + '/tooltips/' + id, data);
export const removeTelcoTooltip = (id) => axios.delete(baseUrl + telco + '/tooltips/' + id);

// GET ELITE ENERGIA OFFERS
export const getEliteEnergiaOffers = () => axios.get(baseUrl + energia + '/offers/elite');

// GET ELITE TELCO OFFERS
export const getEliteTelcoOffers = () => axios.get(baseUrl + telco + '/offers/elite');

// ADDRESSES

// export const findTelcoAddress = (address) => {
export const findTelcoAddress = (address, limit) => {
    const params = new URLSearchParams({
        address,
        limit
    });

    let keysForDel = [];
    params.forEach((value, key) => {
        if (value == "null" || value == "undefined" ) {
            keysForDel.push(key);
        }
    });

    keysForDel.forEach(key => {
        params.delete(key);
    });

    return axios.get(baseUrl + '/addresses' + '?' + params.toString());
}

export const getAddressByBuildingId = (id) => axios.get(baseUrl + '/addresses/' + id);



// COMPERATORE 

// BTA
export const createBta = (payload) => axios.post(baseUrl + energia + '/comperatore/bta', payload);
export const updateBta = (id, payload) => axios.put(baseUrl + energia + '/comperatore/bta/' + id, payload);
export const removeBta = (id) => axios.delete(baseUrl + energia + '/comperatore/bta/' + id);

// Imposte
export const createImposte = (payload) => axios.post(baseUrl + energia + '/comperatore/imposte', payload);
export const updateImposte = (id, payload) => axios.put(baseUrl + energia + '/comperatore/imposte/' + id, payload);
export const removeImposte = (id) => axios.delete(baseUrl + energia + '/comperatore/imposte/' + id);

// Sintetica
export const createSintetica = (payload) => axios.post(baseUrl + energia + '/comperatore/sintetica', payload);
export const updateSintetica = (id, payload) => axios.put(baseUrl + energia + '/comperatore/sintetica/' + id, payload);
export const removeSintetica = (id) => axios.delete(baseUrl + energia + '/comperatore/sintetica/' + id);


// get all data 
export const getComperatoreData = () => axios.get(baseUrl + energia + '/comperatore');

// energia 
export const updateComperatoreEnergia = (payload) => axios.put(baseUrl + energia + '/comperatore/comperatore' + energia, payload);

// gas
export const updateComperatoreGas = (payload) => axios.put(baseUrl  + energia + '/comperatore/comperatore/gas', payload);


// BREAK
export const setBreakConfig = (payload) => axios.put(baseUrl + '/users/pause/config', payload);
export const getBreakConfig = () => axios.get(baseUrl + '/users/pause/config');
export const getMyPauseStatus = () => axios.get(baseUrl + '/users/pause');
export const getBreakUsers = () => axios.get(baseUrl + 'users/pause/list');
export const setBreak = (payload) => axios.put(baseUrl + 'users/pause', payload);
export const generateTimeReport = (query) => axios.get(baseUrl + 'users/pause/report?' + query);
export const stopBreakForOperator = (id) => axios.delete(baseUrl + `users/${id}/pause`);