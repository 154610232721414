import React, { Component, Fragment, PureComponent  } from 'react';
import { Button, Tooltip, GridList, TextField, MenuItem, Icon } from '@material-ui/core';
import Slider from '@material-ui/lab/Slider';
import { DatePicker, InlineDatePicker } from 'material-ui-pickers';
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import { getAllSuppliers, createOffer, getOffer, updateOffer, getOfferById, baseUrlUploads } from './../../../service';
import store from '../../../store';
import { 
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
  } from '../../../actions/actions';

// import './newOffer.scss';

class NewOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            supplier_id: null,
            redirect: false,
            edit: false,
            offer_id: null,

            client_type: '',
            offer_name: '',
            offer_code: '',
            exp_date: new Date(),
            duration: 1,
            type_product: '',
            price_gas: '',
            price_f0: '',
            price_f1: '',
            price_f2: '',
            price_f3: '',
            price_pcv: '',
            monthly_fee: '',
            gas_description: '',
            ee_description: '',
            
            // pagamento
            rid: '',
            bollettino: '',
            bonifico: '',
            carta_credito: '',

            // link
            link: '',
            rating: '',
            verde: 'OFF',  
        }

        this.createOffer = this.createOffer.bind(this);
        this.updateOffer = this.updateOffer.bind(this);
        this.checkValidation = this.checkValidation.bind(this);
    }
    
    componentWillMount() {
        const id = this.props.match.params.id;

        const suppliersPromise = getAllSuppliers();
        const offerPromise = (id || id === 0) ? getOfferById(id) : null;

        store.dispatch({ type: OPEN_LOADING, text: 'Loading suppliers...' })

        Promise.all([suppliersPromise, offerPromise]).then(result => {
            store.dispatch({ type: CLOSE_LOADING });
            const [ supplierResp, offerResp ] = result;

            let suppliers = supplierResp.data.suppliers;
            if(!offerResp)
                return this.setState({ suppliers });

            let offer = offerResp.data.offer;

            const selectedSupplier = offer.supplier_id;

            suppliers = suppliers.map(sup => { 
                if(sup.id != selectedSupplier) 
                    return sup;
                
                sup.selected = true;

                return sup;
            })

            offer.duration = +offer.duration;

            store.dispatch({ type: CLOSE_LOADING });

            this.setState({ 
                suppliers, 
                ...offer, 
                offer_id: id,
                supplier_id: selectedSupplier, 
                edit: true
            });
         }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't load suppliers` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
         })
    }

    changeValue(name, value) {
        const state = this.state;

        if(name == 'rating' && value > 100)
            return false;

        state[name] = value;

        switch(name) {
            case 'offer_name':
                state['offer_name_error'] = false;
            break;
            case 'offer_code':
                state['offer_code_error'] = false;
            break;
            case 'client_type':
                state['client_type_error'] = false;
            break;
            case 'type_product':
                state['type_product_error'] = false;
            break;
            case 'sdd_obblig':
                state['sdd_obblig_error'] = false;
            break;

        }

        this.setState(state);
    }

    createOffer() {
        const {
            // exceptions
            suppliers,
            redirect,

            // data of offer
            ...data
        } = this.state;

        createOffer(data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Offer been created', open: true});
            this.setState({redirect: true});
        })
    }

    updateOffer() {
        const { offer_id: id, ...data } = this.state;

        updateOffer(id, data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Offer been updated', open: true});
            this.setState({ redirect: true });
        }).catch(err => {
            console.log(err);
        })
    }

    selectSupplier(id) {
        const suppliers = this.state.suppliers;
        let theSame = false;

        suppliers.map(supplier => {
            if(supplier.id == id) {
                supplier.selected = !supplier.selected;
                theSame = supplier.selected;

                return supplier;
            } else if(supplier.selected) {
                supplier.selected = false;

                return supplier;
            }


            return supplier;
        });

        if(theSame)
            this.setState({ supplier_id: id });
        else
            this.setState({ supplier_id: null });

    }

    checkValidation() {
        const {
            edit,
            offer_name,
            offer_code,
            client_type,
            type_product,
        } = this.state;

        const state = this.state;

        if(!offer_name)
            state['offer_name_error'] = true;

        if(!offer_code)
            state['offer_code_error'] = true;

        if(!client_type)
            state['client_type_error'] = true;

        if(!type_product)
            state['type_product_error'] = true;

        if(
            !offer_name ||
            !offer_code ||
            !client_type ||
            !type_product
        ) return this.setState({...state});


        !edit ? this.createOffer() : this.updateOffer()
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    onChangeBool(name) {
        const data = this.state;

        data[name] = !data[name];

        this.setState(data);
    }

    render() {
        const {
            suppliers,
            redirect,
            edit,

            supplier_id,
            client_type,
            offer_name,
            offer_code,
            exp_date,
            duration,
            type_product,
            price_gas,
            price_f0,
            price_f1,
            price_f2,
            price_f3,
            price_pcv,
            monthly_fee,
            gas_description,
            ee_description,

            //pagamento
            rid,
            bollettino,
            bonifico,
            carta_credito,

            // link
            link,

            rating,
            verde,

            //error
            offer_name_error,
            offer_code_error,
            client_type_error,
            type_product_error,
            sdd_obblig_error
         } = this.state;

        const {open: loading} = store.getState().loading;

        if(redirect)
            return <Redirect to='/' />

        return(
            <form className={loading ? 'hide' : 'new_offer_seciton anim_content'} onSubmit={ (event) => { event.preventDefault(); this.checkValidation()}}>
            <Tooltip title='Help'>
                <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
            </Tooltip>
            <div className='supplier_list'>
                        <div className='saparetor'>SUPPLIER</div>
                        <div className='content' >
                            <GridList cols={0} id='grid-scroll'>
                                {
                                    suppliers.map(supplier => {
                                        const { id, name, selected, img } = supplier;

                                        return (
                                            <div key={id} className='supplier -disabled'>
                                                <div className='actions'>
                                                    <div className='background'></div>
                                                    { !selected 
                                                        ? <div onClick={() => this.selectSupplier(id)} className='action -select'>Select</div>
                                                        : <div onClick={() => this.selectSupplier(id)} className='action -cancel'>Cancel</div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                { img 
                                                    ? 
                                                    <div className='block_img'>
                                                        <img className='image'src={baseUrlUploads+img}/>
                                                    </div> 
                                                    : 
                                                    <div className='block_text'>
                                                        {name}
                                                    </div> 
                                                }
                                                    { selected ? <div className='selected'></div> : '' }
                                                </div>
                                            </div>
                                        )
                                    })
                                
                                }
                            </GridList>
                        </div>
                    </div>
                <div className='content'>
                    <div className='saparetor'>Dati di base</div>
                    <div className='fields -limit'>
                        <TextField 
                            onChange={ event => this.changeValue('offer_name', event.target.value)} 
                            value={offer_name} 
                            label='Offer name'
                            disabled={true}
                            className={offer_name_error ? 'error': ''}
                         />
                         <TextField 
                            onChange={ event => this.changeValue('offer_code', event.target.value)} 
                            value={offer_code} 
                            label='Offer code'
                            disabled={true}
                            className={offer_code_error ? 'error': ''}
                         />
                         <TextField
                            select
                            label="Tipo cliente"
                            className='select'
                            onChange={ event => this.changeValue('client_type', event.target.value)}
                            margin="normal"
                            variant="filled"
                            value={client_type}
                            disabled={true}
                            className={client_type_error ? 'error': ''}
                            >
                            {['resid', 'business'].map(option => (
                                <MenuItem required key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                                select
                                label="Energia"
                                className='select'
                                onChange={ event => this.changeValue('type_product', event.target.value)}
                                margin="normal"
                                variant="filled"
                                value={type_product}
                                disabled={true}
                                className={type_product_error ? 'error': ''}
                                >
                                {['gas', 'luce', 'dual'].map(option => (
                                    <MenuItem key={option} value={option}>
                                    {option}
                                    </MenuItem>
                                ))}
                        </TextField>
                         <MuiThemeProvider theme={materialTheme}>
                         <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <div className="picker">
                                <InlineDatePicker
                                    format="dd/MM/yyyy"
                                    label="Data fine validità"
                                    value={exp_date}
                                    disabled={true}
                                    onChange={ event => this.changeValue('exp_date', event)}
                                />
                            </div>
                            </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                        <div className='slider_block'>
                            <div className='text -info'>
                                <span>Durata di validità</span>
                                <span>1 mese - 36 mese</span>
                            </div>
                            <div className='text'>
                                {duration} mese
                            </div>
                            <Slider 
                                className='slider'
                                label={duration + ' anno'}
                                value={duration}
                                min={1}
                                max={36}
                                step={1}
                                disabled={true}
                                onChange={ (event, value) => this.changeValue('duration', value)}
                            />
                        </div>
                    </div>

                    <div className={type_product ? 'saparetor' : 'hide'}>Pagamento</div>
                        <div className={ type_product ? 'filters' : 'hide' }>
                            <div 
                                className={rid ? 'item select' : 'item no_select'} 
                                // onClick={() => { this.onChangeBool('rid') }}
                                >RID
                            </div>

                            <div 
                                className={bollettino ? 'item select' : 'item no_select'} 
                                // onClick={() => { this.onChangeBool('bollettino') }}
                                >Bollettino
                            </div>

                            <div 
                                className={bonifico ? 'item select' : 'item no_select'} 
                                // onClick={() => { this.onChangeBool('bonifico') }}
                                >Bonifico
                            </div>

                            {/* <div 
                                className={carta_credito ? 'item select' : 'item no_select'} 
                                onClick={() => { this.onChangeBool('carta_credito') }}
                                >Online con carta credito
                            </div> */}

                        </div>
                        
                    <div className={type_product == 'luce' || type_product == 'dual' ? 'saparetor' : 'hide'}>Prezzo luce</div>
                    <div className={type_product == 'luce' || type_product == 'dual' ? 'fields -price_ee' : 'hide'}>
                        <TextField 
                            onChange={ event => this.changeValue('price_f0', event.target.value)} 
                            value={price_f0}
                            type='number'
                            label='F0 (€/kWh)' 
                            disabled={true}
                         />
                         <TextField 
                            onChange={ event => this.changeValue('price_f1', event.target.value)} 
                            value={price_f1}
                            type='number'
                            label='F1 (€/kWh)' 
                            disabled={true}
                         />
                         <TextField 
                            onChange={ event => this.changeValue('price_f2', event.target.value)} 
                            value={price_f2} 
                            type='number'
                            label='F2 (€/kWh)' 
                            disabled={true}
                         />
                         <TextField 
                            onChange={ event => this.changeValue('price_f3', event.target.value)} 
                            value={price_f3}
                            type='number'
                            label='F3 (€/kWh)' 
                            disabled={true}
                         />
                         <TextField 
                            onChange={ event => this.changeValue('price_pcv', event.target.value)} 
                            value={price_pcv}
                            type='number'
                            label='PCV (€/mese)' 
                            disabled={true}
                         />
                    </div>
                    <div className={type_product == 'gas' || type_product == 'dual' ? 'saparetor' : 'hide'}>Prezzo gas</div>
                    <div className={type_product == 'gas' || type_product == 'dual' ? 'fields -price_gas' : 'hide'}>
                        <TextField 
                            onChange={ event => this.changeValue('price_gas', event.target.value)} 
                            value={price_gas}
                            type='number'
                            label='Prezzo gas (€/Smc)'
                            disabled={true}
                         />
                         <TextField 
                            onChange={ event => this.changeValue('monthly_fee', event.target.value)} 
                            value={monthly_fee}
                            type='number'
                            label='QVD (€/mese)' 
                            disabled={true}
                         />
                    </div>
                    <div className={type_product ? 'saparetor' : 'hide'}>Script</div>
                    <div className={type_product ? 'fields -script' : 'hide'}>
                        <TextField 
                            className={type_product == 'luce' || type_product == 'dual' ? '' : 'hide'}
                            onChange={ event => this.changeValue('ee_description', event.target.value)} 
                            value={ee_description}
                            label='Luce' 
                            multiline 
                            disabled={true}
                            rows='9'/>
                        <TextField 
                            className={type_product == 'gas' || type_product == 'dual' ? '' : 'hide'} 
                            onChange={ event => this.changeValue('gas_description', event.target.value)} 
                            value={gas_description}
                            label='Gas' 
                            multiline 
                            disabled={true}
                            rows='9'/>
                    </div>

                    <div className={type_product ? 'saparetor' : 'hide'}>Link</div>
                    <div className={type_product ? 'fields -limit': 'hide'}>
                        <TextField 
                            onChange={ event => this.changeValue('link', event.target.value)} 
                            value={link} 
                            label='Link for finalization of the Contract'
                            className='col-100'
                            disabled={true}
                         />
                    </div>

                    <div className={type_product ? 'saparetor' : 'hide'}>Other</div>
                    <div className={type_product ? 'fields -limit': 'hide'}>
                        <TextField
                            select
                            label="Verde"
                            className='select'
                            onChange={ event => this.changeValue('verde', event.target.value)}
                            margin="normal"
                            variant="filled"
                            value={verde}
                            disabled={true}
                            // className={type_product_error ? 'error': ''}
                            >
                            {['ON', 'OFF'].map(option => (
                                <MenuItem key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                        </TextField>
                         <TextField 
                            // onChange={ event => this.changeValue('rating', event.target.value)} 
                            value={rating} 
                            type='number'
                            label='Rating'
                            inputProps={{ min: "0", max: "100" }}
                            disabled={true}
                            // className='col-100'
                         />
                    </div>
                </div>
            </form>
        )
    }
}

export default NewOffer;

const style = {
    createButton: {
        width: '200px',
        height: '40px',
        background: '#417BC3',
        color: 'white'
    },

    createButtonDissabled: {
        width: '200px',
        height: '40px',
        background: '#8090a396',
        color: 'white'
    }
}

const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#1460AA',
        },
      },
      MuiPickersDay: {
        day: {
          color: '#1460AA'
        },
        selected: {
          backgroundColor: '#1460AA'
        },
        current: {
          color: '#1460AA'
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: '#1460AA'
        }
      }
    }
  });