import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import ReactQuill from 'react-quill';

import {
    OPEN_NOTIFICATION,
} from '../../../actions/actions';

import './index.scss'

import { createTelcoNumber, updateTelcoNumber } from '../../../service';
import store from '../../../store';

class PopupNumber extends Component {
    constructor(props) {
        super(props);

        const number = this.props.number || {};

        console.log(number, 'test number');

        this.state = {
            id: null, 
            head: number.head || '',
            text: number.text || '',
            phone: number.number || '',

            dirty: false
        }

        this.changeInput = this.changeInput.bind(this);
        this.createNumber = this.createNumber.bind(this);
        this.updateNumber  = this.updateNumber.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    createNumber() {
        const { head, text, phone: number } = this.state;

        const newNumber = { head, text, number };

        this.props.addNumber(newNumber);
        this.closePopup()

        // store.dispatch({type: OPEN_LOADING, text: 'Creating message', open: true});
        createTelcoNumber({ head, text, number }).then(result => {

            const { id } = result.data.number;

            newNumber.id = id;
            this.props.addNumber();

            // store.dispatch({type: CLOSE_LOADING, text: 'Creating message', open: false});
        })
    }

    closePopup() {
        this.setState({dirty: false});
        this.props.handleClose();
    }

    updateNumber() {
        const id = this.props.id;

        const { 
            head, 
            text, 
            phone
        } = this.state;

        const payload = { 
            head, 
            text, 
            number: phone
        };

        this.props.updateNumber(id, payload)

        // store.dispatch({type: OPEN_LOADING, text: 'Updating message', open: true});
        updateTelcoNumber(id, payload ).then(result => {
            // store.dispatch({type: CLOSE_LOADING, text: 'Creating message', open: false});
        }).catch(err => {
            // store.dispatch({type: OPEN_LOADING, text: `Can't update the message`, open: true});

            setTimeout(() => {
                // store.dispatch({type: CLOSE_LOADING, text: 'Creating message', open: false});
            }, 2000)
        })

        this.closePopup();
    }

    changeInput(name, text) {
        const state = this.state;

        state[name] = text;
        state['dirty'] = true;

        this.setState(state);
    }

    render() {
        const { id, number } = this.props;

        let { head, text, phone, dirty } = this.state;
        
        return(
            !id ?
            <Dialog
                open={this.props.open}
                onClose={this.closePopup}
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Add new number</DialogTitle>
                <DialogContent style={style.content} className='vissible-item number_popup_section'>
                    <div className='head_field fields -head'>
                        <TextField 
                            value={head}
                            label='Title'
                            size="small"
                            className='field'
                            onChange={event => this.changeInput('head', event.target.value)}
                            />
                    </div>
                    <ReactQuill style={{height: '100%', minHeight: '250px'}}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        theme={"snow"}
                        onChange={event => this.changeInput('text', event)}
                    />
                    <div className='head_field fields -phone'>
                        <TextField 
                            value={phone}
                            label='Phone'
                            size="small"
                            className='field'
                            onChange={event => this.changeInput('phone', event.target.value)}
                            />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.createNumber} color="primary">
                    Add number
                    </Button>
                </DialogActions>
            </Dialog>
            : 
            <Dialog
                open={this.props.open}
                onClose={this.closePopup}
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Edit number</DialogTitle>
                <DialogContent style={style.content} className='vissible-item number_popup_section'>
                    <div className='head_field fields -head'>
                        <TextField 
                            value={head}
                            label='Title'
                            size="small"
                            className='field'
                            onChange={event => this.changeInput('head', event.target.value)}
                            />
                    </div>
                    <ReactQuill style={{height: '100%', minHeight: '250px'}}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        theme={"snow"}
                        onChange={event => this.changeInput('text', event)}
                        defaultValue={text}
                    />
                    <div className='head_field fields -phone'>
                        <TextField 
                            value={phone}
                            label='Phone'
                            className='field'
                            size="small"
                            onChange={event => this.changeInput('phone', event.target.value)}
                            />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.updateNumber} color="primary">
                    Update
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default PopupNumber;

const style = {
    content: {
        width: '550px',
        // height: '270px',
        minHeight: '270px',
        overflow: 'visible' 
    }
}

const Editor = {
    modules: {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'formula'],
          
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
          
          
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],
          
            ['clean'] 
        ],
      },
    
      formats: [
        'header',
        'color',
        'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ],
}