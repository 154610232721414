import React, { Component } from 'react';
import { Button, Drawer, Tooltip, Icon, MenuItem, TablePagination } from '@material-ui/core';
import { generate } from 'generate-password';
import TextField from '@mui/material/TextField';


import {
    getUsers,
    createUser,
    updateUser,
    updatePassword,
    removeUser
} from '../../service';

import Delete from '../menu/users/dialogs/delete';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import Dialog from './dialog';
import RemoveDialog from '../dialogs/removePopup';
import LogsWindow from './dialog/log';
import GenerateNew from './dialog/generatePass';




import './users.scss';

class Users extends Component {
    constructor() {
        super();

        this.state = {
            users: [],
            count: 0,
            page: 0,
            rowsPerPage: 15,
            searchText: '',
            openLogWindow: false,
            statusPopup: false,
            selectedUser: null,
            openRemovePopup: false,
            openChange: false,
            filtering: false,
            filteredUsers: [],
        };

        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.openRemovePopup = this.openRemovePopup.bind(this);
        this.closeRemovePopup = this.closeRemovePopup.bind(this);

        this.createUser = this.createUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.updateUser = this.updateUser.bind(this);

        this.generateNewPass = this.generateNewPass.bind(this);
        this.showLogWindow = this.showLogWindow.bind(this);
    }

    search(text) {
      this.setState({searchText: text});
      if(text.length == 0)
        return this.setState({ filtering: false, filteredUsers: [] })

      const users = this.state.users;
      const values = text.split(' ');

      let filteredUsers = users;

      for(let i = 0, leng = values.length; i < leng; i++) {
        const regExp = new RegExp(values[i], 'i');

        filteredUsers = filteredUsers.filter(user => {
          const { 
            name, role, access, login
          } = user;
  
          if(
            regExp.test(name)
            || regExp.test(role)
            || regExp.test(access)
            || regExp.test(login)
          )
            return user;
        })
      }
      this.setState({ filteredUsers, filtering: true });
    }

    handleChangePage = (event, changePage) => {
        this.setState({ page: changePage });
      };
 
      handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
        // setPage(0);
      };

    openPopup(user = null) {
      this.setState({ statusPopup: true, selectedUser: user });
    }
  
    closePopup() {
      this.setState({ statusPopup: false, selectedUser: null });
    }
  
    openRemovePopup(user = null) {
      this.setState({ openRemovePopup: true, selectedUser: user });
    }
  
    closeRemovePopup() {
      this.setState({ openRemovePopup: false, selectedUser: null , openChange: false});
      // this.setState({ openRemovePopup: false, selectedUser: null});
    }

    generateNewPass(data) {
      const id = data.id;
  
      const event = (pass) => {
          this.closeRemovePopup();
          
          updatePassword(id, { pass }).then(resp => {
              console.log(resp);
          }).catch(err => console.log(err));
      };
  
      this.setState({ event, openChange: true });
  }


    createUser(user = null) {
      if(!user)
          return this.forceUpdate();

      const users = this.state.users;
      
      users.push(user);

      this.setState({ users });
  }


    inputChange(name, value) {
        const state = this.state;

        state[name] = value;

        this.setState(state);
    }

    removeUser() {
      const { selectedUser: { id }, users } = this.state;

      const updatedUsers = users.filter(user => user.id !== id);

      this.setState({ users: updatedUsers, openRemovePopup: false, selectedUser: {} });

      removeUser(id).then(resp => {
        console.log(resp);
    }).catch(err => console.log(err));

  }


    // generateNewPass(data) {
    //     const id = data.id;

    //     const event = (pass) => {
    //         this.closePopups();
    //         updatePassword(id, { pass }).then(resp => {
    //             console.log(resp);
    //         }).catch(err => console.log(err));
    //     };

    //     this.setState({ event, openChange: true });
    // }

    // closePopups() {
    //     this.setState({ openChange: false, openRemove: false });
    // }

    componentDidMount() {
        getUsers().then(resp => {
            const users = resp.data.users;

            this.setState({ users });
        });
    }

    // showLogWindow(status, id) {
    //     this.setState({ openLogWindow: status, showLogWindowId: id});
    // } 

    // showDataForUpdate(userId) {
    //     let selectedUser = this.state.users.find(x => x.id === userId);
    //     this.setState({
    //         updatedUserId: userId,
    //         name:  selectedUser.name,
    //         login:  selectedUser.login,
    //         role:  selectedUser.role,
    //         elite:  selectedUser.elite,
    //         access:  selectedUser.access,
    //         isUpdate:  true
    //     });
    // }



    updateUser(userId, updatedUser) {
        let users  = this.state.users;
        let userIndex = users.findIndex(element => element.id == userId );
        let updatedUsers = users;
        updatedUsers[userIndex] = {...updatedUsers[userIndex], ...updatedUser}
        this.setState({ users: updatedUsers});

        // updateUser(updatedUserId, { name, login, role, elite, access }).then(resp => {
        //     console.log("resp", resp);
        // }).catch(err => console.log(err));
    }


    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            if (!this.props.open) {
                console.log("CLOSED")

                this.setState({
                    isUpdate: false,
                    updatedUserId: "",
                    name:  "",
                    login:  "",
                    role:  "",
                    elite:  "",
                    access: ""
                });
            }
        }
    }

    showLogWindow(status, id) {
      this.setState({ openLogWindow: status, showLogWindowId: id});
  } 

    render() {
        const {
            open,
            component,
            close
        } = this.props;

        const {
            isUpdate,

            // popups
            openRemove,
            openChange,
            openLogWindow,
            showLogWindowId,
            event,

            selectedUser,
            statusPopup,
            users,
            filteredUsers,

            statusRemovePopup,

            openRemovePopup,

            count,
            rowsPerPage,
            page,
            searchText,
            filtering

            
        } = this.state;

    const id = selectedUser ? selectedUser.id : "";

    const data = (filteredUsers[0] || filtering) ? filteredUsers : users;

        return (
<div className="users-section">
<div className='header'>
                  <TextField onChange={event => this.search(event.target.value)} className='search' variant="outlined" size="medium" value={searchText} label='Search'/>
                </div>
<Box sx={{ width: "fit-content", marginLeft: "auto" }}>

            <Tooltip title="Add new">
              <Button className="add_button" onClick={this.openPopup}>
                <span className="add_button--plusSign">+</span>
                <span>add new</span>
              </Button>
            </Tooltip>
          </Box>

          {statusPopup && (
          <Dialog
            open={statusPopup}
            id={id}
            user={selectedUser}
            handleClose={this.closePopup}
            createUser={this.createUser}
            updateUser={this.updateUser}
          />
        )}
        <RemoveDialog
          remove={this.removeUser}
          open={openRemovePopup}
          close={this.closeRemovePopup}
        />
                <GenerateNew open={openChange} close={this.closeRemovePopup} event={event}/>
                <LogsWindow open={openLogWindow} close={this.showLogWindow} userId={showLogWindowId}/>


          <div className="numbersTableWrapper">
            <TableContainer className="numbersTable">
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>ID User</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Login</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='usersTable'>
                  {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, i) => (
                    <Row
                      key={user.id || + new Date()}
                      user={user}
                      index={i}
                      removeUser={() => this.openRemovePopup(user)}
                      editUser={() => this.openPopup(user)}
                      showLogWindow={() => this.showLogWindow(true, user.id)}
                      generateNewPass={() => this.generateNewPass(user)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                      component="div"
                      backIconButtonProps={{
                          'aria-label': 'Previous Page',
                      }}
                      nextIconButtonProps={{
                          'aria-label': 'Next Page',
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      rowsPerPageOptions={[15, 30, 100]}
                      component="div"
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}

                    />
          </div>
          </div>
        )
    }
}

export default Users;

function Row(props) {
  const { user, index, editUser, removeUser, showLogWindow, generateNewPass } = props;

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > * ": { borderBottom: "none" } }}
        className={index % 2 ? "oddRow" : "evenRow"}
      >
        <TableCell>
          {user.name}
        </TableCell>
        <TableCell>
          {user.id}
        </TableCell>
        <TableCell>
          {user.role}
        </TableCell>
        <TableCell>
          {user.login}
        </TableCell>
        <TableCell>
         {user.access}
        </TableCell>

        <TableCell style={{ borderBottom: "none" }} className="actionsButtons">
          <Tooltip title="Edit">
            <Button
              onClick={() => editUser()}
              className="actionBtn action actionBtn--edit"
              variant="fab"
              mini
              aria-label="Edit"
            >
              <Icon className="offer_icon editIcon">edit_icon</Icon>
            </Button>
          </Tooltip>
          <Tooltip title="Logs">
            <Button
              // onClick={() => editUser()}
              onClick={() => showLogWindow()}
              className="actionBtn action actionBtn--edit"
              variant="fab"
              mini
              aria-label="Edit"
            >
              <Icon className="offer_icon editIcon">menu</Icon>
            </Button>
          </Tooltip>
          <Tooltip title="Generate new password'">
            <Button
              onClick={() => generateNewPass(user)}
              className="actionBtn action actionBtn--edit"
              variant="fab"
              mini
              aria-label="Edit"
            >
              <Icon className="offer_icon editIcon">loop</Icon>
            </Button>
          </Tooltip>
          <Tooltip title="Remove">
            <Button
              onClick={() => removeUser()}
              className="actionBtn actionBtn--remove action"
              style={{marginRight: '10px'}}
              variant="fab"
              mini
              aria-label="Remove"
            >
              <Icon className="offer_icon removeIcon">delete</Icon>
            </Button>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ border: "none", background: "#E4ECF8" }}
          colSpan={6}
        >
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}