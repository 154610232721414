import React, { Component } from 'react';
import { Button, Tooltip, Icon } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';

import './supplierUser.scss';

import { getAllSuppliers, baseUrlUploads } from '../../../../service';
import store from '../../../../store';
import {  
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
   } from '../../../../actions/actions';

class SupplierUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            status: 'pendding',
            id: null,
            redirect: false
        }
    }

    editSupplier(id) {
        this.setState({
            id: id,
            redirect: true
        });
    }

    componentWillMount() {
        store.dispatch({ type: OPEN_LOADING, text: 'Loading suppliers...' });
        getAllSuppliers().then(result => {
            const suppliers = result.data.suppliers;

            this.setState({
                suppliers,
                state: 'loaded'
            });

            store.dispatch({ type: CLOSE_LOADING });
        }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't load suppliers` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_telco' });
    }

    render() {
        const { suppliers, redirect, id } = this.state;

        if(redirect)
            return (<Redirect to={'/'+id} />)

        return (
            <div className='supplier_section'>
                <div className='header anim_content'>
                <Tooltip title='Help'>
                    <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>
                </div>

                <div className='content anim_content'>
                    {suppliers.map( sup => {
                        return (
                            <div key={sup.id} className='supplier'>
                                <div className='actions'> 
                                    <div className='action edit' onClick={() => this.editSupplier(sup.id) }>View</div>
                                </div>
                                <div className='content'>
                                    { sup.img 
                                        ? 
                                        <div className='block_img'>
                                            <img className='image' src={baseUrlUploads+sup.img}/>
                                        </div> 
                                        : 
                                        <div className='block_text'>
                                            {sup.name}
                                        </div> 
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default SupplierUser;