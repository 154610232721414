import React, { Component } from 'react';
import Button from '@mui/material/Button';
import {Button as ButtonAction} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import renderHTML from 'react-render-html';
import store from '../../store';
import BottomMessagesPopup from '../dialogs/bottomMessagePopup/bottomMessagePopup';
import DeialogRemove from '../dialogs/removePopup';

import {
    Tooltip,
    Icon,
} from '@material-ui/core';
import Box from '@mui/material/Box';

import ComperatoreAgentEneDomestico from './comperatoreAgentEneDomestico';
import ComperatoreAgentEneBusiness from './comperatoreAgentEneBusiness';
import ComperatoreAgentGas from './comperatoreAgentGas';
import { 
    getAllOffers, 
    getComperatoreData, 
    getAllComperatoreScripts, 
    deleteComperatoreScript 
} from '../../service';

import {
    OPEN_HELP
} from './../../actions/actions';

import '../comperatoreAdmin/comperatore.scss'

const selectionButtons = [
    { label: 'Luce domestico', id: 'energia_d' },
    { label: 'Luce Business', id: 'energia_b' },
    { label: 'GAS DOMESTICO & BUSINESS', id: 'gas_db' },
];


class Comperatore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedType: "energia_d",
            offers: [],
              
            bta: {},
            imposte: {},
            sintetica: {},
            comperatoreEne: {},
            comperatoreGas: {},

            comperatoreScripts: [],
            openBottomMessagesPopup: false,
            selectedMessage: {},
            openDialogRemove: false
        }

        this.onChangeSelectionType = this.onChangeSelectionType.bind(this);
        this.openBottomMessagesPopup = this.openBottomMessagesPopup.bind(this);
        this.closeBottomMessagesPopup = this.closeBottomMessagesPopup.bind(this);
        this.askRemove = this.askRemove.bind(this);
        this.closeRemove = this.closeRemove.bind(this);
        this.removeMessage = this.removeMessage.bind(this);

        this.updateComperatoreScript = this.updateComperatoreScript.bind(this);
        this.addComperatoreScript = this.addComperatoreScript.bind(this);
    }


    onChangeSelectionType(id) {
        console.log('selectionButtons id ', id);
        this.setState({ selectedType: id })
    }

    componentWillMount() {
        getAllOffers().then(result => {
            this.setState({ offers: result.data.offers });
        }).catch(err => {
            console.log('e', err)
        })

        getComperatoreData().then(result => {
            const { bta, imposte, sintetica, comperatoreEne, comperatoreGas } = result.data.payload;
            this.setState({ comperatoreEne, comperatoreGas });
            this.setState({ 'imposte': imposte.data});
            this.setState({ 'sintetica': sintetica.data});
            this.setState({ 'bta': bta.data});
          }).catch(err => {
              console.log("error", err)
          })

          getAllComperatoreScripts().then(result => {
            const scripts = result.data.scripts;
            this.setState({ comperatoreScripts:  scripts});
        })
    }

        // bottom messages 
        openBottomMessagesPopup(selectedMessage) {
            this.setState({ selectedMessage: !selectedMessage ? {} : selectedMessage, openBottomMessagesPopup: true, });
        }
    
        closeBottomMessagesPopup() {
            this.setState({ selectedMessage: {}, openBottomMessagesPopup: false });
        }

        updateComperatoreScript(script) {
            const comperatoreScripts = this.state.comperatoreScripts.map(message => {
    
                if(message.id == script.id) {
                    message.text = script.text;
                }
    
                return message;
            })
    
            this.setState({ comperatoreScripts });
        }
    
        addComperatoreScript(script) {
            const {comperatoreScripts} = this.state
            console.log('new script', script);
            this.setState({
                comperatoreScripts: [...comperatoreScripts, script]
            })
        }

        askRemove(id) {
            this.setState({openDialogRemove: true, removeId: id});
        }
    
        closeRemove() {
            this.setState({openDialogRemove: false, removeId: null});
        }
    
        removeMessage() {
            const { comperatoreScripts, removeId: id } = this.state;
            this.closeRemove();
    
            deleteComperatoreScript(id)
                .then(reslut => {
                }).catch(err => {
                    this.setState({ updatedScripts });
                })
    
            const updatedScripts = comperatoreScripts.filter(x => x.id != id);
            this.setState({ comperatoreScripts: updatedScripts });
        }

        openHelp() {
            store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
          }

    render() {
        const {
            selectedType,
            offers,
            comperatoreGas,
            imposte,
            sintetica,
            comperatoreScripts,
            openBottomMessagesPopup,
            selectedMessage,
            openDialogRemove
        } = this.state;

        const { role } = store.getState().auth;


        return (
            <div className='comperatore'>
                <div className="comperatore__selectionsButtons">
                    {selectionButtons.map(option => (
                        <Button key={option} className={selectedType === option.id ? "comperatore__selectionBtn--active" : "comperatore__selectionBtn"} onClick={() => this.onChangeSelectionType(option.id)} value={option}>
                            {option.label}
                        </Button>
                    ))}
                    <Tooltip title='Help'>
                        <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                    </Tooltip>
                </div>

                {selectedType === "energia_d" && <ComperatoreAgentEneDomestico offers={offers}/>}
                {selectedType === "energia_b" && <ComperatoreAgentEneBusiness offers={offers}/>}
                {selectedType === "gas_db" && <ComperatoreAgentGas  offers={offers} comperatoreGas={comperatoreGas} imposte={imposte} sintetica={sintetica}/>}
                
                <BottomMessagesPopup open={openBottomMessagesPopup} selectedMessage={selectedMessage} handleClose={this.closeBottomMessagesPopup} updateComperatoreScript={(script) => this.updateComperatoreScript(script)} addComperatoreScript={(script) => this.addComperatoreScript(script)}/>
                <DeialogRemove open={openDialogRemove} remove={this.removeMessage} close={this.closeRemove}/>
                <div className='bottomMessagesSection'>
                        <div className='bottomMessages' >
                            { comperatoreScripts && comperatoreScripts.length > 0 
                            ?
                            comperatoreScripts.map(x => (
                                <div className='bottomMessages__message'>{renderHTML(x.text)}
                                    <div className={role === 'admin' ? 'bottomMessages__actions' : 'hide'}>
                                        <Tooltip title='Edit'><ButtonAction onClick={() => { this.openBottomMessagesPopup(x) }}  className='actionBtn actionBtn--edit' variant="fab" mini aria-label="Edit"><Icon className='editIcon'>edit_icon</Icon></ButtonAction></Tooltip>
                                        <Tooltip title='Delete'><ButtonAction className='actionBtn actionBtn--remove' variant="fab" mini aria-label="Remove" onClick={() => this.askRemove(x.id)}><Icon className='offer_icon removeIcon'>delete</Icon></ButtonAction></Tooltip>
                                    </div>
                                </div>
                            ))
                            : role == 'admin' ? 'Click Add new to insert text' : ''
                            }
                        </div>

                        {role == 'admin' &&
                            <Box sx={{width: 'fit-content', marginLeft: 'auto'}}>
                                <Tooltip title='Add new'>
                                    <Button className='add_button' onClick={() => { this.openBottomMessagesPopup() }} ><span className='add_button--plusSign'>+</span><span>add new</span></Button>
                                </Tooltip>
                            </Box>
                        }
                </div>

            </div>
        )
    }
}


export default Comperatore;