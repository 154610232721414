import React, { Component } from 'react';

import SupplierAdmin from './admin/SupplierAdmin';
import SupplierUser from './user/SupplierUser';

import store from '../../../store';

class List extends Component {

    render() {
        const { role } = store.getState().auth;
        
        return (
            role == 'admin' ? <SupplierAdmin/> : <SupplierUser/>
        )
    }
}

export default List;