import React, { Component } from 'react';
import { Button, Tooltip, Icon } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { generate } from 'generate-password';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";



class GenerateNew extends Component {
    constructor() {
        super()

        this.state = {
            password: ''
        };

        this.generatePass = this.generatePass.bind(this);
        this.changeIntup = this.changeIntup.bind(this);
    }

    changeIntup(event) {
        this.setState({ password: event.target.value });
    }

    generatePass() {
        const password = generate({
            length: 24,
            numbers: true
        })

        this.setState({
            password
        });
    }

  render() {
    const {
        open,
        event,
        close
    } = this.props;

    const {
        password
    } = this.state;

    return (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", mt: "20px", alignItems: 'flex-end' }}>
              <TextField
                label="Password"
                size="small"
                className="passwordInput"
                value={password}
                onChange={this.changeIntup}
              />
              <Box sx={{ ml: "20px" }}>
                <Tooltip title="Generate Password" placement="bottom-end">
                  <Button
                    className="action -generate"
                    onClick={this.generatePass}
                    style={{ background: "#ecf1f9" }}
                    mini
                    aria-label="Generate"
                  >
                    <Icon style={{ color: "#2B61A5" }} className="offer_icon">
                      all_inclusive
                    </Icon>
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </DialogContent>
      
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button onClick={() => event(password)} color="primary">
              Update password
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default GenerateNew;