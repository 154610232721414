import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import moment from 'moment';

// table 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const ComperatoreAgentEneBusiness = (props) => {

    const [monthNumber, setMonthNumber] = useState();
    const [regione, setRegione] = useState();
    const [consumi, setConsumi] = useState(0);

    const [clientType, setClientType] = useState();
    const [iva, setIva] = useState();
    const [selectedOffer, setSelectedOffer] = useState();

    //data
    const [offers, setOffers] = useState([]);
    const [filteredOffers, setFilteredOffers] = useState([]);
    const [imposte, setImposte] = useState([]);
    const [sintetica, setSintetica] = useState([]);
    const [dataGas, setDataGas] = useState();

    // cmperatore result 
    const [isResult, setIsResult] = useState(false);
    const [result, setResult] = useState({});


    useEffect(() => {
        if (!props.offers) return;

        let filteredOffers = props.offers.filter(offer => {
            const {
                client_type,
                type_product,
            } = offer;

            if ((client_type === "resid" || client_type === "business") && type_product === "gas") return offer;
        })
        setOffers(filteredOffers);
    }, [props.offers])

    useEffect(() => {
        setImposte(props.imposte);
        setDataGas(props.comperatoreGas);
        setSintetica(props.sintetica);
    }, [])


    const onChangeTypeClient = (type) => {
        setClientType(type)
        setSelectedOffer()
        setIva()
        setIsResult(false)

        let filteredOffers = offers;
        filteredOffers = offers.filter(offer => {

            const {
                client_type,
                type_product,
            } = offer;
            if (type === 'domestico' && client_type === "resid" && type_product === "gas") return offer;
            if (type === 'iva' && client_type === "business" && type_product === "gas") return offer;
        })
        setFilteredOffers(filteredOffers);
    }


    useEffect(() => {
        if (
            clientType === 'iva' &&
            iva &&
            monthNumber &&
            regione &&
            consumi &&
            selectedOffer &&
            imposte &&
            dataGas &&
            sintetica

        ) {
            setIsResult(true)
        } else if (
            clientType === 'domestico' &&
            monthNumber &&
            regione &&
            consumi &&
            selectedOffer &&
            imposte &&
            dataGas &&
            sintetica
        ) {
            setIsResult(true)
        }
        else {
            setIsResult(false)
        }
    }
        , [
            monthNumber,
            regione,
            consumi,
            clientType,
            iva,
            selectedOffer,
            imposte,
            dataGas,
            sintetica
        ])


    const getResultsDomestico = () => {
        console.log(selectedOffer, regione);
        if (!isResult) return

        let selectedSintetica = null;

        if(regione) {
            regione['erariale'] = regione['erariale'].replace(',', '.')
            regione['total'] = regione['total'].replace(',', '.')
            selectedSintetica = sintetica.find(x => x.name.toLowerCase() === regione.name.toLowerCase())
        }

        if(!selectedSintetica && clientType != 'domestico') {
            return ""
        }

        if(selectedSintetica && selectedSintetica['transporto']) {
            selectedSintetica['transporto'] = selectedSintetica['transporto'].replace(',', '.');
        }

        if(dataGas) {
            dataGas['comsumi_rete_oneri'] = dataGas['comsumi_rete_oneri'].replace(',', '.');
            dataGas['comsumi_energia_transporto'] = dataGas['comsumi_energia_transporto'].replace(',', '.');
            dataGas['iva_fino'] = dataGas['iva_fino'].replace(',', '.');
            dataGas['iva_oltre'] = dataGas['iva_oltre'].replace(',', '.');
            dataGas['iva_agevolata'] = dataGas['iva_agevolata'].replace(',', '.');
            dataGas['per_tuti'] = dataGas['per_tuti'].replace(',', '.');

            if(dataGas['monthly_fee'])
                dataGas['monthly_fee'] = dataGas['monthly_fee'].replace(',', '.');
        }

        dataGas.procent_num = dataGas.procent_num ? dataGas.procent_num : 0;
        // V40
        const total_v40 = selectedOffer.monthly_fee;
        // X36
        // const total_oneri_sistema = +selectedOffer.monthly_fee;
        const total_oneri_sistema = (+dataGas.comsumi_rete_oneri / 12 / 100) + (0.0022 * +consumi / 100);
        // Z36
        const total_transporto_contatore = dataGas.comsumi_energia_transporto / 12 / 100;
        // I5
        const total_smc = selectedOffer.price_gas * 100;
        // I6
        const total_smt = +total_v40
        console.log(total_v40, 'total_v40', dataGas)
        // I7
        const validata_offerta = moment(selectedOffer.exp_date).format('DD/MM/YYYY');
        // I8
        const total_spesa_materia = (total_smc / 100 * consumi + monthNumber * total_smt)
        
        console.log(total_smc, consumi, monthNumber, total_transporto_contatore, (total_smc / 100 * consumi + monthNumber * total_transporto_contatore), dataGas.comsumi_energia_transporto, '(total_smc / 100 * consumi + monthNumber * total_transporto_contatore)')
        // I9
        const total_spesa_transporto = clientType === 'domestico'
            ? (+dataGas.comsumi_energia_transporto_smc * +consumi) + ((+dataGas.comsumi_energia_transporto / 12) * +monthNumber)
            : (+selectedSintetica.transporto * +consumi) + ((+dataGas.comsumi_energia_transporto / 12) * +monthNumber)

        // console.log(total_transporto_contatore, total_spesa_transporto, 'total_spesa_transporto')
        // console.log('selectedSintetica.transporto', selectedSintetica.transporto)
        // console.log('dataGas.comsumi_energia_transporto', dataGas.comsumi_energia_transporto)
        // I10
        const total_spesa_oneri = (consumi * total_oneri_sistema)
        console.log('total_spesa_oneri', total_spesa_oneri, consumi, total_oneri_sistema)

        // I11
        const total_imposte = ((consumi * Number(regione.total) / 100) + (consumi * Number(regione.erariale)))
        // I12
        let total_iva = 0;

        if (clientType === 'domestico') {
            total_iva = consumi <= 480
                ? (+total_spesa_materia + +total_spesa_transporto + +total_spesa_oneri + +total_imposte) * +dataGas.iva_fino / 100
                : (+total_spesa_materia + +total_spesa_transporto + +total_spesa_oneri + +total_imposte) * +dataGas.iva_oltre / 100
        } else {
            total_iva = iva === 'si'
                ? (+total_spesa_materia + +total_spesa_transporto + +total_spesa_oneri + +total_imposte) * +dataGas.iva_agevolata / 100
                : (+total_spesa_materia + +total_spesa_transporto + +total_spesa_oneri + +total_imposte) * +dataGas.per_tuti / 100
        }
        // I13
        const total_boletta_sensa = +total_spesa_materia + +total_spesa_transporto + +total_spesa_oneri + +total_imposte
        // I14
        const total_boletta_con = +total_boletta_sensa + +total_iva
        // I15
        const total_base = (+total_boletta_con * (12 / monthNumber))
        // F4
        const costo_media_materia = (total_spesa_materia / consumi)
        // F5
        const costo_media_unitario = (total_boletta_con / consumi)

        const withCommma = (number) => {
            let result = number.toFixed(2).toString().replace(".", ",");
            return result
        }

        return (
            <Box sx={{ margin: '50px 0', display: 'flex' }}>
                <Box sx={{ marginRight: '50px' }}>
                    <TableContainer component={Paper} sx={{ width: 400 }}>
                        <Table sx={{ minWidth: 250 }} size="small" aria-label="simple table">
                            <TableHead className="comperatore__tableHead">
                                <TableRow>
                                    <TableCell>Nome Offerta</TableCell>
                                    <TableCell align="right">{selectedOffer.offer_name}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Costo medio materia energia
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(costo_media_materia)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='2'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Costo medio unitario della bolletta
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(costo_media_unitario)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box sx={{ marginRight: '50px' }}>
                    <TableContainer component={Paper} sx={{ width: 540 }}>
                        <Table sx={{ minWidth: 250 }} size="small" aria-label="simple table">
                            <TableHead className="comperatore__tableHead">
                                <TableRow>
                                    <TableCell>Dettaglio Della Bolletta</TableCell>
                                    <TableCell align="right">{`Offerta cliente ${clientType === 'domestico' ? 'domestico' : 'busines'}`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        (€cts/Smc)
                                    </TableCell>
                                    <TableCell align="right">€  {withCommma(total_smc)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='2'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Servizi di Vendita (€/messe) SMT
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(total_smt)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='3'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Validita Offerta
                                    </TableCell>
                                    <TableCell align="right">{validata_offerta}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='4'
                                    className="accentRow--blue"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">Spesa Per La Materia Gas Naturale</span>
                                    </TableCell>
                                    <TableCell align="right"> <span class="accentText">€ {withCommma(total_spesa_materia)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='5'
                                    className="accentRow--blue"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <span class="accentText">Spesa per il trasporto e la gestione del contatore</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(total_spesa_transporto)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='6'
                                    className="accentRow--blue"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">Spesa Per Oneri di Sistema</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(total_spesa_oneri)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='7'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Imposte
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(total_imposte)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='8'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        IVA
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(total_iva)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='9'
                                    className="accentRow--orange"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">Totale sensa IVA</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(total_boletta_sensa)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='10'
                                    className="accentRow--orange"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">Totale con IVA</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€ {withCommma(total_boletta_con)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='11'
                                    className="accentRow--lightAccent borderTop"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span class="accentText">Totale Boletta su base annua</span>
                                    </TableCell>
                                    <TableCell align="right"><span class="accentText">€  {withCommma(total_base)}</span></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }



    return (
        <section className="comperatore__section">
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Numero mesi Bolletta
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Numero mesi Bolletta"
                            value={monthNumber}
                            onChange={(e) => setMonthNumber(e.target.value)}
                        // onChange={(e) => setData({ ...data, supplier: e.target.value })}
                        // setData({ ...data, supplier: value })}
                        >
                            <MenuItem key={1} value={1}>1</MenuItem>
                            <MenuItem key={2} value={2}>2</MenuItem>
                            <MenuItem key={3} value={3}>3</MenuItem>
                            <MenuItem key={4} value={4}>4</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Regione Punto Di Prelievo
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={regione}
                            label="Regione Punto Di Prelievo"
                            onChange={(e) => setRegione(e.target.value)}
                        >
                            {imposte.length > 0 ? imposte.map((x, i) => {
                                return (
                                    <MenuItem key={i} value={x}>{x.name}</MenuItem>
                                )
                            }) : <MenuItem>No regione</MenuItem>}
                        </Select>
                    </FormControl>
                </div>
            </Box>
            <Box>
                <TextField
                    className="comperatore__input--long"
                    value={consumi}
                    label="Consumi Fatturati"
                    onChange={(e) => setConsumi(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    size="small"
                    type="number"
                />
            </Box>

            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Tipologia Cliente
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={clientType}
                            label="Tipologia Cliente"
                            onChange={(e) => onChangeTypeClient(e.target.value)}
                        >
                            <MenuItem value='domestico'>Domestico</MenuItem>
                            <MenuItem value='iva'>P.IVA</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {clientType === 'iva' && <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Iva Agevolata
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Iva Agevolata"
                            value={iva}
                            onChange={(e) => setIva(e.target.value)}
                        >
                            <MenuItem value='si'>Si</MenuItem>
                            <MenuItem value='no'>No</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                }

                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Lista Offerte
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedOffer ? selectedOffer : null}
                            disabled={!clientType}
                            onChange={(e) => setSelectedOffer(e.target.value)}
                        >
                            {filteredOffers.length > 0 ? filteredOffers.map(offer => {
                                return (
                                    <MenuItem key={offer.id} value={offer}>{offer.offer_name}</MenuItem>
                                )
                            }) : <MenuItem>No offers</MenuItem>}
                        </Select>
                    </FormControl>
                </div>
            </Box>

            {getResultsDomestico()}

        </section>
    )
};

export default ComperatoreAgentEneBusiness;
