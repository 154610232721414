import React, { Component } from 'react';
import {
  Button,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import TableContainer from '@mui/material/TableContainer';

import './priceTable.scss';
import '../../components/offer/list/admin/OfferAdmin';

import store from '../../store';
import {
  OPEN_LOADING,
  CLOSE_LOADING,
} from '../../actions/actions';

import { getTelcoAllOffers, customeGetRequest, baseUrlUploads } from '../../service';

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, selectedType } = this.props;

    return (
      <TableHead>
        <TableRow className='row_block'>

          {rows.map(row => {

            // if(row.tableTypes.includes(selectedType)) {

            return (
              <TableCell
                key={row.id}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
            // }
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

function desc(a, b, orderBy) {
  if (!(a && b))
    return -1;

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array;
  stabilizedThis.sort((a, b) => {
    const order = cmp(a, b);

    if (order !== 0) return order;

    return a - b;
  });


  return stabilizedThis.map(el => el);
}

function getSorting(order, orderBy) {
  return order === 'Supplier' || orderBy === 'Supplier' ? order === 'desc' ? (a, b) => desc(a.Supplier, b.Supplier, 'name') : (a, b) => -desc(a.Supplier, b.Supplier, 'name') : order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'Supplier', numeric: false, disablePadding: true, label: 'Supplier' },
  { id: 'offer_name', numeric: false, disablePadding: true, label: 'Offerta Name' },
  { id: 'price_f0', numeric: true, disablePadding: true, label: 'Price' },
  { id: 'price_f1', numeric: true, disablePadding: true, label: 'Durata' },
  { id: 'price_f2', numeric: true, disablePadding: true, label: 'Tipo di Offerta' },
  { id: 'price_f3', numeric: true, disablePadding: true, label: 'Tipo di Pagamento' },
  { id: 'price_pcv', numeric: true, disablePadding: true, label: 'Tipo di Cliente' },
];

const selections = {
  sTypeTable: [
    { label: 'Domestico', id: 'domestico' },
    { label: 'Business', id: 'business' },
  ],
}

class PriceTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      offers: [],
      filteredOffers: [],
      filtering: false,
      count: 0,
      page: 0,
      prev: '',
      next: '',
      rowsPerPage: 25,
      // redirect: false,
      selectedOffer: null,
      dialogRemove: false,
      removedId: null,
      searchText: localStorage.getItem("searchText"),

      statusPopupInfo: false,
      selectedOffer: null,
      selectedType: "domestico"
    }
  }

  componentWillMount() {
    store.dispatch({ type: OPEN_LOADING, text: 'Loading offers...' });

    getTelcoAllOffers().then(result => {

      result.data.offers.map(element => {
        element.price = element.price.replace('.', ',');
        return element;
      });

      console.log("RESULAT DATA OFFERS: ", result.data )

      const { offers, prev, next, count } = result.data;

      this.setState({ offers, prev, next, count });
      store.dispatch({ type: CLOSE_LOADING });

      this.onChangeSelectionType(this.state.selectedType)

    }).catch(err => {
      store.dispatch({ type: OPEN_LOADING, text: `Can't load offers` });

      setTimeout(() => {
        store.dispatch({ type: CLOSE_LOADING });
      }, 2500)
    })
  }


  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  requestNextPage() {
    const next = this.state.next;

    if (next)
      customeGetRequest(next).then(result => {
        const { offers, prev, next, count } = result.data;

        this.setState({ offers, prev, next, count });
        store.dispatch({ type: CLOSE_LOADING });
      }).catch(err => {
        store.dispatch({ type: OPEN_LOADING, text: `Can't load offers` });

        setTimeout(() => {
          store.dispatch({ type: CLOSE_LOADING });
        }, 2500)
      })
  }

  requestPrevPage() {
    const prev = this.state.prev;

    if (prev)
      customeGetRequest(prev).then(result => {
        const { offers, prev, next, count } = result.data;

        this.setState({ offers, prev, next, count });
        store.dispatch({ type: CLOSE_LOADING });
      }).catch(err => {
        store.dispatch({ type: OPEN_LOADING, text: `Can't load offers` });

        setTimeout(() => {
          store.dispatch({ type: CLOSE_LOADING });
        }, 2500)
      })
  }

  handleChangePage = (event, changePage) => {
    this.setState({ page: changePage });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onChangeSelectionType = (type, initialLoad) => {

    this.setState({ selectedType: type })

    this.setState({ filtering: false, filteredOffers: [] })

    const offers = this.state.offers;

    let filteredOffers = offers;
    filteredOffers = filteredOffers.filter(offer => {

      const {
        client_type
      } = offer;

      if (type === 'business' && client_type === "business") return offer;
      if (type === 'domestico' && client_type === "resid") return offer;
    })

    this.setState({ filteredOffers, filtering: true });

  }

  render() {
    const {
      order,
      orderBy,
      selected,
      offers,
      filteredOffers,
      filtering,
      page,
      rowsPerPage,
      count,
      selectedType
    } = this.state;
    const { open: loading } = store.getState().loading;

    const data = (filteredOffers[0] || filtering) ? filteredOffers : offers;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className={loading ? 'hide' : 'offer_section anim_content'}>

        <div className='priceTable__selectionsButtons'>
          {selections.sTypeTable.map(option => (
            <Button key={option} className={selectedType === option.id ? "priceTable__selectionBtn--active" : "priceTable__selectionBtn"} onClick={() => this.onChangeSelectionType(option.id)} value={option}>
              {option.label}
            </Button>
          ))}
        </div>
        <div className='content'>
            <div className="numbersTableWrapper">
            <TableContainer className="numbersTable">
              <Table aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={count}

                  selectedType={selectedType}
                />
                <TableBody>
                  {stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(offer => {
                      const date = new Date(offer.exp_date);
                      const day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
                      const month = (date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;

                      const todayStart = + new Date().setHours(0, 0, 0, 0);
                      const todayEnd = + new Date().setHours(23, 59, 59, 999);
                      const offerExp = + new Date(offer.exp_date);

                      const paymentTypes = [
                        {name: 'Rid cc', value: offer.rid_cc} ,
                        {name: 'Rid cp', value: offer.rid_cp} ,
                        {name: 'Ricaricabile', value: offer.ricaricabile} ,
                        {name: 'Bollettino', value: offer.bollettino}
                      ].filter(x => x.value);

                      const offerTypes = [
                        {name: 'Rid cambio_mobile', value: offer.cambio_mobile} ,
                        {name: 'Cambio linea fissa', value: offer.cambio_linea_fissa} ,
                        {name: 'Cambio mobile linea fissa', value: offer.cambio_mobile_linea_fissa} ,
                        {name: 'Nuova attivazione', value: offer.nuova_attivazione}
                      ].filter(x => x.value);

                      let offerClass = 'row_block'

                      if (offerExp >= todayStart && offerExp <= todayEnd)
                        offerClass += ' -today';

                      if (offerExp < todayStart)
                        offerClass += ' -not_valid';

                      if (offerExp > todayStart && offerExp > todayEnd)
                        offerClass += ' -valid';

                      const formatedDate = `${day}/${month}/${date.getFullYear()}`;
                      return (
                        <TableRow
                          // role="checkbox"
                          tabIndex={-1}
                          key={offer.id}
                          className={offerClass}
                        >

                          <TableCell>
                            <div className='offer_supplier'>
                              {offer.TelcoSupplier ? offer.TelcoSupplier.name : ''}
                              {(offer.TelcoSupplier && offer.TelcoSupplier.img) && <div className='logo_supplier_section'>
                                <div className='logo_supplier'>
                                  <img src={baseUrlUploads + offer.TelcoSupplier.img} />
                                </div>
                                <div className='tooltip_image'>
                                  <img src={baseUrlUploads + offer.TelcoSupplier.img} />
                                </div>
                              </div>}
                            </div>
                          </TableCell>
                          <TableCell>{offer.offer_name}</TableCell>
                          <TableCell>{`${offer.price}€`}</TableCell>
                          <TableCell>{offer.duration}</TableCell>
                          <TableCell>{offerTypes.map(x => x.name).join(', ')}</TableCell>
                          <TableCell>{paymentTypes.map(x => x.name).join(', ')}</TableCell>
                          <TableCell>{offer.client_type}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              </TableContainer>
            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        </div>
      </div>
    )
  }
}

export default PriceTable;