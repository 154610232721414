import React, { Component } from 'react';
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';

import store from '../store';
import { LOGIN } from '../actions/actions';

// comp
import Login from './login/login';
import Content from './content/content';
import Loading from './loading/loading';

import * as service from './../service';

// styles
import '../main.scss';
import '../cssLibs/quill.snow.scss';

class App extends Component {
    constructor() {
        super();

        const { auth } = store.getState();

        this.state = {
            auth: auth.auth ? auth.auth : 'pending',
            role: auth.role
        }
    }

    componentDidMount() {
        if(!service.token)
            return this.setState({auth: false});

        service.getMe().then(data => {
            const { ok, user } = data.data;

            store.dispatch({ type: LOGIN, auth: ok, role: user.role, elite: user.elite, access: (user.role === 'admin') ? 'dual' : user.access });
            
            this.setState({auth: true});
        }).catch(err => {
            this.setState({auth: false});
        })
    }

    render() {
        const auth = this.state.auth;
        return (
            <HashRouter>
                <Switch>
                    <Route render={() => (
                        auth == 'pending' ? (
                            <Route component={ Loading }/>
                        ) : (
                            auth ? (  
                                <Route component={ Content }/>  
                              ) : (
                                <Route component={ Login }/>
                              )
                        )
                    )}/>
                </Switch>
            </HashRouter>
        )
    }
}

export default App;