import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

class Notification extends Component {
  
    render() {
      const {
          open,
          text,
          close
      } = this.props;

      return (
          <Snackbar
            className='notification'
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={open}
            autoHideDuration={2500}
            onClose={close}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{text}</span>}
          />
      );
    }
  }

  export default Notification;