import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import renderHTML from 'react-render-html';
import { getMessages, getAllScripts, getAllNumbers } from '../../service';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';

import './helperSearch.scss';

const HelperSearch = (props) => {
    const [messages, setMessages] = useState([]);
    const [scripts, setScripts] = useState([]);
    const [numbers, setNumbers] = useState([]);

    const [showMessage, setShowMessage] = useState(null);
    const [showScript, setShowScript] = useState(null);

    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const promises = [getMessages(), getAllScripts(), getAllNumbers()];

        Promise.all(promises).then(resps => {
            const [
                msgResp,
                scrResp,
                numResp
            ] = resps;

            const messages = msgResp.data.messages;
            const scripts = scrResp.data.scripts;
            const numbers = numResp.data.numbers;

            setMessages(messages || []);
            setScripts(scripts || []);
            setNumbers(numbers || []);
        })
    }, []);

    return (
        <div style={{ paddingBottom: '20vh'}}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextField style={{ width: '500px', marginTop: '20px' }} label='Search' value={searchText} onChange={(event) => setSearchText(event.target.value)}/>
            </div>
            <h3 style={{ paddingLeft: '15px'}}>Messages</h3>
            <div>
                <div>
                    {messages.filter(message => {
                        if(!searchText || searchText.length < 3) return true;

                        const regExp = new RegExp(`(${searchText})`, 'img');

                        return regExp.test(message.text) || regExp.test(message.title);

                    }).map((message, index) => {
                        let { 
                            title,
                            text = ''
                        } = message;

                        if(searchText?.length > 2) {
                            const regExp = new RegExp(`(${searchText})`, 'img');
                            
                            text = text.replace(regExp, ` <span style='background-color: yellow; text-decoration: underline;'>${searchText}</span> `)
                        }

                        return (
                            <div className='message_section_search'>
                                <div className='messageTooltipWrapper_searchHelper'>
                                    <div className='messageTooltip_search'>{renderHTML(text)}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <h3 style={{ paddingLeft: '15px'}}>Scripts</h3>
                <div>
                    {scripts.filter(script => {
                        if(!searchText || searchText.length < 3) return true;

                        const regExp = new RegExp(`${searchText}`, 'img');

                        const exist = regExp.test(script.text) || regExp.test(script.title);

                        return exist;

                    }).map((script, index) => {
                        let {
                            text,
                            title
                        } = script;

                        if(searchText?.length > 2) {
                            const regExp = new RegExp(`(${searchText})`, 'img');

                            text = text.replace(regExp, ` <span style='background-color: yellow; text-decoration: underline;'>${searchText}</span> `)
                        }

                        console.log(text);
                        
                        return (<div className='messageWrapper_search'>
                            <div className='message_section_search'>
                               <div className='messageTooltipWrapper_searchHelper'>
                                <div className='messageTooltip_search'>{renderHTML(text || ``)}</div>
                                </div>
                            </div>
                        </div>);
                    })}
                </div>
                <h3 style={{ paddingLeft: '15px'}}>Numbers</h3>
                <div>

                    <div className="content">
                        <div className="numbersTableWrapper_search">
                            <TableContainer className="numbersTable">
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ paddingLeft: '20px'}}>Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {numbers.filter(num => {
                                        if(!searchText || searchText.length < 3) return true;

                                        const regExp = new RegExp(`(${searchText})`, 'img');

                                        return regExp.test(num.text) || regExp.test(num.head);

                                    }).map((row, i) => {
                                        let text = row.text;
                                        let head = row.head

                                        if(searchText?.length > 2) {
                                            const regExp = new RegExp(`(${searchText})`, 'img');
                                            
                                            text = text.replace(regExp, ` <span style='background-color: yellow; text-decoration: underline;'>${searchText}</span> `)
                                            head = head.replace(regExp, ` <span style='background-color: yellow; text-decoration: underline;'>${searchText}</span> `)
                                        }

                                        return (
                                            <Row
                                                key={row.name}
                                                row={{...row, text, head}}
                                                index={i}
                                            />
                                        )
                                    })}
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
};

export default HelperSearch;




function Row(props) {
    const { row, index, editNumber, removeNumber } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > * ": { borderBottom: "none" } }}
          className={index % 2 ? "oddRow" : "evenRow"}
        >
          <TableCell onClick={() => setOpen(!open)} style={{ cursor: "pointer", height: '50px' }}>
            {renderHTML(row.head)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{ border: "none", background: "#E4ECF8", padding: '20px' }}
            colSpan={6}
          >
            <Collapse in={true} timeout="auto" unmountOnExit>
              <div className="collapse_text">{renderHTML(row.text)}</div>
              <div className="collapse_number">{row.number}</div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }