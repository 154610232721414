import React, { Component } from 'react';
import { 
    TextField,
    Button,
    MenuItem,
    GridList,
    Icon,
    Tooltip
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import store from '../../../store';
import {   
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
    } from '../../../actions/actions';

import { getTelcoAllSuppliers, createTelcoOperation, getTelcoAllOperations, baseUrlUploads } from './../../../service';

import PopupScript from '../../../components/dialogs/script';

import OperationScript from '../operationScript/';

import './newOperation.scss';

class NewOperation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            supplier_id: null,

            redirect: false,

            switch: {
                resid: true,
                business: false
            },

            supplier_id: '',
            cambio_mobile_resid: '',  
            cambio_linea_fissa_resid: '',      
            cambio_mobile_linea_fissa_resid: '', 
            nuova_attivazione_resid: '',        

            cambio_mobile_business: '',             
            cambio_linea_fissa_business: '',        
            cambio_mobile_linea_fissa_business: '', 
            nuova_attivazione_business: '',  
            
            nds_resid: '',
            nds_business: '',

            statusPopupScript: false,
            selectedScript: null,
            selectScriptText: ''
        };

        this.switchTab = this.switchTab.bind(this);
        this.selectSupplier = this.selectSupplier.bind(this);
        this.createOperation = this.createOperation.bind(this);
        this.openPopupScript = this.openPopupScript.bind(this);
        this.closePopupScript = this.closePopupScript.bind(this);
        this.changeTextScript = this.changeTextScript.bind(this);
    }

    componentWillMount() {
        const suppliersPromise = getTelcoAllSuppliers();
        const operationsPromise = getTelcoAllOperations();

        store.dispatch({ type: OPEN_LOADING, text: 'Loading suppliers...' });

        Promise.all([suppliersPromise, operationsPromise]).then(result => {
            let suppliers = result[0].data.suppliers;
            const operations = result[1].data.operations;


            suppliers = suppliers.filter(sup => {
                if(!operations.some(operation => sup.id == operation.supplier_id))
                    return sup;
            })

            this.setState({ suppliers, operations, loading: false });
            store.dispatch({ type: CLOSE_LOADING });
        }).catch( err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't load suppliers` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);

            this.setState({ loading: false, err: true });
        });
    }

    changeValue(name, value) {
        const state = this.state;

        state[name] = value;

        this.setState(state);
    }

    switchTab() {
        let { switch: { resid, business } } = this.state;

        resid= !resid;
        business = !business;

        this.setState({ switch: { resid, business } });
    }

    selectSupplier(id) {
        const suppliers = this.state.suppliers;
        let theSame = false;

        suppliers.map(supplier => {
            if(supplier.id == id) {
                supplier.selected = !supplier.selected;
                theSame = supplier.selected;

                return supplier;
            } else if(supplier.selected) {
                supplier.selected = false;

                return supplier;
            }

            return supplier;
        });

        if(theSame)
            this.setState({ supplier_id: id });
        else
            this.setState({ supplier_id: null });

    }

    getSupplier(id) {
        return this.state.suppliers.find(sup => sup.id == id);
    }

    createOperation() {
        const {
            supplier_id,

            cambio_mobile_resid,  
            cambio_linea_fissa_resid,      
            cambio_mobile_linea_fissa_resid, 
            nuova_attivazione_resid,        

            cambio_mobile_business,             
            cambio_linea_fissa_business,        
            cambio_mobile_linea_fissa_business, 
            nuova_attivazione_business, 
            
            nds_resid,
            nds_business,        
        } = this.state;

        const data = {
            supplier_id,

            cambio_mobile_resid,  
            cambio_linea_fissa_resid,      
            cambio_mobile_linea_fissa_resid, 
            nuova_attivazione_resid,        

            cambio_mobile_business,             
            cambio_linea_fissa_business,        
            cambio_mobile_linea_fissa_business, 
            nuova_attivazione_business,

            nds_resid,
            nds_business,
        }

        store.dispatch({ type: OPEN_LOADING, text: 'Creating new operation...' });

        createTelcoOperation(data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Operation been created', open: true})
            store.dispatch({ type: CLOSE_LOADING });

            this.setState({ redirect: true });
        }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't create operation` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_telco' });
    }

    openPopupScript(selectedScript, text) {
        this.setState({ statusPopupScript: true, selectedScript, selectScriptText: text });
    }

    closePopupScript() {
        this.setState({ statusPopupScript: false, selectScript: null });
    }

    changeTextScript(name, text) {
        const state = this.state;

        state[name] = text;

        this.setState(state);
    }

    render() {
        let supplier = null;
        const {open: loading} = store.getState().loading;

        const { resid, business } = this.state.switch;
        const {
            cambio_mobile_resid,  
            cambio_linea_fissa_resid,      
            cambio_mobile_linea_fissa_resid, 
            nuova_attivazione_resid,        

            cambio_mobile_business,             
            cambio_linea_fissa_business,        
            cambio_mobile_linea_fissa_business, 
            nuova_attivazione_business,   

            statusPopupScript,
            selectedScript,
            selectScriptText,

            nds_business,
            nds_resid
        } = this.state;

        const {suppliers, redirect, supplier_id} = this.state;

        if(redirect)
            return <Redirect to='/' />

        if(supplier_id) 
            supplier = this.getSupplier(supplier_id);

        return (
            <div className={loading ? 'hide' : 'operation_section anim_content'}>
                <Tooltip title='Help'>
                    <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>
                {
                    statusPopupScript && <PopupScript
                        open={statusPopupScript} 
                        selectedScript={selectedScript} 
                        comfirm={this.changeTextScript}
                        handleClose={this.closePopupScript}
                        text={selectScriptText}
                        />
                }
                <div className='suppliers_block'></div>
                <div className='supplier_list'>
                        <div className='saparetor'>SUPPLIER</div>
                        <div className='content' >
                            <GridList cols={0} id='grid-scroll'>
                                {
                                    suppliers.map(supplier => {
                                        const { id, name, selected, img } = supplier;

                                        return (
                                            <div key={id} className='supplier'>
                                                <div className='actions'>
                                                    <div className='background'></div>
                                                    { !selected 
                                                        ? <div onClick={() => this.selectSupplier(id)} className='action -select'>Select</div>
                                                        : <div onClick={() => this.selectSupplier(id)} className='action -cancel'>Cancel</div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                    {img 
                                                    ? <div className='block_img'>
                                                        <img className='image'src={baseUrlUploads+img}/>
                                                     </div> 
                                                    : <div className='text_block'>
                                                        {name}
                                                     </div>
                                                    }
                                                    { selected ? <div className='selected'></div> : '' }
                                                </div>
                                            </div>
                                        )
                                    })
                                
                                }
                            </GridList>
                        </div>
                    </div>
                { resid 
                    ? 
                    <div className={supplier ? 'switch' : 'hide'}>
                        <div className='button -left selected'>Domestico</div>
                        <div onClick={this.switchTab} className='button -right no-selected'>Business</div>
                    </div>
                    :
                    <div className={supplier ? 'switch' : 'hide'}>
                        <div onClick={this.switchTab} className='button -left no-selected'>Resid</div>
                        <div className='button -right selected'>Business</div>
                    </div>
                }

                <div className={!supplier ? 'select_supplier' : 'hide'}>Please, select supplier</div>


                
                <div className={supplier ? 'content' : 'hide'}>
                    { resid ? // RESIDENT
                        <div className={'operations -resid'}>
                            <div className='fields anim_content'>
                                <OperationScript
                                    className={supplier && supplier.cambio_mobile ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio mobile'}
                                    text={cambio_mobile_resid}
                                    edit={() => this.openPopupScript('cambio_mobile_resid', cambio_mobile_resid)}
                                    />
                                <OperationScript
                                    className={supplier && supplier.cambio_linea_fissa ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio linea fissa'}
                                    text={cambio_linea_fissa_resid}
                                    edit={() => this.openPopupScript('cambio_linea_fissa_resid', cambio_linea_fissa_resid)}
                                    />
                                <OperationScript
                                    className={supplier && supplier.cambio_mobile_linea_fissa ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio mobile linea fissa'}
                                    text={cambio_mobile_linea_fissa_resid}
                                    edit={() => this.openPopupScript('cambio_mobile_linea_fissa_resid', cambio_mobile_linea_fissa_resid)}
                                    />
                                <OperationScript
                                    className={supplier && supplier.nuova_attivazione ? 'show_operation' : 'disabled_operation'}
                                    title={'Nuova attivazione resid'}
                                    text={nuova_attivazione_resid}
                                    edit={() => this.openPopupScript('nuova_attivazione_resid', nuova_attivazione_resid)}
                                    />

                                <OperationScript
                                    className='show_operation'
                                    title={'NDS'}
                                    text={nds_resid}
                                    edit={() => this.openPopupScript('nds_resid', nds_resid)}
                                />
                            </div>

                        </div>
                        : // BUSINESS
                        <div className='operations -business'>
                            <div className='fields anim_content2'>
                            <OperationScript
                                    className={supplier && supplier.cambio_mobile ? 'show_operation' : 'disabled_operation'}
                                    title='Cambio Mobile'
                                    text={cambio_mobile_business}
                                    edit={() => this.openPopupScript('cambio_mobile_business', cambio_mobile_business)}
                                    />
                                <OperationScript
                                    className={supplier && supplier.cambio_linea_fissa ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio Linea Fissa'}
                                    text={cambio_linea_fissa_business}
                                    edit={() => this.openPopupScript('cambio_linea_fissa_business', cambio_linea_fissa_business)}
                                    />
                                <OperationScript
                                    className={supplier && supplier.cambio_mobile_linea_fissa ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio Mobile Linea Fissa'}
                                    text={cambio_mobile_linea_fissa_business}
                                    edit={() => this.openPopupScript('cambio_mobile_linea_fissa_business', cambio_mobile_linea_fissa_business)}
                                    />
                                <OperationScript
                                    className={supplier && supplier.nuova_attivazione? 'show_operation' : 'disabled_operation'}
                                    title={'Nuova Attivazione'}
                                    text={nuova_attivazione_business}
                                    edit={() => this.openPopupScript('nuova_attivazione_business', nuova_attivazione_business)}
                                    />
                                <OperationScript
                                    className='show_operation'
                                    title={'NDS'}
                                    text={nds_business}
                                    edit={() => this.openPopupScript('nds_business', nds_business)}
                                />
                            </div>

                        </div>
                    }
                </div>
                    <div className={supplier ? 'submit' : 'hide'}>
                        <Button style={ supplier_id ? style.createButton : style.createButtonDissabled } disabled={ !supplier_id } onClick={this.createOperation}>Create</Button>
                    </div>
            </div>
        )
    }
}

export default NewOperation;

const style = {
    createButton: {
        width: '200px',
        height: '40px',
        background: '#417BC3',
        color: 'white'
    },

    createButtonDissabled: {
        width: '200px',
        height: '40px',
        background: '#8090a396',
        color: 'white'
    }
}

// const CheckUsed = (fields, filter) => fields.some(field => field == filter);