import {
    CHANGE_MENU_TYPE
} from '../actions/actions';

export default (state = { menuType: 'energia' }, action) => {
    switch(action.type) {
        case CHANGE_MENU_TYPE:
            return {
                ...state,
                menuType: action.menuType
            };

        default:
            return state;
    }
}