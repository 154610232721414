import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import renderHTML from 'react-render-html'

import {
    OPEN_NOTIFICATION,
} from '../../../actions/actions';

import './index.scss'

import { baseUrlUploads } from './../../../service';

import store from '../../../store';

class PopupInfo extends Component {
    constructor(props) {
        super(props);

        this.closePopup = this.closePopup.bind(this);
    }

    closePopup() {
        this.props.handleClose();
    }

    render() {
        const { offer } = this.props;

        console.log(offer, 'offer');

        const {
            id,
            client_type,
            offer_name,
            offer_code,
            exp_date,
            duration,
            price,
            script,
            TelcoSupplier: supplier,
        } = offer;

        let operationQueryLuce = '';
        let operationQueryGas = '';

        let strPayments = '';

        if(offer) {
            if(offer.rid_cc)
                strPayments += 'Rid CC';

            if (offer.rid_cp)
                strPayments += 'Rid CP';


            if (offer.bollettino)
                strPayments += (strPayments) ? ', Bollettino': 'Bollettino';

            if(offer.ricaricabile)
                strPayments += (strPayments) ? ', ricaricabile': 'Ricaricabile';

            if(!strPayments)
                strPayments = 'Not assigned';
        }

        const date = new Date(exp_date);
        const day = (date.getDate() < 10) ? '0' + date.getDate(): date.getDate();
        const month = (date.getMonth()+1 < 10) ? '0' + (date.getMonth() + 1): date.getMonth()+1;

        const formatedDate = `${day}/${month}/${date.getFullYear()}`;

        const offerClass = ' -today'
        
        return(
            <Dialog
                open={this.props.open}
                onClose={this.closePopup}
                maxWidth='lg'
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Offer info</DialogTitle>
                <DialogContent style={style.content} className='vissible-item number_popup_section'>
                    <div className='popup_offer_info'>
                        <div className='list_offer'>
                            <div className='head'>
                                <div className='field -name'>Offer Name</div>
                                <div className='field -valid'>Exp. Date</div>
                                <div className='field -payment'>Payment Type</div>
                                <div className='field -supplier'>Supplier Name</div>
                            </div>
                            <div className={'offer' + offerClass} key={id}>
                                <div className='head' onClick={() => this.showOffer(offer)}>
                                    <div className='field -name'>{offer_name}</div>
                                    <div className='field -valid'>{formatedDate}</div>
                                    <div className='field -payment'>{strPayments}</div>
                                    <div className='field -supplier'>
                                    <div className='offer_supplier'>
                                        <div className='sup_name'>{supplier ? supplier.name : ''}</div>
                                        { (supplier && supplier.img) && <div className='logo_supplier_section'>
                                          <div className='logo_supplier'>
                                              <img src={baseUrlUploads + supplier.img}/>
                                          </div>
                                          <div className='tooltip_image'>
                                            <img src={baseUrlUploads + supplier.img}/>
                                          </div>
                                        </div> }
                                      </div>
                                    </div>
                                </div>
                                { <div className='body'>
                                    <div className='section -main'>
                                        <div className='field -fornitore'><span className='text'>Fornitore: </span> {supplier ? supplier.name : ''}</div>
                                        <div className='field -codince'><span className='text'>Codice: </span>{offer_code}</div>
                                        <div className='field -tipo_cliente'><span className='text'>Tipo cliente: </span>{client_type}</div>
                                    </div>
                                    <div className={'section -price_luce'}>
                                        <div className='field'><span className='text'>Price: </span>{price?.replace('.', ',')} €</div>
                                    </div>
                                    <div className='section -terms'>
                                        <div className='field -validita'><span className='text'>Validita: </span>{formatedDate}</div>
                                        <div className='field -durata'><span className='text'>Durata: </span>{duration} mese</div>
                                    </div>
                                    <div className='section -script'>
                                        <div className='field -script_luce'>
                                            <h3 className='header'>Fornitore e Servizi Aggiuntivi</h3>
                                            <div className='text_script'>{(supplier && supplier.description) ? supplier.description : <div className='text_center'>Empty</div>} </div>
                                        </div>
                                    </div>
                                </div> }
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                    Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default PopupInfo;

const style = {
    content: {
        width: '960px',
        // height: '270px',
        minHeight: '270px'
    }
}