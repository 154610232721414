import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import ReactQuill from 'react-quill';

import {
    OPEN_NOTIFICATION,
} from '../../../actions/actions';

import { createBta } from './../../../service';
// import { createBta, updateScript } from './../../../service';
import store from '../../../store';

// import '../comperatore.scss';

class PopupComperatore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // id: null,
            code: '',
            ene_kwh: '',
            ene_kw: '',
            ene_pcv: '',
            ene_disp: '',
            rete_reti: '',
            rete_sist: '',
            rete_kw: '',
            rete_reti_pod: '',
            rete_oneri: '',
            imposte_kwh: '',
            imposte_kw: '',
            // text: this.props.text
        }

        this.changeInput = this.changeInput.bind(this);
        this.createBta = this.createBta.bind(this);
        this.updateBta = this.updateBta.bind(this);

        
        this.selectChange = this.selectChange.bind(this);

    }

    selectChange(name, value) {
        const state = this.state;
        state[name] = value;
    }


    createBta() {
        // const { data } = this.state;

        const data = this.state;
        // delete data.id;
        console.log("DATA",  data)


        // const newScript = { text };

        // this.props.addBta(newScript);
        // this.props.handleClose();

        // store.dispatch({type: OPEN_LOADING, text: 'Creating message', open: true});


        createBta(data).then(result => {

            // this.props.addBta();

            console.log("RESULT DATA ", result.data)

            // store.dispatch({type: CLOSE_LOADING, text: 'Creating message', open: false});
        })
    }

    updateBta() {
        const id = this.props.id,
            text = this.state.text;

        // this.props.updateBta(id, text)

        // store.dispatch({type: OPEN_LOADING, text: 'Updating message', open: true});
        // updateBta(id, { text }).then(result => {
        //     // store.dispatch({type: CLOSE_LOADING, text: 'Creating message', open: false});
        // }).catch(err => {
        //     // store.dispatch({type: OPEN_LOADING, text: `Can't update the message`, open: true});

        //     setTimeout(() => {
        //         // store.dispatch({type: CLOSE_LOADING, text: 'Creating message', open: false});
        //     }, 2000)
        // })

        // this.props.handleClose();
    }

    changeInput(text) {
        this.setState({ text });
    }

    render() {
        const {
            id,
            // text ,
            code,
            ene_kwh,
            ene_kw,
            ene_pcv,
            ene_disp,
            rete_reti,
            rete_sist,
            rete_kw,
            rete_reti_pod,
            rete_oneri,
            imposte_kwh,
            imposte_kw,

        } = this.props;

        return (
            !id ?
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                    // classes={{
                    //     paper: 'visible-item',
                    // }}
                >
                    <DialogTitle id="form-dialog-title">Add new</DialogTitle>
                    {/* <DialogContent className='vissible-item'> */}
                    <DialogContent style={style.content} className='vissible-item'>


                        <TextField
                            style={style.input}
                            value={code}
                            label="Code"
                            onChange={event => this.selectChange('code', event.target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                        <div style={style.heading}>Energia e commercializzazione</div>
                        <div>
                            <TextField
                                style={style.input}
                                type="number"
                                value={ene_kwh}
                                label="(€/kWh)"
                                onChange={event => this.selectChange('ene_kwh', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                style={style.input}
                                type="number"
                                value={ene_kw}
                                label="(€/kW)"
                                onChange={event => this.selectChange('ene_kw', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                                style={style.input}
                                type="number"
                                value={ene_pcv}
                                label="PCV (€/POD)"
                                onChange={event => this.selectChange('ene_pcv', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                style={style.input}
                                type="number"
                                value={ene_disp}
                                label="DISPbt (€/POD)"
                                onChange={event => this.selectChange('ene_disp', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div style={style.heading}>Rete e oneri di sistema</div>
                        <div>
                            <TextField
                                style={style.input}
                                type="number"
                                value={rete_reti}
                                label="var reti (€/kWh)"
                                onChange={event => this.selectChange('rete_reti', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                value={rete_sist}
                                label="var sist (€/kWh)"
                                onChange={event => this.selectChange('rete_sist', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                        <div>
                            <TextField
                                style={style.input}
                                type="number"
                                value={rete_kw}
                                label="(€/kW)"
                                onChange={event => this.selectChange('rete_kw', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                style={style.input}
                                type="number"
                                value={rete_reti_pod}
                                label="reti (€/POD)"
                                onChange={event => this.selectChange('rete_reti_pod', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>
                            <TextField
                                style={style.input}
                                type="number"
                                value={rete_oneri}
                                label="oneri (€/POD)"
                                onChange={event => this.selectChange('rete_oneri', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />

                        <div style={style.heading}>Imposte</div>
                        <div>
                            <TextField
                                style={style.input}
                                type="number"
                                value={imposte_kwh}
                                label="(€/kWh)"
                                onChange={event => this.selectChange('imposte_kwh', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                style={style.input}
                                type="number"
                                value={imposte_kw}
                                label="(€/kW)"
                                onChange={event => this.selectChange('imposte_kw', event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.createBta} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                :
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                    classes={{
                        paper: 'visible-item',
                    }}
                >
                    <DialogTitle id="form-dialog-title">Edit script</DialogTitle>
                    <DialogContent style={style.content} className='vissible-item'>
                        HELLO EDIT
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.updateBta} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
        )
    }
}

export default PopupComperatore;

const style = {
    content: {
        minheight: '270px',
        overflow: 'visible'
    },

    heading: {
        marginTop: '20px',
    },

    input: {
        width: '250px',
        marginRight: '25px',
    }
}