import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

class PausaDialog extends Component {
  constructor(props) {
      super();

      this.remove = props.remove;
      this.close = props.close;
  }

  render() {
    const open = this.props.open;

    return (
        <Dialog
          open={open}
          onClose={this.close}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle style={{ backgroundColor: '#F2F3F4' }} id="responsive-dialog-title">
            Pausa

            <IconButton style={{ float: 'right'}} onClick={this.close}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ paddingBottom: '50px',  backgroundColor: '#F2F3F4'}}>
            <DialogContentText style={{ textAlign: 'center', padding: '20px 0px'}}>
            Il limite massimo di operatori in pausa è stato raggiunto.
Desideri comunque fare una pausa?
            </DialogContentText>
            <div style={{ display: 'flex', justifyContent: "center"}}>
                <div style={{ padding: '12px 0', width: '200px'}} onClick={() => { this.remove(); this.close()}} className='pause_button'>
                    INIZIO PAUSA
                </div>
            </div>
          </DialogContent>
        </Dialog>
    );
  }
}

export default PausaDialog;