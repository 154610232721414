import React, { Component } from 'react';
import { Button, Drawer } from '@material-ui/core';
import HelperSearch from '../helperSearch/helperSearch';
import HelperSearchTelco from './../../pages/telcoProposal/helperSearch/helperSearch';

class HowTo extends Component {
    render() {
        const {
            open,
            component,
            close
        } = this.props;

        let RenderComponent = () => (<div>{component}</div>);

        if(component === 'proposal_energia') {
            RenderComponent = HelperSearch;
        }

        if(component === 'proposal_telco') {
            RenderComponent = HelperSearchTelco;
        }
        
        return (
            <Drawer open={open} anchor="bottom" className='how_to' onClose={close}>
                <div className='how_to'>
                    <RenderComponent/>
                </div>
            </Drawer>
        )
    }
}

export default HowTo;