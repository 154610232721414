import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import TextField from '@mui/material/TextField';

import store from '../../../store';
import { OPEN_NOTIFICATION } from '../../../actions/actions';

import { createBta, updateBta } from '../../../service';

class ComperatoreEnergiaBusinessPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.selectedItem ? this.props.selectedItem.id : "",
            code:  this.props.selectedItem ? this.props.selectedItem.code : "",
            ene_kwh: this.props.selectedItem ? this.props.selectedItem.ene_kwh : "",
            ene_kw: this.props.selectedItem ? this.props.selectedItem.ene_kw : "",
            ene_pcv: this.props.selectedItem ? this.props.selectedItem.ene_pcv : "",
            ene_disp: this.props.selectedItem ? this.props.selectedItem.ene_disp : "",
            rete_reti: this.props.selectedItem ? this.props.selectedItem.rete_reti : "",
            rete_sist: this.props.selectedItem ? this.props.selectedItem.rete_sist : "",
            rete_kw: this.props.selectedItem ? this.props.selectedItem.rete_kw : "",
            rete_reti_pod: this.props.selectedItem ? this.props.selectedItem.rete_reti_pod : "",
            rete_oneri: this.props.selectedItem ? this.props.selectedItem.rete_oneri : "",
            imposte_kwh: this.props.selectedItem ? this.props.selectedItem.imposte_kwh : "",
            imposte_kw: this.props.selectedItem ? this.props.selectedItem.imposte_kw : "",
        }

        this.changeInput = this.changeInput.bind(this);
        this.createBta = this.createBta.bind(this);
        this.updateBta = this.updateBta.bind(this);

        
        this.changeValue = this.changeValue.bind(this);

    }

    changeValue(name, value) {
        const state = this.state;
        state[name] = value;
        this.setState(state);
    }


    createBta() {
        const data = this.state;
        console.log("DATA",  data)

        createBta(data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'BTA created', open: true});
            this.props.handleClose();
            this.props.addBta(result.data.bta)
        })
    }

    updateBta() {
        let id = this.props.selectedItem.id;
        const data = this.state;
        
        updateBta(id, data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'BTA updated', open: true});
            this.props.handleClose();
            let dataWithId = {...data, id};
            this.props.updateBta(dataWithId)
        })

    }

    changeInput(text) {
        this.setState({ text });
    }

    render() {
        const {
            id,
            code,
            ene_kwh,
            ene_kw,
            ene_pcv,
            ene_disp,
            rete_reti,
            rete_sist,
            rete_kw,
            rete_reti_pod,
            rete_oneri,
            imposte_kwh,
            imposte_kw,

        } = this.state;

        return (
            !id ?
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Add new</DialogTitle>
                    <DialogContent style={style.content} className='vissible-item'>
                        <TextField
                            style={style.input}
                            value={code}
                            label="Code"
                            onChange={event => this.changeValue('code', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size='small'
                        />
                        <div style={style.heading}>Energia e commercializzazione</div>
                        <div className="inputsWrapper">
                            <TextField
                                style={style.input}
                                type="number"
                                value={ene_kwh}
                                label="(€/kWh)"
                                onChange={event => this.changeValue('ene_kwh', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='small'
                            />
                            <TextField
                                style={style.input}
                                type="number"
                                value={ene_kw}
                                label="(€/kW)"
                                onChange={event => this.changeValue('ene_kw', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='small'
                            />
                        </div>
                        <div className="inputsWrapper">
                            <TextField
                                style={style.input}
                                type="number"
                                value={ene_pcv}
                                label="PCV (€/POD)"
                                onChange={event => this.changeValue('ene_pcv', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='small'
                            />
                            <TextField
                                style={style.input}
                                type="number"
                                value={ene_disp}
                                label="DISPbt (€/POD)"
                                onChange={event => this.changeValue('ene_disp', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='small'
                            />
                        </div>
                        <div style={style.heading}>Rete e oneri di sistema</div>

                        <div className="inputsWrapper">
                            <TextField
                                style={style.input}
                                type="number"
                                value={rete_reti}
                                label="var reti (€/kWh)"
                                onChange={event => this.changeValue('rete_reti', event.target.value)}
                                margin="normal"
                                size='small'
                                variant="outlined"
                            />
                            <TextField
                                style={style.input}
                                value={rete_sist}
                                label="var sist (€/kWh)"
                                onChange={event => this.changeValue('rete_sist', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='small'
                            />
                        </div>

                        <div className="inputsWrapper">
                            <TextField
                                style={style.input}
                                type="number"
                                value={rete_kw}
                                label="(€/kW)"
                                onChange={event => this.changeValue('rete_kw', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='small'
                            />
                            <TextField
                                style={style.input}
                                type="number"
                                value={rete_reti_pod}
                                label="reti (€/POD)"
                                onChange={event => this.changeValue('rete_reti_pod', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='small'
                            />
                        </div>
                            <TextField
                                style={style.input}
                                type="number"
                                value={rete_oneri}
                                label="oneri (€/POD)"
                                onChange={event => this.changeValue('rete_oneri', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='small'
                                />

                        <div style={style.heading}>Imposte</div>
                        <div className="inputsWrapper">
                            <TextField
                                style={style.input}
                                type="number"
                                value={imposte_kwh}
                                label="(€/kWh)"
                                onChange={event => this.changeValue('imposte_kwh', event.target.value)}
                                margin="normal"
                                size='small'
                                variant="outlined"
                            />
                            <TextField
                                style={style.input}
                                type="number"
                                value={imposte_kw}
                                label="(€/kW)"
                                onChange={event => this.changeValue('imposte_kw', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size='small'
                            />
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.createBta} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                :
                <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Update Bta</DialogTitle>
                <DialogContent style={style.content} className='vissible-item'>
                    <TextField
                        style={style.input}
                        value={code}
                        label="Code"
                        onChange={event => this.changeValue('code', event.target.value)}
                        margin="normal"
                        variant="outlined"
                        size='small'
                    />
                    <div style={style.heading}>Energia e commercializzazione</div>
                    <div className="inputsWrapper">
                        <TextField
                            style={style.input}
                            size='small'
                            type="number"
                            value={ene_kwh}
                            label="(€/kWh)"
                            onChange={event => this.changeValue('ene_kwh', event.target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            style={style.input}
                            type="number"
                            value={ene_kw}
                            label="(€/kW)"
                            onChange={event => this.changeValue('ene_kw', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                    </div>
                    <div className="inputsWrapper">
                        <TextField
                            style={style.input}
                            type="number"
                            value={ene_pcv}
                            label="PCV (€/POD)"
                            onChange={event => this.changeValue('ene_pcv', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                        <TextField
                            style={style.input}
                            type="number"
                            value={ene_disp}
                            label="DISPbt (€/POD)"
                            onChange={event => this.changeValue('ene_disp', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                    </div>
                    <div style={style.heading}>Rete e oneri di sistema</div>

                    <div className="inputsWrapper">
                        <TextField
                            style={style.input}
                            type="number"
                            value={rete_reti}
                            label="var reti (€/kWh)"
                            onChange={event => this.changeValue('rete_reti', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                        <TextField
                            style={style.input}
                            value={rete_sist}
                            label="var sist (€/kWh)"
                            onChange={event => this.changeValue('rete_sist', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                    </div>

                    <div className="inputsWrapper">
                        <TextField
                            style={style.input}
                            type="number"
                            value={rete_kw}
                            label="(€/kW)"
                            onChange={event => this.changeValue('rete_kw', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                        <TextField
                            style={style.input}
                            type="number"
                            value={rete_reti_pod}
                            label="reti (€/POD)"
                            onChange={event => this.changeValue('rete_reti_pod', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                    </div>
                        <TextField
                            style={style.input}
                            type="number"
                            value={rete_oneri}
                            label="oneri (€/POD)"
                            onChange={event => this.changeValue('rete_oneri', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />

                    <div style={style.heading}>Imposte</div>
                    <div className="inputsWrapper">
                        <TextField
                            style={style.input}
                            type="number"
                            value={imposte_kwh}
                            label="(€/kWh)"
                            onChange={event => this.changeValue('imposte_kwh', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                        <TextField
                            style={style.input}
                            type="number"
                            value={imposte_kw}
                            label="(€/kW)"
                            onChange={event => this.changeValue('imposte_kw', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.updateBta} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ComperatoreEnergiaBusinessPopup;

const style = {
    content: {
        width: '500px',
        minheight: '270px',
        overflow: 'visible'
    },

    heading: {
        marginTop: '20px',
    },

    input: {
        flex: '1',
        // marginRight: '25px',
    },
    
    inputFullwidth: {
        width: '100%',
    }
}