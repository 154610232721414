import { combineReducers } from 'redux';
import popup from './popup';
import auth from './auth';
import notification from './notification';
import loading from './loading';
import help from './help';
import menu from './menu';


export default combineReducers({
    popup,
    auth,
    notification,
    loading,
    help,
    menu
});