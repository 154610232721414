import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Link, Route, HashRouter, Switch, Redirect } from 'react-router-dom';

import List from './list/list';
import NewSupplier from './new/NewSupplier';

import store from '../../store';

class Supplier extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { role } = store.getState().auth;

        return (
            <HashRouter basename='energia/suppliers'>
                <Switch>
                    <Route path='/new' render={ props => {
                        return (role == 'admin') ?
                            <NewSupplier {...props} />
                            : 
                            <Redirect to='/' />
                    }}/>
                    <Route path='/:id' render={ props => {
                        return (role == 'admin') ?
                            <NewSupplier {...props}/>
                            : 
                            <NewSupplier {...props}/>
                    }}/>
                    <Route path='/' component={ List }/>
                </Switch>
            </HashRouter>
        )
    }
}

export default Supplier;