import React, { Component } from 'react';
import {
    // Button,
    TextField,
    Tooltip,
    Icon,
    Radio,
    MenuItem
} from '@material-ui/core';

import Button from '@mui/material/Button'

import store from '../../store';

import { Link, Redirect } from 'react-router-dom';
import { parse } from 'query-string';

import PopupComperatore from './dialog';

import {
    OPEN_HELP
} from './../../actions/actions';


import { getComperatoreData } from '../../service';

import './comperatore.scss';

import ComperatoreEnergiaBusiness from './comperatoreEnergiaBusiness';
import ComperatoreEnergiaDomestico from './comperatoreEnergiaDomestico';
import ComperatoreGas from './comperatoreGas';


const selectionButtons = [
    { label: 'Luce domestico', id: 'energia_d' },
    { label: 'Luce Business', id: 'energia_b' },
    { label: 'GAS DOMESTICO & BUSINESS', id: 'gas_db' },
];


class ComperatoreAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openPopup: false,
            selectedType: "energia_d",
            selectedScript: {},
            isImposte: true,

            isLoading: true,
            
            bta: {},
            imposte: {},
            sintetica: {},
            comperatoreEne: {},
            comperatoreGas: {}


        }

        this.onChangeSelectionType = this.onChangeSelectionType.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);

        this.updateEnergiaDomestico = this.updateEnergiaDomestico.bind(this)
        this.updateGas = this.updateGas.bind(this);
        this.updateBta = this.updateBta.bind(this);

        this.updateImposte = this.updateImposte.bind(this);
        this.updateSintetica = this.updateSintetica.bind(this);

    }

    componentWillMount() {
        getComperatoreData().then(result => {
          const { bta, imposte, sintetica, comperatoreEne, comperatoreGas } = result.data.payload;

          console.log("DATA", result.data.payload)

          this.setState({ comperatoreEne, comperatoreGas });
          this.setState({ 'imposte': imposte.data});
          this.setState({ 'sintetica': sintetica.data});
          this.setState({ 'bta': bta.data});

          this.setState({ isLoading: false });
        }).catch(err => {
            console.log("error", err)
        })
    }

    updateEnergiaDomestico(data) {
        this.setState( { comperatoreEne : data });
    }
    updateGas(data) {
        this.setState( { comperatoreGas : data });
    }

    updateImposte(data) {
        this.setState( { imposte : data });
    }

    updateSintetica(data) {
        this.setState( { sintetica : data });
    }

    updateBta(data) {
        this.setState( { bta : data });
    }

    openPopup() {
        this.setState({ openPopup: true });
    }

    closePopup() {
        this.setState({ openPopup: false });
    }



    onChangeSelectionType(id) {
        console.log('selectionButtons id ', id);
        this.setState({ selectedType: id })
    }

    onAddNew() {
        console.log("on Add New ")
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
      }

    render() {
        const {
            selectedType,
            openPopup,
            selectedScript,
            isImposte,
            showContent,

            isLoading,


            comperatoreEne,
            comperatoreGas,
            sintetica,
            imposte,
            bta

        } = this.state;

        return (
            <div className='comperatore'>
                <div className="comperatore__selectionsButtons">
                    {selectionButtons.map(option => (
                        <Button key={option} className={selectedType === option.id ? "comperatore__selectionBtn--active" : "comperatore__selectionBtn"} onClick={() => this.onChangeSelectionType(option.id)} value={option}>
                            {option.label}
                        </Button>
                    ))}
                    <Tooltip title='Help'>
                        <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                    </Tooltip>
                </div>


                {selectedType === "energia_b" && <ComperatoreEnergiaBusiness bta={bta} update={this.updateEnergiaDomestico} comperatoreEne={comperatoreEne} updateBta={this.updateBta}/>}
                {selectedType === "energia_d" && !isLoading && <ComperatoreEnergiaDomestico comperatoreEne={comperatoreEne} update={this.updateEnergiaDomestico}/>}
                {selectedType === "gas_db" && !isLoading && <ComperatoreGas comperatoreGas={comperatoreGas} imposte={imposte} sintetica={sintetica} update={this.updateGas} updateImposte={this.updateImposte} updateSintetica={this.updateSintetica}/>}

               
            </div>
        )
    }
}


export default ComperatoreAdmin;

const style = {
    content: {
        minheight: '270px',
        overflow: 'visible'
    },

    heading: {
        marginTop: '20px',
    },

    input: {
        width: '250px',
        marginRight: '25px',
    }
}