import React, { Component } from 'react';
import { Button, Tooltip, GridList, TextField, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { getAllSuppliers, getAllOperations, baseUrlUploads } from './../../../../service';

import './operationUser.scss';
import store from '../../../../store';
import {  
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
} from '../../../../actions/actions';

import OperationScript from '../../operationScript/';

class OperationUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            supplier_id: null,
            operations: [],
            operation: null,

            switch: {
                resid: true,
                business: false
            },

            allacciamento_power_resid: '',
            subentro_power_resid: '',
            '1a_attivazione_power_resid': '',
            cambio_fornitore_power_resid: '',
            cambio_con_voltura_power_resid: '',
            allacciamento_gas_resid: '',
            subentro_gas_resid: '',
            '1a_attivazione_gas_resid': '',
            cambio_fornitore_gas_resid: '',
            cambio_con_voltura_gas_resid: '',
            allacciamento_power_business: '',
            subentro_power_business: '',
            '1a_attivazione_power_business': '',
            cambio_fornitore_power_business: '',
            cambio_con_voltura_power_business: '',
            allacciamento_gas_business: '',
            subentro_gas_business: '',
            '1a_attivazione_gas_business': '',
            cambio_fornitore_gas_business: '',
            cambio_con_voltura_gas_business: '',

            taglio_colonna_power_resid: '',
            contatore_rimosso_power_resid: '',
            chiuso_per_morosita_power_resid: '',

            taglio_colonna_power_business: '',
            contatore_rimosso_power_business: '',
            chiuso_per_morosita_power_business: '',

            taglio_colonna_gas_resid: '',
            contatore_rimosso_gas_resid: '',
            chiuso_per_morosita_gas_resid: '',

            taglio_colonna_gas_business: '',
            contatore_rimosso_gas_business: '',
            chiuso_per_morosita_gas_business: '',

            nds_gas_business: '',
            nds_gas_resid: '',

            loading: true
        }

        this.switchTab = this.switchTab.bind(this);
    }

    componentWillMount() {
        const suppliersPromise = getAllSuppliers();
        const operationsPromise = getAllOperations();
        store.dispatch({ type: OPEN_LOADING, text: 'Loading operations...' });

        Promise.all([suppliersPromise, operationsPromise]).then(result => {
            const suppliers = result[0].data.suppliers;
            const operations = result[1].data.operations;

            this.setState({ suppliers, operations, loading: false });
            store.dispatch({ type: CLOSE_LOADING });
        }).catch( err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't load suppliers` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);

            this.setState({ loading: false, err: true });
        });
    }

    selectSupplier(id) {
        let { suppliers, operations } = this.state;

        let theSame = false;

        suppliers.map(supplier => {
            if(supplier.id == id) {
                supplier.selected = !supplier.selected;
                theSame = supplier.selected;
            } else if(supplier.selected) {
                supplier.selected = false;
            }

            return supplier;
        });

        let operation = operations.find( operation => operation.supplier_id == id);

        for(let key in operation) {
            if(operation[key] == null)
                operation[key] = '';
        }


        if(theSame)
            this.setState({ supplier_id: id, ...operation, operation });
        else
            this.setState({ supplier_id: null, operation: null });
    }

    switchTab() {
        let { switch: { resid, business } } = this.state;

        resid= !resid;
        business = !business;

        this.setState({ switch: { resid, business } });
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    render() {
        const { suppliers, operation, supplier_id } = this.state;

        const { resid, business } = this.state.switch;

        const {
            allacciamento_power_resid,
            subentro_power_resid,
            '1a_attivazione_power_resid': a_attivazione_power_resid,
            cambio_fornitore_power_resid,
            cambio_con_voltura_power_resid,
            allacciamento_gas_resid,
            subentro_gas_resid,
            '1a_attivazione_gas_resid': a_attivazione_gas_resid,
            cambio_fornitore_gas_resid,
            cambio_con_voltura_gas_resid,
            allacciamento_power_business,
            subentro_power_business = '',
            '1a_attivazione_power_business': a_attivazione_power_business,
            cambio_fornitore_power_business,
            cambio_con_voltura_power_business,
            allacciamento_gas_business,
            subentro_gas_business,
            '1a_attivazione_gas_business': a_attivazione_gas_business,
            cambio_fornitore_gas_business,
            cambio_con_voltura_gas_business,

            taglio_colonna_power_resid,
            contatore_rimosso_power_resid,
            chiuso_per_morosita_power_resid,

            taglio_colonna_power_business,
            contatore_rimosso_power_business,
            chiuso_per_morosita_power_business,

            taglio_colonna_gas_resid,
            contatore_rimosso_gas_resid,
            chiuso_per_morosita_gas_resid,

            taglio_colonna_gas_business,
            contatore_rimosso_gas_business,
            chiuso_per_morosita_gas_business,

            nds_gas_business,
            nds_gas_resid,

        } = this.state;
        const {open: loading} = store.getState().loading;

        return (
            <div className={loading ? 'hide' : 'operation_section'}>
                <Tooltip title='Help'>
                    <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>
                <div className='content anim_content'>
                    <div className='supplier_list'>
                        <div className='saparetor'>SUPPLIERS</div>
                        <div className='content' >
                            <GridList cols={0} id='grid-scroll'>
                                {
                                    suppliers.map(supplier => {
                                        const { id, name, selected, img } = supplier;

                                        return (
                                            <div key={id} className='supplier'>
                                                <div className='actions'>
                                                    <div className='background'></div>
                                                    { !selected 
                                                        ? <div onClick={() => this.selectSupplier(id)} className='action -select'>Select</div>
                                                        : <div onClick={() => this.selectSupplier(id)} className='action -cancel'>Cancel</div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                    {img 
                                                    ? <div className='block_img'>
                                                        <img className='image'src={baseUrlUploads+img}/>
                                                     </div> 
                                                    : <div className='text_block'>
                                                        {name}
                                                     </div>
                                                    }
                                                    { selected ? <div className='selected'></div> : '' }
                                                </div>
                                            </div>
                                        )
                                    })
                                
                                }
                            </GridList>
                        </div>
                    </div>

                    { operation 
                        ?
                        <div className='main_section'>
                            <div className="comperatore__switcher">
                                <div className={resid ? "comperatore__switcherTab--active" : "comperatore__switcherTab"} onClick={this.switchTab}>Business</div>
                                <div className={!resid ? "comperatore__switcherTab--active" : "comperatore__switcherTab"} onClick={this.switchTab}>Resid</div>
                            </div>

                            <div className='fields_section'>
                            { resid ? // RESIDENT
                                <div className={'operations -resid'}>
                                    <div className='saparetor'>LUCE</div>
                                    <div className='fields anim_content'>
                                        <div className='content'>
                                        <OperationScript
                                            title={'Allacciamento'}
                                            text={allacciamento_power_resid}
                                            />
                                        <OperationScript
                                            title={'1a Attivazione'}
                                            text={a_attivazione_power_resid}
                                            />
                                        <OperationScript
                                            title={'Subentro'}
                                            text={subentro_power_resid}
                                            />
                                        <OperationScript
                                            title={'Cambio fornitore'}
                                            text={cambio_fornitore_power_resid}
                                            />
                                        <OperationScript
                                            title={'Cambio con voltura'}
                                            text={cambio_con_voltura_power_resid}
                                            />
                                        <OperationScript
                                            title={'Taglio colonna'}
                                            text={taglio_colonna_power_resid}
                                            />
                                        <OperationScript
                                            title={'Contatore rimosso'}
                                            text={contatore_rimosso_power_resid}
                                            />
                                        <OperationScript
                                            title={'Allacciamento temporaneo'}
                                            text={chiuso_per_morosita_power_resid}
                                            />
                                            {/* <TextField disabled={true}
                                                value={allacciamento_power_resid}  
                                                label='Allacciamento' 
                                                multiline 
                                                rows='9'/>
                                            <TextField  disabled={true} 
                                                value={a_attivazione_power_resid}  
                                                label='1a Attivazione' 
                                                multiline 
                                                rows='9'/>
                                            <TextField  disabled={true} 
                                                value={subentro_power_resid}  
                                                label='Subentro' 
                                                multiline 
                                                rows='9'/>
                                            <TextField  disabled={true} 
                                                value={cambio_fornitore_power_resid}
                                                label='Cambio fornitore' 
                                                multiline
                                                rows='9'/>
                                            <TextField  disabled={true} 
                                                value={cambio_con_voltura_power_resid}  
                                                label='Cambio con voltura' 
                                                multiline 
                                                rows='9'/>

                                            <TextField 
                                                disabled={true} 
                                                value={taglio_colonna_power_resid}  
                                                label='Taglio colonna' 
                                                multiline 
                                                rows='9'/>
                                            <TextField 
                                                disabled={true} 
                                                value={contatore_rimosso_power_resid}
                                                label='Contatore rimosso' 
                                                multiline
                                                rows='9'/>
                                            <TextField 
                                                disabled={true} 
                                                value={chiuso_per_morosita_power_resid}  
                                                label='Allacciamento temporaneo' 
                                                multiline 
                                                rows='9'/> */}
                                            </div>
                                    </div>

                                    <div className='saparetor'>GAS</div>
                                    <div className='fields anim_content'>
                                    <div className='content'>
                                    <OperationScript
                                            title={'Allacciamento'}
                                            text={allacciamento_gas_resid}
                                            />
                                        <OperationScript
                                            title={'1a Attivazione'}
                                            text={a_attivazione_gas_resid}
                                            />
                                        <OperationScript
                                            title={'Subentro'}
                                            text={subentro_gas_resid}
                                            />
                                        <OperationScript
                                            title={'Cambio fornitore'}
                                            text={cambio_fornitore_gas_resid}
                                            />
                                        <OperationScript
                                            title={'Cambio con voltura'}
                                            text={cambio_con_voltura_gas_resid}
                                            />
                                        <OperationScript
                                            title={'Taglio colonna'}
                                            text={taglio_colonna_gas_resid}
                                            />
                                        <OperationScript
                                            title={'Contatore rimosso'}
                                            text={contatore_rimosso_gas_resid}
                                            />
                                        <OperationScript
                                            title={'Allacciamento temporaneo'}
                                            text={chiuso_per_morosita_gas_resid}
                                            />

                                        <OperationScript
                                            title={'NDS'}
                                            text={nds_gas_resid}
                                            />
                                            </div>
                                    </div>

                                    <div className='saparetor'>NDS</div>
                                    <div className='fields anim_content2'>
                                        <OperationScript
                                            title={'NDS'}
                                            text={nds_gas_business}
                                            />
                                    </div>
                                </div>
                                : // BUSINESS
                                <div className='operations -business'>
                                    <div className='saparetor'>LUCE</div>
                                    <div className='fields anim_content2'>
                                    <div className='content'>
                                    <OperationScript
                                            title={'Allacciamento'}
                                            text={allacciamento_power_business}
                                            />
                                        <OperationScript
                                            title={'1a Attivazione'}
                                            text={a_attivazione_power_business}
                                            />
                                        <OperationScript
                                            title={'Subentro'}
                                            text={subentro_power_business}
                                            />
                                        <OperationScript
                                            title={'Cambio fornitore'}
                                            text={cambio_fornitore_power_business}
                                            />
                                        <OperationScript
                                            title={'Cambio con voltura'}
                                            text={cambio_con_voltura_power_business}
                                            />
                                        <OperationScript
                                            title={'Taglio colonna'}
                                            text={taglio_colonna_power_business}
                                            />
                                        <OperationScript
                                            title={'Contatore rimosso'}
                                            text={contatore_rimosso_power_business}
                                            />
                                        <OperationScript
                                            title={'Allacciamento temporaneo'}
                                            text={chiuso_per_morosita_power_business}
                                            />
                                        {/* <TextField  disabled={true} 
                                            value={allacciamento_power_business}  
                                            label='Allacciamento' 
                                            multiline 
                                            rows='9'/>
                                        <TextField  disabled={true} 
                                            value={a_attivazione_power_business}  
                                            label='1a Attivazione' 
                                            multiline 
                                            rows='9'/>
                                        <TextField  disabled={true} 
                                            value={subentro_power_business}  
                                            label='Subentro' 
                                            multiline 
                                            rows='9'/>
                                        <TextField  disabled={true} 
                                            value={cambio_fornitore_power_business}
                                            label='Cambio fornitore' 
                                            multiline
                                            rows='9'/>
                                        <TextField  disabled={true} 
                                            value={cambio_con_voltura_power_business}  
                                            label='Cambio con voltura' 
                                            multiline 
                                            rows='9'/>

                                        <TextField 
                                            disabled={true} 
                                            value={taglio_colonna_power_business}  
                                            label='Taglio colonna' 
                                            multiline 
                                            rows='9'/>
                                        <TextField 
                                            disabled={true} 
                                            value={contatore_rimosso_power_business}
                                            label='Contatore rimosso' 
                                            multiline
                                            rows='9'/>
                                        <TextField 
                                            disabled={true} 
                                            value={chiuso_per_morosita_power_business}  
                                            label='Allacciamento temporaneo' 
                                            multiline 
                                            rows='9'/> */}
                                            </div>
                                    </div>

                                    <div className='saparetor'>GAS</div>
                                    <div className='fields anim_content2'>
                                        <div className='content'>
                                            <OperationScript
                                                title={'Allacciamento'}
                                                text={allacciamento_gas_business}
                                                />
                                            <OperationScript
                                                title={'1a Attivazione'}
                                                text={a_attivazione_gas_business}
                                                />
                                            <OperationScript
                                                title={'Subentro'}
                                                text={subentro_gas_business}
                                                />
                                            <OperationScript
                                                title={'Cambio fornitore'}
                                                text={cambio_fornitore_gas_business}
                                                />
                                            <OperationScript
                                                title={'Cambio con voltura'}
                                                text={cambio_con_voltura_gas_business}
                                                />
                                            <OperationScript
                                                title={'Taglio colonna'}
                                                text={taglio_colonna_gas_business}
                                                />
                                            <OperationScript
                                                title={'Contatore rimosso'}
                                                text={contatore_rimosso_gas_business}
                                                />
                                            <OperationScript
                                                title={'Allacciamento temporaneo'}
                                                text={chiuso_per_morosita_gas_business}
                                                />
                                            {/* <TextField  disabled={true} 
                                                value={allacciamento_gas_business}  
                                                label='Allacciamento' 
                                                multiline 
                                                rows='9'/>
                                            <TextField  disabled={true}  
                                                value={a_attivazione_gas_business}  
                                                label='1a Attivazione' 
                                                multiline 
                                                rows='9'/>
                                            <TextField  disabled={true} 
                                                value={subentro_gas_business}  
                                                label='Subentro' 
                                                multiline 
                                                rows='9'/>
                                            <TextField  disabled={true} 
                                                value={cambio_fornitore_gas_business}
                                                label='Cambio fornitore' 
                                                multiline
                                                rows='9'/>
                                            <TextField  disabled={true} 
                                                value={cambio_con_voltura_gas_business}  
                                                label='Cambio con voltura' 
                                                multiline 
                                                rows='9'/>

                                            <TextField 
                                                disabled={true} 
                                                value={taglio_colonna_gas_business}  
                                                label='Taglio colonna' 
                                                multiline 
                                                rows='9'/>
                                            <TextField 
                                                disabled={true} 
                                                value={contatore_rimosso_gas_business}
                                                label='Contatore rimosso' 
                                                multiline
                                                rows='9'/>
                                            <TextField 
                                                disabled={true} 
                                                value={chiuso_per_morosita_gas_business}  
                                                label='Allacciamento temporaneo' 
                                                multiline 
                                                rows='9'/> */}
                                            </div>
                                    </div>

                                    <div className='saparetor'>NDS</div>
                                    <div className='fields anim_content2'>
                                        <OperationScript
                                            title={'NDS'}
                                            text={nds_gas_business}
                                            />
                                    </div>
                                </div>
                            }
                            </div>
                        </div> 
                        : 
                        <div className='info_section'>
                            { supplier_id 
                                ? <div>The supplier does't have associated operation</div>
                                : <div>Please, select supplier</div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default OperationUser;