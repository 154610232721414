import React, { Component } from 'react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import { Icon } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import ComperatoreGasPopup from './dialog/comperatoreGasPopup';
import RemoveDialog from '../dialogs/removePopup';

import store from '../../store';
import { OPEN_NOTIFICATION } from '../../actions/actions';


import './comperatore.scss';
import { updateComperatoreGas, removeImposte, removeSintetica } from '../../service';


class ComperatoreGas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openPopup: false,
            // selectedScript: {},
            isImposte: true,

            // domestico
            iva_fino: '',
            iva_oltre: '',

            // business
            iva_agevolata: '',
            per_tuti: '',

            // comsumi
            comsumi_rete_oneri: '',
            comsumi_energia_transporto: '',
            comsumi_energia_transporto_smc: '',

            // imposte
            imposte: [],
            sintetica: [],

            editableItem: null,
            statusRemovePopup: false,
            typeToRemove: ""
        }

        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateComperatoreGas = this.updateComperatoreGas.bind(this);

        this.addImposte = this.addImposte.bind(this);
        this.updateImposte = this.updateImposte.bind(this);

        this.addSintetica = this.addSintetica.bind(this);
        this.updateSintetica = this.updateSintetica.bind(this);

        this.removeItem = this.removeItem.bind(this);
        this.closeRemovePopup = this.closeRemovePopup.bind(this);
    }


    componentWillMount() {

        console.log("PROPS", this.props.comperatoreGas)
        if (this.props.comperatoreGas != null) {
            const {
                iva_fino,
                iva_oltre,
                iva_agevolata,
                per_tuti,
                comsumi_rete_oneri,
                comsumi_energia_transporto,
                comsumi_energia_transporto_smc

            } = this.props.comperatoreGas;


            this.setState({
                iva_fino,
                iva_oltre,
                iva_agevolata,
                per_tuti,
                comsumi_rete_oneri,
                comsumi_energia_transporto,
                comsumi_energia_transporto_smc
            });

            if (this.props.imposte != null) {
                this.setState({ 'imposte': this.props.imposte })
            }

            if (this.props.sintetica != null) {
                this.setState({ 'sintetica': this.props.sintetica })
            }
        }
    }



    handleChange(name, value) {
        this.setState({ [name]: value })
    }


    openPopup(data) {
        if (data) {
            this.setState({ editableItem: data });
        }
        this.setState({ openPopup: true });
    }

    closePopup() {
        this.setState({ editableItem: null })
        this.setState({ openPopup: false });
    }

    openRemovePopup(data, typeToRemove) {
        if (data) {
            this.setState({ editableItem: data, typeToRemove: typeToRemove });
        }
        this.setState({ statusRemovePopup: true });
    }

    closeRemovePopup() {
        this.setState({ editableItem: null })
        this.setState({ statusRemovePopup: false });
    }

    // comperatore gas 

    updateComperatoreGas() {
        const {
            iva_fino,
            iva_oltre,
            iva_agevolata,
            per_tuti,
            comsumi_rete_oneri,
            comsumi_energia_transporto,
            comsumi_energia_transporto_smc

        } = this.state;


        const data = {
            iva_fino,
            iva_oltre,
            iva_agevolata,
            per_tuti,
            comsumi_rete_oneri,
            comsumi_energia_transporto,
            comsumi_energia_transporto_smc
        };

        console.log('DATA updateComperatoreGas', data)

        updateComperatoreGas(data).then(result => {
            console.log("RESULT DATA ", result.data)
            this.props.update(result.data.comperatore);
        })
    }

    // imposte

    addImposte(data) {
        this.setState(prevState => ({
            imposte: [...prevState.imposte, data]
        }))
    }

    updateImposte(data) {
        const imposte = this.state.imposte.map(x => {
            if (x.id != data.id)
                return x;
            x = data;
            return x;
        });

        this.setState({ imposte });
    }

    removeItem() {
        const {
            editableItem,
            imposte,
            sintetica
        } = this.state;

        this.closeRemovePopup();
        console.log('editableItem', editableItem)
        if (this.state.typeToRemove === 'imposte') {
            console.log('we deleted imposte');

            const updatedImposte = imposte.filter(x => x.id != editableItem.id)
            this.setState({ imposte: updatedImposte });
            this.props.updateImposte(updatedImposte);
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Imposte removed', open: true});

            removeImposte(editableItem.id).then(result => {
                console.log("RESULT DATA ", result.data)
            })
        }

        if (this.state.typeToRemove === 'sintetica') {
            console.log('we deleted sintetica');

            const updatedSintetica = sintetica.filter(x => x.id != editableItem.id)
            this.setState({ sintetica: updatedSintetica });
            this.props.updateSintetica(updatedSintetica);
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Sintetica removed', open: true});

            removeSintetica(editableItem.id).then(result => {
                console.log("RESULT DATA ", result.data)
            })
        }
        
    }

    // sintetica


    addSintetica(data) {
        this.setState(prevState => ({
            sintetica: [...prevState.sintetica, data]
        }))
    }

    updateSintetica(data) {
        const sintetica = this.state.sintetica.map(x => {
            if (x.id != data.id)
                return x;
            x = data;
            return x;
        });

        this.setState({ sintetica });
    }


    render() {
        const {
            openPopup,
            isImposte,

            iva_fino,
            iva_oltre,
            iva_agevolata,
            per_tuti,

            comsumi_rete_oneri,
            comsumi_energia_transporto,
            comsumi_energia_transporto_smc,

            imposte,
            sintetica,

            editableItem,
            statusRemovePopup

        } = this.state;

        return (
            <div>
                <div class="comperatore__twoColumns">
                    <div className="comperatore__gasInputGroup">
                        <div className="comperatore__titleBold">IVA Cliente Domestico</div>
                        <TextField
                            className="comperatore__defaultInput"
                            value={iva_fino}
                            label="IVA fino a 480 m3"
                            onChange={event => this.handleChange('iva_fino', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            // type="number"
                            size="small"
                        />
                        <TextField
                            className="comperatore__defaultInput"
                            value={iva_oltre}
                            label="IVA oltre 480 m3"
                            onChange={event => this.handleChange('iva_oltre', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            // type="number"
                            size="small"
                        />
                    </div>
                    <div className="comperatore__gasInputGroup">
                        <div className="comperatore__titleBold">IVA Cliente Business</div>
                        <TextField
                            className="comperatore__defaultInput--long"
                            value={iva_agevolata}
                            label="IVA agevolata"
                            onChange={event => this.handleChange('iva_agevolata', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            // type="number"
                            size="small"
                            helperText="IVA agevolata per le imprese estrattive, agricole,manifatturiere"
                        />

                        <TextField
                            className="comperatore__defaultInput--long"
                            value={per_tuti}
                            label="Per tutti settori"
                            onChange={event => this.handleChange('per_tuti', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            // type="number"
                            size="small"
                        />
                    </div>
                </div>

                <div className="comperatore__title">Comsuni (Smc/anno)</div>
                <div className="comperatore__gasTableWrapper">
                    <img src="../images/comperatoreGasTable.svg" alt="SVG as an image" />
                    <input type="number" value={comsumi_rete_oneri} onChange={event => this.handleChange('comsumi_rete_oneri', event.target.value)} className="comperatore__tableInput comperatore__tableInputLeft" />
                    <input type="number" value={comsumi_energia_transporto} onChange={event => this.handleChange('comsumi_energia_transporto', event.target.value)} className="comperatore__tableInput comperatore__tableInputRight" />
                    <input type="number" value={comsumi_energia_transporto_smc} onChange={event => this.handleChange('comsumi_energia_transporto_smc', event.target.value)} className="comperatore__tableInput comperatore__tableInputRightBottom" />
                </div>

                <Box textAlign='center' mt={5} className='comperatore__updateBtn'>
                    <Button variant="contained" onClick={this.updateComperatoreGas}>
                        Update
                    </Button>
                </Box>

                <div className="comperatore__switcherWrapper">
                    <div className="comperatore__switcher">
                        <div className={isImposte ? "comperatore__switcherTab--active" : "comperatore__switcherTab"} onClick={() => this.setState({ isImposte: true })}>Imposte</div>
                        <div className={!isImposte ? "comperatore__switcherTab--active" : "comperatore__switcherTab"} onClick={() => this.setState({ isImposte: false })}>Sintetica</div>
                    </div>
                    <Button className='comperatore__addBtn' onClick={() => this.openPopup()}>+ Add New</Button>
                </div>

                {isImposte ?
                    <Box mt={5} mb={10}>
                        <div className="comperatore__titleBold">Imposte sul gas</div>
                        <div className="comperatore__tableWrapper">
                            <TableContainer component={Paper}>
                                <Table sx={{ width: 1000 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Regione</b></TableCell>
                                            <TableCell><b>128-480 m</b></TableCell>
                                            <TableCell><b>Erariale di consumo</b></TableCell>
                                            <TableCell><b>Actions</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {imposte.map(imposte => (
                                            <TableRow
                                                key={imposte.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {imposte.name}
                                                </TableCell>
                                                <TableCell>{imposte.total}</TableCell>
                                                <TableCell>{imposte.erariale}</TableCell>
                                                <TableCell>
                                                    <div className="comperatore__actionButtonsWrapper">
                                                        <Tooltip title='Edit'><Button onClick={() => this.openPopup(imposte)} className='actionBtn actionBtn--edit' variant="fab" mini aria-label="Edit"><Icon className='editIcon'>edit_icon</Icon></Button></Tooltip>
                                                        <Tooltip title='Delete'><Button onClick={() => this.openRemovePopup(imposte, 'imposte')} className='actionBtn actionBtn--remove' variant="fab" mini aria-label="Remove"><Icon className='offer_icon removeIcon'>delete</Icon></Button></Tooltip>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    </Box>
                    :

                    <Box mt={5} mb={10}>
                        <div className="comperatore__titleBold">Tabella sintetica fornitura gas clienti non domestici</div>
                        <div className="comperatore__tableWrapper">
                            <TableContainer component={Paper}>
                                <Table sx={{ width: 1000 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Regione</b></TableCell>
                                            <TableCell><b>Spesa Materia Gas Naturale</b></TableCell>
                                            <TableCell><b>Trasporto e gestione del contatore</b></TableCell>
                                            <TableCell><b>Oneri di sistema</b></TableCell>
                                            <TableCell><b>Actions</b></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sintetica.map(sintetica => (
                                            <TableRow
                                                key={sintetica.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {sintetica.name}
                                                </TableCell>
                                                <TableCell>{sintetica.spesa}</TableCell>
                                                <TableCell>{sintetica.transporto}</TableCell>
                                                <TableCell>{sintetica.oneri}</TableCell>
                                                <TableCell>
                                                    <div className="comperatore__actionButtonsWrapper">
                                                        <Tooltip title='Edit'><Button onClick={() => this.openPopup(sintetica)} className='actionBtn actionBtn--edit' variant="fab" mini aria-label="Edit"><Icon className='editIcon'>edit_icon</Icon></Button></Tooltip>
                                                        <Tooltip title='Delete'><Button onClick= {() => this.openRemovePopup(sintetica, 'sintetica')} className='actionBtn actionBtn--remove' variant="fab" mini aria-label="Remove"><Icon className='offer_icon removeIcon'>delete</Icon></Button></Tooltip>
                                                    </div>
                                                </TableCell>

                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    </Box>
                }

                {statusRemovePopup && <RemoveDialog remove={this.removeItem} open={statusRemovePopup} close={this.closeRemovePopup}/> }

                {openPopup && <ComperatoreGasPopup
                    isImposte={isImposte}
                    addImposte={this.addImposte}
                    updateImposte={this.updateImposte}

                    addSintetica={this.addSintetica}
                    updateSintetica={this.updateSintetica}

                    open={openPopup}
                    handleClose={this.closePopup}

                    editableItem={editableItem}
                />}

            </div>
        )
    }
}

export default ComperatoreGas;
