import React, { Component } from 'react';
import {
    Button,
    TextField,
    Tooltip,
    Icon,
    Radio,
    MenuItem
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { Link, Redirect } from 'react-router-dom';
import { parse } from 'query-string';

import { getAllScripts, removeScript } from '../../service';
import store from '../../store';

import './index.scss';

import {
    OPEN_HELP,
    CLOSE_HELP
} from '../../actions/actions';


import Script from './script';
import PopupScript from  './dialog';
import RemoveDialog from '../dialogs/removePopup';
import { changeOrderScripts } from './../../service';


class Scripts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scripts: [],

            openPopup: false,
            openRemovePopup: false,
            selectedScript: {},

            width: 0, 
            height: 0,

            columnsNumber: 0,
            // for last elem show on right or left side
            hasExtraSpace: false
        }

        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.openRemovePopup = this.openRemovePopup.bind(this);
        this.closeRemovePopup = this.closeRemovePopup.bind(this);
        this.createScript = this.createScript.bind(this);
        this.updateScript = this.updateScript.bind(this);
        this.removeScript = this.removeScript.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
        getAllScripts().then(resp => {
            const { ok, scripts } = resp.data;

            if(ok)
                this.setState({ scripts });
        })

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
      
    updateWindowDimensions() {
        let menuWidth = 220;
        let messageWidth = 450;
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        // set columns number
        this.setState({ columnsNumber:  Math.trunc((window.innerWidth - menuWidth) / messageWidth)});

        if(((window.innerWidth - menuWidth) / messageWidth) % 1 < 0.8) {
            this.setState({ hasExtraSpace:  false});
        }
        else {
            this.setState({ hasExtraSpace:  true});
        }
    }

    openRemovePopup(script) {
        this.setState({ openRemovePopup: true, selectedScript: script });
    }

    closeRemovePopup() {
        this.setState({ openRemovePopup: false, selectedScript: {} });
    }

    openPopup(script = null) {
        const { role } = store.getState().auth;

        if(role != 'admin')
            return false;

        this.setState({ openPopup: true, selectedScript: script });
    }

    closePopup() {
        this.setState({ openPopup: false });
    }

    createScript(script = null) {
        if(!script)
            return this.forceUpdate();

        const scripts = this.state.scripts;
        
        scripts.push(script);

        this.setState({ scripts });
    }

    updateScript(id, text, title) {
        const scripts = this.state.scripts.map(scr => {
            if(scr.id != id)
                return scr;

            scr.text = text;
            scr.title = title;

            return scr;
        });

        this.setState({ scripts });
    }

    removeScript() {
        const { selectedScript: { id }, scripts } = this.state;

        const updatedScripts = scripts.filter(scr => scr.id !== id);

        this.setState({ scripts: updatedScripts, openRemovePopup: false, selectedScript: {} });

        removeScript(id).then(resp => {
            const { ok } = resp.data;

            // if(ok)

        }).catch(err => {

        })
    }

    changeOrder(curr, to) {
        const currQ = curr.q;
        const toQ = to.q;

        curr.q = toQ;
        to.q = currQ;

        changeOrderScripts({...curr}, {...to}).then(resp => {
            const { ok } = resp.data;
        });

        this.forceUpdate();
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    render() {
        const { role } = store.getState().auth;

        const { 
            openPopup,
            openRemovePopup,
            selectedScript,
            columnsNumber,
            hasExtraSpace,

            scripts: notFiltered
        } = this.state;

        const scripts = notFiltered.sort((a, b) => {
            return (a.q > b.q) ? 1 : (a.q < b.q) ? -1 : 0;
        })
        

        return (
            <div className='scripts_section'>
                { role == 'admin' && 
                    <Box sx={{width: 'fit-content', marginLeft: 'auto', marginRight: '32px'}}>
                        <Tooltip title='Add new'>
                            <Button className='add_button' onClick={this.openPopup}><span className='add_button--plusSign'>+</span><span>add new</span></Button>
                        </Tooltip>
                    </Box>
                }
                
                <PopupScript addScript={this.createScript} updateScript={this.updateScript} id={selectedScript.id} text={selectedScript.text} title={selectedScript.title} open={openPopup} handleClose={this.closePopup}/>
                <RemoveDialog open={openRemovePopup} close={this.closeRemovePopup} remove={this.removeScript}/>

                <Tooltip title='Help'>
                    <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>

                <div className='content'>
                { scripts ? (
                    scripts.map((script, index) => {
                        const curr = script;
                        const prev = scripts[index - 1] || null;
                        const next = scripts[index + 1] || null;

                        return <Script 
                            bottomTooltipPosition={columnsNumber === 1 ? false : (index + 1) % columnsNumber == 0}
                            hasExtraSpace={hasExtraSpace}
                            key={script.id} 
                            script={script} 
                            editScript={this.openPopup} 
                            removeScript={() => this.openRemovePopup(script)}
                            downOrder={prev ? () => this.changeOrder(curr, prev): null}
                            upOrder={next ? () => this.changeOrder(curr, next): null}
                            />
                })
                ) : (
                    <div></div>
                ) }
                </div>
            </div>
        )
    }
}

export default Scripts;