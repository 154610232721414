import React, { Component } from 'react';
import { spacing } from '@mui/system';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';

import store from '../../store';
import { OPEN_NOTIFICATION } from '../../actions/actions';

import { updateComperatoreEnergia } from '../../service';
import './comperatore.scss';

class ComperatoreEnergiaDomestico extends Component {
    constructor(props) {
        super(props);

        this.state = {

            // Energia
            ene_resid_kwh: '',
            ene_resid_kw: '',
            ene_resid_pcv: '',

            ene_non_resid_kwh: '',
            ene_non_resid_kw: '',
            ene_non_resid_pcv: '',

            // Rete
            rete_resid_kwh: '',
            rete_resid_kw: '',
            rete_resid_pcv: '',

            rete_non_resid_kwh: '',
            rete_non_resid_kw: '',
            rete_non_resid_pcv: '',

            // Oneri
            oneri_resid_kwh: '',
            oneri_resid_pcv: '',

            oneri_non_resid_kwh: '',
            oneri_non_resid_pcv: '',

            // Imposte

            imposte_resid: [
                { min: 0, max: 1800, price: '' },
                { min: 1801, max: 2640, price: '' },
                { min: 2641, max: 4440, price: '' },
                { min: 4441, max: '∞', price: '' }
            ],


            imposte_business: [
                { min: 0, max: 1800, price: '' },
                { min: 1801, max: 2640, price: '' },
                { min: 2641, max: 4440, price: '' },
                { min: 4441, max: '∞', price: '' }
            ],

            iva_vat: '',

            // UDA //

            // Energia
            uda_ene_kwh: '',
            uda_ene_kw: '',
            uda_ene_pcv: '',

            // Rete
            uda_rete_kwh: '',
            uda_rete_kw: '',
            uda_rete_pcv: '',

            // Oneri
            uda_oneri_kwh: '',
            uda_oneri_pcv: '',

            // Imposte
            // uda_imposte: '',

            uda_imposte: [
                { min: 0, max: 1800, price: '' },
                { min: 1801, max: 2640, price: '' },
                { min: 2641, max: 4440, price: '' },
                { min: 4441, max: '∞', price: '' }
            ],


            
            // DOMESTICO
            perdite_di_rete_domestico: '',
        }

        this.handleChange = this.handleChange.bind(this);
        this.updateComperatoreEnergia = this.updateComperatoreEnergia.bind(this);
        this.updateImposte = this.updateImposte.bind(this);
    }


    componentDidMount() {

        // console.log("PROPS", this.props.comperatoreEne)
        if (this.props.comperatoreEne != null) {
            let {
                ene_resid_kwh,
                ene_resid_kw,
                ene_resid_pcv,

                ene_non_resid_kwh,
                ene_non_resid_kw,
                ene_non_resid_pcv,

                rete_resid_kwh,
                rete_resid_kw,
                rete_resid_pcv,

                rete_non_resid_kwh,
                rete_non_resid_kw,
                rete_non_resid_pcv,

                oneri_resid_kwh,
                oneri_resid_pcv,

                oneri_non_resid_kwh,
                oneri_non_resid_pcv,

                imposte_resid,
                imposte_business,

                iva_vat,

                uda_ene_kwh,
                uda_ene_kw,
                uda_ene_pcv,

                uda_rete_kwh,
                uda_rete_kw,
                uda_rete_pcv,

                uda_oneri_kwh,
                uda_oneri_pcv,

                uda_imposte,


                perdite_di_rete_domestico

            } = this.props.comperatoreEne;


            imposte_resid = imposte_resid && JSON.parse(imposte_resid)
            imposte_business = imposte_business && JSON.parse(imposte_business)
            uda_imposte = uda_imposte && JSON.parse(uda_imposte)

            console.log("imposte_resid", imposte_resid)
            console.log("imposte_resid", imposte_business)
            console.log("imposte_resid", uda_imposte)


            imposte_resid &&
                this.setState({
                    imposte_resid
                })
            imposte_business &&
                this.setState({
                    imposte_business
                })

            uda_imposte &&
                this.setState({
                    uda_imposte
                })

            this.setState({
                ene_resid_kwh,
                ene_resid_kw,
                ene_resid_pcv,

                ene_non_resid_kwh,
                ene_non_resid_kw,
                ene_non_resid_pcv,

                rete_resid_kwh,
                rete_resid_kw,
                rete_resid_pcv,

                rete_non_resid_kwh,
                rete_non_resid_kw,
                rete_non_resid_pcv,

                oneri_resid_kwh,
                oneri_resid_pcv,

                oneri_non_resid_kwh,
                oneri_non_resid_pcv,

                iva_vat,

                uda_ene_kwh,
                uda_ene_kw,
                uda_ene_pcv,

                uda_rete_kwh,
                uda_rete_kw,
                uda_rete_pcv,

                uda_oneri_kwh,
                uda_oneri_pcv,

                perdite_di_rete_domestico
            });

        }
    }


    handleChange(name, value) {
        this.setState({ [name]: value })
    }

    updateComperatoreEnergia() {
        const {
            ene_resid_kwh,
            ene_resid_kw,
            ene_resid_pcv,

            ene_non_resid_kwh,
            ene_non_resid_kw,
            ene_non_resid_pcv,

            rete_resid_kwh,
            rete_resid_kw,
            rete_resid_pcv,

            rete_non_resid_kwh,
            rete_non_resid_kw,
            rete_non_resid_pcv,

            oneri_resid_kwh,
            oneri_resid_pcv,

            oneri_non_resid_kwh,
            oneri_non_resid_pcv,

            imposte_resid,
            imposte_business,

            iva_vat,

            uda_ene_kwh,
            uda_ene_kw,
            uda_ene_pcv,

            uda_rete_kwh,
            uda_rete_kw,
            uda_rete_pcv,

            uda_oneri_kwh,
            uda_oneri_pcv,

            uda_imposte,


            perdite_di_rete_domestico

        } = this.state;


        const data = {
            ene_resid_kwh,
            ene_resid_kw,
            ene_resid_pcv,

            ene_non_resid_kwh,
            ene_non_resid_kw,
            ene_non_resid_pcv,

            rete_resid_kwh,
            rete_resid_kw,
            rete_resid_pcv,

            rete_non_resid_kwh,
            rete_non_resid_kw,
            rete_non_resid_pcv,

            oneri_resid_kwh,
            oneri_resid_pcv,

            oneri_non_resid_kwh,
            oneri_non_resid_pcv,

            imposte_resid: JSON.stringify(imposte_resid),
            imposte_business: JSON.stringify(imposte_business),

            iva_vat,

            uda_ene_kwh,
            uda_ene_kw,
            uda_ene_pcv,

            uda_rete_kwh,
            uda_rete_kw,
            uda_rete_pcv,

            uda_oneri_kwh,
            uda_oneri_pcv,

            uda_imposte: JSON.stringify(uda_imposte),

            perdite_di_rete_domestico
        };

        updateComperatoreEnergia(data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Luce domestico was updated', open: true});
            this.props.update(result.data.comperatore);
        })
    }


    updateImposte(imposteType, index, value) {
        if (imposteType === 'imposte_resid') {
            let imposte_resid = [...this.state.imposte_resid];

            imposte_resid[index] = { ...imposte_resid[index], price: value };

            this.setState({ imposte_resid });
        }

        else if (imposteType === 'imposte_business') {
            let imposte_business = [...this.state.imposte_business];

            imposte_business[index] = { ...imposte_business[index], price: value };

            this.setState({ imposte_business });
        }

        if (imposteType === 'uda_imposte') {
            let uda_imposte = [...this.state.uda_imposte];

            uda_imposte[index] = { ...uda_imposte[index], price: value };
            
            this.setState({ uda_imposte });
        }
    }


    render() {
        const {

            // Energia
            ene_resid_kwh,
            ene_resid_kw,
            ene_resid_pcv,

            ene_non_resid_kwh,
            ene_non_resid_kw,
            ene_non_resid_pcv,

            // Rete
            rete_resid_kwh,
            rete_resid_kw,
            rete_resid_pcv,

            rete_non_resid_kwh,
            rete_non_resid_kw,
            rete_non_resid_pcv,

            // Oneri
            oneri_resid_kwh,
            oneri_resid_pcv,

            oneri_non_resid_kwh,
            oneri_non_resid_pcv,

            // Imposte
            imposte_resid,
            imposte_business,

            iva_vat,

            // UDA //

            // Energia
            uda_ene_kwh,
            uda_ene_kw,
            uda_ene_pcv,

            // Rete
            uda_rete_kwh,
            uda_rete_kw,
            uda_rete_pcv,

            // Oneri
            uda_oneri_kwh,
            uda_oneri_pcv,

            // Imposte
            uda_imposte,


            // DOMESTICO
            perdite_di_rete_domestico
        } = this.state;

        return (
            <section className="comperatore__section">
                <div className="comperatore__title">Energia e commercializzazione</div>
                <div className="comperatore__subtitle">Resid</div>
                <TextField
                    className="comperatore__defaultInput"
                    value={ene_resid_kwh}
                    label="(€/kWh)"
                    onChange={event => this.handleChange('ene_resid_kwh', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={ene_resid_kw}
                    label="(€/kW)"
                    onChange={event => this.handleChange('ene_resid_kw', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={ene_resid_pcv}
                    label="PCV (€/POD)"
                    onChange={event => this.handleChange('ene_resid_pcv', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />



                <div className="comperatore__subtitle">Non Resid</div>
                <TextField
                    className="comperatore__defaultInput"
                    value={ene_non_resid_kwh}
                    label="(€/kWh)"
                    onChange={event => this.handleChange('ene_non_resid_kwh', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={ene_non_resid_kw}
                    label="(€/kW)"
                    onChange={event => this.handleChange('ene_non_resid_kw', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={ene_non_resid_pcv}
                    label="PCV (€/POD)"
                    onChange={event => this.handleChange('ene_non_resid_pcv', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />


                <hr className="comperatore__separator"></hr>

                <div className="comperatore__title">Rete e contatore</div>
                <div className="comperatore__subtitle">Resid</div>
                <TextField
                    className="comperatore__defaultInput"
                    value={rete_resid_kwh}
                    label="(€/kWh)"
                    onChange={event => this.handleChange('rete_resid_kwh', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={rete_resid_kw}
                    label="(€/kW)"
                    onChange={event => this.handleChange('rete_resid_kw', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={rete_resid_pcv}
                    label="PCV (€/POD)"
                    onChange={event => this.handleChange('rete_resid_pcv', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <div className="comperatore__subtitle">Non Resid</div>
                <TextField
                    className="comperatore__defaultInput"
                    value={rete_non_resid_kwh}
                    label="(€/kWh)"
                    onChange={event => this.handleChange('rete_non_resid_kwh', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={rete_non_resid_kw}
                    label="(€/kW)"
                    onChange={event => this.handleChange('rete_non_resid_kw', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={rete_non_resid_pcv}
                    label="PCV (€/POD)"
                    onChange={event => this.handleChange('rete_non_resid_pcv', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <hr className="comperatore__separator"></hr>

                <div className="comperatore__title">Oneri di sistema</div>
                <div className="comperatore__subtitle">Resid</div>
                <TextField
                    className="comperatore__defaultInput"
                    value={oneri_resid_kwh}
                    label="(€/kWh)"
                    onChange={event => this.handleChange('oneri_resid_kwh', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={oneri_resid_pcv}
                    label="PCV (€/POD)"
                    onChange={event => this.handleChange('oneri_resid_pcv', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />

                <div className="comperatore__subtitle">Non Resid</div>
                <TextField
                    className="comperatore__defaultInput"
                    value={oneri_non_resid_kwh}
                    label="(€/kWh)"
                    onChange={event => this.handleChange('oneri_non_resid_kwh', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={oneri_non_resid_pcv}
                    label="PCV (€/POD)"
                    onChange={event => this.handleChange('oneri_non_resid_pcv', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <hr className="comperatore__separator"></hr>

                <div className="comperatore__title">Imposte (accise)</div>
                <div className="comperatore__subtitle">Resid</div>

                {imposte_resid.map((el, index) => {
                    return (
                        <TextField
                            className="comperatore__defaultInput"
                            value={el.price}
                            onChange={(event) => this.updateImposte("imposte_resid", index, event.target.value)}
                            label="(€/kWh)"
                            margin="normal"
                            variant="outlined"
                            type="number"
                            size="small"
                            helperText="0 - 1800"
                        />
                    )
                })}

                <div className="comperatore__subtitle">Non Resid</div>
                {imposte_business.map((el, index) => {
                    return (
                        <TextField
                            className="comperatore__defaultInput"
                            value={el.price}
                            onChange={(event) => this.updateImposte("imposte_business", index, event.target.value)}
                            label="(€/kWh)"
                            margin="normal"
                            variant="outlined"
                            type="number"
                            size="small"
                            helperText="0 - 1800"
                        />
                    )
                })}
                <Box>
                    <TextField
                        className="comperatore__defaultInput"
                        value={iva_vat}
                        onChange={(event) => this.updateImposte("iva_vat", event.target.value)}
                        label="IVA (VAT)"
                        margin="normal"
                        variant="outlined"
                        type="number"
                        size="small"
                    />
                </Box>


                <hr className="comperatore__separator"></hr>

                <div className="comperatore__titleBold">Uda</div>
                <div className="comperatore__title">Energia e commercializzazione</div>
                <TextField
                    className="comperatore__defaultInput"
                    value={uda_ene_kwh}
                    label="(€/kWh)"
                    onChange={event => this.handleChange('uda_ene_kwh', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={uda_ene_kw}
                    label="(€/kW)"
                    onChange={event => this.handleChange('uda_ene_kw', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={uda_ene_pcv}
                    label="PCV (€/POD)"
                    onChange={event => this.handleChange('uda_ene_pcv', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />

                <hr className="comperatore__separator"></hr>

                <div className="comperatore__title">Rete e contatore</div>
                <TextField
                    className="comperatore__defaultInput"
                    value={uda_rete_kwh}
                    label="(€/kWh)"
                    onChange={event => this.handleChange('uda_rete_kwh', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={uda_rete_kw}
                    label="(€/kW)"
                    onChange={event => this.handleChange('uda_rete_kw', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={uda_rete_pcv}
                    label="PCV (€/POD)"
                    onChange={event => this.handleChange('uda_rete_pcv', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />

                <hr className="comperatore__separator"></hr>
                <div className="comperatore__title">Oneri di sistema</div>
                <TextField
                    className="comperatore__defaultInput"
                    value={uda_oneri_kwh}
                    label="(€/kWh)"
                    onChange={event => this.handleChange('uda_oneri_kwh', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <TextField
                    className="comperatore__defaultInput"
                    value={uda_oneri_pcv}
                    label="PCV (€/POD)"
                    onChange={event => this.handleChange('uda_oneri_pcv', event.target.value)}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    size="small"
                />
                <hr className="comperatore__separator"></hr>

                <div className="comperatore__title">Imposte (accise)</div>

                {uda_imposte.map((el, index) => {
                    return (
                        <TextField
                            className="comperatore__defaultInput"
                            value={el.price}
                            onChange={(event) => this.updateImposte("uda_imposte", index, event.target.value)}
                            label="(€/kWh)"
                            margin="normal"
                            variant="outlined"
                            type="number"
                            size="small"
                            helperText="0 - 1800"
                        />
                    )
                })}
                <Box mt={5}>
                    <TextField
                        type="number"
                        defaultValue="1"
                        label="Perdite di rete %"
                        margin="normal"
                        variant="outlined"
                        size="small"
                        value={perdite_di_rete_domestico}
                        onChange={event => this.handleChange('perdite_di_rete_domestico', event.target.value)}
                    />
                </Box>

                <div className="comperatore__okCancelButtonsWrapper">
                    <Button variant="outlined" sx={{ mr: 4 }}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={this.updateComperatoreEnergia}>
                        Update
                    </Button>
                </div>

            </section>
        )
    }
}


export default ComperatoreEnergiaDomestico;

