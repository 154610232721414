import React, { Component } from 'react';
import { 
    Button,
    MenuItem,
    Icon,
    Tooltip
} from '@material-ui/core';
import TextField from '@mui/material/TextField';

import { Redirect } from 'react-router-dom';
import UploadImage from '../../../components/dialogs/uploadImage';
import axios from 'axios';

import store from '../../../store';
import { 
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
  } from '../../../actions/actions';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import './NewSupplier.scss';

import { createTelcoSupplier, getTelcoSupplierById, updateTelcoSupplier, uploadTelcoLogo } from '../../../service';

class NewSupplier extends Component {
    constructor(props) {
        super(props)

        this.state =  {
            // main data
            name: null,
            code: null,
            description: null,

            upImage: null,
            openUploadPopup: false,

            // true/false data
            business: false,
            resid: false,

            cambio_mobile: false,
            cambio_linea_fissa: false,
            cambio_mobile_linea_fissa : false,
            nuova_attivazione: false,

            redirect: false
        }

        this.onChangeBool = this.onChangeBool.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.createSupplier = this.createSupplier.bind(this);
        this.updateSupplier = this.updateSupplier.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.openUplaodPopup = this.openUplaodPopup.bind(this);
        this.closeUpload = this.closeUpload.bind(this);
        this.cropedImage = this.cropedImage.bind(this);
    }

    componentWillMount() {
        const id = this.props.match.params.id;

        if(!id || id === 0)
            return false;

        getTelcoSupplierById(id).then(result => {
            const sup = result.data.supplier;

            this.setState({ ...sup });
        }).catch(err => {

        })
    }

    onChangeBool(name) {
        const data = this.state;

        data[name] = !data[name];

        this.setState(data);
    }

    onChangeText(name, val) {
        const data = this.state;

        data[name] = val;

        this.setState(data);
    }

    createSupplier() {
        const { upImage, ...data } = this.state;

        store.dispatch({ type: OPEN_LOADING, text: 'Creating supplier...' });
        createTelcoSupplier(data).then(supplier => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Supplier been created', open: true})

            if(upImage) {
                store.dispatch({ type: OPEN_LOADING, text: 'Uploading image...' });

                const data = new FormData();
                fetch(upImage)
                    .then(result => result.blob())
                    .then(imageBlob => {
                        data.append('file', imageBlob, 'logo.jpg');
                        uploadTelcoLogo(supplier.data.supplier.id, data).then(result => {
                            store.dispatch({type: OPEN_NOTIFICATION, text: 'Logo been uploaded', open: true})

                            this.setState({redirect: true});

                            store.dispatch({ type: CLOSE_LOADING });
                        }).catch(err => {
                            store.dispatch({ type: OPEN_LOADING, text: `Can't upload image` });
                            console.log(err);

                            setTimeout(() => {
                                store.dispatch({ type: CLOSE_LOADING });
                            }, 2500);
                        });
                    })
                return false;
            }else {
                this.setState({redirect: true});
                store.dispatch({ type: CLOSE_LOADING });
            }
            
        }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't create supplier` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    updateSupplier() {
        const id = this.state.id;
        const data = this.state;
        const upImage = this.state.upImage;

        store.dispatch({ type: OPEN_LOADING, text: 'Updating supplier...' });

        updateTelcoSupplier(id , data).then(supplier => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Supplier been updated', open: true})
            if(upImage) {
                store.dispatch({ type: OPEN_LOADING, text: 'Uploading image...' });

                const data = new FormData();
                fetch(upImage)
                    .then(result => result.blob())
                    .then(imageBlob => {
                        data.append('file', imageBlob, 'logo.jpg');
                        uploadTelcoLogo(supplier.data.supplier.id, data).then(result => {
                            this.setState({redirect: true});

                            store.dispatch({type: OPEN_NOTIFICATION, text: 'Logo been uploaded', open: true})
                            store.dispatch({ type: CLOSE_LOADING });
                        }).catch(err => {
                            store.dispatch({ type: OPEN_LOADING, text: `Can't upload image` });
                            console.log(err);

                            setTimeout(() => {
                                store.dispatch({ type: CLOSE_LOADING });
                            }, 2500);
                        });
                    })
                return false;
            }else {
                this.setState({redirect: true});
                store.dispatch({ type: CLOSE_LOADING });
            }
        }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't create supplier` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    selectChange(name, value) {
        const state = this.state;

        state[name] = value;

        this.setState(state);
    }

    openUplaodPopup() {
        this.setState({openUploadPopup: true});
    }

    closeUpload() {
        this.setState({openUploadPopup: false});
    }

    cropedImage(path) {
        this.closeUpload();
        this.setState({upImage: path});
    }

    createProposal() {
        const data = {
            
        }
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_telco' });
    }

    render() {
        const redirect = this.state.redirect;

        if(redirect)
            return(
                <Redirect to='/' />
            );

        const { 
            id,
            name,
            code,
            description,
            upImage,
            openUploadPopup,

            // tipo cliente
            resid,
            business,
            
            // true/false data
            cambio_mobile,
            cambio_linea_fissa,
            cambio_mobile_linea_fissa,
            nuova_attivazione



        } = this.state;

        const dissabled = !(name && code && description);

        const filterOptions = ['nd', 'dual', 'luce', 'gas'];

        const { role } = store.getState().auth;

        return (
            <div className='new_supplier_section anim_content'>
                {role !== 'admin' && <div className='disable_screen'></div>}
                <UploadImage open={openUploadPopup} close={this.closeUpload} cropedFile={this.cropedImage}/>
                <div className='main'>
                    <Tooltip title='Help'>
                            <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                    </Tooltip>

                    <div className='fields mainInfo'>
                        <h3 className='head'>Add new supplier</h3>
                        <TextField size="small" type='text' style={style.mainTextField} value={name ? ''+name : ''} label='Supplier Name' onChange={event => { this.onChangeText('name', event.target.value) }} required/>
                        <TextField size="small" type='text' style={style.mainTextField} value={code ? ''+code : ''} label='Code' onChange={event => { this.onChangeText('code', event.target.value) }} required/>
                        <TextField size="small" type='text' style={style.mainTextFieldMult} value={description ? ''+description : ''} multiline rows='5' label='Description' onChange={event => { this.onChangeText('description', event.target.value) }} required/>
                    </div>

                    <div className='upload_image mainInfo'>
                        <h3 className='head'>Upload logo</h3>
                        <div className='image_block'>
                         {upImage 
                            ? <img className='image' src={upImage} onClick={this.openUplaodPopup}/>
                            : <div className='text' onClick={this.openUplaodPopup}>Upload Logo</div>}
                        </div>
                    </div>
                </div>

                <div className='filter_section'>
                    <h3 className='head'>Filters</h3>

                    <div className='content'>

                        <div className='saparetor'>Tipo cliente</div>
                        <div className='filters'>
                            <FormControlLabel className={resid ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={resid} onChange={() => { this.onChangeBool('resid') }} />} label="Resid" />
                            <FormControlLabel className={business ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={business} onChange={() => { this.onChangeBool('business') }} />} label="Business" />
                        </div>

                        <div className='saparetor'>Tipo di operazioni</div>
                        <div className='filters'>
                            <FormControlLabel className={cambio_mobile ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={cambio_mobile} onChange={() => { this.onChangeBool('cambio_mobile') }} />} label="Cambio Mobile" />
                            <FormControlLabel className={cambio_linea_fissa ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={cambio_linea_fissa} onChange={() => { this.onChangeBool('cambio_linea_fissa') }} />} label="Cambio linea fissa" />
                            <FormControlLabel className={cambio_mobile_linea_fissa ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={cambio_mobile_linea_fissa} onChange={() => { this.onChangeBool('cambio_mobile_linea_fissa') }} />} label="Cambio mobile linea fissa" />
                            <FormControlLabel className={nuova_attivazione ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={nuova_attivazione} onChange={() => { this.onChangeBool('nuova_attivazione') }} />} label="Nuova Attivazione" />
                        </div>


                    </div>
                    {role === 'admin' && <div className='action_section'>
                        { id
                            ? <Button style={dissabled ? style.createButtonDissabled : style.createButton} disabled={dissabled} onClick={this.updateSupplier}>Update</Button>
                            : <Button style={dissabled ? style.createButtonDissabled : style.createButton} disabled={dissabled} onClick={this.createSupplier}>Create</Button>
                        }
                    </div> }
                </div>
            </div>
        )
    }
}

export default NewSupplier;

const calcWithW = (num) => {
    return `calc(${num}vw - 220px)`;
}

const style = {
    mainTextField: {
        minWidth: '350px',
        height: '60px',
        marginBottom: '10px'
    },

    mainTextFieldMult: {
        minWidth: '350px',
        marginTop: '10px'
    },

    createButton: {
        width: '200px',
        height: '40px',
        background: '#417BC3',
        color: 'white'
    },

    createButtonDissabled: {
        width: '200px',
        height: '40px',
        background: '#8090a396',
        color: 'white'
    }
}