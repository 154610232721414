import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import store from '../../../store';

import OfferAdmin from './admin/OfferAdmin';
import OfferUser from './user/OfferUser';

class OfferList extends Component {
    render() {
        const { role } = store.getState().auth;

        return (
            role == 'admin' ? <OfferAdmin/> : <OfferUser/>
        )
    }
}

export default OfferList;