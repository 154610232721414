import React, { Component } from 'react';
import { Button, Icon, Tooltip } from '@material-ui/core';
import { Link, HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import renderHTML from 'react-render-html'

import './index.scss';

import store from '../../../store';

class OperationScript extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { role } = store.getState().auth;
        const { title, text, edit, className, disabled } = this.props;

        return(
            <div className={'opeation_script ' + className}>
                {role === 'admin' && <div className='actions'>
                    <Tooltip title='Edit'><Button onClick={edit} className='action -edit' variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>edit_icon</Icon></Button></Tooltip>
                </div>}
                <div className='head'>{title}</div>
                <div className='content'>
                    {renderHTML(text || '')}
                </div>
            </div>
        )
    }
}

export default OperationScript;