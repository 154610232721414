import React from 'react';
import {
    Button,
    Tooltip,
    Icon,
    Radio,
    MenuItem
} from '@material-ui/core';
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { findAddress } from '../../../service';

import './address.scss';
 
class LocationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      addresses: [],
      isLoading: false,
    };

    this.loadAddress = this.loadAddress.bind(this);
    this.onAddressSelect = this.onAddressSelect.bind(this);
  }
 
 

  loadAddress = (value) => {

    if (value.length > 0) {
      findAddress(value, 10).then(resp => {
        this.setState({ addresses: resp.data.addresses });
      })
    }
  }

  onAddressSelect(value) {
    this.props.setAddress(value);
  }


  render() {
    const {
      addresses,
      isLoading,
    } = this.state;

    return (
      <div className="autocomplete__wrapper">
        <Autocomplete
          className="autocompleteAdress"
          onChange={(event, value) => this.onAddressSelect(value)}
          onInputChange={(event, newInputValue) => this.loadAddress(newInputValue)}
          id="disable-close-on-select"
          options={addresses}
          getOptionLabel={option => (option.city.charAt(0).toUpperCase() + option.city.slice(1) + (option.province ? (', ' + option.province) : '') + ', ' + option.cap)}
          renderInput={(params) => (
            <TextField {...params} label="INDIRIZZO DI PRELIEVO" variant="outlined" size="small" />
          )}
        />
        {isLoading &&
          <div className="addressLoader">
            <Box sx={{ display: 'flex' }}>
              <CircularProgress size="17px" />
            </Box>
          </div>
        }
      </div>
    );
  }
}

export default LocationSearch;

function getCity(place) {
  const COMPONENT_TEMPLATE = { locality: 'long_name' };
  let city = getAddrComponent(place, COMPONENT_TEMPLATE);

  if(!city && place.address_components[0])
    city = place.address_components[0].long_name;

  return city;
}

function getPostalCode(place) {
  const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postalCode = getAddrComponent(place, COMPONENT_TEMPLATE);
  return postalCode;
}

function isGooglePlace(place) {
  if (!place)
    return false;
  return !!place.place_id;
}

function getAddrComponent(place, componentTemplate) {
  let result;
  if (!isGooglePlace(place))
    return;
  for (let i = 0; i < place.address_components.length; i++) {
    const addressType = place.address_components[i].types[0];
    if (componentTemplate[addressType]) {
      result = place.address_components[i][componentTemplate[addressType]];
      return result;
    }
  }
  return;
}