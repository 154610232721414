// actions 
import {
    OPEN_NOTIFICATION,
    CLOSE_NOTIFICATION
} from './actions.js';

export const notifications = {
    sendNotification(text) {
        return {
            type: OPEN_NOTIFICATION,
            open: true,
            text
        }
    },
    closeNotification() {
        return {
            type: CLOSE_NOTIFICATION,
            open: false
        }
    }
}