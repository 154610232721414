import React, { Component } from 'react';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import store from '../../../store';
import { OPEN_NOTIFICATION } from '../../../actions/actions';

import { createImposte, createSintetica, updateImposte, updateSintetica } from '../../../service';

import '../comperatore.scss';
import { isAfter } from 'date-fns';

class ComperatoreGasPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.editableItem ? this.props.editableItem.id : "",
            name: this.props.editableItem ? this.props.editableItem.name : "",
            total: this.props.editableItem ? this.props.editableItem.total : "",
            erariale: this.props.editableItem ? this.props.editableItem.erariale : "",

            isImposte: this.props.isImposte,

            spesa: this.props.editableItem ? this.props.editableItem.spesa : "",
            oneri: this.props.editableItem ? this.props.editableItem.oneri : "",
            transporto: this.props.editableItem ? this.props.editableItem.transporto : "",

            errorText: '',
            isDataSetted: false
        }

        this.changeValue = this.changeValue.bind(this);

        this.createImposte = this.createImposte.bind(this);
        this.updateSintetica = this.updateSintetica.bind(this);
        
        this.createSintetica = this.createSintetica.bind(this);
        this.updateImposte = this.updateImposte.bind(this);
        
    }

    changeValue(name, value) {
        const state = this.state;
        state[name] = value;
        this.setState(state);
    }

    createImposte() {
        const {
            name,
            total,
            erariale,
        } = this.state;

        const data = { name, total, erariale };

        createImposte(data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Imposte created', open: true});
            this.props.handleClose();
            this.props.addImposte(result.data.imposte)

        }).catch(error => {
            if(error.response.data.code === 409) {
                this.setState({errorText: "This imposte already exists"})
            }
        })
    }


    updateImposte() {
        const {
            id,

            name,
            total,
            erariale,
        } = this.state;

        const data = { name, total, erariale };
        
        updateImposte(id, data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Imposte updated', open: true});
            this.props.handleClose();
            let dataWithId = {...data, id};
            this.props.updateImposte(dataWithId)
        })
    }

    createSintetica() {
        const {
            name,
            spesa,
            oneri,
            transporto,
        } = this.state;

        const data = { name, spesa, oneri, transporto };


        createSintetica(data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Sintetica created', open: true});
            this.props.handleClose();
            console.log("RESULT DATA ", result.data.sintetica)
            this.props.addSintetica(result.data.sintetica)


        }).catch(error => {
            if(error.response.data.code === 409) {
                this.setState({errorText: "This sintetica already exists"})
            }
        })
    }

    updateSintetica() {

        const {
            id,
            name,
            spesa,
            oneri,
            transporto,
        } = this.state;

        const data = { name, spesa, oneri, transporto };
        
        updateSintetica(id, data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Sintetica updated', open: true});
            this.props.handleClose();
            let dataWithId = {...data, id};
            this.props.updateSintetica(dataWithId)
        })
    }




    render() {
        const {
            id,
            name,
            total,
            erariale,
            isImposte,


            spesa,
            oneri,
            transporto,

            errorText

        } = this.state;

        return (
            isImposte ?
            !id ? 
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <DialogTitle id="form-dialog-title">Add new</DialogTitle>
                    {errorText && <Box sx={{ typography: 'subtitle2', color: 'error.main', mr: '25px' }}>{errorText}</Box>}
                    </Box>
                    <DialogContent style={style.content} className='vissible-item'>
                        <TextField
                            style={style.inputFullwidth}
                            value={name}
                            label="Regione"
                            onChange={event => this.changeValue('name', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"
                        />
                        <div className="inputsWrapper">
                            <TextField
                                style={style.input}
                                type="number"
                                value={total}
                                label="128-480 m"
                                onChange={event => this.changeValue('total', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />
                            <TextField
                                style={style.input}
                                value={erariale}
                                label="Erariale di consumo"
                                onChange={event => this.changeValue('erariale', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.createImposte} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                : 
                <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Update Imposte</DialogTitle>
                <DialogContent style={style.content} className='vissible-item'>
                    <TextField
                        style={style.inputFullwidth}
                        defaultValue={name}
                        label="Regione"
                        onChange={event => this.changeValue('name', event.target.value)}
                        margin="normal"
                        variant="outlined"
                        size="small"

                    />
                    <div className="inputsWrapper">
                        <TextField
                            style={style.input}
                            type="number"
                            defaultValue={total}
                            label="128-480 m"
                            onChange={event => this.changeValue('total', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"

                        />
                        <TextField
                            style={style.input}
                            defaultValue={erariale}
                            label="Erariale di consumo"
                            onChange={event => this.changeValue('erariale', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"

                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.updateImposte} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>


                : 

                !id ? 

                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                    classes={{
                        paper: 'visible-item',
                    }}
                >
                    <DialogTitle id="form-dialog-title">Add new</DialogTitle>
                    <DialogContent style={style.content} className='vissible-item'>
                        <TextField
                            style={style.inputFullwidth}
                            value={name}
                            label="Regione"
                            onChange={event => this.changeValue('name', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"

                        />
                        <div className="inputsWrapper">
                            <TextField
                                style={style.input}
                                value={spesa}
                                label="Spesa Materia Gas Naturale"
                                onChange={event => this.changeValue('spesa', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />
                            <TextField
                                style={style.input}
                                value={oneri}
                                label="Oneri di sistema"
                                onChange={event => this.changeValue('oneri', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"

                            />
                        </div>
                        <TextField
                            style={style.inputFullwidth}
                            value={transporto}
                            label="Trasporto e gestione del contatore"
                            onChange={event => this.changeValue('transporto', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"

                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.createSintetica} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
        
                    : 
                    <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                    classes={{
                        paper: 'visible-item',
                    }}
                >
                    <DialogTitle id="form-dialog-title">Update Sintetica</DialogTitle>
                    <DialogContent style={style.content} className='vissible-item'>
                        <TextField
                            style={style.inputFullwidth}
                            value={name}
                            label="Regione"
                            onChange={event => this.changeValue('name', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"

                        />
                        <div className="inputsWrapper">
                            <TextField
                                style={style.input}
                                value={spesa}
                                label="Spesa Materia Gas Naturale"
                                onChange={event => this.changeValue('spesa', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"

                            />
                            <TextField
                                style={style.input}
                                value={oneri}
                                label="Oneri di sistema"
                                onChange={event => this.changeValue('oneri', event.target.value)}
                                margin="normal"
                                variant="outlined"
                                size="small"
                            />
                        </div>
                        <TextField
                            style={style.inputFullwidth}
                            value={transporto}
                            label="Trasporto e gestione del contatore"
                            onChange={event => this.changeValue('transporto', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size="small"

                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.updateSintetica} color="primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
        )
    }
}

export default ComperatoreGasPopup;

const style = {
    content: {
        width: '500px',
        minheight: '270px',
        overflow: 'visible'
    },

    heading: {
        marginTop: '20px',
    },

    input: {
        flex: '1',
        // marginRight: '25px',
    },

    inputFullwidth: {
        width: '100%',
    }
}