export default {
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    content: {
        width: 'calc(100vw - 200px)',
        height: 'calc(100vh - 20px)',
        background: 'white',
        overflow: 'auto',
        paddingBottom: '20px'
    }
}