import React, { Component } from 'react';
import { Button, Drawer, TextField, Tooltip, Icon, MenuItem } from '@material-ui/core';
import { generate } from 'generate-password';

import {
    getUsers,
    createUser,
    updateUser,
    updatePassword,
    removeUser
} from './../../../service';

import GenerateNew from './dialogs/generatePass';
import Delete from './dialogs/delete';
import LogsWindow from './dialogs/log';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import './users.scss';

class Users extends Component {
    constructor() {
        super();

        this.state = {
            name: '',
            login: '',
            password: '',
            role: '',
            elite: false,
            access: '',
            isUpdate: false,
            updatedUserId: '',

            users: [],

            //popups
            openRemove: false,
            openChange: false,
            openLogWindow: false,
            event: null
        };

        this.generatePass = this.generatePass.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.createUser = this.createUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.generateNewPass = this.generateNewPass.bind(this);
        this.closePopups = this.closePopups.bind(this);
        this.showLogWindow = this.showLogWindow.bind(this);
        this.showDataForUpdate = this.showDataForUpdate.bind(this);
        this.onUserUpdate = this.onUserUpdate.bind(this);
    }

    generatePass() {
        const password = generate({
            length: 24,
            numbers: true
        })

        this.setState({
            password
        });
    }

    createUser() {
        const users = this.state.users;
        const {
            name,
            login,
            password,
            role,
            elite,
            access
        } = this.state;

        const data = {
            name,
            login,
            pass: password,
            role,
            elite,
            access
        };

        const newUser = {
            name,
            login,
            role, 
            elite,
            access
        }

        users.push(newUser)

        this.setState({ users });

        createUser(data).then(resp => {

            newUser.id = resp.data.id;

            this.setState({ users });
        }).catch(err => {
            console.log(err);
        })
    }

    inputChange(name, value) {
        const state = this.state;

        state[name] = value;

        this.setState(state);
    }

    removeUser(data) {
        const id = data;
        
        const event = () => {
            const users = this.state.users.filter(user => user.id != id);

            this.setState({ users });
            this.closePopups();

            removeUser(id).then(resp => {
                console.log(resp);
            }).catch(err => console.log(err));
        }

        this.setState({ event, openRemove: true });
    };

    generateNewPass(data) {
        const id = data.id;

        const event = (pass) => {
            this.closePopups();
            updatePassword(id, { pass }).then(resp => {
                console.log(resp);
            }).catch(err => console.log(err));
        };

        this.setState({ event, openChange: true });
    }

    closePopups() {
        this.setState({ openChange: false, openRemove: false });
    }

    componentDidMount() {
        getUsers().then(resp => {
            const users = resp.data.users;

            this.setState({ users });
        });
    }

    showLogWindow(status, id) {
        this.setState({ openLogWindow: status, showLogWindowId: id});
    } 

    showDataForUpdate(userId) {
        let selectedUser = this.state.users.find(x => x.id === userId);
        this.setState({
            updatedUserId: userId,
            name:  selectedUser.name,
            login:  selectedUser.login,
            role:  selectedUser.role,
            elite:  selectedUser.elite,
            access:  selectedUser.access,
            isUpdate:  true
        });
    }

    onUserUpdate() {
        const {
            name,
            login,
            role,
            elite,
            access,
            updatedUserId,
            users
        } = this.state;

        const userIndex = users.findIndex(element => element.id == updatedUserId );
        const updatedUsers = users;
        updatedUsers[userIndex] = {...updatedUsers[userIndex], elite, access, login, name, role}
        this.setState({ users: updatedUsers});

        updateUser(updatedUserId, { name, login, role, elite, access }).then(resp => {
            console.log("resp", resp);
        }).catch(err => console.log(err));
    }


    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            if (!this.props.open) {
                console.log("CLOSED")

                this.setState({
                    isUpdate: false,
                    updatedUserId: "",
                    name:  "",
                    login:  "",
                    role:  "",
                    elite:  "",
                    access: ""
                });
            }
        }
    }

    render() {
        const {
            open,
            component,
            close
        } = this.props;

        const {
            name,
            login,
            password,
            role,
            users,
            elite,
            access,
            isUpdate,

            // popups
            openRemove,
            openChange,
            openLogWindow,
            showLogWindowId,
            event
        } = this.state;

        return (
            <Drawer open={open} onClose={close} anchor="bottom" className=''>
                <Delete open={openRemove} close={this.closePopups} event={event}/>
                <GenerateNew open={openChange} close={this.closePopups} event={event}/>
                <LogsWindow open={openLogWindow} close={this.showLogWindow} userId={showLogWindowId}/>
                <div className='users_section'>
                    <div className='add_new'>
                        <div className='header'>
                            {isUpdate ? "Update user" : "Create new user"}
                        </div>
                        <div className='fields'>
                            <TextField label='Enter full name' value={name} onChange={event => this.inputChange('name', event.target.value)} />
                            <TextField label='login name' value={login} onChange={event => this.inputChange('login', event.target.value)} />
                        </div>
                        {!isUpdate &&
                            <div className='fields -pass'>
                                <TextField label='Password' value={password} onChange={event => this.inputChange('password', event.target.value)} />
                                <Tooltip title='Generate Password'><Button className='action -generate' onClick={this.generatePass} variant="fab" mini aria-label="Generate"><Icon className='offer_icon'>all_inclusive</Icon></Button></Tooltip>
                            </div>
                        }
                        <div className='fields -role'>
                            <TextField
                                    select
                                    label="Role"
                                    className='select'
                                    onChange={event => this.inputChange('role', event.target.value)}
                                    margin="normal"
                                    variant="filled"
                                    value={role}
                                    >
                                    {['admin', 'user'].map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                            </TextField>

                            <TextField
                                select
                                label="Access"
                                className='select'
                                onChange={event => this.inputChange('access', event.target.value)}
                                margin="normal"
                                variant="filled"
                                value={access}
                                >
                                {['energia', 'telco', 'dual'].map(option => (
                                    <MenuItem key={option} value={option}>
                                    {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        
                        <div className='fields -role'>
                            <FormControlLabel className="superUser" control={<Checkbox checked={elite} onChange={(e) => this.inputChange('elite', e.target.checked)} />} label="Super User" />
                        </div>
                        <div className='actions'>
                        { isUpdate
                            ? <Button onClick={this.onUserUpdate}>Update</Button>
                            : <Button onClick={this.createUser}>Create</Button>
                        }
                        </div>
                    </div>
                    <div className='list'>
                        {users.map(user => (
                            <div className='user' key={user.id || + new Date()}>
                                <Tooltip title='Name'>
                                    <div className='name'>
                                        {user.name}
                                    </div>
                                </Tooltip>
                                <div className='info'>
                                    <Tooltip title='Login'><span>{user.login}</span></Tooltip>
                                    <Tooltip title='Role'><span>{user.role} ({user.access})</span></Tooltip>
                                </div>
                                <div className='actions'>
                                    <Tooltip title='Delete'><Button onClick={() => this.removeUser(user.id)} className='action -delete' variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>delete</Icon></Button></Tooltip>
                                    <Tooltip title='Generate new password'><Button onClick={() => this.generateNewPass(user)} className='action -pass' variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>loop</Icon></Button></Tooltip>
                                    <Tooltip title='Logs'><Button onClick={() => this.showLogWindow(true, user.id)} className='action -pass' variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>list_alt</Icon></Button></Tooltip>
                                    <Tooltip title='Update'><Button onClick={() => this.showDataForUpdate(user.id)} className='action -pass' variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>edit</Icon></Button></Tooltip>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Drawer>
        )
    }
}

export default Users;