import React, { Component } from 'react';
import { Button, TextField, Tooltip, Icon } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
    getLogsById,
} from '../../../service';

import './log.scss';

class LogWindow extends Component {
    constructor(props) {
        super(props)

        this.state = {
            logs: {},
            loaded: false
        };

        this.resetComp = this.resetComp.bind(this);
    }

    componentDidUpdate() {
        const { userId } = this.props;
        const { loaded } = this.state;

        if(!userId || loaded)
            return false;

        getLogsById(userId).then(resp => {
            const { log } = resp.data;

            this.setState({ logs: log, loaded: true });
        })
    }

    resetComp() {
        this.setState({ logs: {}, loaded: false });
    }

  render() {
    const {
        open,
        event,
        close
    } = this.props;

    const {
        logs
    } = this.state;

    return (
        <Dialog style={{minWidth: '700px'}}
          open={open}
          onClose={() => { this.resetComp(); close(false, null); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">Logs for the last 2 months</DialogTitle>
          <DialogContent style={{padding: "0px 30px 0px 20px"}}>
            <div>
                {Object.keys(logs).map((key) => {
                    const log = logs[key];

                    return (
                        <ul key={key}>
                            <h3><Tooltip title='week - month - year'><span>{key}</span></Tooltip></h3>

                            {log.map(lg => {
                                return <li className='log_item' key={lg.time}>{`Time: ${lg.time}, Action: ${lg.action}, IP: ${lg.ip} `}</li>
                            })}
                        </ul>
                    )
                })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.resetComp(); close(false, null)}} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

export default LogWindow;