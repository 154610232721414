// popup
export const OPEN_MESSAGE_POPUP = 'OPEN_MESSAGE_POPUP';
export const CLOSE_MESSAGE_POPUP = 'CLOSE_MESSAGE_POPUP';
export const EDIT_MESSAGE_POPUP = 'EDIT_MESSAGE_POPUP';
export const EDIT_MESSAGE = "EDIT_MESSAGE";

// auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// notification
export const OPEN_NOTIFICATION = 'OPEN_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';

// loading
export const OPEN_LOADING = 'OPEN_LOADING';
export const CLOSE_LOADING = 'CLOSE_LOADING';

// help
export const OPEN_HELP = 'OPEN_HELP';
export const CLOSE_HELP = 'CLOSE_HELP';

// menu
export const CHANGE_MENU_TYPE = 'CHANGE_MENU_TYPE';
