import React from 'react';
import { Button } from '@material-ui/core';
// import PlacesAutocomplete, {
//   geocodeByAddress
// } from 'react-places-autocomplete';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';
import { findTelcoAddress, getAddressByBuildingId } from '../../../service';

import './address.scss';

class LocationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
      isLoading: false,
      enteredAddress: '',
      addressError: '',
      isSuggestionsOpen: false,
      telcoOperators: [],
    };

    this.onAddressSelect = this.onAddressSelect.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.loadAddress = this.loadAddress.bind(this);
    this.timerHandle = null;
  }

  loadAddress(value) {
    if(!value) {
      value = this.state.enteredAddress;
    }

    if(!value) {
      return;
    }
    
    this.setState({ isLoading: true });

    if (value.length > 0) {
      findTelcoAddress(value, 10).then(resp => {
        if(resp.data.addresses) {
          this.setState({ addresses: resp.data.addresses });
          this.setState({ isSuggestionsOpen: true });
          this.setState({ addressError: '' });
          this.setState({ isLoading: false });
          this.setState({ telcoOperators: [] });

        } else if (!resp.data.addresses && resp.data.streets) {
          this.setState({ addresses: resp.data.streets });
          this.setState({ isSuggestionsOpen: true });
          this.setState({ addressError: '' });
          this.setState({ isLoading: false });
          this.setState({ telcoOperators: [] });

        }

      }).catch((error) => {
        if (error) {
          this.setState({ addressError: error.response.data.message });
          this.setState({ isSuggestionsOpen: false });
          this.setState({ telcoOperators: [] });
          this.setState({ isLoading: false });
        }
      })
    }
  }

  onAddressSelect(address) {
    this.setState({ isLoading: true });
    this.props.setAddress(address);
    
    if(this.timerHandle){
      window.clearTimeout(this.timerHandle);
    }

    if(address && address.components && address.components.building && address.components.building.id) {
    this.setState({ isLoading: true });
      getAddressByBuildingId(address.components.building.id).then(resp => {
        console.log("RESP", resp)
        this.setState({ telcoOperators: resp.data.carriers });
      this.setState({ isLoading: false });
      }).catch((error) => {
        if (error) {
          console.log(error)
        }
      })
    } else {
      this.setState({ addressError: 'Please enter the building number' });
      this.setState({ telcoOperators: [] });
      this.setState({ isLoading: false });
    }
  }

  onInputChange(newInputValue) {
    if(this.timerHandle){
      window.clearTimeout(this.timerHandle);
    }
    if (newInputValue) {
      this.setState({ enteredAddress: newInputValue });
      console.log('newInputValue', newInputValue);
    }

    this.timerHandle = window.setTimeout(() => {    // ***
      this.loadAddress()                // ***
    }, 300);  
  }

  render() {
    const {
      addresses,
      isLoading,
      enteredAddress,
      addressError,
      isSuggestionsOpen,
      telcoOperators,
    } = this.state;

    return (
      <div className="autocomplete__wrapper">
        <div className={!addressError ? "autocomplete__input" : "autocomplete__input--error"}>
          <Autocomplete
            open={isSuggestionsOpen}
            onClose={() => this.setState({ isSuggestionsOpen: false })}
            clearOnBlur={false}
            disableClearable
            className="autocompleteAdress"
            onChange={(event, value) => this.onAddressSelect(value)}
            onInputChange={(event, newInputValue) => this.onInputChange(newInputValue)}
            id="disable-close-on-select"
            options={addresses}
            autoComplete
            getOptionLabel={option => option.address ? option.address : option.street}
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                label="INDIRIZZO DI PRELIEVO"
                variant="outlined"
                size="small"
                className="autocompleteAdress__input"
                onKeyPress={(ev) => {
                  console.log(`Pressed keyCode ${ev.key}`);
                  if (ev.key === 'Enter') {
                    this.loadAddress(enteredAddress)
                    ev.preventDefault();
                  }
                }}
              />
            )}
          />
          {addressError && <div className="errorMessage">{addressError}</div>}
        </div>
        <Button className="autocomplete__btn" onClick={() => this.loadAddress(enteredAddress)}>Check Copertura
          {isLoading && <CircularProgress style={{ 'color': 'white', 'marginLeft': "10px" }} size="15px" />}
        </Button>


        <div className="telcoOperators__wrapper">
          {/* {isLoading && !telcoOperators &&  <div className="telcoOperators__loadingOverlay">
            <CircularProgress />
          </div>} */}
          {telcoOperators.length > 1 &&
            telcoOperators.map(x => {
              return (
                <a href={x.url} target="_blank" className="telcoOperators__operator">
                  <img src={x.img} />
                  <div className="telcoOperators__operatorName">{x.name.charAt(0).toUpperCase() + x.name.slice(1)}</div>
                  <div>ADSL: {x.adsl}</div>
                  <div>FTTC: {x.fttc}</div>
                  <div>FWA: {x.fwa}</div>
                </a>
              )
            })
          }
        </div>
      </div>
    );
  }
}

export default LocationSearch;

function getCity(place) {
  const COMPONENT_TEMPLATE = { locality: 'long_name' };
  let city = getAddrComponent(place, COMPONENT_TEMPLATE);

  if (!city && place.address_components[0])
    city = place.address_components[0].long_name;

  return city;
}

function getPostalCode(place) {
  const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
    postalCode = getAddrComponent(place, COMPONENT_TEMPLATE);
  return postalCode;
}

function isGooglePlace(place) {
  if (!place)
    return false;
  return !!place.place_id;
}

function getAddrComponent(place, componentTemplate) {
  let result;
  if (!isGooglePlace(place))
    return;
  for (let i = 0; i < place.address_components.length; i++) {
    const addressType = place.address_components[i].types[0];
    if (componentTemplate[addressType]) {
      result = place.address_components[i][componentTemplate[addressType]];
      return result;
    }
  }
  return;
}