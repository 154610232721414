import React, { Component } from 'react';

import './loading.scss';

class Loading extends Component {
    render() {
        const { text, open } = this.props;

        return(
            <div className={open ? 'load_section' : 'hide'}>
                <div className='background'></div>
                <div className='content'>
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                    <div>{text}</div>
                </div>
            </div>
        )
    }
}

export default Loading;