import React, { Component } from 'react';
import { Button, Tooltip, GridList, TextField, Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

import './operationAdmin.scss';

import store from '../../../../store';
import {   
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
    } from '../../../../actions/actions';

import RemoveDialog from '../../../../components/dialogs/removePopup';

import { 
    getTelcoAllSuppliers,
    getTelcoAllOperations,
    updateTelcoOperation,
    removeTelcoOperation,
    baseUrlUploads
} from './../../../../service';

import PopupScript from '../../../../components/dialogs/script';

import OperationScript from '../../operationScript/';

class OperationAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            supplier_id: null,
            operations: [],
            operation: null,
            dialogRemove: false,

            switch: {
                resid: true,
                business: false
            },

            cambio_mobile_resid: '',  
            cambio_linea_fissa_resid: '',      
            cambio_mobile_linea_fissa_resid: '', 
            nuova_attivazione_resid: '',        

            cambio_mobile_business: '',             
            cambio_linea_fissa_business: '',        
            cambio_mobile_linea_fissa_business: '', 
            nuova_attivazione_business: '',

            nds_resid: '',
            nds_business: '',

            loading: true,

            statusPopupScript: false,
            selectedScript: null,
            selectScriptText: ''
        }

        this.switchTab = this.switchTab.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.updateOperation = this.updateOperation.bind(this);
        this.removeOperation = this.removeOperation.bind(this);
        this.askCancel = this.askCancel.bind(this);
        this.askRemove = this.askRemove.bind(this);

        this.openPopupScript = this.openPopupScript.bind(this);
        this.closePopupScript = this.closePopupScript.bind(this);
        this.changeTextScript = this.changeTextScript.bind(this);
        this.updateOperationField = this.updateOperationField.bind(this);
    }

    componentWillMount() {
        const suppliersPromise = getTelcoAllSuppliers();
        const operationsPromise = getTelcoAllOperations();

        store.dispatch({ type: OPEN_LOADING, text: 'Loading operations...'})

        Promise.all([suppliersPromise, operationsPromise]).then(result => {
            const suppliers = result[0].data.suppliers;
            const operations = result[1].data.operations;

            this.setState({ suppliers, operations, loading: false });
            store.dispatch({ type: CLOSE_LOADING })

        }).catch( err => {
            console.log(err);
            store.dispatch({ type: OPEN_LOADING, text: `Can't load operations`})

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING })
            }, 2500);

            this.setState({ loading: false, err: true });
        });
    }

    switchTab() {
        let { switch: { resid, business } } = this.state;

        resid= !resid;
        business = !business;

        this.setState({ switch: { resid, business } });
    }

    selectSupplier(id) {
        let { suppliers, operations } = this.state;

        let theSame = false;

        suppliers.map(supplier => {
            if(supplier.id == id) {
                supplier.selected = !supplier.selected;
                theSame = supplier.selected;
            } else if(supplier.selected) {
                supplier.selected = false;
            }

            return supplier;
        });

        let operation = operations.find( operation => operation.supplier_id == id);

        for(let key in operation) {
            if(operation[key] == null)
                operation[key] = '';
        }


        if(theSame)
            this.setState({ supplier_id: id, ...operation, operation });
        else
            this.setState({ supplier_id: null, operation: null });
    }

    changeValue(name, value) {
        const state = this.state;

        state[name] = value;

        this.setState(state);
    }

    updateOperation() {
        const {
            supplier_id,
            cambio_mobile_resid,  
            cambio_linea_fissa_resid,      
            cambio_mobile_linea_fissa_resid, 
            nuova_attivazione_resid,        

            cambio_mobile_business,             
            cambio_linea_fissa_business,        
            cambio_mobile_linea_fissa_business, 
            nuova_attivazione_business,   

            nds_resid,
            nds_business,

            operation: { id }
        } = this.state;

        const data = {
            supplier_id,
            cambio_mobile_resid,  
            cambio_linea_fissa_resid,      
            cambio_mobile_linea_fissa_resid, 
            nuova_attivazione_resid,        

            cambio_mobile_business,             
            cambio_linea_fissa_business,        
            cambio_mobile_linea_fissa_business, 
            nuova_attivazione_business, 

            nds_resid,
            nds_business,
        }

        store.dispatch({ type: OPEN_LOADING, text: 'Updating the operation...' });

        updateTelcoOperation(id, data).then(result => {
            store.dispatch({ type: CLOSE_LOADING });
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Operation been updated', open: true})
        }).catch(err => {
            console.log(err);
            store.dispatch({ type: OPEN_LOADING, text: `Can't update the operation` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    updateOperationField(name, value) {
        const other = ['a_attivazione_power_resid', 'a_attivazione_gas_resid', 'a_attivazione_power_business', 'a_attivazione_gas_business'];
        const obj = {};
        let fieldName = name;

        if(other.some(key => key == name)) {
            fieldName = '1'+fieldName;
        };

        obj[fieldName] = value;

        const id = this.state.id;
    
        updateTelcoOperation(id, obj).then(resp => {
            console.log(resp);
        })
    }

    removeOperation() {
        const { operations, operation: { id }} = this.state;
        const operationsFiltered = operations.filter(operation => operation.id != id);

        this.setState({ dialogRemove: false, operation: null, operations: operationsFiltered });

        removeTelcoOperation(id).then(result => {
            store.dispatch({ type: OPEN_NOTIFICATION, text: 'Operation has been removed'})
        }).catch(err => {
            console.log(err);
            this.setState({ operations });
        })
    };

    askRemove() {
        this.setState({ dialogRemove: true });
    }

    askCancel() {
        this.setState({ dialogRemove: false });
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_telco' });
    }

    getSupplier(id) {
        return this.state.suppliers.find(sup => sup.id == id);
    }

    openPopupScript(selectedScript, text) {
        this.setState({ statusPopupScript: true, selectedScript, selectScriptText: text });
    }

    closePopupScript() {
        this.setState({ statusPopupScript: false, selectScript: null });
    }

    changeTextScript(name, text) {
        const state = this.state;

        state[name] = text;

        this.updateOperationField(name, text);

        this.setState(state);
    }
    
    render() {
        let supplier = null;
        const { suppliers, operations, operation, supplier_id, dialogRemove } = this.state;
        const { resid, business } = this.state.switch;
        const {open: loading} = store.getState().loading;

        const {
            cambio_mobile_resid,  
            cambio_linea_fissa_resid,      
            cambio_mobile_linea_fissa_resid, 
            nuova_attivazione_resid,        

            cambio_mobile_business,             
            cambio_linea_fissa_business,        
            cambio_mobile_linea_fissa_business, 
            nuova_attivazione_business, 

            nds_resid,
            nds_business,


            statusPopupScript,
            selectedScript,
            selectScriptText
        } = this.state;

        if(supplier_id) 
            supplier = this.getSupplier(supplier_id);
        
        return (
            <div className={loading ? 'hide' : 'operation_section'}>
                <Link to='/new' supplier_id={supplier_id}>
                    <Box sx={{width: 'fit-content', marginLeft: 'auto'}}>
                        <Tooltip title='Add new'>
                            <Button className='add_button'><span className='add_button--plusSign'>+</span><span>add new</span></Button>
                        </Tooltip>
                    </Box>
                </Link>
                <Tooltip title='Help'>
                    <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>
                <RemoveDialog open={dialogRemove} remove={this.removeOperation} close={this.askCancel}/>

                {
                    statusPopupScript && <PopupScript
                        open={statusPopupScript} 
                        selectedScript={selectedScript} 
                        comfirm={this.changeTextScript}
                        handleClose={this.closePopupScript}
                        text={selectScriptText}
                        />
                }

                { operation 
                    ?
                        <div className='actions'>
                            <div className='action -edit' onClick={this.updateOperation}><Icon className='offer_icon'>edit_icon</Icon></div>
                            <div className='action -remove' onClick={this.askRemove}><Icon className='offer_icon'>delete</Icon></div>
                        </div>
                    : '' }
                <div className='content anim_content'>
                    <div className='supplier_list'>
                        <div className='saparetor'>SUPPLIERS</div>
                        <div className='content' >
                            <GridList cols={0} id='grid-scroll'>
                                {
                                    suppliers.map(supplier => {
                                        const { id, name, selected, img } = supplier;

                                        return (
                                            <div key={id} className='supplier'>
                                                <div className='actions'>
                                                    <div className='background'></div>
                                                    { !selected 
                                                        ? <div onClick={() => this.selectSupplier(id)} className='action -select'>Select</div>
                                                        : <div onClick={() => this.selectSupplier(id)} className='action -cancel'>Cancel</div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                    {img 
                                                    ? <div className='block_img'>
                                                        <img className='image'src={baseUrlUploads+img}/>
                                                     </div> 
                                                    : <div className='text_block'>
                                                        {name}
                                                     </div>
                                                    }
                                                    { selected ? <div className='selected'></div> : '' }
                                                </div>
                                            </div>
                                        )
                                    })
                                
                                }
                            </GridList>
                        </div>
                    </div>

                    { operation 
                        ?
                        <div className='main_section'>
                            <div className="comperatore__switcher">
                                <div className={resid ? "comperatore__switcherTab--active" : "comperatore__switcherTab"} onClick={this.switchTab}>Business</div>
                                <div className={!resid ? "comperatore__switcherTab--active" : "comperatore__switcherTab"} onClick={this.switchTab}>Resid</div>
                            </div>

                            <div className='fields_section'>
                                {resid ? // RESIDENT
                                    <div className={'operations -resid'}>
                                        <div className='fields anim_content'>
                                            <OperationScript
                                                className={supplier && supplier.cambio_mobile ? 'show_operation' : 'disabled_operation'}
                                                title={'Cambio mobile'}
                                                text={cambio_mobile_resid}
                                                edit={() => this.openPopupScript('cambio_mobile_resid', cambio_mobile_resid)}
                                            />
                                            <OperationScript
                                                className={supplier && supplier.cambio_linea_fissa ? 'show_operation' : 'disabled_operation'}
                                                title={'Cambio linea fissa'}
                                                text={cambio_linea_fissa_resid}
                                                edit={() => this.openPopupScript('cambio_linea_fissa_resid', cambio_linea_fissa_resid)}
                                            />
                                        </div>
                                        <div className='fields anim_content'>
                                            <OperationScript
                                                className={supplier && supplier.cambio_mobile_linea_fissa ? 'show_operation' : 'disabled_operation'}
                                                title={'Cambio mobile linea fissa'}
                                                text={cambio_mobile_linea_fissa_resid}
                                                edit={() => this.openPopupScript('cambio_mobile_linea_fissa_resid', cambio_mobile_linea_fissa_resid)}
                                            />
                                            <OperationScript
                                                className={supplier && supplier.nuova_attivazione ? 'show_operation' : 'disabled_operation'}
                                                title={'Nuova attivazione resid'}
                                                text={nuova_attivazione_resid}
                                                edit={() => this.openPopupScript('nuova_attivazione_resid', nuova_attivazione_resid)}
                                            />
                                        </div>
                                        <div className='fields anim_content'>
                                            <OperationScript
                                                className='show_operation'
                                                title={'NDS'}
                                                text={nds_resid}
                                                edit={() => this.openPopupScript('nds_resid', nds_resid)}
                                            />
                                        </div>
                                    </div>
                                : // BUSINESS
                                    <div className='operations -business'>
                                        <div className='fields anim_content2'>
                                            <OperationScript
                                                className={supplier && supplier.cambio_mobile ? 'show_operation' : 'disabled_operation'}
                                                title={'Cambio Mobile'}
                                                text={cambio_mobile_business}
                                                edit={() => this.openPopupScript('cambio_mobile_business', cambio_mobile_business)}
                                            />
                                            <OperationScript
                                                className={supplier && supplier.cambio_linea_fissa ? 'show_operation' : 'disabled_operation'}
                                                title={'Cambio Linea Fissa'}
                                                text={cambio_linea_fissa_business}
                                                edit={() => this.openPopupScript('cambio_linea_fissa_business', cambio_linea_fissa_business)}
                                            />
                                        </div>
                                        <div className='fields anim_content'>
                                            <OperationScript
                                                className={supplier && supplier.cambio_mobile_linea_fissa ? 'show_operation' : 'disabled_operation'}
                                                title={'Cambio Mobile Linea Fissa'}
                                                text={cambio_mobile_linea_fissa_business}
                                                edit={() => this.openPopupScript('cambio_mobile_linea_fissa_business', cambio_mobile_linea_fissa_business)}
                                            />
                                            <OperationScript
                                                className={supplier && supplier.nuova_attivazione ? 'show_operation' : 'disabled_operation'}
                                                title={'Nuova Attivazione'}
                                                text={nuova_attivazione_business}
                                                edit={() => this.openPopupScript('nuova_attivazione_business', nuova_attivazione_business)}
                                            />
                                        </div>
                                        <div className='fields anim_content'>
                                            <OperationScript
                                                className='show_operation'
                                                title={'NDS'}
                                                text={nds_business}
                                                edit={() => this.openPopupScript('nds_business', nds_business)}
                                            />
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>
                        : 
                        <div className='info_section'>
                            { supplier_id 
                                ? <div>The supplier does't have associated operation</div>
                                : <div>Please, select supplier</div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default OperationAdmin;

const CheckUsed = (fields, filter) => fields.some(field => field == filter);