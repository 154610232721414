import { 
    OPEN_LOADING, 
    CLOSE_LOADING 
} from '../actions/actions';

export default (state = { open: false, text: null }, action) => {
    switch(action.type) {
        case OPEN_LOADING: 
            return {
                ...state,
                open: true,
                text: action.text
            }

        case CLOSE_LOADING:
            return {
                ...state,
                open: false,
                text: null
            }

        default:
            return state;
    }
}