import React, { Component } from 'react';
import { Button, Drawer } from '@material-ui/core';
import classNames from 'classnames';
import Dropzone from 'react-dropzone'

import { updadFile } from './../../../service';

import store from '../../../store';
import { notifications } from '../../../actions/notifications';

import './remi.scss';

class Remi extends Component {
    constructor() {
        super()

        this.state = { 
            file: null,
            error: '',
            disabledButton: false
        }

        this.upload = this.upload.bind(this);
    }

    upload() {
        const data = this.state.file;

        this.setState({ disabledButton: true });

        const file = new FormData();

        file.append('file', data, data.name);

        updadFile(file).then(resp => {
            const data = resp.data;
            let error = '';

            error += 'Success created: '+ data.successCreated;
            error += ', Amount: ' + data.amount;
            error += ', Wrong fields: ' + data.lengthWrong;
            error += ', Amount error: ' + data.amountError;

            this.setState({ error, disabledButton: false });
            store.dispatch(notifications.sendNotification('Uploading REMI is done!'))

        }).catch(err => {
            this.setState({ disabledButton: false });
            store.dispatch(notifications.sendNotification('Uploading REMI is failed!'))
        })
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        const file = acceptedFiles[0];

        if(file && file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            this.setState({ file });
    }

    render() {
        const {
            open,
            component,
            close
        } = this.props;

        const {
            file,
            error,
            disabledButton
        } = this.state;

        return (
            <Drawer open={open} onClose={close} anchor="bottom" className=''>
                { !disabledButton ? <div className='remi_section'>
                    <div className='help_text'>
                        <p className='head'>
                            { !error ? 'To process a file you need to have 16 required fields and in the same order' : error}
                        </p>
                        <span className='fields'>
                        { !error ? 'cod_selectra, cap, regione, state, city, gas_utility, luce_utility, cabine_remi_presenti_nel_comune, remi, wekiwi, sorgenia, energrid, ovenergy, illumia, eviva, iren': ''}
                        </span>
                    </div>
                    <Dropzone onDrop={this.onDrop}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <div
                            {...getRootProps()}
                            className={classNames('dropzone', {'dropzone --isActive': isDragActive})}
                            >
                                <div className='upload_file'>
                                    <input {...getInputProps()} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'/>
                                    {
                                        isDragActive ?
                                            <div className='drop_section'>
                                                drop
                                            </div>
                                        : !file ?
                                            <div className='try_drop_section'>Try dropping some files here, or click to select files to upload.</div>
                                            : <div className='droped_file'>{file.name}</div>
                                    }
                                </div>
                            </div>
                        )
                        }}
                    </Dropzone>
                    <div className='actions'>
                        <Button className={file && !disabledButton ? 'button': 'button -disabled'} onClick={this.upload}>Upload</Button>
                    </div>
                </div>
                : <div className='remi_section -processing'>
                    <div className="loading">
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            }
            </Drawer>
        )
    }
}

export default Remi;