import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import moment from 'moment';

// table 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { getComperatoreData } from '../../service';


const ComperatoreAgentEneBusiness = (props) => {

    const [monthNumber, setMonthNumber] = useState();
    const [offerDetails, setOfferDetails] = useState();
    const [potenza, setPotenza] = useState();
    const [iva, setIva] = useState('');
    const [tariffType, setTariffType] = useState();

    const [f0, setF0] = useState(0);
    const [f1, setF1] = useState(0);
    const [f2, setF2] = useState(0);
    const [f3, setF3] = useState(0);

    const [calcResult, setCalcResult] = useState({});

    const [offers, setOffers] = useState([]);
    const [btas, setBtas] = useState([]);
    const [comperatoreEne, setComperatoreEne] = useState({});

    useEffect(() => {
        if (!props.offers) return;

        let filteredOffers = props.offers.filter(offer => {

            const {
                client_type,
                type_product,
            } = offer;
            if (client_type === "business" && type_product === "luce") return offer;
        })
        setOffers(filteredOffers);
    }, [props.offers])

    useEffect(() => {
        getComperatoreData().then(result => {
            const { bta, comperatoreEne } = result.data.payload;

            const preparedBtas = [];

            bta.data.map(bta => {
                for( let key in bta) {
                    if(listToTransform.some(k => k === key)) {
                        bta[key] = bta[key].replaceAll(',', '.')
                    }
                }

                preparedBtas.push(bta);
            })

            comperatoreEne['perdite_di_rete'] = comperatoreEne['perdite_di_rete'].replace(',', '.');

            
    
            setBtas(preparedBtas);
            setComperatoreEne(comperatoreEne)
        }).catch(err => {
              console.log("error", err)
        })
    }, [])

    useEffect(() => {
        const preparedData = {
            monthNumber,
            offerDetails,
            potenza,
            iva,
            tariffType,
            f0,
            f1,
            f2,
            f3
        };

        if(!monthNumber || !offerDetails || !potenza || !iva || !tariffType)
            return false;

        const result = calculate(preparedData, comperatoreEne, btas)

        console.log(result);

        setCalcResult(result);

    }, [
        monthNumber,
        offerDetails,
        potenza,
        iva,
        tariffType,
        f0,
        f1,
        f2,
        f3
    ]);

    const {
        dettaglio_offerta = 0,
        spesa_materia = 0,
        spesa_transporto = 0,
        oneri_di_sistema = 0,
        imposte_iva = 0,
        totale_senza_iva = 0,
        totale_con_iva = 0,
        totale_base_annua = 0
    } = calcResult;


    const withCommma = (number) => {
        let result = number.toFixed(2).toString().replace(".", ",");
        return result
    } 

    return (
        <section className="comperatore__section">
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Numero mesi Bolletta
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={monthNumber}
                            label="Numero mesi Bolletta"
                            onChange={(e) => setMonthNumber(e.target.value)}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Lista Offerte
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={offerDetails}
                            label="Lista Offerte"
                            onChange={(e) => setOfferDetails(e.target.value)}
                        >
                            {offers.length > 0 ? offers.map(offer => {
                                return (
                                    <MenuItem value={offer}>{offer.offer_name}</MenuItem>
                                )
                            }) : <MenuItem>No offers</MenuItem>}
                        </Select>
                    </FormControl>
                </div>
            </Box>
            {/* <Box sx={{ margin: '10px 0' }}> */}
                <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    className="comperatore__input--long"
                    label="Potenza Impegnata"
                    margin="normal"
                    value={potenza}
                    onChange={(e) => setPotenza(e.target.value)}
                />
            {/* </Box> */}

            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Iva Agevolata
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={iva}
                            label="Iva Agevolata"
                            onChange={(e) => setIva(e.target.value)}
                        >
                            <MenuItem value={'si'}>Si</MenuItem>
                            <MenuItem value={'no'}>No</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className='comperatore__selectInput'>
                    <FormControl size='small' fullWidth>
                        <InputLabel
                            id="demo-simple-select-label">
                            Tipo di Tariffa
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={tariffType}
                            label="Tipo di Tariffa"
                            onChange={(e) => setTariffType(e.target.value)}
                        >
                            <MenuItem value={'mono'}>Monoraria</MenuItem>
                            <MenuItem value={'bio'}>Bioraria</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </Box>


            {/* This field should show only if Tipo di Tariffa chosen is Monoraria  */}
            {/* <Box sx={{ margin: '10px 0' }}> */}
                <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    className="comperatore__input--long"
                    label="Consumi Fatturati in F0"
                    margin="normal"
                    disabled={tariffType != "mono"}
                    value={f0}
                    onChange={(e) => setF0(e.target.value)}
                />
            {/* </Box> */}

            {/* This fields should show only if Tipo di Tariffa chosen is Bioraria  */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
                <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    className="comperatore__input--long"
                    label="F1"
                    margin="normal"
                    disabled={tariffType != 'bio'}
                    value={f1}
                    onChange={(e) => setF1(e.target.value)}

                />
                <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    className="comperatore__input--long"
                    label="F2"
                    margin="normal"
                    disabled={tariffType != 'bio'}
                    value={f2}
                    onChange={(e) => setF2(e.target.value)}

                />
                <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    className="comperatore__input--long"
                    label="F3"
                    margin="normal"
                    disabled={tariffType != 'bio'}
                    value={f3}
                    onChange={(e) => setF3(e.target.value)}
                />
            </Box>

            <Box sx={{ margin: '50px 0', display: 'flex' }}>
                { monthNumber && iva && offerDetails && tariffType &&  
                <Box sx={{ marginRight: '50px' }}>
                    <TableContainer component={Paper} sx={{ width: 450 }}>
                        <Table sx={{ minWidth: 250 }} size="small" aria-label="simple table">
                            <TableHead className="comperatore__tableHead">
                                <TableRow>
                                    <TableCell>Dettaglio Della Bolletta</TableCell>
                                    <TableCell align="right">{offerDetails ? offerDetails.offer_name : '-'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key='1'
                                    className="accentRow--blue"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span className="accentText">Dettaglio Offerta (cts/kWh)</span>
                                    </TableCell>
                                    <TableCell align="right"><span className="accentText">€ {withCommma(dettaglio_offerta)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='2'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        Validità Offerta
                                    </TableCell>
                                    <TableCell align="right">{moment(offerDetails.exp_date).format('DD/MM/YYYY')}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='3'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Spesa Materia Energia
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(spesa_materia)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='4'
                                    className='accentRow--blue'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span className="accentText">Spesa Trasporto e gestione del contatore</span>
                                    </TableCell>
                                    <TableCell align="right"><span className="accentText">€ {withCommma(spesa_transporto)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='1'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Oneri di Sistema
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(oneri_di_sistema)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='5'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Imposte e IVA
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(imposte_iva)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='6'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    Totale senza Iva
                                    </TableCell>
                                    <TableCell align="right">€ {withCommma(totale_senza_iva)}</TableCell>
                                </TableRow>
                                <TableRow
                                    key='7'
                                    className='accentRow--orange'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span className='accentText'>Totale con Iva</span>
                                    </TableCell>
                                    <TableCell align="right"><span className='accentText'>€ {withCommma(totale_con_iva)}</span></TableCell>
                                </TableRow>
                                <TableRow
                                    key='8'
                                    className='accentRow--lightAccent  borderTop'
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                    <span className='accentText'>Totale su base annua</span>
                                    </TableCell>
                                    <TableCell align="right"><span className='accentText'>€ {withCommma(totale_base_annua)}</span></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                }
            </Box>

        </section>
    )
};


const calculate = (formData, manageData, btas) => {
    const {
        // Energia
        pf_l0_f0 = 0, // AK18
        pf_l0_f1 = 0.0311, // AL18
        pf_l0_f2 = 0.03565, // AM18
        pf_l0_f3 = 0.02301, // AN18

        pf_l1_f0 = 0, // AK19
        pf_l1_f1 = 0.03168, // AL19
        pf_l1_f2 = 0.03424, // AM19
        pf_l1_f3 = 0.02328, // AN19

        // Rete
        pf_l2_f0 = 0, // AK20
        pf_l2_f1 = 0.03508, // AL20
        pf_l2_f2 = 0.03683, // AM20
        pf_l2_f3 = 0.02652, // AN20

        // BUSINESS
        perdite_di_rete, // O39
    } = manageData;

    const {
        monthNumber,
        offerDetails,
        potenza,
        iva,
        tariffType,
        f0,
        f1,
        f2,
        f3

    } = formData;

    const {
        price_f0,
        price_f1,
        price_f2,
        price_f3,
        price_pcv,
        dispbt_fissa
    } = offerDetails;

    console.log(offerDetails, )

    let codeBta = null;

    if(potenza <= 1.5) {
        codeBta = 'BTA1.1';
    } else if(potenza <= 3) {
        codeBta = 'BTA1.2';
    } else if(potenza <= 6) {
        codeBta = 'BTA1.3';
    } else if(potenza <= 10) {
        codeBta = 'BTA1.4';
    } else if(potenza > 10) {
        codeBta = 'BTA2.0'
    }

    const usedBta = btas.find(bta => bta.code === codeBta);
    
    if(!usedBta)
        return {}

    const {
        ene_kwh, // AC26
        ene_kw, // AC27
        ene_pcv, // AC28
        ene_disp, // AC29

        rete_reti, // AC31
        rete_sist, // AC32
        rete_kw, // AC33
        rete_reti_pod, // AC34
        rete_oneri, // AC35

        imposte_kwh, // AC37
        imposte_kw // AC38
    } = usedBta;

    console.log(usedBta);

    // AJ41
    const PE = sumProduct([price_f0, price_f1, price_f2, price_f3], [f0,f1,f2,f3]) / (+f0 + +f1 + +f2 + +f3);
    // AJ42
    const PE_predite = PE * perdite_di_rete / 100;
    // AJ43
    const PE_altro = ene_kwh;
    // AJ44
    const PE_total = +PE + +PE_predite + +PE_altro;

    const PE_disp = +dispbt_fissa;

    console.log(PE, PE_predite, PE_altro, PE_total);

    // main table
    // N4
    const dettaglio_offerta = +price_f0 * 100;

    // I15
    const PCV = +price_pcv + (PE_disp / 12);
    
    // N6
    const spesa_materia = (tariffType === 'mono' ? +f0 * PE_total : (+f1 + +f2 + +f3) * PE_total) + (+PCV * monthNumber);

    // N7
    const spesa_transporto = (tariffType === 'mono' ? +rete_reti * +f0 : (+f1 + +f2 + +f3) * rete_reti) + (potenza * rete_kw / 12 * monthNumber);

    // N8
    const oneri_di_sistema = (tariffType === 'mono' ? +rete_sist * +f0 : (+f1 + +f2 + +f3) * rete_sist);

    const step2 = (+f0 <= 200 ? (+f0 / 12) * (monthNumber * imposte_kwh) : (+f0 / 12) * (monthNumber * imposte_kw));
    const step1 = (tariffType === 'mono' ? (iva === 'si' ? (+spesa_materia + +spesa_transporto + +oneri_di_sistema) * 10 / 100 : (+spesa_materia + +spesa_transporto + +oneri_di_sistema) * 22 / 100) + step2 : iva === 'si' ? ((+spesa_materia + +spesa_transporto + +oneri_di_sistema) * 10 / 100) : ((+spesa_materia + +spesa_transporto + +oneri_di_sistema) * 22 / 100));
    const step3 = iva === 'si' ? (+dettaglio_offerta + +spesa_transporto + +oneri_di_sistema) * 10 / 100 : (+dettaglio_offerta + +spesa_transporto + +oneri_di_sistema) * 22 / 100
    const step4 = ((+f0 + +f1 + +f2 + +f3) <= 20000 ? ((+f0 + +f1 + +f2 + +f3) / 12) * (monthNumber * +imposte_kwh) : ((+f0 + +f1 + +f2 + +f3) / 12) * (monthNumber * +imposte_kw));

    console.log(step1, step2, step3, step4, step2 + step3)
    
    console.log((+spesa_materia + +spesa_transporto + +oneri_di_sistema) * 22 / 100, '(+dettaglio_offerta + +spesa_transporto + +oneri_di_sistema) * 22 / 100', +spesa_materia, +spesa_transporto, +oneri_di_sistema, 22, 100)

    // IF(D8="monoraria";IF(D11="si";SUM(N6:N8)*10%;IF(D11="no";SUM(N6:N8)*22%;))+(IF(E18<=200;(E18/12)*(D2*AC37);IF(E18>200;(E18/12)*(D2*AC38))));IF(D11="si";SUM(N6:N8)*10%;IF(D11="no";SUM(N6:N8)*22%;)))

    // N9
    const imposte_iva = step1 + step4;

    // =IF(D8="monoraria";IF(D11="si";SUM(N6:N8)*10%;IF(D11="no";SUM(N6:N8)*22%;))+(IF(E18<=200;(E18/12)*(D2*AC37);IF(E18>200;(E18/12)*(D2*AC38))));IF(D11="si";SUM(N6:N8)*10%;IF(D11="no";SUM(N6:N8)*22%;)))+(IF(SUM(E18:H18)<=200000;(SUM(E18:H18)/12)*(D2*AC37);IF(SUM(E18:H18)>200000;(SUM(E18:H18)/12)*(D2*AC38))))

    // N10
    const totale_senza_iva = (+spesa_materia + +spesa_transporto + +oneri_di_sistema) + ((+f0 + +f1 + +f2 + +f3) <= 20000 ? ((+f0 + +f1 + +f2 + +f3) / 12) * (monthNumber * +imposte_kwh) : ((+f0 + +f1 + +f2 + +f3) / 12) * (monthNumber * +imposte_kw))

    // N11
    const totale_con_iva = (+spesa_materia + +spesa_transporto + +oneri_di_sistema + +imposte_iva);

    const totale_base_annua = +totale_con_iva / monthNumber * 12

    console.log(dettaglio_offerta, spesa_materia, spesa_transporto, oneri_di_sistema, imposte_iva, totale_senza_iva, totale_con_iva, totale_base_annua);

    return {
        dettaglio_offerta,
        spesa_materia,
        spesa_transporto,
        oneri_di_sistema,
        imposte_iva,
        totale_senza_iva,
        totale_con_iva,
        totale_base_annua
    }
}


const sumProduct = (arr, arr2) => {
    let sum = 0;

    arr.map((el, index) => {
        const result = el * arr2[index];

        sum += result;
    })

    return sum;
}

export default ComperatoreAgentEneBusiness;

const listToTransformComp = [
    'perdite_di_rete'
];

const listToTransform = [
    'ene_kwh', // AC26
    'ene_kw', // AC27
    'ene_pcv', // AC28
    'ene_disp', // AC29

    'rete_reti', // AC31
    'rete_sist', // AC32
    'rete_kw', // AC33
    'rete_reti_pod', // AC34
    'rete_oneri', // AC35

    'imposte_kwh', // AC37
    'imposte_kw', // AC38
]