import {
    OPEN_HELP,
    CLOSE_HELP
} from '../actions/actions';

export default (state = { open: false, component: null }, action) => {
    switch(action.type) {
        case OPEN_HELP:
            return {
                ...state,
                open: true, 
                component: action.component
            };

        case CLOSE_HELP:
            return {
                ...state,
                open: false,
                component: null
            };

        default:
            return state;
    }
}