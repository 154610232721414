import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import store from '../../../store';

import OperationAdmin from './admin/OperationAdmin';
import OperationUser from './user/OperationUser';

class OperationList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { role } = store.getState().auth;
        
        return (
            role == 'admin' ? <OperationAdmin /> : <OperationUser/>
        )
    }
}

export default OperationList;