import React, { useState, useEffect } from 'react';
import { 
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import ReactQuill from 'react-quill';

import { addProposalScript, updateProposalScript } from '../../../service';

const BottomMessagePopup = (props) => {

    const [isEditMode, setIsEditMode] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState({});
    const [text, setText]= useState('');

    useEffect(() => {
        props.open && setIsEditMode(false);
        if(Object.keys(props.selectedMessage).length != 0) {
            setSelectedMessage(props.selectedMessage)
            setIsEditMode(true);
            setText(props.selectedMessage.text)
        }
    }, [props.open])

    const changeInput = (value) => {
        setText(value)
    }

    const onClose = () => {
        props.handleClose()
        setText('')
        console.log('closed')
    }

    const updateScript = async () => {
        const data = {
            text: text, 
            part: 'energia'
        };

        if(text === selectedMessage.text) return;
        const res = await updateProposalScript(selectedMessage.id, data)
        console.log('updateProposalScript RES', res)
        onClose()
        props.updateProposalScript({id: selectedMessage.id, text: text});
    }

    const addScripts = async () => {

        const data = {
            text: text, 
            part: 'energia'
        };

        if(!text) return;
        const res = await addProposalScript(data);
        console.log('addProposalScript RES', res.data.script);
        onClose()
        props.addPropposalScript(res.data.script);
    }

  return (
    <Dialog
                    open={props.open}
                    onClose={onClose}
                    aria-labelledby="form-dialog-title"
                    classes={{
                        paper: 'visible-item',
                      }}
                    >
                    <DialogTitle id="form-dialog-title">{isEditMode ? 'Edit message' : 'Add message'}</DialogTitle>
                    <DialogContent style={style.content} className='vissible-item'>
                        <ReactQuill style={{height: '250px'}}
                            modules={Editor.modules}
                            formats={Editor.formats}
                            theme={"snow"}
                            onChange={changeInput}
                            defaultValue={text}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                        Cancel
                        </Button>
                        {isEditMode ? 
                        <Button onClick={updateScript} color="primary">
                        Update
                        </Button>
                        : 
                        <Button onClick={addScripts} color="primary">
                        Add
                        </Button>
                        }
                    </DialogActions>
                </Dialog>
  )
}

export default BottomMessagePopup

const style = {
    content: {
        width: '550px',
        height: '400px',
        minheight: '270px',
        overflow: 'visible' 
    }
}

const Editor = {
    modules: {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'formula'],
          
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            [{ 'direction': 'rtl' }],                         // text direction
          
          
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'align': [] }],
          
            ['clean'] 
        ],
      },
    
      formats: [
        'header',
        'color',
        'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ],
}
