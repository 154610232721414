import React, { Component } from 'react';
import { 
    TextField,
    Button,
    MenuItem,
    GridList,
    Icon,
    Tooltip
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import store from '../../../store';
import {   
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
    } from '../../../actions/actions';

import { getAllSuppliers, createOperation, getAllOperations, baseUrlUploads } from './../../../service';

import PopupScript from '../../dialogs/script';
import OperationScript from '../operationScript/';

import './newOperation.scss';

class NewOperation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suppliers: [],
            supplier_id: null,

            redirect: false,

            switch: {
                resid: true,
                business: false
            },

            allacciamento_power_resid: '',
            subentro_power_resid: '',
            '1a_attivazione_power_resid': '',
            cambio_fornitore_power_resid: '',
            cambio_con_voltura_power_resid: '',
            allacciamento_gas_resid: '',
            subentro_gas_resid: '',
            '1a_attivazione_gas_resid': '',
            cambio_fornitore_gas_resid: '',
            cambio_con_voltura_gas_resid: '',
            allacciamento_power_business: '',
            subentro_power_business: '',
            '1a_attivazione_power_business': '',
            cambio_fornitore_power_business: '',
            cambio_con_voltura_power_business: '',
            allacciamento_gas_business: '',
            subentro_gas_business: '',
            '1a_attivazione_gas_business': '',
            cambio_fornitore_gas_business: '',
            cambio_con_voltura_gas_business: '',

            taglio_colonna_power_resid: '',
            contatore_rimosso_power_resid: '',
            chiuso_per_morosita_power_resid: '',

            taglio_colonna_power_business: '',
            contatore_rimosso_power_business: '',
            chiuso_per_morosita_power_business: '',

            taglio_colonna_gas_resid: '',
            contatore_rimosso_gas_resid: '',
            chiuso_per_morosita_gas_resid: '',

            taglio_colonna_gas_business: '',
            contatore_rimosso_gas_business: '',
            chiuso_per_morosita_gas_business: '',

            statusPopupScript: false,
            selectedScript: null,
            selectScriptText: ''
        };

        this.switchTab = this.switchTab.bind(this);
        this.selectSupplier = this.selectSupplier.bind(this);
        this.createOperation = this.createOperation.bind(this);
        this.openPopupScript = this.openPopupScript.bind(this);
        this.closePopupScript = this.closePopupScript.bind(this);
        this.changeTextScript = this.changeTextScript.bind(this);
    }

    componentWillMount() {
        const suppliersPromise = getAllSuppliers();
        const operationsPromise = getAllOperations();

        store.dispatch({ type: OPEN_LOADING, text: 'Loading suppliers...' });

        Promise.all([suppliersPromise, operationsPromise]).then(result => {
            let suppliers = result[0].data.suppliers;
            const operations = result[1].data.operations;


            suppliers = suppliers.filter(sup => {
                if(!operations.some(operation => sup.id == operation.supplier_id))
                    return sup;
            })

            this.setState({ suppliers, operations, loading: false });
            store.dispatch({ type: CLOSE_LOADING });
        }).catch( err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't load suppliers` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);

            this.setState({ loading: false, err: true });
        });
    }

    changeValue(name, value) {
        const state = this.state;

        state[name] = value;

        this.setState(state);
    }

    switchTab() {
        let { switch: { resid, business } } = this.state;

        resid= !resid;
        business = !business;

        this.setState({ switch: { resid, business } });
    }

    selectSupplier(id) {
        const suppliers = this.state.suppliers;
        let theSame = false;

        suppliers.map(supplier => {
            if(supplier.id == id) {
                supplier.selected = !supplier.selected;
                theSame = supplier.selected;

                return supplier;
            } else if(supplier.selected) {
                supplier.selected = false;

                return supplier;
            }

            return supplier;
        });

        if(theSame)
            this.setState({ supplier_id: id });
        else
            this.setState({ supplier_id: null });

    }

    getSupplier(id) {
        return this.state.suppliers.find(sup => sup.id == id);
    }

    createOperation() {
        const {
            supplier_id,
            allacciamento_power_resid,
            subentro_power_resid,
            '1a_attivazione_power_resid': a_attivazione_power_resid,
            cambio_fornitore_power_resid,
            cambio_con_voltura_power_resid,
            allacciamento_gas_resid,
            subentro_gas_resid,
            '1a_attivazione_gas_resid': a_attivazione_gas_resid,
            cambio_fornitore_gas_resid,
            cambio_con_voltura_gas_resid,
            allacciamento_power_business,
            subentro_power_business,
            '1a_attivazione_power_business': a_attivazione_power_business,
            cambio_fornitore_power_business,
            cambio_con_voltura_power_business,
            allacciamento_gas_business,
            subentro_gas_business,
            '1a_attivazione_gas_business': a_attivazione_gas_business,
            cambio_fornitore_gas_business,
            cambio_con_voltura_gas_business,

            taglio_colonna_power_resid,
            contatore_rimosso_power_resid,
            chiuso_per_morosita_power_resid,

            taglio_colonna_power_business,
            contatore_rimosso_power_business,
            chiuso_per_morosita_power_business,

            taglio_colonna_gas_resid,
            contatore_rimosso_gas_resid,
            chiuso_per_morosita_gas_resid,

            taglio_colonna_gas_business,
            contatore_rimosso_gas_business,
            chiuso_per_morosita_gas_business,
        } = this.state;

        const data = {
            supplier_id,
            allacciamento_power_resid,
            subentro_power_resid,
            a_attivazione_power_resid,
            cambio_fornitore_power_resid,
            cambio_con_voltura_power_resid,
            allacciamento_gas_resid,
            subentro_gas_resid,
            a_attivazione_gas_resid,
            cambio_fornitore_gas_resid,
            cambio_con_voltura_gas_resid,
            allacciamento_power_business,
            subentro_power_business,
            a_attivazione_power_business,
            cambio_fornitore_power_business,
            cambio_con_voltura_power_business,
            allacciamento_gas_business,
            subentro_gas_business,
            a_attivazione_gas_business,
            cambio_fornitore_gas_business,
            cambio_con_voltura_gas_business,

            taglio_colonna_power_resid,
            contatore_rimosso_power_resid,
            chiuso_per_morosita_power_resid,

            taglio_colonna_power_business,
            contatore_rimosso_power_business,
            chiuso_per_morosita_power_business,

            taglio_colonna_gas_resid,
            contatore_rimosso_gas_resid,
            chiuso_per_morosita_gas_resid,

            taglio_colonna_gas_business,
            contatore_rimosso_gas_business,
            chiuso_per_morosita_gas_business,
        }

        delete data.a_attivazione_power_resid;
        delete data.a_attivazione_gas_resid;
        delete data.a_attivazione_power_business;
        delete data.a_attivazione_gas_business;

        data['1a_attivazione_power_resid'] = a_attivazione_power_resid;
        data['1a_attivazione_gas_resid'] = a_attivazione_gas_resid;
        data['1a_attivazione_power_business'] = a_attivazione_power_business;
        data['1a_attivazione_gas_business'] = a_attivazione_gas_business;

        store.dispatch({ type: OPEN_LOADING, text: 'Creating new operation...' });

        createOperation(data).then(result => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Operation been created', open: true})
            store.dispatch({ type: CLOSE_LOADING });

            this.setState({ redirect: true });
        }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't create operation` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    openPopupScript(selectedScript, text) {
        this.setState({ statusPopupScript: true, selectedScript, selectScriptText: text });
    }

    closePopupScript() {
        this.setState({ statusPopupScript: false, selectScript: null });
    }

    changeTextScript(name, text) {
        const state = this.state;

        state[name] = text;

        this.setState(state);
    }

    render() {
        let supplier = null;
        const {open: loading} = store.getState().loading;

        const { resid, business } = this.state.switch;
        const {
            allacciamento_power_resid,
            subentro_power_resid,
            '1a_attivazione_power_resid': a_attivazione_power_resid,
            cambio_fornitore_power_resid,
            cambio_con_voltura_power_resid,
            allacciamento_gas_resid,
            subentro_gas_resid,
            '1a_attivazione_gas_resid': a_attivazione_gas_resid,
            cambio_fornitore_gas_resid,
            cambio_con_voltura_gas_resid,
            allacciamento_power_business,
            subentro_power_business,
            '1a_attivazione_power_business': a_attivazione_power_business,
            cambio_fornitore_power_business,
            cambio_con_voltura_power_business,
            allacciamento_gas_business,
            subentro_gas_business,
            '1a_attivazione_gas_business': a_attivazione_gas_business,
            cambio_fornitore_gas_business,
            cambio_con_voltura_gas_business,

            taglio_colonna_power_resid,
            contatore_rimosso_power_resid,
            chiuso_per_morosita_power_resid,

            taglio_colonna_power_business,
            contatore_rimosso_power_business,
            chiuso_per_morosita_power_business,

            taglio_colonna_gas_resid,
            contatore_rimosso_gas_resid,
            chiuso_per_morosita_gas_resid,

            taglio_colonna_gas_business,
            contatore_rimosso_gas_business,
            chiuso_per_morosita_gas_business,

            statusPopupScript,
            selectedScript,
            selectScriptText,

            // new 
            nds_gas_resid,
            nds_gas_business
        } = this.state;

        const {suppliers, redirect, supplier_id} = this.state;

        if(redirect)
            return <Redirect to='/' />

        if(supplier_id) 
            supplier = this.getSupplier(supplier_id);

        return (
            <div className={loading ? 'hide' : 'operation_section anim_content'}>
                <Tooltip title='Help'>
                    <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>
                {
                    statusPopupScript && <PopupScript
                        open={statusPopupScript} 
                        selectedScript={selectedScript} 
                        comfirm={this.changeTextScript}
                        handleClose={this.closePopupScript}
                        text={selectScriptText}
                        />
                }
                <div className='suppliers_block'></div>
                <div className='supplier_list'>
                        <div className='saparetor'>SUPPLIER</div>
                        <div className='content' >
                            <GridList cols={0} id='grid-scroll'>
                                {
                                    suppliers.map(supplier => {
                                        const { id, name, selected, img } = supplier;

                                        return (
                                            <div key={id} className='supplier'>
                                                <div className='actions'>
                                                    <div className='background'></div>
                                                    { !selected 
                                                        ? <div onClick={() => this.selectSupplier(id)} className='action -select'>Select</div>
                                                        : <div onClick={() => this.selectSupplier(id)} className='action -cancel'>Cancel</div>
                                                    }
                                                </div>
                                                <div className='content'>
                                                    {img 
                                                    ? <div className='block_img'>
                                                        <img className='image'src={baseUrlUploads+img}/>
                                                     </div> 
                                                    : <div className='text_block'>
                                                        {name}
                                                     </div>
                                                    }
                                                    { selected ? <div className='selected'></div> : '' }
                                                </div>
                                            </div>
                                        )
                                    })
                                
                                }
                            </GridList>
                        </div>
                    </div>
                { resid 
                    ? 
                    <div className={supplier ? 'switch' : 'hide'}>
                        <div className='button -left selected'>Resid</div>
                        <div onClick={this.switchTab} className='button -right no-selected'>Business</div>
                    </div>
                    :
                    <div className={supplier ? 'switch' : 'hide'}>
                        <div onClick={this.switchTab} className='button -left no-selected'>Resid</div>
                        <div className='button -right selected'>Business</div>
                    </div>
                }

                <div className={!supplier ? 'select_supplier' : 'hide'}>Please, select supplier</div>


                
                <div className={supplier ? 'content' : 'hide'}>
                    { resid ? // RESIDENT
                        <div className={'operations -resid'}>
                            <div className='saparetor'>LUCE</div>
                            <div className='fields anim_content'>
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.allacciamento) ? 'show_operation' : 'disabled_operation'}
                                    title={'Allacciamento'}
                                    text={allacciamento_power_resid}
                                    edit={() => this.openPopupScript('allacciamento_power_resid', allacciamento_power_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.prima_attivazione) ? 'show_operation' : 'disabled_operation'}
                                    title={'1a Attivazione'}
                                    text={a_attivazione_power_resid}
                                    edit={() => this.openPopupScript('1a_attivazione_power_resid', a_attivazione_power_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.subentro) ? 'show_operation' : 'disabled_operation'}
                                    title={'Subentro'}
                                    text={subentro_power_resid}
                                    edit={() => this.openPopupScript('subentro_power_resid', subentro_power_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.cambio_fornitore) ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio fornitore'}
                                    text={cambio_fornitore_power_resid}
                                    edit={() => this.openPopupScript('cambio_fornitore_power_resid', cambio_fornitore_power_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.cambio_fornitore_con_voltura) ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio con voltura'}
                                    text={cambio_con_voltura_power_resid}
                                    edit={() => this.openPopupScript('cambio_con_voltura_power_resid', cambio_con_voltura_power_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.taglio_colonna) ? 'show_operation' : 'disabled_operation'}
                                    title={'Taglio colonna'}
                                    text={taglio_colonna_power_resid}
                                    edit={() => this.openPopupScript('taglio_colonna_power_resid', taglio_colonna_power_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.contatore_rimosso) ? 'show_operation' : 'disabled_operation'}
                                    title={'Contatore rimosso'}
                                    text={contatore_rimosso_power_resid}
                                    edit={() => this.openPopupScript('contatore_rimosso_power_resid', contatore_rimosso_power_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.chiuso_per_morosita) ? 'show_operation' : 'disabled_operation'}
                                    title={'Allacciamento temporaneo'}
                                    text={chiuso_per_morosita_power_resid}
                                    edit={() => this.openPopupScript('chiuso_per_morosita_power_resid', chiuso_per_morosita_power_resid)}
                                    />
                            </div>

                            <div className='saparetor'>GAS</div>
                            <div className='fields anim_content'>
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.allacciamento) ? 'show_operation' : 'disabled_operation'}
                                    title={'Allacciamento'}
                                    text={allacciamento_gas_resid}
                                    edit={() => this.openPopupScript('allacciamento_gas_resid', allacciamento_gas_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.prima_attivazione) ? 'show_operation' : 'disabled_operation'}
                                    title={'1a Attivazione'}
                                    text={a_attivazione_gas_resid}
                                    edit={() => this.openPopupScript('1a_attivazione_gas_resid', a_attivazione_gas_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.subentro) ? 'show_operation' : 'disabled_operation'}
                                    title={'Subentro'}
                                    text={subentro_gas_resid}
                                    edit={() => this.openPopupScript('subentro_gas_resid', subentro_gas_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.cambio_fornitore) ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio fornitore'}
                                    text={cambio_fornitore_gas_resid}
                                    edit={() => this.openPopupScript('cambio_fornitore_gas_resid', cambio_fornitore_gas_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.cambio_fornitore_con_voltura) ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio con voltura'}
                                    text={cambio_con_voltura_gas_resid}
                                    edit={() => this.openPopupScript('cambio_con_voltura_gas_resid', cambio_con_voltura_gas_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.taglio_colonna) ? 'show_operation' : 'disabled_operation'}
                                    title={'Taglio colonna'}
                                    text={taglio_colonna_gas_resid}
                                    edit={() => this.openPopupScript('taglio_colonna_gas_resid', taglio_colonna_gas_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.contatore_rimosso) ? 'show_operation' : 'disabled_operation'}
                                    title={'Contatore rimosso'}
                                    text={contatore_rimosso_gas_resid}
                                    edit={() => this.openPopupScript('contatore_rimosso_gas_resid', contatore_rimosso_gas_resid)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.chiuso_per_morosita) ? 'show_operation' : 'disabled_operation'}
                                    title={'Allacciamento temporaneo'}
                                    text={chiuso_per_morosita_gas_resid}
                                    edit={() => this.openPopupScript('chiuso_per_morosita_gas_resid', chiuso_per_morosita_gas_resid)}
                                    />
                            </div>

                            <div className='saparetor'>GAS</div>
                            <div className='fields anim_content2'>

                                <OperationScript
                                    className='show_operation'
                                    title={'NDS'}
                                    text={nds_gas_resid}
                                    edit={() => this.openPopupScript('nds_gas_resid', nds_gas_resid)}
                                    />
                            </div>
                        </div>
                        : // BUSINESS
                        <div className='operations -business'>
                            <div className='saparetor'>LUCE</div>
                            <div className='fields anim_content2'>
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.allacciamento) ? 'show_operation' : 'disabled_operation'}
                                    title={'Allacciamento'}
                                    text={allacciamento_power_business}
                                    edit={() => this.openPopupScript('allacciamento_power_business', allacciamento_power_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.prima_attivazione) ? 'show_operation' : 'disabled_operation'}
                                    title={'1a Attivazione'}
                                    text={a_attivazione_power_business}
                                    edit={() => this.openPopupScript('1a_attivazione_power_business', a_attivazione_power_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.subentro) ? 'show_operation' : 'disabled_operation'}
                                    title={'Subentro'}
                                    text={subentro_power_business}
                                    edit={() => this.openPopupScript('subentro_power_business', subentro_power_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.cambio_fornitore) ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio fornitore'}
                                    text={cambio_fornitore_power_business}
                                    edit={() => this.openPopupScript('cambio_fornitore_power_business', cambio_fornitore_power_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.cambio_fornitore_con_voltura) ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio con voltura'}
                                    text={cambio_con_voltura_power_business}
                                    edit={() => this.openPopupScript('cambio_con_voltura_power_business', cambio_con_voltura_power_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.taglio_colonna) ? 'show_operation' : 'disabled_operation'}
                                    title={'Taglio colonna'}
                                    text={taglio_colonna_power_business}
                                    edit={() => this.openPopupScript('taglio_colonna_power_business', taglio_colonna_power_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.contatore_rimosso) ? 'show_operation' : 'disabled_operation'}
                                    title={'Contatore rimosso'}
                                    text={contatore_rimosso_power_business}
                                    edit={() => this.openPopupScript('contatore_rimosso_power_business', contatore_rimosso_power_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['luce', 'dual'], supplier.chiuso_per_morosita) ? 'show_operation' : 'disabled_operation'}
                                    title={'Allacciamento temporaneo'}
                                    text={chiuso_per_morosita_power_business}
                                    edit={() => this.openPopupScript('chiuso_per_morosita_power_business', chiuso_per_morosita_power_business)}
                                    />
                            </div>

                            <div className='saparetor'>GAS</div>
                            <div className='fields anim_content2'>
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.allacciamento) ? 'show_operation' : 'disabled_operation'}
                                    title={'Allacciamento'}
                                    text={allacciamento_gas_business}
                                    edit={() => this.openPopupScript('allacciamento_gas_business', allacciamento_gas_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.prima_attivazione) ? 'show_operation' : 'disabled_operation'}
                                    title={'1a Attivazione'}
                                    text={a_attivazione_gas_business}
                                    edit={() => this.openPopupScript('1a_attivazione_gas_business', a_attivazione_gas_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.subentro) ? 'show_operation' : 'disabled_operation'}
                                    title={'Subentro'}
                                    text={subentro_gas_business}
                                    edit={() => this.openPopupScript('subentro_gas_business', subentro_gas_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.cambio_fornitore) ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio fornitore'}
                                    text={cambio_fornitore_gas_business}
                                    edit={() => this.openPopupScript('cambio_fornitore_gas_business', cambio_fornitore_gas_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.cambio_fornitore_con_voltura) ? 'show_operation' : 'disabled_operation'}
                                    title={'Cambio con voltura'}
                                    text={cambio_con_voltura_gas_business}
                                    edit={() => this.openPopupScript('cambio_con_voltura_gas_business', cambio_con_voltura_gas_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.taglio_colonna) ? 'show_operation' : 'disabled_operation'}
                                    title={'Taglio colonna'}
                                    text={taglio_colonna_gas_business}
                                    edit={() => this.openPopupScript('taglio_colonna_gas_business', taglio_colonna_gas_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.contatore_rimosso) ? 'show_operation' : 'disabled_operation'}
                                    title={'Contatore rimosso'}
                                    text={contatore_rimosso_gas_business}
                                    edit={() => this.openPopupScript('contatore_rimosso_gas_business', contatore_rimosso_gas_business)}
                                    />
                                <OperationScript
                                    className={supplier && CheckUsed(['gas', 'dual'], supplier.chiuso_per_morosita) ? 'show_operation' : 'disabled_operation'}
                                    title={'Allacciamento temporaneo'}
                                    text={chiuso_per_morosita_gas_business}
                                    edit={() => this.openPopupScript('chiuso_per_morosita_gas_business', chiuso_per_morosita_gas_business)}
                                    />
                            </div>

                            <div className='saparetor'>GAS</div>
                            <div className='fields anim_content2'>

                                <OperationScript
                                    className='show_operation'
                                    title={'NDS'}
                                    text={nds_gas_business}
                                    edit={() => this.openPopupScript('nds_gas_business', nds_gas_business)}
                                    />
                            </div>
                        </div>
                    }
                </div>
                    <div className={supplier ? 'submit' : 'hide'}>
                        <Button style={ supplier_id ? style.createButton : style.createButtonDissabled } disabled={ !supplier_id } onClick={this.createOperation}>Create</Button>
                    </div>
            </div>
        )
    }
}

export default NewOperation;

const style = {
    createButton: {
        width: '200px',
        height: '40px',
        background: '#417BC3',
        color: 'white'
    },

    createButtonDissabled: {
        width: '200px',
        height: '40px',
        background: '#8090a396',
        color: 'white'
    }
}

const CheckUsed = (fields, filter) => fields.some(field => field == filter);