import React, { Component } from 'react';
import { Button, Icon, Tooltip } from '@material-ui/core';
import { Link, HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import renderHTML from 'react-render-html'

import './index.scss';

import store from '../../../store';

class OfferScript extends Component {
    constructor(props) {
        super(props)
    }


    render() {
        const { role } = store.getState().auth;
        const { title, text, edit } = this.props;

        return(
            <div className='offer_script'>
                {role === 'admin' && <div className='actions'>
                    <Tooltip title='Edit'><Button onClick={edit} className='actionBtn actionBtn--edit' variant="fab" mini aria-label="Edit"><Icon className='editIcon'>edit_icon</Icon></Button></Tooltip>
                </div>}
                <div className='head'>{title}</div>
                <div className='content'>
                    {renderHTML(text || '')}
                </div>
            </div>
        )
    }
}

export default OfferScript;