import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import OperationList from './operationList/OperationList';
import NewOperation from './newOperation/NewOperation';

import store from '../../store';

class Operation extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        const { role } = store.getState().auth;

        return (
            <div>
                <HashRouter basename='energia/operations'>
                    <Switch>
                        <Route path='/new' render={ props => {
                            return (role == 'admin') ?
                                <NewOperation/>
                                : 
                                <Redirect to='/' />
                        }}/>
                        <Route path='/:id' render={ props => {
                            return (role == 'admin') ?
                                <NewOperation/>
                                : 
                                <Redirect to='/' />
                        }}/>
                        <Route path='/' component={ OperationList }/>
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}

export default Operation;