import React, { Component } from 'react';
import { 
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

import './upload.scss';

class UploadImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadedImage: null,
            croppedImageUrl: null,
            crop: {
                x: 13,
                y: 13,
                aspect: 1.66,
                width: 50,
            }
        }

        this.selectFile = this.selectFile.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.getCroppedImg = this.getCroppedImg.bind(this);
        this.cropImage = this.cropImage.bind(this);
    }

    cropImage() {
        this.props.cropedFile(this.state.croppedImageUrl);
    }

    selectFile(e) {
        const files = e.target.files;

        if(!files && files.length >! 0)
            return false;

        const reader = new FileReader();
        reader.addEventListener('load', () => { 
            this.setState({ uploadedImage: reader.result })
            console.log(reader.result, 'result');
        });
        reader.readAsDataURL(files[0]);
    }

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    };
    
    onCropComplete = async (crop, pixelCrop) => {
        if (crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(this.imageRef, pixelCrop, 'newFile.jpeg');
          this.setState({ croppedImageUrl });
        }
      };
    
    onCropChange = crop => {
        this.setState({ crop });
    };
    
    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    render() {
        const { uploadedImage, crop, croppedImageUrl } = this.state;
        const { open, close } = this.props;
        
        return(
            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Upload Image</DialogTitle>
                <DialogContent style={style.content} className='body_upload'>
                    <div id='perview'>
                    {uploadedImage && (
                        <ReactCrop
                            src={uploadedImage}
                            crop={crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                      />
                        )}
                    <div className='upload'>
                        Click to select file
                        <input className='select_button' type='file' onChange={this.selectFile}/>
                    </div>
                    {croppedImageUrl && <img alt="Crop" className='testimage' src={croppedImageUrl} />}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={this.cropImage} color="primary">
                    Select
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default UploadImage;

const style = {
    content: {
        width: '600px',
        height: '600px',
        minheight: '270px',
        overflow: 'hidden',
        boxSizing: 'border-box'
    }
}