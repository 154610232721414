import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import renderHTML from 'react-render-html'

import {
    OPEN_NOTIFICATION,
} from '../../../actions/actions';

import './index.scss'

import { baseUrlUploads } from './../../../service';

import store from '../../../store';

class PopupInfo extends Component {
    constructor(props) {
        super(props);

        this.closePopup = this.closePopup.bind(this);
    }

    closePopup() {
        this.props.handleClose();
    }

    render() {
        const { offer } = this.props;

        console.log(offer, 'offer');

        const {
            id,
            client_type,
            offer_name,
            offer_code,
            exp_date,
            duration,
            sdd_obblig,
            type_product,
            price_gas,
            price_f0,
            price_f1,
            price_f2,
            price_f3,
            price_pcv,
            monthly_fee,
            gas_description,
            ee_description,
            dual,
            Supplier: supplier,
         } = offer;

        let operationQueryLuce = '';
        let operationQueryGas = '';
        let clientType = type_product;

        let strPayments = '';

        if(offer) {
            if(offer.rid)
                strPayments += 'Rid';
        
            if(offer.bollettino)
                strPayments += (strPayments) ? ', Bollettino': 'Bollettino';

            if(offer.bonifico)
                strPayments += (strPayments) ? ', Bonifico': 'Bonifico';

            if(offer.carta_credito)
                strPayments += (strPayments) ? ', Carta credito': 'Carta credito';

            if(!strPayments)
                strPayments = 'Not assigned';
        }

        const date = new Date(exp_date);
        const day = (date.getDate() < 10) ? '0' + date.getDate(): date.getDate();
        const month = (date.getMonth()+1 < 10) ? '0' + (date.getMonth() + 1): date.getMonth()+1;

        const formatedDate = `${day}/${month}/${date.getFullYear()}`;

        const offerClass = ' -today'
        
        return(
            <Dialog
                open={this.props.open}
                onClose={this.closePopup}
                maxWidth='lg'
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Offer info</DialogTitle>
                <DialogContent style={style.content} className='vissible-item number_popup_section'>
                    <div className='popup_offer_info'>
                        <div className='list_offer'>
                            <div className='head'>
                                <div className='field -name'>Offer Name</div>
                                <div className='field -valid'>Exp. Date</div>
                                <div className='field -payment'>Payment Type</div>
                                <div className='field -supplier'>Supplier Name</div>
                            </div>
                            <div className={'offer' + offerClass} key={id}>
                                <div className='head' onClick={() => this.showOffer(offer)}>
                                    <div className='field -name'>{offer_name}</div>
                                    <div className='field -valid'>{formatedDate}</div>
                                    <div className='field -payment'>{strPayments}</div>
                                    <div className='field -supplier'>
                                    <div className='offer_supplier'>
                                        <div className='sup_name'>{supplier ? supplier.name : ''}</div>
                                        { (supplier && supplier.img) && <div className='logo_supplier_section'>
                                          <div className='logo_supplier'>
                                              <img src={baseUrlUploads + supplier.img}/>
                                          </div>
                                          <div className='tooltip_image'>
                                            <img src={baseUrlUploads + supplier.img}/>
                                          </div>
                                        </div> }
                                      </div>
                                    </div>
                                </div>
                                { <div className='body'>
                                    <div className='section -main'>
                                        <div className='field -fornitore'><span className='text'>Fornitore: </span> {supplier ? supplier.name : ''}</div>
                                        <div className='field -energia'><span className='text'>Energia: </span>{type_product}</div>
                                        <div className='field -codince'><span className='text'>Codice: </span>{offer_code}</div>
                                        <div className='field -tipo_cliente'><span className='text'>Tipo cliente: </span>{client_type}</div>
                                    </div>
                                    <div className={(type_product == 'luce' || type_product == 'dual' ? 'section -price_luce': 'hide')}>
                                        <div className='field -f0'><span className='text'>F0: </span>{price_f0?.replace('.', ',')} €</div>
                                        <div className='field -f1'><span className='text'>F1: </span>{price_f1?.replace('.', ',')} €</div>
                                        <div className='field -f2'><span className='text'>F2: </span>{price_f2?.replace('.', ',')} €</div>
                                        <div className='field -f3'><span className='text'>F3: </span>{price_f3?.replace('.', ',')} €</div>
                                        <div className='field -PCV'><span className='text'>PCV: </span>{price_pcv?.replace('.', ',')} €</div>
                                    </div>
                                    <div className={(type_product == 'gas' || type_product == 'dual' ? 'section -price_gas': 'hide')}>
                                        <div className='field -gas'><span className='text'>Gas: </span>{price_gas?.replace('.', ',')} €</div>
                                        <div className='field -fee'><span className='text'>Month fee: </span>{monthly_fee?.replace('.', ',')} €</div>
                                    </div>
                                    <div className='section -terms'>
                                        <div className='field -validita'><span className='text'>Validita: </span>{formatedDate}</div>
                                        <div className='field -durata'><span className='text'>Durata: </span>{duration} mese</div>
                                        <div className='field -ssd'><span className='text'>SSD: </span>{sdd_obblig}</div>
                                    </div>
                                    <div className='section -script'>
                                        <div className={type_product == 'luce' ? 'field -script_luce' : 'hide'}>
                                            <h3 className='header'>Offerta Luce</h3>
                                            <div className='text_script'>{renderHTML(ee_description || '')} </div>
                                        </div>
                                        <div className={type_product == 'gas' ? 'field -script_gas' : 'hide'}>
                                            <h3 className='header'>Offerta Gas</h3>
                                            <div className='text_script'>{renderHTML(gas_description || '')} </div>
                                        </div>
                                        <div className={type_product == 'dual' ? 'field -script_dual' : 'hide'}>
                                            <h3 className='header'>Offerta Dual</h3>
                                            <div className='text_script'>{renderHTML(dual || '')} </div>
                                        </div>
                                    </div>

                                    <div className='section -script'>
                                        <div className='field -script_luce'>
                                            <h3 className='header'>Fornitore e Servizi Aggiuntivi</h3>
                                            <div className='text_script'>{(supplier && supplier.description) ? supplier.description : <div className='text_center'>Empty</div>} </div>
                                        </div>
                                    </div>
                                </div> }
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                    Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default PopupInfo;

const style = {
    content: {
        width: '960px',
        // height: '270px',
        minHeight: '270px'
    }
}