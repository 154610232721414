import React, { Component } from 'react';
import { Button, Tooltip, IconButton, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';

import MessageList from './messagesList/messagesList';
import PopupMessage from './popupMessage/popupMessage';
import DeialogRemove from '../../components/dialogs/removePopup';
import { getTelcoMessages, removeTelcoMessage } from '../../service';
import store from '../../store';

import {
    OPEN_MESSAGE_POPUP,
    EDIT_MESSAGE_POPUP,
    CLOSE_MESSAGE_POPUP,
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP,
    CLOSE_HELP
   
} from '../../actions/actions';

import './telcoHome.scss';

const mapStateToProps = state => ({
    ...state,
    open: state.open,
    text: state.text,
    title: state.title,
    id: state.id,
});

const mapDispatchToProps = dispatch => ({
    openPopup: () => {
        return dispatch({type: OPEN_MESSAGE_POPUP})
    },
    closePopup: () => {
        return dispatch({type: CLOSE_MESSAGE_POPUP})
    },
    editPopup: (id, text, title) => {
        return dispatch({type: EDIT_MESSAGE_POPUP, id, text, title });
    }
});

class TelcoHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: null,
            openDialogRemove: false
        }

        this.updateMessage = this.updateMessage.bind(this);
        this.addMessage = this.addMessage.bind(this);
        this.removeMessage = this.removeMessage.bind(this);
        this.askRemove = this.askRemove.bind(this);
        this.closeRemove = this.closeRemove.bind(this);
    }

    componentWillMount() {
        store.dispatch({type: OPEN_LOADING, text: 'Loading messages...', open: true});
        
        getTelcoMessages().then(result => {
            const messages = result.data.messages;

            this.setState({ messages });
            store.dispatch({ type: CLOSE_LOADING });
        }).catch(err => {
            store.dispatch({type: OPEN_LOADING, text: `Can't load messages, please check server status`, open: true});

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 25000)
        })
    }

    updateMessage(id, text, title) {
        const messages = this.state.messages.map(message => {

            if(message.id == id) {
                message.text = text;
                message.title = title;
            }

            return message;
        })

        this.setState({ messages });
    }

    addMessage(message) {
        const messages = this.state.messages;

        messages.push(message);

        this.setState({ message });
        this.props.closePopup();
    }

    askRemove(id) {
        this.setState({openDialogRemove: true, removeId: id});
    }

    closeRemove() {
        this.setState({openDialogRemove: false, removeId: null});
    }

    removeMessage() {
        const { messages, removeId: id } = this.state;
        this.closeRemove();

        removeTelcoMessage(id)
            .then(reslut => {
            }).catch(err => {
                this.setState({ messages });

                store.dispatch({type: OPEN_LOADING, text: `Can't remove the message` });
            })

        const removedMessage = messages.filter(message => message.id != id);

        this.setState({ messages: removedMessage });
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_telco' });
    }

    render() {
        const { id = '', text = '', title = '', open } = this.props.popup;
        const { role } = this.props.auth;

        const { openDialogRemove } = this.state;

        return (
            <div className='home_content'>
                { role == 'admin' ? (
                        <div>
                            <Box sx={{width: 'fit-content', marginLeft: 'auto'}}>
                                <Tooltip title='Add new'>
                                    <Button className='add_button' onClick={this.props.openPopup}><span className='add_button--plusSign'>+</span><span>add new</span></Button>
                                </Tooltip>
                            </Box>
                            <Tooltip title='Help'>
                                <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                            </Tooltip>
                            <PopupMessage handleClose={this.props.closePopup} addMessage={this.addMessage} updateMessage={this.updateMessage} id={id} text={text} title={title} open={open}/>
                            <DeialogRemove open={openDialogRemove} remove={this.removeMessage} close={this.closeRemove}/>
                            <MessageList editMessage={this.props.editPopup} removeMessage={this.askRemove} messages={this.state.messages}/>
                        </div>
                    ) 
                    : ( 
                        <MessageList editMessage={this.props.editPopup} removeMessage={this.removeMessage} messages={this.state.messages}/>
                     )}
            </div>
        )
    }
}

const HomeCon = connect(
    mapStateToProps,
    mapDispatchToProps
  )(TelcoHome);

export default HomeCon;