
import React, { Component } from 'react';
import { 
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

class ZohoPopup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            open,
            handleClose
        } = this.props;

        return(
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                classes={{
                    paper: 'visible-item',
                  }}
                >
                <DialogTitle id="form-dialog-title">Message</DialogTitle>
                <DialogContent className='vissible-item'>
                    <p className='zoho_popup'>Utilizza Zoho per aprire l'OPP</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                    Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ZohoPopup;
