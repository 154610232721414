import React, { Component } from 'react';
import {
    Button,
    Tooltip,
    Icon,
    Radio,
    MenuItem
} from '@material-ui/core';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import renderHTML from 'react-render-html'
import { Link, Redirect } from 'react-router-dom';
import Popup from './popup/popup';
import BottomMessagesPopup from './popup/bottomMessagePopup';
import DeialogRemove from '../dialogs/removePopup';

import ZohoPopup from './popup/useZoho';
import PopupLink from './popupLink';
import LocationSearch from './address/address';
import { parse } from 'query-string';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
    OPEN_HELP,
    CLOSE_HELP
} from '../../actions/actions';

import {
    getAllOffers, 
    getAllTooltips, 
    createTooltip, 
    createRecord, 
    findCity, 
    baseUrlUploads, 
    filterOffers,
    getAllOperations,
    getEliteEnergiaOffers,
    getAllProposalsScripts,
    deleteProposalScript
} from './../../service';
import store from '../../store';

import './proposal.scss';
import moment from 'moment';

class Proposal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            energia: '',
            typeClient: '',
            typeClient_f2: '',
            address: '',
            city: '',
            statoLuce: '',
            statoGas: '',
            datoLuce: '',
            datoGas: '',
            copertura: '',
            payment: '',
            datoDispGas: '',
            datoDispLuce: '',
            operationLuce: '',
            operationGas: '',
            operation: '',
            email: '',
            ssd: '',

            verde: 'OFF',
            elite: false,

            offers: [],
            filteredOffers: [],
            usedFilters: [],
            operations: [],

            selectedOffer: null,
            parsedData: null,

            tooltip: {},

            // popup tooltip
            openTooltipPopup: false,
            openTooltipPopupLink: false,
            textTooltip: '',
            nameField: '',

            // pagination
            currPage: 1,
            countItems: 20,

            // utility
            distributoreLuce: '',
            distributoreGas: '',
            remiData: '',
            cityError: null,
            cityLoading: false,

            // telcoOffers
            telcoOffers: [],

            // bottom messages
            openBottomMessagesPopup: false,
            selectedMessage: {},
            proposalScripts: [],
            removeId: '',

            prezzo: ''
        }

        this.active = '';

        this.selectChange = this.selectChange.bind(this);
        this.showOffer = this.showOffer.bind(this);
        this.filter = this.filter.bind(this);
        this.openTooltipPopup = this.openTooltipPopup.bind(this);
        this.closeTooltipPopup = this.closeTooltipPopup.bind(this);
        this.openTooltipPopupLink = this.openTooltipPopupLink.bind(this);
        this.closeTooltipPopupLink = this.closeTooltipPopupLink.bind(this);
        this.pushUpdatedTooltip = this.pushUpdatedTooltip.bind(this);
        this.openPlatform = this.openPlatform.bind(this);
        this.openOPP = this.openOPP.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.createRecord = this.createRecord.bind(this);
        this.setEmptyLuceOperations = this.setEmptyLuceOperations.bind(this)
        this.setEmptyGasOperations = this.setEmptyGasOperations.bind(this)
        this.openZohoPopup = this.openZohoPopup.bind(this);
        this.closeZohoPopup = this.closeZohoPopup.bind(this);

        this.openBottomMessagesPopup = this.openBottomMessagesPopup.bind(this);
        this.closeBottomMessagesPopup = this.closeBottomMessagesPopup.bind(this);

        this.updateProposalScript = this.updateProposalScript.bind(this);
        this.addPropposalScript = this.addPropposalScript.bind(this);

        this.askRemove = this.askRemove.bind(this);
        this.closeRemove = this.closeRemove.bind(this);
        this.removeMessage = this.removeMessage.bind(this);
    }

    selectChange(name, value) {
        const state = this.state;

        if(name === 'elite') {
            state['selectedOffer'] = null;
        }
        let nameField = '';
        let valueField = ''

        state[name] = value;

        if(name == 'statoLuce') {
            state['operationLuce'] = fillOperation(value);

            nameField = 'operationLuce';
            valueField = fillOperation(value);
        }

        if(name == 'statoGas') {
            state['operationGas'] = fillOperation(value);

            nameField = 'operationGas';
            valueField = fillOperation(value);
        }

        this.setState(state);

        this.filter(name, value);
        this.filter(nameField, valueField);
    }

    componentWillMount() {
        const search = window.location.hash.replace('#/proposals', '');
        const parsedData = parse(search);

        this.setState({ parsedData });

        getAllOffers().then(result => {
            const offers = result.data.offers;

            const filteredOffers = offers.filter(offer =>  {return offer.Supplier ? true : false} );

            this.setState({ offers: filteredOffers, filteredOffers });
        })

        getAllOperations().then(result => {
            const { operations, ok } = result.data;

            this.setState({ operations });
        })

        getAllTooltips().then(result => {
            const tooltip = result.data.tooltips;

            if(tooltip) {
                toParseValue.map(key => {
                    if(tooltip[key])
                        tooltip[key] = JSON.parse(tooltip[key]);
                })

                return this.setState({ tooltip });
            }

            createTooltip({energia: '{}', operationLuce: '{}', operationGas: '{}', payment: '{}'}).then(resp => {
                const tooltip = resp.data.tooltips;

                toParseValue.map(key => {
                    tooltip[key] = JSON.parse(tooltip[key]);
                })

                this.setState({ tooltip });
            })
        })

        getEliteEnergiaOffers().then(result => {
            const { offers } = result.data;
            console.log('OFFERSSSSS ENERGIA', offers);
            this.setState({ telcoOffers:  offers});
        })


        getAllProposalsScripts().then(result => {
            const scripts = result.data.scripts.filter(x => x.part === 'energia')
            this.setState({ proposalScripts:  scripts});
        })
    }

    showOffer(offer) {
        const { showedOffer } = this.state;
        
        if(showedOffer && showedOffer.show && showedOffer != offer)
            showedOffer.show = false;

        offer.show = !offer.show;

        this.setState({ showedOffer: offer })
    }

    setEmptyLuceOperations() {
        const { usedFilters } = this.state;

        const filters = usedFilters.filter(filter => filter.propName !== 'operation');

        this.setState({
            statoLuce: '',
            datoLuce: '',
            datoDispLuce: '',
            operationLuce: '',
            usedFilters: filters
        })
    }

    setEmptyGasOperations() {
        const { usedFilters } = this.state;

        const filters = usedFilters.filter(filter => filter.propName !== 'operation');

        this.setState({
            statoGas: '',
            datoGas: '',
            datoDispLuce: '',
            copertura: '',
            datoDispGas: '',
            operationGas: '',
            usedFilters: filters
        })
    }

    filter(nameField, value) {
        let name = nameField;
        const { offers: allOffers, filteredOffers, usedFilters, city, currPage, countItems  } = this.state;
        const allowedKeys = allowedFilterFields;

        if(name === 'energia') {
            switch(value) {
                case 'luce': this.setEmptyGasOperations();
                break;

                case 'gas' : this.setEmptyLuceOperations();
                break;
            }
        }

        // if(name == 'operationGas' || name == 'operationLuce') {
        //     name = 'operation';
        // }

        if(!allowedKeys.some(key => key == name))
            return false;

        const theSame = usedFilters.some(filter => filter.propName == name)

        let index = null;

        if(theSame) {
            usedFilters.find((filter, index) => {
                if(filter.propName == name) {
                    index = index;
                    usedFilters[index] = {
                        propName: name,
                        value: value
                    }
                }
            });
        }else {
            usedFilters.push({
                propName: name,
                value: value
            })
        }

        let processOffers = allOffers//(theSame) ? allOffers : filteredOffers;

        const filteredOff = filtration(processOffers, usedFilters, this.state, index)

        if(city) {
            // this.setState({ usedFilters });

            this.filterOffersByCity(this.state.address, filteredOff);
        }

        // filterOffers(currPage, countItems, usedFilters).then(resp => {
        //     console.log(resp.data, 'resp data');
        // })

        this.setState({ filteredOffers: filteredOff, usedFilters });

        
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    openTooltipPopup(name, value) {
        this.setState({ textTooltip: value, openTooltipPopup: true, nameField: name,  });
    }

    closeTooltipPopup() {
        this.setState({ textTooltip: '', openTooltipPopup: false, nameField: '' });
    }

    // bottom messages 
    openBottomMessagesPopup(selectedMessage) {
        this.setState({ selectedMessage: !selectedMessage ? {} : selectedMessage, openBottomMessagesPopup: true, });
    }

    closeBottomMessagesPopup() {
        this.setState({ selectedMessage: {}, openBottomMessagesPopup: false });
    }


    openTooltipPopupLink(name, value) {
        this.setState({ textTooltip: value, openTooltipPopupLink: true, nameField: name, });
    }

    closeTooltipPopupLink() {
        this.setState({ textTooltip: '', openTooltipPopupLink: false, nameField: '' });
    }

    pushUpdatedTooltip(tooltip) {
        toParseValue.map(key => {
            if(tooltip[key] && tooltip[key] === 'string')
                tooltip[key] = JSON.parse(tooltip[key]);
        })

        this.setState({ tooltip });
    }

    setOffer(offer) {
        // console.log(offer);
        this.setState({ selectedOffer: offer });
    }

    openPlatform() {
        window.open(this.state.selectedOffer.link, '_blank');
    }

    openOPP() {
        window.open('http://google.com.ua', '_blank');
    }

    filterOffersByCity(resp, arr = null ) {
        this.setState({ cityLoading: !resp });

        if(!resp){
            return;
        }

        const typeProduct = this.state.energia;

        const offers = arr || this.state.filteredOffers;
        const luceOffers = [];

        const {
            wekiwi,
            sorgenia,
            energrid,
            ovenergy,
            illumia,
            eviva,
            iren,
            eni,
            enel,
            nen,
            cambio,
            gas_utility,
            luce_utility,
            cabine_remi_presenti_nel_comune
        } = resp;

        const suppliers = {
            wekiwi,
            'Green Network': energrid,
            ovenergy,
            illumia,
            eviva,
            iren
        };

        const distributoreLuce = luce_utility;
        const distributoreGas = gas_utility;
        const remiData = cabine_remi_presenti_nel_comune;

        // console.log({ 
        //     distributoreLuce,
        //     distributoreGas,
        //     remiData
        // }, 'suppliers filter by city')
        const keysSuppliers = Object.keys(suppliers).filter(key => {
            const supplierSupport = suppliers[key];

            if(supplierSupport === "Edison")
                return 1;

            // console.log('filter by city', supplierSupport, key, suppliers[key])

            return (supplierSupport) ? 1 : 0;
        });

        if(typeProduct == 'luce')
            return this.setState(
                {
                    distributoreLuce,
                    distributoreGas,
                    remiData,
                    cityError: null,
                    cityLoading: false
                }
            );

        const filteredOffers = offers.filter(offer => {
            if(offer.Supplier && offer.Supplier.name.toLowerCase() == 'edison')
                return true;

            if(offer.Supplier.copertura_nazionale)
                return true;
                
            return keysSuppliers.some(key => key.toLowerCase() == offer.Supplier.name.toLowerCase());
        });

        this.setState(
            { 
                filteredOffers, 
                distributoreLuce,
                distributoreGas,
                remiData,
                cambio,
                cityError: null,
                cityLoading: false
            }
        );
    }

    // PAGINATION

    prevPage() {
        let { currPage } = this.state;

        this.setState({ currPage: --currPage });
    }

    nextPage() {
        let { currPage } = this.state;

        this.setState({ currPage: ++currPage });
    }

    getFilteredOffers(page, lim) {
        const {
            currPage,
            limit,
            usedFilters
        } = this.state;

        // filterOffers(page || currPage, lim || limit, usedFilters).then(resp => {
        //     console.log(resp.data, 'resp data');
        // })
    }

    setAddress(result) {

        this.filterOffersByCity(result);

        if(result) {
            this.setState({ address: result, city: result && result.city });
        }
    }

    createRecord() {
        const {
            parsedData,
            selectedOffer
        } = this.state;

        let strParsed = '';

        for(let key in parsedData) {
            strParsed += `${key}=${parsedData[key]}&`;
        }

        let strOffer = '';

        for(let key in selectedOffer) {
            if(key != 'Supplier')
                strOffer += `${key}=${selectedOffer[key]}$`;
        }

        const query = strParsed + strOffer;

        createRecord(query);
    }

    closeZohoPopup() {
        this.setState({
            openZoho: false
        })
    }

    openZohoPopup() {
        this.setState({
            openZoho: true
        })
    }

    
    updateProposalScript(script) {
        const proposalScripts = this.state.proposalScripts.map(message => {

            if(message.id == script.id) {
                message.text = script.text;
            }

            return message;
        })

        this.setState({ proposalScripts });
    }


    addPropposalScript(script) {
        const {proposalScripts} = this.state
        console.log('new script', script);
        this.setState({
            proposalScripts: [...proposalScripts, script]
        })
    }

    askRemove(id) {
        this.setState({openDialogRemove: true, removeId: id});
    }

    closeRemove() {
        this.setState({openDialogRemove: false, removeId: null});
    }

    removeMessage() {
        const { proposalScripts, removeId: id } = this.state;
        this.closeRemove();

        deleteProposalScript(id)
            .then(reslut => {
            }).catch(err => {
                this.setState({ updatedScripts });
            })

        const updatedScripts = proposalScripts.filter(x => x.id != id);
        this.setState({ proposalScripts: updatedScripts });
    }


    render() {
        const { role } = store.getState().auth;

        const {
            sEnergia,
            sTypeClient,
            sTypeDome,
            sTypeBusi,
            sStatoLuce,
            sStatoGas,
            sDatoLuce,
            sDatoGas,
            sPayment,
            sOperationLuce,
            sOperationGas
        } = selections;

        const { 
            energia,
            typeClient,
            typeClient_f2,
            address,
            statoLuce,
            statoGas,
            datoLuce,
            datoGas,
            payment,
            copertura,
            operationLuce,
            operationGas,
            email,
            ssd,

            verde,

            elite,
            offers,
            filteredOffers,
            operations,

            selectedOffer,

            tooltip,

            // popup
            openTooltipPopup,
            openTooltipPopupLink,
            textTooltip,
            nameField,

            // pagination
            currPage,
            countItems,

            distributoreLuce,
            distributoreGas,
            remiData,
            cityError,
            cityLoading,
            cambio,
            openZoho,
            closeZohoPopup,

            city,
            telcoOffers,
            operation,

            proposalScripts,
            openBottomMessagesPopup,
            selectedMessage,
            openDialogRemove,
            
            prezzo
        } = this.state;

        const todayStart = + new Date().setHours(0,0,0,0);
        const todayEnd = + new Date().setHours(23,59,59,999);

        const unType = (typeClient == 'Cliente domestico')? sTypeDome : sTypeBusi;

        const expOffers = filteredOffers.filter(offer => + new Date(offer.exp_date) < todayStart);
        const freshOffers = filteredOffers.filter(offer => + new Date(offer.exp_date) >= todayStart);
        freshOffers.filter(offer => {
            const seconds = +new Date() - +new Date(offer.updatedAt);
            const secondsExp = +new Date(offer.exp_date) - +new Date();

            if((offer.aggiornamento === 'Aggiornata' && seconds <= 259200000 && seconds > 0)) { 
                offer.green = true;
            }

            if((offer.aggiornamento === 'In scadenza' && secondsExp <= 259200000 && secondsExp > 0)) { 
                offer.exp = true;
            }

            console.log(secondsExp, + new Date(), +new Date(offer.exp_date), offer.offer_name);
        });

        // console.log(freshOffers);

        const bonusText = showQuestion(tooltip, 'bonus_text_top', 'default_value');
        const bonusTextBottom = showQuestion(tooltip, 'bonus_text_bottom', 'default_value');

        return(
            <div className='proposal_section anim_content'>
                <Tooltip title='Help'>
                    <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                </Tooltip>

                <Popup open={openTooltipPopup} id={tooltip.id} tooltip={tooltip} field={nameField} name={textTooltip} handleClose={this.closeTooltipPopup} pushUpdatedTooltip={this.pushUpdatedTooltip}/>
                <BottomMessagesPopup open={openBottomMessagesPopup} selectedMessage={selectedMessage} handleClose={this.closeBottomMessagesPopup} updateProposalScript={(script) => this.updateProposalScript(script)} addPropposalScript={(script) => this.addPropposalScript(script)}/>
                <DeialogRemove open={openDialogRemove} remove={this.removeMessage} close={this.closeRemove}/>
                <ZohoPopup open={openZoho} handleClose={this.closeZohoPopup} />
                <PopupLink open={openTooltipPopupLink} id={tooltip.id} tooltip={tooltip} field={nameField} name={textTooltip} handleClose={this.closeTooltipPopupLink} pushUpdatedTooltip={this.pushUpdatedTooltip}/>
                <div className='section -client'>
                    <div className='bonus_text'>
                        { bonusText ? renderHTML(bonusText) : role == 'admin' ? 'Click on the button to insert text' : ''}
                        <Tooltip title='edit'>
                            <Button className={role === 'admin' ? 'edit_tooltip' : 'hide'} onClick={() => { this.openTooltipPopup('bonus_text_top', 'default_value') }} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>edit</Icon></Button>
                        </Tooltip>
                    </div>

                    <div className='fields'>
                        <div className='tooltip'>
                            <TextField
                                select
                                label="Energia"
                                className='select'
                                value={energia}
                                onChange={ event => this.selectChange('energia', event.target.value)}
                                margin="normal"
                                size='small'
                                variant="outlined"
                                >
                                {sEnergia.map(option => (
                                    <MenuItem key={option} value={option}>
                                    {option}
                                    </MenuItem>
                                ))} 
                            </TextField>
                          <div className={energia ? 'help' : 'help opacityNone'} >
                           {renderHTML(showQuestion(tooltip, 'energia', energia))}
                            <Tooltip title='edit'>
                                <Button className={energia && role === 'admin' ? 'edit_tooltip' : 'hide'} onClick={() => { this.openTooltipPopup('energia', energia) }} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>edit</Icon></Button>
                            </Tooltip>
                            
                            </div>
                        </div>
                    </div>

                    <div className='fields -no_tooltip'>
                        <TextField
                                select
                                label="Tipo cliente"
                                className='select'
                                value={typeClient}
                                onChange={ event => this.selectChange('typeClient', event.target.value)}
                                margin="normal"
                                size='small'
                                variant="outlined"
                                >
                                {sTypeClient.map(option => (
                                    <MenuItem key={option} value={option}>
                                    {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="Profilo utenza"
                                className={typeClient? 'select': 'select disabled'}
                                value={typeClient_f2}
                                onChange={ event => this.selectChange('typeClient_f2', event.target.value)}
                                margin="normal"
                                size='small'
                                variant="outlined"
                                >
                               {unType.map(option => (
                                    <MenuItem key={option} value={option}>
                                    {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                    </div>


                    <div className='fields -search'>
                        <LocationSearch show={true} setAddress={this.setAddress}/> 
{/* typeClient_f2 */}
                        { !cityLoading && <div className={(city && !cityError) ? 'ext_data' : 'ext_data hide'}>
                            <div className='data'>Distributore luce: {distributoreLuce}</div>
                            <div className='data'>Distributore gas: {distributoreGas}</div>
                            <div className='data'>REMI: {remiData}</div>
                        </div> }

                        {/* { cityLoading && <div className='loading_city'>Loading...</div> } */}

                        { (cityError && !cityLoading) && <div className={(city && cityError) ? 'ext_data' : 'ext_data hide'}>
                            <div className='data'>{cityError.message}</div>
                        </div> }
                    </div>
                    
                </div>
                <div className='section -product'>
                    <div className='product'>
                        <div className={(typeClient_f2 && energia != 'gas') ? 'item -luce' : 'item -luce disabled'}>
                        <div className='header'>LUCE</div>
                            <div className='tooltip'>
                                <TextField
                                    select
                                    label="Operation"
                                    className='select'
                                    value={operationLuce}
                                    onChange={ event => this.selectChange('operationLuce', event.target.value)}
                                    margin="normal"
                                    size='small'
                                    variant="outlined"
                                    >
                                    {sOperationLuce.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <div className='text'>
                                    {renderHTML(showQuestion(tooltip, 'operationLuce', operationLuce))}
                                    
                                    <Tooltip title='edit'>
                                        <Button className={operationLuce && role === 'admin' ? 'edit_tooltip' : 'hide'} onClick={() => this.openTooltipPopup('operationLuce', operationLuce) } variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>edit</Icon></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        {/* <div className='line'></div> */}
                        <div className={typeClient_f2 && energia != 'luce' ? 'item -gas' : 'item -gas disabled'}>
                            <div className='header'>GAS</div>
                            <div className='tooltip'>
                                <TextField
                                    select
                                    label="Operation"
                                    className='select'
                                    value={operationGas}
                                    onChange={ event => this.selectChange('operationGas', event.target.value)}
                                    margin="normal"
                                    size='small'
                                    variant="outlined"
                                    >
                                    {sOperationGas.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <div className='text'>
                                    {renderHTML(showQuestion(tooltip, 'operationGas', operationGas))}
                                        
                                    <Tooltip title='edit'>
                                        <Button className={operationGas && role === 'admin' ? 'edit_tooltip' : 'hide'} onClick={() => this.openTooltipPopup('operationGas', operationGas) } variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>edit</Icon></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={operationLuce || operationGas ? 'section -info': 'section -info disabled'}>
                    <div className='fields -buttons'> 
                        <Button>
                            <a href={showQuestion(tooltip, 'check_distributore', 'default_value')} target='_blank'>Check Distributore</a>

                            <Tooltip title='edit'>
                                <Button className={role === 'admin' ? 'edit_tooltip' : 'hide'} onClick={() => this.openTooltipPopupLink('check_distributore', 'default_value') } variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>edit</Icon></Button>
                            </Tooltip>
                        </Button>

                        <Button>
                            <a href={showQuestion(tooltip, 'check_contatore', 'default_value')} target='_blank'>Check Contatore</a>

                            <Tooltip title='edit'>
                                <Button className={role === 'admin' ? 'edit_tooltip' : 'hide'} onClick={() => this.openTooltipPopupLink('check_contatore', 'default_value') } variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>edit</Icon></Button>
                            </Tooltip>
                        </Button>
                    </div>

                    <div className='fields'> 
                        <div className='tooltip'>
                            <TextField
                                    select
                                    label="Payment"
                                    className='select'
                                    value={payment}
                                    onChange={ event => this.selectChange('payment', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size='small'
                                    >
                                    {sPayment.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <div className={payment ? 'text' : 'text opacityNone'} >
                                    {renderHTML(showQuestion(tooltip, 'payment', payment))}

                                    <Tooltip title='edit'>
                                        <Button className={payment && role === 'admin' ? 'edit_tooltip' : 'hide'} onClick={() => this.openTooltipPopup('payment', payment) } variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>edit</Icon></Button>
                                    </Tooltip>
                                </div>
                        </div>

                        <TextField
                            select
                            label="Prezzo"
                            className='select'
                            value={prezzo}
                            onChange={ event => this.selectChange('prezzo', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size='small'
                            >
                            {['Fisso', 'Variabile', 'Tutte le offerte'].map(option => (
                                <MenuItem key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                        </TextField>
{/* 
                        <TextField
                            select
                            label="Prezzo"
                            className='select'
                            onChange={ event => this.changeValue('prezzo', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            value={prezzo}
                            size='medium'
                            >
                            {['Fisso', 'Variabile'].map(option => (
                                <MenuItem required key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                        </TextField> */}
                    </div>

                    <div className='fields'> 
                        <TextField
                            select
                            label="Email"
                            className='select'
                            value={email}
                            onChange={ event => this.selectChange('email', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size='small'
                            >
                                {['si', 'no', 'solo telefono'].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                        </TextField>
                        <TextField
                            select
                            label="Offerta Verde"
                            className='select'
                            value={verde}
                            onChange={ event => this.selectChange('verde', event.target.value)}
                            margin="normal"
                            variant="outlined"
                            size='small'
                            >
                                {['ON', 'OFF'].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </div>
                    <Box sx={{mt: "20px"}}>
                        {store.getState().auth.elite &&
                            <FormControlLabel className={elite ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={elite} onChange={() => { this.onChangeBool('gas_matricola_contatore_elettronico') }} />} label="Elite" />
                        }
                    </Box>
                </div>
                <div className={operationLuce || operationGas ? 'offer' : 'offer disabled'}>

                    {!elite ? 
                    <div className='list_offer'>
                        <div className='head'>
                            <div className='field -name'>Offer Name</div>
                            <div className='field -valid'>Exp. Date</div>
                            <div className='field -payment'>Payment Type</div>
                            <div className='field -payment'>Prezzo</div>
                            <div className='field -supplier'>Supplier Name</div>
                        </div>
                     {freshOffers
                     .sort((a, b) => {
                        let ratingA = (a.rating && a.rating >= 0) ? a.rating : 100;
                        let ratingB = (b.rating && b.rating >= 0) ? b.rating : 100;

                        ratingA++;
                        ratingB++;

                        // console.log(ratingA > ratingB, ratingA, ratingB, a.offer_name, b.offer_name, a.rating,b.rating)

                        return ratingA > ratingB ? 1 : ratingA === ratingB ? 0 : -1;
                      })
                     .concat(expOffers)
                     .filter(offer => verde == "ON" ? offer.verde == "ON" ? true: false : true  )
                     .filter(offer => !elite ? true : offer.elite == true )
                     .filter(offer => (!prezzo || prezzo.toLowerCase() === 'Tutte le offerte'.toLowerCase()) ? true : offer?.prezzo?.toLowerCase() == prezzo.toLowerCase() )
                     .filter(offer => moment(offer.exp_date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') )
                    //  .slice((1 - currPage) * countItems, countItems)
                     .map((offer) => {
                        //  console.log('slice:', (1 - currPage) * countItems, countItems)
                         let operationQueryLuce = '';
                         let operationQueryGas = '';
                         let clientType = typeClient == 'Cliente domestico' ? 'resid' : 'business';

                         const {
                            id,
                            client_type,
                            offer_name,
                            offer_code,
                            exp_date,
                            duration,
                            sdd_obblig,
                            type_product,
                            price_gas,
                            price_f0,
                            price_f1,
                            price_f2,
                            price_f3,
                            price_pcv,
                            monthly_fee,
                            gas_description,
                            ee_description,
                            dual,
                            Supplier: supplier,
                            show
                         } = offer;

                         const operation = supplier ? operations.find(operation => operation.supplier_id == supplier.id) : null;

                         if(operationLuce)
                            operationQueryLuce = `${keyForBoolean['operationLuce'][operationLuce]}_power_${clientType}`

                         if(operationGas)
                            operationQueryGas = `${keyForBoolean['operationGas'][operationGas]}_gas_${clientType}`            
                         
                         let strPayments = '';

                        //  console.log(operation)
                        //  console.log(operationLuce, operationGas, 'operations');
                        //  console.log(operationQueryLuce, operationQueryGas, 'query');
                        //  console.log(supplier, operations, 'supplier');

                         if(offer) {
                            if(offer.rid)
                                strPayments += 'Rid';
                        
                            if(offer.bollettino)
                                strPayments += (strPayments) ? ', Bollettino': 'Bollettino';

                            if(offer.bonifico)
                                strPayments += (strPayments) ? ', Bonifico': 'Bonifico';

                            if(offer.carta_credito)
                                strPayments += (strPayments) ? ', Carta credito': 'Carta credito';

                            if(!strPayments)
                                strPayments = 'Not assigned';
                         }

                        const date = new Date(exp_date);
                        const day = (date.getDate() < 10) ? '0' + date.getDate(): date.getDate();
                        const month = (date.getMonth()+1 < 10) ? '0' + (date.getMonth() + 1): date.getMonth()+1;

                        const formatedDate = `${day}/${month}/${date.getFullYear()}`;
                        const offerExp = + new Date(offer.exp_date);
                        const notValid = offerExp < todayStart;

                        const priceSame = (price_f0 === price_f1 && price_f1 === price_f2 && price_f2 === price_f3) ? true : false;

                        let offerClass = ' '

                        if(offerExp > todayStart && offerExp < todayEnd)
                            offerClass += ' -today';
                        
                        if(notValid)
                            offerClass += ' -not_valid';

                        if(offer.green) {
                            offerClass += ' -show_green';
                        }

                        if(offer.exp) {
                            offerClass += ' -show_exp';
                        }

                         return (
                            <div className={'offer' + offerClass} key={id}>
                                <div className='head' onClick={() => this.showOffer(offer)}>
                                    <div className='field -name'>{offer_name}</div>
                                    <div className='field -valid'>{formatedDate}</div>
                                    <div className='field -payment'>{strPayments}</div>
                                    <div className='field -payment'>{offer.prezzo}</div>
                                    <div className='field -supplier'>
                                    <div className='offer_supplier'>
                                        <div className='sup_name'>{supplier ? supplier.name : ''}</div>
                                        { (supplier && supplier.img) && <div className='logo_supplier_section'>
                                          <div className='logo_supplier'>
                                              <img src={baseUrlUploads + supplier.img}/>
                                          </div>
                                          <div className='tooltip_image'>
                                            <img src={baseUrlUploads + supplier.img}/>
                                          </div>
                                        </div> }
                                      </div>
                                    </div>
                                    <div className='offerExpandBtn'><img src={!show ? "./images/expandBottomArrow.svg" : "./images/expandTopArrow.svg"} alt="expand info" /></div>
                                </div>
                                { show && <div className={(show) ? 'body' : 'body hide'}>
                                    <div className='section -main'>
                                        <div className='field -fornitore'><span className='text'>Fornitore: </span> {supplier.name}</div>
                                        <div className='field -energia'><span className='text'>Energia: </span>{type_product}</div>
                                        <div className='field -codince'><span className='text'>Codice: </span>{offer_code}</div>
                                        <div className='field -tipo_cliente'><span className='text'>Tipo cliente: </span>{client_type}</div>
                                    </div>
                                    <div className={(type_product == 'luce' || type_product == 'dual' ? 'section -price_luce': 'hide')}>
                                        <div className='field'><span className='text'>{priceSame ? 'MONORARIA' : "BIORARIA"}</span></div>
                                        <div className='field -f0'><span className='text'>F0: </span>{price_f0} €/kWh</div>
                                        { !priceSame && <div className='field -f1'><span className='text'>F1: </span>{price_f1?.replace('.', ',')} €/kWh</div> }
                                        { !priceSame && <div className='field -f2'><span className='text'>F2: </span>{price_f2?.replace('.', ',')} €/kWh</div> }
                                        { !priceSame && <div className='field -f3'><span className='text'>F3: </span>{price_f3?.replace('.', ',')} €/kWh</div> } 
                                        <div className='field -PCV'><span className='text'>PCV: </span>{price_pcv?.replace('.', ',')} €</div>
                                    </div>
                                    <div className={(type_product == 'gas' || type_product == 'dual' ? 'section -price_gas': 'hide')}>
                                        <div className='field -gas'><span className='text'>Gas: </span>{price_gas?.replace('.', ',')} €/smc</div>
                                        <div className='field -fee'><span className='text'>Month fee: </span>{monthly_fee?.replace('.', ',')} €</div>
                                    </div>
                                    <div className='section -terms'>
                                        <div className='field -validita'><span className='text'>Validita: </span>{formatedDate}</div>
                                        <div className='field -durata'><span className='text'>Durata: </span>{duration} mese</div>
                                        <div className='field -ssd'><span className='text'>SSD: </span>{sdd_obblig}</div>
                                    </div>

                                    <div className='section -script'>
                                        <div className='link_offer'>
                                            <Link to={'/energia/offers/' + id} target='blank'><Button> Dettagli offerta </Button></Link>
                                        </div>

                                        <div className={type_product == 'luce' ? 'field -script_luce' : 'hide'}>
                                            <h3 className='header'>Offerta Luce</h3>
                                            <div className='text_script'>{renderHTML(ee_description || '')} </div>
                                        </div>
                                        <div className={type_product == 'gas' ? 'field -script_gas' : 'hide'}>
                                            <h3 className='header'>Offerta Gas</h3>
                                            <div className='text_script'>{renderHTML(gas_description || '')} </div>
                                        </div>
                                        <div className={type_product == 'dual' ? 'field -script_dual' : 'hide'}>
                                            <h3 className='header'>Offerta Dual</h3>
                                            <div className='text_script'>{renderHTML(dual || '')} </div>
                                        </div>

                                        <div className='field -script_luce'>
                                            <h3 className='header'>Script Operation</h3>
                                            <div className='text_script'>
                                                { operation ?
                                                    operationQueryLuce && operationQueryGas 
                                                        ? <div className='text_center'>
                                                            <div className='title'>Luce</div>
                                                            {renderHTML(operation[operationQueryLuce] || '')}
                                                            <div className='title'>Gas</div>
                                                            {renderHTML(operation[operationQueryGas] || '')}
                                                        </div>
                                                        : operationQueryLuce 
                                                            ? <div className='text_center'> {renderHTML(operation[operationQueryLuce] || '')}</div> 
                                                            : operationQueryGas 
                                                                ? <div className='text_center'> {renderHTML(operation[operationQueryGas] || '')}</div>  : '' :''
                                                                
                                                }
                                            </div>
                                        </div>

                                         <div className='field -script_luce'>
                                            <h3 className='header'>Script NDS</h3>
                                            <div className='text_script'>
                                                <div className='text_center'>
                                                    {renderHTML(operation['nds_gas_' + ((typeClient === 'Cliente domestico') ? 'resid' : 'business')] || '')}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='section -script'>
                                        <div className='field -script_luce'>
                                            <h3 className='header'>Fornitore e Servizi Aggiuntivi</h3>
                                            <div className='text_script'>{(supplier.description) ? renderHTML(supplier.description) : <div className='text_center'>Empty</div>} </div>
                                        </div>
                                    </div>
                                </div> }
                                { notValid && <Tooltip title='Not valid offer'><Radio 
                                    className='radio'
                                    name='selected-offer'
                                    checked={(selectedOffer && selectedOffer.id == id) ? true : false}
                                    onChange={() => !notValid ? this.setOffer(offer) : ''}
                                    value={id.toString()}
                                    /></Tooltip>}
                                { !notValid && <Radio 
                                    className='radio'
                                    name='selected-offer' 
                                    checked={(selectedOffer && selectedOffer.id == id) ? true : false}
                                    onChange={() => this.setOffer(offer)}
                                    value={id.toString()}
                                    />}
                            </div>
                         )
                     })}
                    </div>

                    : 

                    <div className='list_offer'>
                            <div className='head'>
                                <div className='field -name'>Offer Name</div>
                                <div className='field -valid'>Exp. Date</div>
                                <div className='field -payment'>Payment Type</div>
                                <div className='field -payment'>Payment Type</div>
                                <div className='field -payment'>Payment Type</div>
                                <div className='field -supplier'>Supplier Name</div>
                            </div>
                            {telcoOffers
                                .sort((a, b) => {
                                    let ratingA = (a.rating && a.rating >= 0) ? a.rating : 100;
                                    let ratingB = (b.rating && b.rating >= 0) ? b.rating : 100;

                                    ratingA++;
                                    ratingB++;

                                    // console.log(ratingA > ratingB, ratingA, ratingB, a.offer_name, b.offer_name, a.rating,b.rating)

                                    return ratingA > ratingB ? 1 : ratingA === ratingB ? 0 : -1;
                                })
                                // .concat(expOffers)
                                .filter(offer => verde == "ON" ? offer.verde == "ON" ? true : false : true)
                                .filter(offer => !elite ? true : offer.elite == true)
                                .filter(offer => (!prezzo || prezzo === 'Tutte le offerte') ? true : offer?.prezzo?.toLowerCase() == prezzo.toLowerCase() )
                                //  .slice((1 - currPage) * countItems, countItems)
                                .map((offer) => {
                                    //  console.log('slice:', (1 - currPage) * countItems, countItems)
                                    let operationQueryLuce = '';
                                    let operationQueryGas = '';
                                    let clientType = typeClient == 'Cliente domestico' ? 'resid' : 'business';

                                    const {
                                        id,
                                        client_type,
                                        offer_name,
                                        offer_code,
                                        exp_date,
                                        duration,
                                        price,
                                        script,
                                        TelcoSupplier: supplier,
                                        show
                                    } = offer;

                                    let strPayments = '';

                                    if (offer) {
                                        if (offer.rid_cc)
                                            strPayments += 'RID-CC';

                                        if (offer.rid_cp)
                                            strPayments += (strPayments) ? ', RID-CP' : 'RID-CP';

                                        if (offer.ricaricabile)
                                            strPayments += (strPayments) ? ', Ricaricabile' : 'Ricaricabile';

                                        if (offer.bollettino)
                                            strPayments += (strPayments) ? ', Bollettino' : 'Bollettino';

                                        if (!strPayments)
                                            strPayments = 'Not assigned';
                                    }

                                    const date = new Date(exp_date);
                                    const day = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
                                    const month = (date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;

                                    const formatedDate = `${day}/${month}/${date.getFullYear()}`;
                                    const offerExp = + new Date(offer.exp_date);
                                    const notValid = offerExp < todayStart;

                                    let offerClass = ' '

                                    if (offerExp > todayStart && offerExp < todayEnd)
                                        offerClass += ' -today';

                                    if (notValid)
                                        offerClass += ' -not_valid';

                                    return (
                                        <div className={'offer' + offerClass} key={id}>
                                            <div className='head' onClick={() => this.showOffer(offer)}>
                                                <div className='field -name'>{offer_name}</div>
                                                <div className='field -valid'>{formatedDate}</div>
                                                <div className='field -payment'>{strPayments}</div>
                                                <div className='field -payment'>{offer.prezzo}</div>
                                                <div className='field -supplier'>
                                                    <div className='offer_supplier'>
                                                        <div className='sup_name'>{supplier ? supplier.name : ''}</div>
                                                        {(supplier && supplier.img) && <div className='logo_supplier_section'>
                                                            <div className='logo_supplier'>
                                                                {/* <img src={baseUrlUploads + supplier.img} /> */}
                                                            </div>
                                                            <div className='tooltip_image'>
                                                                {/* <img src={baseUrlUploads + supplier.img} /> */}
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                            {show && <div className={(show) ? 'body' : 'body hide'}>
                                                <div className='section -main'>
                                                    <div className='field -fornitore'><span className='text'>Fornitore: </span> {supplier.name}</div>
                                                    <div className='field -codince'><span className='text'>Codice: </span>{offer_code}</div>
                                                    <div className='field -tipo_cliente'><span className='text'>Tipo cliente: </span>{client_type}</div>
                                                </div>
                                                <div className='section -main'>
                                                    <div className='field -f0'><span className='text'>Price:  </span>{price?.replace('.', ',')} €/kWh</div>
                                                    <div className='field -validita'><span className='text'>Validita: </span>{formatedDate}</div>
                                                    <div className='field -durata'><span className='text'>Durata: </span>{duration} mese</div>
                                                </div>

                                                <div className='section -script'>
                                                    <div className='link_offer'>
                                                        <Link to={'/telco/offers/' + id} target='blank'><Button> Dettagli offerta </Button></Link>
                                                    </div>

                                                    <div className='field -script_luce'>
                                                        <h3 className='header'>Offerta Script</h3>
                                                        <div className='text_script'>{renderHTML(script || '')} </div>
                                                    </div>

                                                </div>

                                                <div className='section -script'>
                                                    <div className='field -script_luce'>
                                                        <h3 className='header'>Fornitore e Servizi Aggiuntivi</h3>
                                                        <div className='text_script'>{(supplier.description) ? renderHTML(supplier.description) : <div className='text_center'>Empty</div>} </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {notValid && <Tooltip title='Not valid offer'><Radio
                                                className='radio'
                                                name='selected-offer'
                                                checked={(selectedOffer && selectedOffer.id == id) ? true : false}
                                                onChange={() => !notValid ? this.setOffer(offer) : ''}
                                                value={id.toString()}
                                            /></Tooltip>}
                                            {!notValid && <Radio
                                                className='radio'
                                                name='selected-offer'
                                                checked={(selectedOffer && selectedOffer.id == id) ? true : false}
                                                onChange={() => this.setOffer(offer)}
                                                value={id.toString()}
                                            />}
                                        </div>
                                    )
                                })}
                        </div>
                        }
                </div>
                <div className='actions'>
                    <Button className={selectedOffer && selectedOffer.link ? 'button' : 'button disabled'} onClick={this.openPlatform}>Create on supplier platform</Button>
                    <Button className={selectedOffer && selectedOffer.check_credit_link ? 'button' : 'button disabled'}><a href={selectedOffer && selectedOffer.check_credit_link ? selectedOffer.check_credit_link : ''} target='_blank'>Check credit</a></Button>
                </div>
                <div className='bottomMessagesSection'>
                        <div className='bottomMessages' >
                            { proposalScripts && proposalScripts.length > 0 
                            ?
                            proposalScripts.map(x => (
                                <div className='bottomMessages__message'>{renderHTML(x.text)}
                                    <div className={role === 'admin' ? 'bottomMessages__actions' : 'hide'}>
                                        <Tooltip title='Edit'><Button onClick={() => { this.openBottomMessagesPopup(x) }}  className='actionBtn actionBtn--edit' variant="fab" mini aria-label="Edit"><Icon className='editIcon'>edit_icon</Icon></Button></Tooltip>
                                        <Box><Tooltip title='Delete'><Button className='actionBtn actionBtn--remove' variant="fab" mini aria-label="Remove" onClick={() => this.askRemove(x.id)}><Icon className='offer_icon removeIcon'>delete</Icon></Button></Tooltip></Box>
                                    </div>
                                </div>
                            ))
                            : role == 'admin' ? 'Click Add new to insert text' : ''
                            }
                        </div>

                        {role == 'admin' &&
                            <Box sx={{width: 'fit-content', marginLeft: 'auto'}}>
                                <Tooltip title='Add new'>
                                    <Button className='add_button' onClick={() => { this.openBottomMessagesPopup() }} ><span className='add_button--plusSign'>+</span><span>add new</span></Button>
                                </Tooltip>
                            </Box>
                        }
                </div>
            </div>
        )
    }
}

export default Proposal;

const selections = {
    sEnergia: ['luce', 'gas', 'dual'],
    sTypeClient: ['Cliente domestico', 'Business o altro'],
    sTypeDome: ['Residente', 'Non residente', 'UDA'],
    sTypeBusi: ['Attivita commerciale', 'Condominio'],
    sStatoLuce: [
        'Mai installato, POD non attribuito',
        'Utenza creata, mai attivata',
        'Installato e chiuso regolarmente',
        'Attivo con altro fornitore, altro intestatario',
        'Attivo a nome del cliente con altro fornitore',
        'Taglio colonna',
        'Contatore rimosso',
        'Allacciamento temporaneo'
    ],
    sStatoGas: [
        'Mai installato, PDR non attribuito',
        'Utenza creata, mai attivata',
        'Installato e chiuso regolarmente',
        'Attivo con altro fornitore, altro intestatario',
        'Attivo a nome del cliente con altro fornitore',
        'Taglio colonna',
        'Contatore rimosso',
        'Allacciamento temporaneo'
    ],
    sDatoLuce: ['POD', 'Numero utente', 'Matricola contatore'],
    sDatoGas: ['PDR', 'Matricola contatore'],
    sPayment: ['Bonifico', 'Bollettino', 'Rid'],
    sOperationLuce: [
        'ALLACCIAMENTO',
        'PRIMA ATTIVAZIONE',
        'SUBENTRO',
        'CAMBIO CON VOLTURA',
        'CAMBIO FORNITORE',
        'Taglio colonna',
        'Contatore rimosso',
        'Allacciamento temporaneo'
    ],
    sOperationGas: [
        'ALLACCIAMENTO',
        'PRIMA ATTIVAZIONE',
        'SUBENTRO',
        'CAMBIO CON VOLTURA',
        'CAMBIO FORNITORE',
        'Taglio colonna',
        'Contatore rimosso',
        'Allacciamento temporaneo'
    ]
}

const filtration = (offers, filters, state, index = null) => {
    const filterValues = [];

    (index || index === 0) 
        ? filterValues.push(filters[index]) 
        : filterValues.push(...filters);

    const filteredOffers = offers.filter(offer => {
        for(let i = 0, length = filterValues.length; i < length; i++) {
            const { name, propName, value } = filterValues[i];

            // const value = state[propName];

            switch(propName) {
                case 'energia': 
                    const result = offer['type_product'] == value;

                    if(!result)
                        return false;
                break;
                case 'typeClient': 
                    const type = value == 'Cliente domestico'? 'resid' : 'business'
                    const exist = offer['client_type'] == type;

                    if(!exist)
                        return false;
                break;
                case 'operationGas':
                    const operationGas = keyForBoolean['operationGas'][value];
    
                    if(!offer[operationGas])
                        return false;
                break;
                case 'operationLuce':
                    const operationLuce = keyForBoolean['operationLuce'][value];
    
                    if(!offer[operationLuce])
                        return false;
                break;
                case 'typeClient_f2':
                    const existLib = value === 'Libero professionista';
                    const existUDA = value === 'UDA';
                    const existResid = value === 'Residente';
                    const existNonResid = value === 'Non residente';

                    const existAttivita = value === 'Attivita commerciale';
                    const existCondominio = value === 'Condominio';

                    if(existLib)
                        if(offer['client_sub_type'] !== 'libero_professionista')
                            return false;

                    if(existUDA)
                        if(!offer['uda_o_attivita_commerciale'])
                            return false;

                    if(existResid)
                        if(!offer['residente'])
                            return false;

                    if(existNonResid)
                        if(!offer['non_residente'])
                            return false;

                    if(existAttivita)
                        if(!offer['amministrazione'])
                            return false;

                    if(existCondominio)
                        if(!offer['condominio'])
                            return false;
                break;
                case 'payment': 
                    const payment = keyForBoolean['payment'][value];
    
                    if(!offer[payment])
                        return false;
            }
        }

        return true;
    })

    return filteredOffers;
}

const allowedFilterFields = ['energia', 'payment', 'typeClient', 'typeClient_f2', 'operationGas', 'operationLuce', 'elite']; //, 'typeClient', 'ssd'

const keysForLiter = {
    energia: 'type_product'
}

const keyForBoolean = {
    payment: {
        'Bonifico': 'bonifico',
        'Bollettino': 'bollettino',
        'Carta di credito': 'carta_credito',
        'Rid': 'rid',

        'RID-CC': 'rid_cc',
        'RID-CP': 'rid_cp',
        'Ricaricabile': 'ricaricabile',
        'Bollettino': 'bollettino'
    },

    operationGas: {
        'ALLACCIAMENTO': 'allacciamento',
        'PRIMA ATTIVAZIONE': '1a_attivazione',
        'SUBENTRO': 'subentro',
        'CAMBIO CON VOLTURA': 'cambio_con_voltura',
        'CAMBIO FORNITORE': 'cambio_fornitore',
        'Taglio colonna': 'taglio_colonna',
        'Contatore rimosso': 'contatore_rimosso',
        'Allacciamento temporaneo': 'chiuso_per_morosita'
    },
    operationLuce: {
        'ALLACCIAMENTO': 'allacciamento',
        'PRIMA ATTIVAZIONE': '1a_attivazione',
        'SUBENTRO': 'subentro',
        'CAMBIO CON VOLTURA': 'cambio_con_voltura',
        'CAMBIO FORNITORE': 'cambio_fornitore',
        'Taglio colonna': 'taglio_colonna',
        'Contatore rimosso': 'contatore_rimosso',
        'Allacciamento temporaneo': 'chiuso_per_morosita'
    },

    operation: {
        'Cambio mobile': 'cambio_mobile',
        'Cambio Linea Fissa': 'cambio_linea_fissa',
        'Cambio Mobile + Linea Fissa': 'cambio_mobile_linea_fissa',
        'Nuova Attivazione': 'nuova_attivazione',
    }
}

const allowedValues = {
    typeClient_f2: ['libero_professionista']
}

// // tipo cliente
// resid,
// business,
// condominio,

// // pagamento
// rid,
// bollettino,
// bonifico,

// // tarifa
// residente,
// non_residente,
// altri_usi,

// // ee product
// codice_pod,

// // gas product
// codice_pdr,

// // products
// gas_numero_utente_contatore_elettronico,
// gas_matricola_contatore_elettronico,
// gas_matricola_contatore_mecanico,

// ee_numero_utente_contatore_elettronico,
// ee_matricola_contatore_elettronico,
// ee_matricola_contatore_mecanico,

// // filters
// allacciamento,
// prima_attivazione,
// subentro,
// subentro_con_contatore_rimosso,
// subentro_con_var_potenza,
// cambio_fornitore,
// cambio_fornitore_con_voltura

const showQuestion = (tooltip, name, value) => {
    if(!tooltip)
        return '';

    if(!name || !tooltip[name])
        return '';

    if(!value || !tooltip[name][value] )
        return '';

    return tooltip[name][value];
}

const showQuestionEnergia = data => {
    let text = '';

    switch(data) {
        case 'luce':
            text = 'Al cliente occorre anche il gas?';
        break;
        case 'gas':
            text = 'Al cliente occorre anche il luce?';
    }

    return text;

}

const fillOperation = data => {
    let text = '';

    switch(data) {
        case 'Mai installato, POD non attribuito':
            text = 'ALLACCIAMENTO';
        break;
        case 'Mai installato, PDR non attribuito':
            text = 'ALLACCIAMENTO';
        break;
        case 'Utenza creata, mai attivata':
            text = 'PRIMA ATTIVAZIONE';
        break;
        case 'Installato e chiuso regolarmente':
            text = 'SUBENTRO';
        break;
        case 'Attivo con altro fornitore, altro intestatario':
            text = 'CAMBIO CON VOLTURA';
        break;
        case 'Attivo a nome del cliente con altro fornitore':
            text = 'CAMBIO FORNITORE';
        break;
        case 'Taglio colonna':
            text = 'SUBENTRO';
        break;
        case 'Contatore rimosso':
            text = 'SUBENTRO';
        break;
        case 'Allacciamento temporaneo':
            text = 'SUBENTRO';
        break;
    }

    return text;
}

const questionsOperation = data => {
    let text = '';

    switch(data) {
        case 'CAMBIO FORNITORE':
            text = 'Current supplier? State of the payments?';
        break;
        case 'CAMBIO CON VOLTURA':
            text = 'do you have the data of the previous customer? Current supplier? State of the payments? Ha i dati catastali?';
        break;
    }

    return text;
}

const questionPayment = data => {
    let text = '';

    switch(data) {
        case 'Bonifico':
            text = 'Conto estero?';
        break;
    }

    return text;
}

const toParseValue = ['energia', 'operationLuce', 'operationGas', 'payment', 'bonus_text_top', 'check_contatore', 'check_distributore', 'bonus_text_bottom'];