import React, { Component } from 'react';
import { 
    Button,
    MenuItem,
    Icon,
    Tooltip
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import UploadImage from '../../dialogs/uploadImage';
import TextField from '@mui/material/TextField';

import axios from 'axios';

import store from '../../../store';
import { 
    OPEN_NOTIFICATION,
    OPEN_LOADING,
    CLOSE_LOADING,
    OPEN_HELP
  } from '../../../actions/actions';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import './NewSupplier.scss';

import { createSupplier, getSupplierById, updateSupplier, uploadLogo } from './../../../service';

class NewSupplier extends Component {
    constructor(props) {
        super(props)

        this.state =  {
            // main data
            name: null,
            code: null,
            description: null,
            upImage: null,
            openUploadPopup: false,

            // true/false data
            non_residente: false,
            condominio: false,
            codice_pod: false,
            residente: false,
            altri_usi: false,
            business: false,
            resid: false,

            // product
            gas_numero_utente_contatore_elettronico: false,
            gas_matricola_contatore_elettronico: false,
            gas_matricola_contatore_mecanico: false,

            ee_numero_utente_contatore_elettronico: false,
            ee_matricola_contatore_elettronico: false,
            ee_matricola_contatore_mecanico: false,

            //filter
            allacciamento: 'nd',
            prima_attivazione: 'nd',
            subentro: 'nd',
            subentro_con_contatore_rimosso: 'nd',
            subentro_con_var_potenza: 'nd',
            cambio_fornitore: 'nd',
            cambio_fornitore_con_voltura: 'nd',

            taglio_colonna: 'nd',
            contatore_rimosso: 'nd',
            chiuso_per_morosita: 'nd',
            nds: 'nd',
            copertura_nazionale: false,



            redirect: false
        }

        this.onChangeBool = this.onChangeBool.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.createSupplier = this.createSupplier.bind(this);
        this.updateSupplier = this.updateSupplier.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.openUplaodPopup = this.openUplaodPopup.bind(this);
        this.closeUpload = this.closeUpload.bind(this);
        this.cropedImage = this.cropedImage.bind(this);
    }

    componentWillMount() {
        const id = this.props.match.params.id;

        if(!id || id === 0)
            return false;

        getSupplierById(id).then(result => {
            const sup = result.data.supplier;

            this.setState({ ...sup });
        }).catch(err => {

        })
    }

    onChangeBool(name) {
        const data = this.state;

        data[name] = !data[name];

        this.setState(data);
    }

    onChangeText(name, val) {
        const data = this.state;

        data[name] = val;

        this.setState(data);
    }

    createSupplier() {
        const { upImage, ...data } = this.state;

        store.dispatch({ type: OPEN_LOADING, text: 'Creating supplier...' });
        createSupplier(data).then(supplier => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Supplier been created', open: true})

            if(upImage) {
                store.dispatch({ type: OPEN_LOADING, text: 'Uploading image...' });

                const data = new FormData();
                fetch(upImage)
                    .then(result => result.blob())
                    .then(imageBlob => {
                        data.append('file', imageBlob, 'logo.jpg');
                        uploadLogo(supplier.data.supplier.id, data).then(result => {
                            store.dispatch({type: OPEN_NOTIFICATION, text: 'Logo been uploaded', open: true})

                            this.setState({redirect: true});

                            store.dispatch({ type: CLOSE_LOADING });
                        }).catch(err => {
                            store.dispatch({ type: OPEN_LOADING, text: `Can't upload image` });
                            console.log(err);

                            setTimeout(() => {
                                store.dispatch({ type: CLOSE_LOADING });
                            }, 2500);
                        });
                    })
                return false;
            }else {
                this.setState({redirect: true});
                store.dispatch({ type: CLOSE_LOADING });
            }
            
        }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't create supplier` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    updateSupplier() {
        const id = this.state.id;
        const data = this.state;
        const upImage = this.state.upImage;

        store.dispatch({ type: OPEN_LOADING, text: 'Updating supplier...' });

        updateSupplier(id , data).then(supplier => {
            store.dispatch({type: OPEN_NOTIFICATION, text: 'Supplier been updated', open: true})
            if(upImage) {
                store.dispatch({ type: OPEN_LOADING, text: 'Uploading image...' });

                const data = new FormData();
                fetch(upImage)
                    .then(result => result.blob())
                    .then(imageBlob => {
                        data.append('file', imageBlob, 'logo.jpg');
                        uploadLogo(supplier.data.supplier.id, data).then(result => {
                            this.setState({redirect: true});

                            store.dispatch({type: OPEN_NOTIFICATION, text: 'Logo been uploaded', open: true})
                            store.dispatch({ type: CLOSE_LOADING });
                        }).catch(err => {
                            store.dispatch({ type: OPEN_LOADING, text: `Can't upload image` });
                            console.log(err);

                            setTimeout(() => {
                                store.dispatch({ type: CLOSE_LOADING });
                            }, 2500);
                        });
                    })
                return false;
            }else {
                this.setState({redirect: true});
                store.dispatch({ type: CLOSE_LOADING });
            }
        }).catch(err => {
            store.dispatch({ type: OPEN_LOADING, text: `Can't create supplier` });
            console.log(err);

            setTimeout(() => {
                store.dispatch({ type: CLOSE_LOADING });
            }, 2500);
        })
    }

    selectChange(name, value) {
        const state = this.state;

        state[name] = value;

        this.setState(state);
    }

    openUplaodPopup() {
        this.setState({openUploadPopup: true});
    }

    closeUpload() {
        this.setState({openUploadPopup: false});
    }

    cropedImage(path) {
        this.closeUpload();
        this.setState({upImage: path});
    }

    createProposal() {
        const data = {
            
        }
    }

    openHelp() {
        store.dispatch({ type: OPEN_HELP, component: 'proposal_energia' });
    }

    render() {
        const redirect = this.state.redirect;

        if(redirect)
            return(
                <Redirect to='/' />
            );

        const { 
            id,
            name,
            code,
            description,
            upImage,
            openUploadPopup,

            // true/false data
            // tipo cliente
            resid,
            business,
            condominio,

            // pagamento
            rid,
            bollettino,
            bonifico,

            // tarifa
            residente,
            non_residente,
            altri_usi,

            // ee product
            codice_pod,

            // gas product
            codice_pdr,
            
            // products
            gas_numero_utente_contatore_elettronico,
            gas_matricola_contatore_elettronico,
            gas_matricola_contatore_mecanico,

            ee_numero_utente_contatore_elettronico,
            ee_matricola_contatore_elettronico,
            ee_matricola_contatore_mecanico,

            // filters
            allacciamento,
            prima_attivazione,
            subentro,
            subentro_con_contatore_rimosso,
            subentro_con_var_potenza,
            cambio_fornitore,
            cambio_fornitore_con_voltura,
            taglio_colonna,
            nds,
            contatore_rimosso,
            chiuso_per_morosita,
            copertura_nazionale

        } = this.state;

        const dissabled = !(name && code && description);

        const filterOptions = ['nd', 'dual', 'luce', 'gas'];

        const { role } = store.getState().auth;

        return (
            <div className='new_supplier_section anim_content'>
                {role !== 'admin' && <div className='disable_screen'></div>}
                <UploadImage open={openUploadPopup} close={this.closeUpload} cropedFile={this.cropedImage}/>
                <div className='main'>
                    <Tooltip title='Help'>
                            <Button className='how_to_button' onClick={this.openHelp} variant="fab" mini aria-label="Edit"><Icon className='offer_icon'>help_outline</Icon></Button>
                    </Tooltip>

                    <div className='fields mainInfo'>
                        <h3 className='head'>Add new supplier</h3>
                        <TextField size="small" type='text' style={style.mainTextField} value={name ? ''+name : ''} label='Supplier Name' onChange={event => { this.onChangeText('name', event.target.value) }} required/>
                        <TextField size="small" type='text' style={style.mainTextField} value={code ? ''+code : ''} label='Code' onChange={event => { this.onChangeText('code', event.target.value) }} required/>
                        <TextField size="small" type='text' style={style.mainTextFieldMult} value={description ? ''+description : ''} multiline rows='5' label='Description' onChange={event => { this.onChangeText('description', event.target.value) }} required/>
                    </div>

                    <div className='upload_image mainInfo'>
                        <h3 className='head'>Upload logo</h3>
                        <div className='image_block'>
                         {upImage 
                            ? <img className='image' src={upImage} onClick={this.openUplaodPopup}/>
                            : <div className='text' onClick={this.openUplaodPopup}>Upload Logo</div>}
                        </div>
                    </div>
                </div>

                <div className='filter_section'>
                    <h3 className='head'>Filters</h3>

                    <div className='content'>
                        <div className='saparetor'>Tipo cliente</div>
                        <div className='filters'>
                            <FormControlLabel className={resid ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={resid} onChange={() => { this.onChangeBool('resid') }} />} label="Resid" />
                            <FormControlLabel className={business ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={business} onChange={() => { this.onChangeBool('business') }} />} label="Business" />
                            <FormControlLabel className={condominio ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={condominio} onChange={() => { this.onChangeBool('condominio') }} />} label="Condominio" />
                        </div>

                        <div className='saparetor'>Tipo di tariffa</div>
                        <div className='filters'>
                            <FormControlLabel className={residente ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={residente} onChange={() => { this.onChangeBool('residente') }} />} label="Residente" />
                            <FormControlLabel className={non_residente ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={non_residente} onChange={() => { this.onChangeBool('non_residente') }} />} label="Non residente" />
                            <FormControlLabel className={altri_usi ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={altri_usi} onChange={() => { this.onChangeBool('altri_usi') }} />} label="Altri usi" />
                        </div>

                        <div className='saparetor'>Dati cont EE</div>
                        <div className='filters -m'>
                            <FormControlLabel className={ee_numero_utente_contatore_elettronico ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={ee_numero_utente_contatore_elettronico} onChange={() => { this.onChangeBool('ee_numero_utente_contatore_elettronico') }} />} label="EE Numero utente contatore elettronico" />
                            <FormControlLabel className={ee_matricola_contatore_elettronico ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={ee_matricola_contatore_elettronico} onChange={() => { this.onChangeBool('ee_matricola_contatore_elettronico') }} />} label="EE Matricola contatore elettronico" />
                            <FormControlLabel className={ee_matricola_contatore_mecanico ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={ee_matricola_contatore_mecanico} onChange={() => { this.onChangeBool('ee_matricola_contatore_mecanico') }} />} label="EE Matricola contatore mecanico" />
                            <FormControlLabel className={codice_pod ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={codice_pod} onChange={() => { this.onChangeBool('codice_pod') }} />} label="Codice POD" />
                        </div>

                        <div className='saparetor'>Dati cont GAS</div>
                        <div className='filters -m'>
                            <FormControlLabel className={gas_numero_utente_contatore_elettronico ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={gas_numero_utente_contatore_elettronico} onChange={() => { this.onChangeBool('gas_numero_utente_contatore_elettronico') }} />} label="GAS Numero utente contatore elettronico" />
                            <FormControlLabel className={gas_matricola_contatore_elettronico ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={gas_matricola_contatore_elettronico} onChange={() => { this.onChangeBool('gas_matricola_contatore_elettronico') }} />} label="GAS Matricola contatore elettronico" />
                            <FormControlLabel className={gas_matricola_contatore_mecanico ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={gas_matricola_contatore_mecanico} onChange={() => { this.onChangeBool('gas_matricola_contatore_mecanico') }} />} label="GAS Matricola contatore mecanico" />
                            <FormControlLabel className={codice_pdr ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={codice_pdr} onChange={() => { this.onChangeBool('codice_pdr') }} />} label="Codice PDR" />
                        </div>

                        <div className='saparetor'>Tipo di operazioni</div>
                        <div className='filters -not'>
                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Allacciamento"
                                    className='select'
                                    value={allacciamento}
                                    onChange={ event => this.selectChange('allacciamento', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Prima attivazione"
                                    className='select'
                                    value={prima_attivazione}
                                    onChange={ event => this.selectChange('prima_attivazione', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Subentro"
                                    className='select'
                                    value={subentro}
                                    onChange={ event => this.selectChange('subentro', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Subentro con contatore rimosso"
                                    className='select'
                                    value={subentro_con_contatore_rimosso}
                                    onChange={ event => this.selectChange('subentro_con_contatore_rimosso', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Subentro con var potenza"
                                    className='select'
                                    value={subentro_con_var_potenza}
                                    onChange={ event => this.selectChange('subentro_con_var_potenza', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Cambio fornitore"
                                    className='select'
                                    value={cambio_fornitore}
                                    onChange={ event => this.selectChange('cambio_fornitore', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Cambio fornitore con voltura"
                                    className='select'
                                    value={cambio_fornitore_con_voltura}
                                    onChange={ event => this.selectChange('cambio_fornitore_con_voltura', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Taglio colonna"
                                    className='select'
                                    value={taglio_colonna}
                                    onChange={ event => this.selectChange('taglio_colonna', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Contatore rimosso"
                                    className='select'
                                    value={contatore_rimosso}
                                    onChange={ event => this.selectChange('contatore_rimosso', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            <div className='select_block'>
                                <TextField
                                    select
                                    label="Allacciamento temporaneo"
                                    className='select'
                                    value={chiuso_per_morosita}
                                    onChange={ event => this.selectChange('chiuso_per_morosita', event.target.value)}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>

                            {/* <div className='select_block'>
                                <TextField
                                    select
                                    label="NDS"
                                    className='select'
                                    value={nds}
                                    onChange={ event => this.selectChange('nds', event.target.value)}
                                    margin="normal"
                                    variant="filled"
                                    >
                                    {filterOptions.map(option => (
                                        <MenuItem key={option} value={option}>
                                        {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div> */}
                        </div>

                        <div className='filters coperturaNazional'>
                            <FormControlLabel className={copertura_nazionale ? "-superUser defaultCheckbox--checked" : "-superUser defaultCheckbox"} control={<Checkbox checked={copertura_nazionale} onChange={() => { this.onChangeBool('copertura_nazionale') }} />} label="Copertura Nazionale" />
                        </div>

                    </div>
                    {role === 'admin' && <div className='action_section'>
                        { id
                            ? <Button style={dissabled ? style.createButtonDissabled : style.createButton} disabled={dissabled} onClick={this.updateSupplier}>Update</Button>
                            : <Button style={dissabled ? style.createButtonDissabled : style.createButton} disabled={dissabled} onClick={this.createSupplier}>Create</Button>
                        }
                    </div> }
                </div>
            </div>
        )
    }
}

export default NewSupplier;

const calcWithW = (num) => {
    return `calc(${num}vw - 220px)`;
}

const style = {
    mainTextField: {
        minWidth: '350px',
        height: '60px',
        marginBottom: '10px'
    },

    mainTextFieldMult: {
        minWidth: '350px',
        marginTop: '10px'
    },

    createButton: {
        width: '200px',
        height: '40px',
        background: '#417BC3',
        color: 'white'
    },

    createButtonDissabled: {
        width: '200px',
        height: '40px',
        background: '#8090a396',
        color: 'white'
    }
}